import { Text } from '@nike/eds';
import { track } from '../utils/analytics';
import camelCase from 'lodash.camelcase';

// used for logging the number of filters applied
function get_value_count(filter, event) {
  if (filter?.facet_type === 'select') {
    return event?.length;
  } else if (filter?.facet_type === 'toggle') {
    return event?.target?.checked === true ? 1 : 0;
  } else if (filter?.facet_type === 'checkbox') {
    return event?.target?.checked === true ? 1 : 0;
  } else if (filter?.facet_type === 'min_max') {
    return event?.minValue && event?.maxValue ? 2 : event?.minValue || event?.maxValue ? 1 : 0;
  } else if (filter?.facet_type === 'checkbox_group') {
    return event?.target?.checked === true ? 1 : 0;
  }
}

export default function Filters({ filters }) {
  return (
    <div data-testid="filter-list-header">
      {filters?.map(filter => {
        const props = { ...filter?.metadata?.props } || {};
        const label = filter?.display_name;
        // used for any select drop downs
        const options =
          props?.facets?.map(item => {
            return { value: item.name, label: item.name };
          }) || [];

        const data_massager = filter?.metadata?.data_massager;

        return (
          <div key={label} className="filter">
            <Text font="subtitle-1" className="filter-label">
              {label}
            </Text>
            <div className="filter-input">
              {filter?.template({
                ...props,
                options,
                onChange: event => {
                  track({
                    eventName: 'filterMaterials',
                    fieldName: camelCase(filter?.display_name),
                    numFilters: get_value_count(filter, event),
                  });

                  data_massager(event);
                },
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
