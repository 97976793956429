import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useLibraryFilterStore } from './use_library_filters';
import { usePaletteFilterStore } from './use_palette_filters';
import { useBoardFilterStore } from './use_board_filters';

export const useFacetStore = create(
  devtools(
    (set, get) => ({
      library_facets: {},
      library_facets_loaded: false,
      palette_facets: {}, // list of palette facets the user has access to
      board_facets: {},
      set_library_facets: async data => {
        const store = useLibraryFilterStore.getState();
        const update_filter = store.update;
        const list = Object.values(store.list);

        for (const [key, value] of Object.entries(data)) {
          const list_item = list.find(item => item.facet_property_name === key);

          if (list_item?.id) {
            update_filter(list_item?.id, {
              ...list_item,
              metadata: {
                ...list_item?.metadata,
                props: {
                  ...list_item?.metadata?.props,
                  facets: value,
                },
              },
            });
          }
        }

        const current_library_facets = get().library_facets;
        set({ library_facets: { ...current_library_facets, ...data } });
      },
      set_library_facets_loaded: () => {
        set({ library_facets_loaded: true });
      },
      set_palette_facets: async data => {
        const store = usePaletteFilterStore.getState();
        const update_filter = store.update;
        const list = Object.values(store.list);

        for (const [key, value] of Object.entries(data)) {
          const list_item = list.find(item => item.facet_property_name === key);

          if (list_item?.id) {
            update_filter(list_item?.id, {
              ...list_item,
              metadata: {
                ...list_item?.metadata,
                props: {
                  ...list_item?.metadata?.props,
                  facets: value,
                },
              },
            });
          }
        }

        const current_palette_facets = get().palette_facets;
        set({ palette_facets: { ...current_palette_facets, ...data } });
      },
      set_board_facets: async data => {
        const store = useBoardFilterStore.getState();
        const update_filter = store.update;
        const list = Object.values(store.list);

        for (const [key, value] of Object.entries(data)) {
          const list_item = list.find(item => item.facet_property_name === key);

          if (list_item?.id) {
            update_filter(list_item?.id, {
              ...list_item,
              metadata: {
                ...list_item?.metadata,
                props: {
                  ...list_item?.metadata?.props,
                  facets: value,
                },
              },
            });
          }
        }

        const current_board_facets = get().board_facets;
        set({ board_facets: { ...current_board_facets, ...data } });
      },
    }),
    { name: 'facets' }
  )
);
