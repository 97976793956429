import Droppable from '../droppable';
import { Icon, Select, Tag, Text, TextField } from '@nike/eds';
import { useAutoSections, autoSectionTypeOptions } from '../../hooks/use_auto_sections';
import { arrayMove } from '@dnd-kit/sortable';
import { DndContext, useDraggable, DragOverlay } from '@dnd-kit/core';
import { useState } from 'react';
import useColorScheme from '../../hooks/use_color_scheme';

function matchingSection(targetId) {
  return section => {
    return targetId === section?.id;
  };
}

export function AutoSections({
  sections,
  activeAutoSections,
  activeSectionType,
  reorderSections,
  onActiveAutoSectionTypeChange,
}) {
  const { setActiveSectionType } = useAutoSections();

  const [activeId, setActiveId] = useState(null);

  function handleDragStart(event) {
    setActiveId(event.active?.id);
  }

  function handleDragEnd(event) {
    const dropTargetId = event.over?.id;
    const dragTargetId = event.active?.id;

    const oldIndex = sections.findIndex(matchingSection(dragTargetId));
    const newIndex = sections.findIndex(matchingSection(dropTargetId));
    const newArr = arrayMove(sections, oldIndex, newIndex);
    reorderSections(newArr);
  }

  function handleAutoSectionTypeChange(value) {
    setActiveSectionType(value);
    onActiveAutoSectionTypeChange();
  }

  return (
    <div className="auto-sections">
      <Tag color="purple" size="small">
        AUTO SECTION
      </Tag>
      <Select
        className="eds-spacing--mb-24"
        value={autoSectionTypeOptions.find(option => option.value === activeSectionType) || null}
        options={autoSectionTypeOptions}
        onChange={item => handleAutoSectionTypeChange(item.value)}
      />
      <Text font="legal-1">
        By selecting an auto-section option, your board will automatically section itself
        accordingly as well as place all respective materials in those sections. If there are
        materials that do not fit in one of the sections, they will appear at the bottom of the
        board in a section titled, “Other Materials.”
      </Text>
      {activeAutoSections?.length > 0 ? (
        <div className="auto-sections__items">
          <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            {activeAutoSections?.map(section => (
              <Droppable key={section.id} id={section.id} data={section}>
                <AutoSection key={section?.id} section={section} />
              </Droppable>
            ))}

            <DragOverlay>
              {activeAutoSections
                ?.filter(section => section.id === activeId)
                .map(section => (
                  <AutoSection section={section} key={`drag-overlay-auto-section-${section?.id}`} />
                ))}
            </DragOverlay>
          </DndContext>
        </div>
      ) : undefined}
    </div>
  );
}

export function AutoSection({ section }) {
  const { attributes, listeners, setNodeRef } = useDraggable({ id: section?.id });
  const { appearance } = useColorScheme();
  return (
    <div className="auto-section" ref={setNodeRef}>
      <div className="auto-section__name">
        <TextField
          disabled
          placeholder={section?.label || 'Section Name'}
          message=" "
          value={section?.label || ''}
        />
      </div>

      <div className="auto-section__drag-handle" {...listeners} {...attributes} role="button">
        <Icon
          name="EllipsisVertical"
          size="m"
          color={appearance === 'dark' ? 'white' : 'black'}
          style={{ marginRight: '-7px' }}
        />
        <Icon
          name="EllipsisVertical"
          size="m"
          color={appearance === 'dark' ? 'white' : 'black'}
          style={{ marginLeft: '-7px' }}
        />
      </div>
    </div>
  );
}
