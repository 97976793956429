import { Text, Icon } from '@nike/eds';
import React from 'react';
import useColorScheme from '../../hooks/use_color_scheme';

export const AccessDenied = () => {
  const { appearance } = useColorScheme();

  return (
    <div
      className={`page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
      data-testid="access-denied"
    >
      <div className="page-header"></div>
      <div className="centered" style={{ maxWidth: 470, margin: 'auto', textAlign: 'center' }}>
        <Icon
          name="Null"
          size="m"
          backgroundShape="circle"
          backgroundColor="var(--eds-color-text-default)"
          color="var(--eds-color-background-default)"
        />
        <Text font="title-4" className="eds-spacing--mt-24 eds-spacing--mb-24">
          Access Denied
        </Text>
        <Text font="body-2" className="eds-spacing--mb-24" color="secondary">
          It looks like you do not have access to the Nike MaterialHUB. If you believe that this is
          a mistake, please contact our team for help. If you need to request access, pleas visit
          NikeNow.
        </Text>
      </div>
    </div>
  );
};
