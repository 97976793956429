import { Menu } from '@nike/eds';
import { useLayoutEffect } from 'react';

export function FixedContextMenu({
  isOpen,
  onClickOutside,
  position = { x: 0, y: 0 },
  bodySlot,
  headerSlot,
  footerSlot,
  ...rest
}) {
  // prevent scrolling when the menu is open since it's fixed and will keep position if the user scrolls
  // right now this is only being used within a grid context so we may need to revisit this if it's used elsewhere
  useLayoutEffect(() => {
    try {
      const grid = document.querySelector('.grid');
      if (isOpen && grid) {
        grid.style.overflow = 'hidden';
      } else if (grid) {
        grid.style.overflow = 'auto';
      }
    } catch (error) {
      console.error('Error setting grid overflow while context menu is open', error);
    }
  }, [isOpen]);

  const modifiers = [
    {
      name: 'context_menu_position',
      enabled: true,
      phase: 'main',
      fn({ state }) {
        state.options.strategy = 'fixed';

        const menu_width = state?.rects?.reference?.width;

        // if the menu is going to be off the screen, move it to the left
        if (position?.x + menu_width > window.innerWidth) {
          state.modifiersData.popperOffsets.x = position?.x - menu_width - 60;
        } else {
          state.modifiersData.popperOffsets.x = position?.x + 20;
        }

        state.modifiersData.popperOffsets.y = position?.y;

        return state;
      },
    },
  ];

  return (
    <Menu
      headerSlot={headerSlot}
      bodySlot={bodySlot}
      footerSlot={footerSlot}
      isOpen={isOpen}
      onClickOutside={onClickOutside}
      modifiers={modifiers}
      placement="right"
      {...rest}
    />
  );
}
