import { useState } from 'react';
import { IconButton, Popover } from '@nike/eds';
import { useRecentBoardsHook } from '../../../hooks/use_boards';
import { use_auth_store } from '../../../hooks/use_auth';
import { use_engine_store } from '../../../hooks/use_engine';
import { MaterialActionsMenu } from '../material_actions_menu/material_actions_menu';

import './material_actions.css';
import { useFeaturesStore } from '../../../hooks/use_features';

export function MaterialActions({
  material,
  appearance,
  actions,
  show_card_actions,
  onMaterialAction,
  sections = [],
}) {
  const [is_open, set_is_open] = useState(false);
  const [is_board_menu_open, set_is_board_menu_open] = useState(false);
  const [is_sections_menu_open, set_is_sections_menu_open] = useState(false);
  const { user } = use_auth_store();
  const { active_engine } = use_engine_store();
  const { features } = useFeaturesStore();

  const { data } = useRecentBoardsHook({
    createdByUserId: user?.preferred_username,
    division: `${active_engine} DIVISION`,
  });
  return (
    // we're setting the background explicitly here because toggling the background and button appearance kinda breaks the way light/dark mode usually works
    <div
      className="material-card__actions"
      style={{
        // we can rip this out once we add a 3rd aciton to this actions list
        background: 'transparent',
        // appearance === 'dark' ? 'var(--eds-color-text-default)' : 'var(--eds-color-text-default)',
      }}
    >
      <MaterialActionsMenu
        material={material}
        appearance={appearance}
        actions={{
          ...actions,
        }}
        show_card_actions={show_card_actions}
        is_open={is_open}
        set_is_open={set_is_open}
        is_board_menu_open={is_board_menu_open}
        set_is_board_menu_open={set_is_board_menu_open}
        recent_boards={data?.results || []}
        is_sections_menu_open={is_sections_menu_open}
        set_is_sections_menu_open={set_is_sections_menu_open}
        sections={sections}
        onUpdateMaterial={onMaterialAction}
      >
        <Popover isOpen={is_open} isDark={appearance === 'dark' ? false : true}>
          <IconButton
            icon="Ellipsis"
            onClick={e => {
              e.stopPropagation();
              set_is_open(!is_open);
            }}
          />
        </Popover>
      </MaterialActionsMenu>

      {/* we can rip this out once we add a 3rd action to this actions list */}
      <div style={{ width: 64, height: 48 }}></div>
      {features?.favorites ? (
        <IconButton
          icon="Favorite"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      ) : undefined}
    </div>
  );
}
