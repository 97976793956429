import useColorScheme from '../../hooks/use_color_scheme';
import { Icon, Text } from '@nike/eds';

import './empty_tote.css';

export default function EmptyTote() {
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';

  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`}>
      <div className="empty-tote-page page-contents">
        <Icon size="l" name="Bag" className="icon" />
        <Text className="empty-tote__header" font={'title-5'} as={'h1'}>
          Tote is Empty
        </Text>
        <Text className="empty-tote-page__body" font={'body-3'} as={'p'}>
          Add materials to your tote by selecting options on a material and <br />
          selecting “Add To...”, then “Tote.” You can also multi-select materials <br />
          and add multiple materials to your tote at once. <br />
        </Text>
      </div>
    </div>
  );
}
