import { useState, useEffect, useRef } from 'react';
import { Text, Card, Divider, Icon, Button, Tab, TabGroup, Link, Popover } from '@nike/eds';
import OutsideClickHandler from 'react-outside-click-handler';

import useColorScheme, { useColorSchemeStore } from '../../hooks/use_color_scheme';
import { use_engine_store } from '../../hooks/use_engine';
import { Header } from '../../components/header';

import system from '../../assets/default_mode.svg';
import light_mode from '../../assets/light_mode.svg';
import dark_mode from '../../assets/dark_mode.svg';
import settings_white_checked from '../../assets/settings_white_checked.svg';

import './settings.css';
import { track } from '../../utils/analytics';

// little unmount hook. I'm not sure if this is the best way to do this, but it works for now.
const useUnmount = fn => {
  const fn_ref = useRef(fn);
  fn_ref.current = fn;

  useEffect(() => () => fn_ref.current(), []);
};

export default function Settings({ title }) {
  const { appearance } = useColorScheme();
  const { active_engine, set_active_engine, engines } = use_engine_store();
  const { set_system_color_scheme_override, set_color_scheme, color_scheme } =
    useColorSchemeStore();

  const [selected_mode, set_selected_mode] = useState(color_scheme);
  const [engine, set_engine] = useState(active_engine);
  const [is_accessories_popover_open, set_is_accessories_popover_open] = useState(false);
  const [is_footwear_popover_open, set_is_footwear_popover_open] = useState(false);
  const [is_apparel_popover_open, set_is_apparel_popover_open] = useState(false);

  useUnmount(() => {
    try {
      track({
        eventName: 'appSettings',
        settingsAppearance: selected_mode,
        settingsEngine: engine?.toLowerCase(),
      });
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    set_color_scheme(selected_mode);

    if (selected_mode === 'system') {
      set_system_color_scheme_override(false);
    } else {
      set_system_color_scheme_override(true);
    }
  }, [selected_mode, set_system_color_scheme_override, set_color_scheme]);

  useEffect(() => {
    set_active_engine(engine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine]);

  const show_popover = tab => {
    switch (tab) {
      case 'FOOTWEAR':
        set_is_footwear_popover_open(true);
        set_is_accessories_popover_open(false);
        set_is_apparel_popover_open(false);

        break;
      case 'APPAREL':
        set_is_footwear_popover_open(false);
        set_is_accessories_popover_open(false);
        set_is_apparel_popover_open(true);
        break;
      case 'ACCESSORIES':
        set_is_footwear_popover_open(false);
        set_is_accessories_popover_open(true);
        set_is_apparel_popover_open(false);
        break;
      default:
        return;
    }
  };

  const hide_popover = tab => {
    switch (tab) {
      case 'FOOTWEAR':
        set_is_footwear_popover_open(false);
        break;
      case 'APPAREL':
        set_is_apparel_popover_open(false);
        break;
      case 'ACCESSORIES':
        set_is_accessories_popover_open(false);
        break;
      default:
        return;
    }
  };

  return (
    <div className={`page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'} settings`}>
      <div className="page-header">
        {/* TODO: refactor header_bottom to be a render prop */}
        <Header
          title={title}
          show_search={false}
          show_tote={false}
          header_bottom={() => undefined}
          icon_row={{ filter: false, sort: false, select: false, display_settings: false }}
        />
      </div>
      <div className="page-contents">
        <Card
          style={{ padding: 24, borderRadius: 0 }}
          padding={0}
          id="app-appearance"
          className="appearance-card eds-spacing--mb-36"
        >
          <Text font="title-5">Appearance</Text>
          <Divider className="eds-spacing--mb-24 eds-spacing--mt-24" />

          <div className="appearance-card__options">
            <div id="system-preference">
              <Button
                variant="ghost"
                onClick={() => set_selected_mode('system')}
                className={
                  selected_mode === 'system'
                    ? 'appearance-card__option-selected'
                    : 'appearance-card__option'
                }
              >
                <img src={system} alt="system color mode" />
                {selected_mode === 'system' && <SelectedIcon />}
              </Button>
              <Text font="subtitle-1" className="eds-spacing--mt-12">
                System Preference
              </Text>
            </div>
            <div id="light-mode">
              <Button
                variant="ghost"
                onClick={() => set_selected_mode('light')}
                className={
                  selected_mode === 'light'
                    ? 'appearance-card__option-selected'
                    : 'appearance-card__option'
                }
              >
                <img src={light_mode} alt="light color mode" />
                {selected_mode === 'light' && <SelectedIcon />}
              </Button>
              <Text font="subtitle-1" className="eds-spacing--mt-12">
                Light
              </Text>
            </div>

            <div id="dark-mode">
              <Button
                variant="ghost"
                onClick={() => set_selected_mode('dark')}
                className={
                  selected_mode === 'dark'
                    ? 'appearance-card__option-selected'
                    : 'appearance-card__option'
                }
              >
                <img src={dark_mode} alt="dark color mode" />
                {selected_mode === 'dark' && <SelectedIcon />}
              </Button>
              <Text font="subtitle-1" className="eds-spacing--mt-12">
                Dark
              </Text>
            </div>
          </div>
        </Card>
        {/* engine */}
        <Card style={{ padding: 24, borderRadius: 0 }} padding={0} className="engine-card">
          <Text font="title-5">Engine</Text>
          <Divider className="eds-spacing--mb-24 eds-spacing--mt-24" />
          <TabGroup
            name="engine-button-group"
            activeId={active_engine}
            onChange={e => set_engine(e.target.id)}
            variant="toggle"
          >
            <Tab disabled={!engines?.includes('FOOTWEAR')} id="FOOTWEAR">
              <EngineTabContents
                show_popover={show_popover}
                hide_popover={hide_popover}
                is_popover_open={is_footwear_popover_open}
                engines={engines}
                icon_name="Run"
                engine="FOOTWEAR"
              />
            </Tab>
            <Tab id="APPAREL" disabled={!engines?.includes('APPAREL')}>
              <EngineTabContents
                show_popover={show_popover}
                hide_popover={hide_popover}
                is_popover_open={is_apparel_popover_open}
                engines={engines}
                icon_name="MuscleGroup"
                engine="APPAREL"
              />
            </Tab>
            <Tab id="ACCESSORIES" disabled={!engines?.includes('ACCESSORIES')}>
              <EngineTabContents
                show_popover={show_popover}
                hide_popover={hide_popover}
                is_popover_open={is_accessories_popover_open}
                engines={engines}
                icon_name="WorkoutEquipment"
                engine="ACCESSORIES"
              />
            </Tab>
          </TabGroup>
          <div className="engine-card__description">
            <Text font="legal-1" className="eds-spacing--mt-24" color="grey-1">
              Your selected Engine Material Library will be shown throughout your Nike Material Hub
              experience. Once an Engine Library is selected, you will still be able to see
              materials not from your selected engine through boards or direct links that are shared
              with you.
            </Text>
          </div>
        </Card>
      </div>
    </div>
  );
}

const EngineTabContents = ({
  engines,
  engine,
  icon_name,
  is_popover_open,
  show_popover,
  hide_popover,
}) => {
  const disabled = !engines?.includes(engine);

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => hide_popover(engine)}>
        <div className="engine-card__tab">
          {disabled ? (
            <>
              <Popover
                isDark
                isOpen={is_popover_open}
                bodySlot={
                  <div className="no-access-tooltip-content">
                    <Text font="body-3">
                      It looks like you dont have access to the {engine} Library. If you need access
                      please,
                      <Link
                        target="_blank"
                        href="https://niketech.service-now.com/nikenow?id=sc_cat_item&table=sc_cat_item&sys_id=62060ef71b2a50108d9521fcbc4bcb43"
                        onClick={() => hide_popover(engine)}
                      >
                        {' submit your request here'}
                      </Link>
                    </Text>
                    <Text font="legal-1" color="grey-2" className="eds-spacing--mt-16">
                      Not having access to an Engine Library will not prohibit you from seeing those
                      materials in boards or links to materials that are shared with you.
                    </Text>
                  </div>
                }
                placement={'right'}
                enableFocus
              >
                <Icon
                  name="Lock"
                  size="m"
                  className="eds-spacing--mr-8"
                  onMouseEnter={() => show_popover(engine)}
                />
              </Popover>
            </>
          ) : (
            <Icon name={icon_name} size="m" className="eds-spacing--mr-8" />
          )}
          <Text font="subtitle-1" className="engine-card__tab-text">
            {engine.toLowerCase()}
          </Text>
        </div>
      </OutsideClickHandler>
    </>
  );
};

const SelectedIcon = () => {
  return (
    <div className="appearance-card__option-icon">
      <img src={settings_white_checked} alt="Selected Icon" />
    </div>
  );
};
