import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { Card, Text } from '@nike/eds';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';

export function DNDGrid({ data = [], set_data, card_size, gap = 24, ...rest }) {
  const { sizes, size } = useDisplaySettingsStore();
  const size_in_pixels = card_size || sizes[size];
  const [active_id, set_active_id] = useState(null);
  const pointer_sensor = useSensor(MyPointerSensor, {
    activationConstraint: {
      distance: 8,
    },
  });
  const sensors = useSensors(
    pointer_sensor,
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handle_drag_start = event => {
    set_active_id(event.active.id);
  };

  const handle_drag_end = event => {
    set_active_id(null);
    const { active, over } = event;

    if (active.id !== over.id) {
      const new_data_set = data => {
        const old_index = data.findIndex(mat => mat?.id === active?.id);
        const new_index = data.findIndex(mat => mat?.id === over?.id);

        return arrayMove(data, old_index, new_index);
      };

      set_data(new_data_set(data));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handle_drag_end}
      onDragStart={handle_drag_start}
    >
      <SortableContext items={data} strategy={rectSortingStrategy}>
        <ol
          className={`eds-spacing--mt-16 eds-spacing--mb-24 material-list`}
          data-testid="grid-list"
          style={{
            gap: gap,
            gridTemplateColumns: `repeat(auto-fill, minmax(${size_in_pixels}px, 1fr))`,
          }}
        >
          {/* rest should contain the same props we pass down to cards in the StaticGrid for the cards we render */}
          {data.map((sortable_item_data, index) => (
            <SortableItem key={index} index={index} item={sortable_item_data} {...rest} />
          ))}
          <DragOverlay>
            {active_id ? (
              <Card
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'var(--eds-color-background-active)',
                  opacity: 0.8,
                  borderRadius: 0,
                }}
              ></Card>
            ) : null}
          </DragOverlay>
        </ol>
      </SortableContext>
    </DndContext>
  );
}

const SortableItem = ({ item, index, ...rest }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    height: '100%',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
    borderRadius: 0,
  };
  const { card, card_element, select_material } = rest;

  if (card) {
    return (
      <div ref={setNodeRef} style={style}>
        <div {...listeners} {...attributes}>
          {card(item, card_element, select_material)}
        </div>
      </div>
    );
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div {...listeners} {...attributes}>
        <Card
          key={index}
          data-testid={item.id}
          padding={16}
          style={{ width: 184, height: 280, borderRadius: 0 }}
        >
          <Text font="body-2">{item.id}</Text>
        </Card>
      </div>
    </div>
  );
};

class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target)) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  // adding this span allows us to still interact with the card action items
  const interactiveElements = ['button', 'input', 'textarea', 'select', 'option', 'span'];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}
