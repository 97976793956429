import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';

import { AuthGroups, RequireOktaAuth } from './utils/RequireOktaAuth';

import Library from './pages/library/library';
import Palettes from './pages/palettes/palettes';
import Boards from './pages/boards/boards';
import Settings from './pages/settings/settings';
import Material from './pages/material/material';
import Features from './pages/features/features';
import Playground from './pages/playground/playground';
import Compare from './pages/compare/compare';
import Tote from './pages/tote/tote';

import { MaterialCollection } from './components/material_collection/material_collection';
import { SideNav } from './components/side_nav';
import { getPaletteSummaries } from './hooks/use_palettes';
import { Login } from './components/Login';
import { use_auth_store } from './hooks/use_auth';
import { fetch_materials } from './hooks/use_materials';
import { getBoardSummaries, getBoardEntries } from './hooks/use_boards';
import { useFacetStore } from './hooks/use_facets';
import { use_engine_store } from './hooks/use_engine';
import { MaterialModal } from './components/material_details/material_modal';
import ExportModal from './components/export/export_modal';
import { AddToBoardModal } from './components/add_to_board/add_to_board_modal';
import { CreateBoardModal } from './components/create_board/create_board_modal';
import PrintModal from './components/print/print_modal';
import Upload from './pages/upload/upload';
import { MoveToModal } from './components/common/move_to_modal';
import Board from './components/common/board';
import { DragSelectProvider } from './components/drag_select';
import { useSelectStore } from './hooks/use_select';
const { multi_select } = useSelectStore.getState();
const { user } = use_auth_store.getState();

function DefaultAuth({ children }) {
  return (
    <RequireOktaAuth
      allowGroups={[
        AuthGroups.ACC_USER,
        AuthGroups.FW_USER,
        AuthGroups.FW_ADMIN,
        AuthGroups.AP_USER,
        AuthGroups.AP_ADMIN,
      ]}
    >
      {children}
    </RequireOktaAuth>
  );
}

function UploadAuth({ children }) {
  return (
    <RequireOktaAuth allowGroups={[AuthGroups.AP_ADMIN, AuthGroups.FW_ADMIN]}>
      {children}
    </RequireOktaAuth>
  );
}

async function load_library_facet_data() {
  try {
    const { active_engine } = use_engine_store.getState();
    const { library_facets, set_library_facets, library_facets_loaded, set_library_facets_loaded } =
      useFacetStore.getState();
    if (!library_facets_loaded) {
      const res = await fetch_materials({
        size: 1,
      });
      const facets = res?.facets;

      if (!Object.values(library_facets)?.length || active_engine !== library_facets?.engine) {
        facets.engine = active_engine;

        await set_library_facets(facets);
        set_library_facets_loaded();
        return facets;
      }
    } else {
      return library_facets;
    }
  } catch (error) {
    console.warn(error);
    // doing this allows the normal okta flow to continue instead of causing a mid flight error that results in the route being reset
    return null;
  }
}

async function library_loader() {
  return await load_library_facet_data();
}

async function material_loader({ params }) {
  try {
    const res = await fetch_materials({
      id: params?.material_id,
      size: 10,
    });
    const summary = res?.results?.[0];

    return summary;
  } catch (error) {
    console.warn(error);
    // doing this allows the normal okta flow to continue instead of causing a mid flight error that results in the route being reset
    return null;
  }
}

async function palettes_loader() {
  try {
    const { active_engine } = use_engine_store.getState();
    const { palette_facets, set_palette_facets } = useFacetStore.getState();
    const res = await getPaletteSummaries({
      active: true,
      paletteClass: 'material',
      size: 1,
      source: 'PCX',
    });
    const summary = res?.results?.[0];
    const facets = res?.facets;

    if (!Object.values(palette_facets)?.length || active_engine !== palette_facets?.engine) {
      facets.engine = active_engine;

      await set_palette_facets(facets);
    }

    return summary;
  } catch (error) {
    console.warn(error);
    // doing this allows the normal okta flow to continue instead of causing a mid flight error that results in the route being reset
    return null;
  }
}

async function palette_loader({ params }) {
  try {
    // need to load library facet data since the palette page uses these same facet options
    await load_library_facet_data();

    const res = await getPaletteSummaries({
      paletteId: params?.palette_id,
      active: true,
      paletteClass: 'material',
      size: 10,
      source: 'PCX',
    });
    const summary = res?.results?.[0];

    return summary;
  } catch (error) {
    console.warn(error);
    // doing this allows the normal okta flow to continue instead of causing a mid flight error that results in the route being reset
    return null;
  }
}

async function board_loader({ params }) {
  try {
    const res = await getBoardSummaries({
      paletteId: params?.palette_id,
      active: true,
      paletteClass: 'material',
      size: 10,
      source: 'DPC',
    });

    // fetch all materials for the board
    let from = 0;
    const size = 100;
    const initial_data = await getBoardEntries({ size, from }, params?.palette_id);
    const total_materials = initial_data?.pagination?.total;
    const has_materials = total_materials > 0 ? true : false;
    let materials = initial_data?.results;

    // fetch all materials for the board if there are more than 100 materials
    while (materials?.length < total_materials) {
      from += size;
      const data = await getBoardEntries({ size, from }, params?.palette_id);
      materials.push(...data?.results);
    }

    const boardData = res?.results?.[0];

    const summary = { ...boardData, has_materials, materials };

    return summary;
  } catch (error) {
    console.warn(error);
    // doing this allows the normal okta flow to continue instead of causing a mid flight error that results in the route being reset
    return null;
  }
}

function Home({ title }) {
  return (
    <DefaultAuth>
      <SideNav user={user} />
      <Library title={title} />
    </DefaultAuth>
  );
}

export const router = createBrowserRouter(
  [
    {
      path: '/',
      exact: true,
      loader: ({ request }) => {
        const url = request?.url;
        const origin = window?.location?.origin;

        if (url === `${origin}/`) {
          return redirect('/library');
        } else {
          return null;
        }
      },
      element: (
        <DefaultAuth>
          <Outlet />
          <ExportModal />
          <MaterialModal />
          <PrintModal />
          <AddToBoardModal />
          <CreateBoardModal />
          <MoveToModal />
        </DefaultAuth>
      ),
      children: [
        // downside w/ this approach is that we lose scroll restoration BUT this does allow us to render a modal as a route w/ the library or previous route in the background
        // {
        //   path: 'details/:material_id',
        //   loader: material_loader,
        //   element: <MaterialModal />,
        // },
        {
          path: 'library',
          loader: library_loader,
          element: <Home title="Material Library" />,
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'material/:material_id',
          loader: material_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Material />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'palettes',
          loader: palettes_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Palettes title="Palettes" />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'boards',
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Boards title="Boards" />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'compare',
          // loader: palette_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Compare title="Material Comparison" bread_crumb />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'tote',
          // loader: palette_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Tote title="Tote" bread_crumb />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'settings',
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Settings title="Material Hub Settings" />
            </DefaultAuth>
          ),
        },
        {
          path: 'features',
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <Features title="Material Hub Feature Flags" />
            </DefaultAuth>
          ),
        },
        {
          path: 'playground',
          element: (
            <RequireOktaAuth
              allowGroups={[AuthGroups.ACC_USER, AuthGroups.FW_ADMIN, AuthGroups.AP_ADMIN]}
            >
              <SideNav user={user} />
              <Playground title="Material Hub Playground" />
            </RequireOktaAuth>
          ),
        },
        {
          path: 'palettes/:palette_id',
          loader: palette_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <MaterialCollection title="Palettes" bread_crumb source="palette" />
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: 'boards/:palette_id',
          loader: board_loader,
          element: (
            <DefaultAuth>
              <SideNav user={user} />
              <DragSelectProvider multi_select={multi_select}>
                <Board title="Board" />
              </DragSelectProvider>
            </DefaultAuth>
          ),
          // since we're using a loader we need to be able to trigger an okta auth flow if it throws a 401 error
          // https://reactrouter.com/en/main/route/error-element
          errorElement: <Login user={user} />,
        },
        {
          path: '/upload',
          element: (
            <UploadAuth>
              <SideNav user={user}></SideNav>
              <Upload title="Upload Material/Item Files" />
            </UploadAuth>
          ),
        },
      ],
    },

    {
      path: '/implicit/callback',
      element: <Login user={user} />,
    },
  ],
  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  }
);
