import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useModalStore = create(
  devtools(
    set => ({
      active_modal: undefined, // string value of which modal you want to show up by name 'export', 'tote', etc.
      modal_data: {},
      trigger_location: undefined, // string value of where the modal was triggered from 'material_details', 'material_card', etc. used for tracking purposes
      set_active_modal: modal => {
        set({ active_modal: modal });
      },
      dismiss_modal: () => {
        set({
          active_modal: undefined,
          modal_data: {},
        });
      },
      set_modal_data: data => {
        set({ modal_data: data });
      },
      set_trigger_location: location => {
        set({ trigger_location: location });
      },
    }),
    { name: 'modal' }
  )
);
