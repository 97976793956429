import { Table, TableCell, TableHeading, Spinner, Text, Icon } from '@nike/eds';
import InfiniteScroll from 'react-infinite-scroller';
import { useUploadHistoryHook } from '../../hooks/use_files';

import { useEffect, useState } from 'react';

import './upload_history.css';

export function UploadHistory() {
  const [uploadHistory, setUploadHistory] = useState([]);

  const { data, fetchNextPage, hasNextPage } = useUploadHistoryHook();

  useEffect(() => {
    const firstPage = data?.pages?.[0]?.results || [];

    if (firstPage?.length > 0) {
      setUploadHistory(data?.pages?.map(item => item.results).flat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const headers = [
    {
      Header: (
        <div>
          <Text font="body-2" color="secondary">
            Material Name
          </Text>
          <Text font="subtitle-1" color="secondary">
            Material ID
          </Text>
        </div>
      ),
    },
    {
      Header: 'Engine',
    },
    {
      Header: 'Type',
    },
    {
      Header: 'Sub-Type',
    },
    {
      Header: 'View',
    },
    {
      Header: 'Timestamp',
    },
    {
      Header: '',
    },
  ];

  return (
    <div className="upload-history eds-spacing--mt-36">
      <Text font="title-6" className="eds-spacing--mb-24">
        Upload History
      </Text>
      <div className="upload-history--body">
        {uploadHistory?.length > 0 ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
            useWindow={false}
            loader={
              <div className="infinite-loading-spinner" key={0}>
                <Spinner size="large" />
              </div>
            }
          >
            <Table>
              <thead>
                <tr>
                  {headers.map((column, header_index) => (
                    <TableHeading key={`add-board-header-${header_index}`}>
                      {column.Header}
                    </TableHeading>
                  ))}
                </tr>
              </thead>
              <tbody>
                {uploadHistory.map((file, index) => {
                  const material = file?.links?.find(l => l.objectType === 'material')?.linkData;
                  const materialTypes = material?.type?.split('/');

                  return (
                    <tr key={`upload_${index}_${file?.id}`}>
                      <TableCell>
                        <div>
                          <Text>{material?.name || file?.name}</Text>
                          <Text font="body-2" color="secondary">
                            {material?.id}
                          </Text>
                        </div>
                      </TableCell>
                      <TableCell>{materialTypes?.[2]}</TableCell>
                      <TableCell>{materialTypes?.[3]}</TableCell>
                      <TableCell>{materialTypes?.[4]}</TableCell>
                      <TableCell>{file?.metadata?.viewType}</TableCell>
                      <TableCell>
                        {new Date(file?.version?.createTimestamp)?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Icon name="Check" size="m" />
                      </TableCell>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </InfiniteScroll>
        ) : (
          <div className="container eds-spacing--mt-16">
            <div
              className="queue eds-color--secondary eds-type--legal-1 centered"
              style={{ backgroundColor: 'white' }}
            >
              <Text font="body-3" color="secondary">
                You haven't uploaded any files yet. Once you do, you will see them here.
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
