import { useEffect, useRef, useState } from 'react';

import { use_engine_store } from '../../hooks/use_engine';
import { useBoardMutation, create_board, poll_for_board } from '../../hooks/use_boards';
import {
  general_user_groups_mapping,
  general_user_icons_mapping,
  admin_user_groups_mapping,
} from './mappings';
import { useLocation, useNavigate } from 'react-router-dom';
import { track } from '../../utils/analytics';
import { useModalStore } from '../../hooks/use_modal';

const options = [
  { value: ['READ'], label: 'can view' },
  { value: ['READ', 'WRITE'], label: 'can edit' },
  { value: ['READ', 'WRITE', 'ADMIN'], label: 'Admin' },
];
const general_user_options = [
  { value: ['READ'], label: 'can view' },
  { value: [], label: 'No Access' },
];

export function BoardAccessManagement({
  materials,
  palette_summary,
  render,
  dismiss,
  is_open,
  set_update_board_response,
  on_success,
  has_user_permissions,
}) {
  const is_admin = palette_summary ? palette_summary?.currentUserAccess?.includes('ADMIN') : true;
  const owner = palette_summary?.createdByUserId;

  const [new_team_members, set_new_team_members] = useState();
  const [new_team_members_entitlement, set_new_team_members_entitlement] = useState(options[0]);
  const [entitlements, set_entitlements] = useState([]);
  const [user_lookup_options, set_user_lookup_options] = useState([]);
  const [to_archive, set_to_archive] = useState(false);
  const [show_archiving, set_show_archiving] = useState(false);
  const [palette_name, set_palette_name] = useState(palette_summary?.name);
  const [loading, set_loading] = useState(false);

  const { trigger_location } = useModalStore();
  const board_mutation = useBoardMutation(set_update_board_response);
  const navigate = useNavigate();
  const location = useLocation();

  const { active_engine } = use_engine_store();
  const team_members_table = useRef();
  const board_name_ref = useRef();
  const all_engine_user_group = general_user_groups_mapping[active_engine];
  const all_engine_admin_group = admin_user_groups_mapping[active_engine];

  // default this to be false since the desired behaviour for a board is to be private by default
  const engine_wide_access =
    palette_summary?.entitlements?.findIndex(item => item?.name === all_engine_user_group) === -1 ||
    !palette_summary?.paletteId
      ? false
      : true;

  const ad_groups = palette_summary?.entitlements?.filter(item => {
    const is_ad_group = !item?.name?.includes('@nike.com');

    return is_ad_group;
  });
  const admin_groups =
    ad_groups
      ?.filter(item => item?.name?.includes('Admin'))
      ?.filter(
        (thing, index, self) =>
          // dedupe the admin groups
          index === self?.findIndex(other_thing => other_thing?.name === thing?.name)
      ) || [];
  const user_groups =
    ad_groups
      ?.filter(item => !item?.name?.includes('Admin'))
      ?.filter(
        (thing, index, self) =>
          // dedupe the user groups
          index === self?.findIndex(other_thing => other_thing?.name === thing?.name)
      ) || [];
  const entitled_users =
    palette_summary?.entitlements?.filter(item => {
      const is_user = item?.name?.includes('@nike.com') && item?.name !== owner;

      return is_user;
    }) || [];

  // set the default user entitlements we're going to show in the UI
  useEffect(() => {
    const groups = user_groups?.filter(item => item?.name === all_engine_user_group)?.length
      ? user_groups?.filter(item => item?.name === all_engine_user_group)
      : [
          {
            name: all_engine_user_group,
            permissions: undefined,
            type: 'group',
          },
        ];

    set_entitlements([
      { name: owner, permissions: ['READ', 'WRITE', 'ADMIN'], type: 'user' },
      ...groups,
      ...entitled_users,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset the board once it's been closed
  useEffect(() => {
    board_name_ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    if (!is_open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_open]);

  // get the entitlement groups from the palette summary to get back an array of results
  const is_general_user_group = group => {
    return all_engine_user_group === group ? true : false;
  };
  const ad_group_entitilement_name = group => {
    const engine = active_engine.toLowerCase();
    const sentence_case_engine = engine.charAt(0).toUpperCase() + engine.slice(1);

    return is_general_user_group(group) ? `All ${sentence_case_engine} Users` : group;
  };

  function add_entitled_users() {
    // as long as new team members are selected we'll add them to the entitlements list
    if (new_team_members?.length) {
      let new_entitled_users = [];

      for (const new_team_member of new_team_members) {
        new_entitled_users.push({
          name: new_team_member?.value,
          permissions: new_team_members_entitlement?.value,
          type: 'user',
        });
      }

      set_entitlements([...entitlements, ...new_entitled_users]);
    }
    // reset select options for the users and entitlements
    set_new_team_members(undefined);
    set_new_team_members_entitlement(options[0]);
    // scroll down to the bottom after a little timeout to make sure the DOM has been updated w/ the new entitlements list
    setTimeout(() => {
      team_members_table?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 200);
  }
  function update_entitlements(updated_entitlement) {
    if (!updated_entitlement?.permissions) {
      set_entitlements(entitlements?.filter(item => item?.name !== updated_entitlement?.name));
    } else {
      set_entitlements(
        entitlements?.map(item => {
          return {
            ...item,
            permissions:
              item?.name === updated_entitlement?.name
                ? updated_entitlement?.permissions
                : item?.permissions,
          };
        })
      );
    }
  }
  function reset() {
    dismiss();
    set_new_team_members(undefined);
    set_new_team_members_entitlement(options[0]);
    set_entitlements([
      { name: owner, permissions: ['READ', 'WRITE', 'ADMIN'], type: 'user' },
      ...palette_summary?.entitlements?.filter(item => !item?.name?.includes('Admin')),
    ]);
    set_palette_name(palette_summary?.name || '');
    set_user_lookup_options([]);
    set_to_archive(false);
    set_loading(false);
  }
  function update() {
    set_loading(true);
    const is_private = entitlements?.find(item => item?.name === all_engine_user_group)?.permissions
      ?.length
      ? false
      : true;
    const updated_entitlements = [...entitlements, ...admin_groups].filter(
      item => item?.permissions?.length
    );
    board_mutation.mutate({
      ...palette_summary,
      palette_id: palette_summary?.paletteId,
      private: is_private,
      active: !to_archive,
      name: palette_name,
      entitlements: updated_entitlements,
      method: 'put',
    });

    set_show_archiving(false);
    dismiss(false);
    reset();
  }

  async function create() {
    set_loading(true);
    const is_private = entitlements?.find(item => item?.name === all_engine_user_group)?.permissions
      ?.length
      ? false
      : true;
    const updated_entitlements = [...entitlements, ...admin_groups].filter(
      item => item?.permissions?.length
    );
    const action_from = trigger_location;
    function check_access(entitlements, access_level) {
      return entitlements?.filter(
        item => item?.permissions?.includes(access_level) && item?.type === 'user'
      )?.length;
    }

    track({
      eventName: 'addToBoard',
      actionFrom: action_from,
      totalMaterials: materials?.length || 0,
      boardType: 'new',
      totalBoards: 1,
      allUserAccess: engine_wide_access,
      individualViewAccess: check_access(updated_entitlements, 'READ'),
      individualEditAccess: check_access(updated_entitlements, 'WRITE'),
      individualAdminAccess: check_access(updated_entitlements, 'ADMIN'),
    });

    const response = await create_board({
      division: `${active_engine} DIVISION`,
      private: is_private,
      name: palette_name,
      entitlements: updated_entitlements,
      ids: materials,
      activeSectionsType: 'default',
    });

    // ensure board is available in search before routing
    await poll_for_board(response.paletteId);

    navigate({
      pathname: `/boards/${response.paletteId}`,
      state: {
        previous: location,
      },
    });

    reset();

    if (on_success) {
      on_success();
    }
  }

  return render({
    create,
    update,
    reset,
    update_entitlements,
    add_entitled_users,
    has_user_permissions,
    is_admin,
    ad_group_entitilement_name,
    is_general_user_group,
    entitled_users,
    user_groups,
    admin_groups,
    ad_groups,
    engine_wide_access,
    palette_summary,
    all_engine_admin_group,
    all_engine_user_group,
    general_user_icons_mapping,
    to_archive,
    set_to_archive,
    show_archiving,
    set_show_archiving,
    palette_name,
    set_palette_name,
    set_new_team_members,
    user_lookup_options,
    set_new_team_members_entitlement,
    new_team_members_entitlement,
    new_team_members,
    team_members_table,
    board_name_ref,
    entitlements,
    owner,
    active_engine,
    general_user_options,
    options,
    loading,
  });
}
