import { Text, Icon, Menu, MenuItem } from '@nike/eds';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useModalStore } from '../../../hooks/use_modal';
import { useNotificationsStore } from '../../../hooks/use_notifications';

import './material_actions_menu.css';
import { useCustomSections } from '../../../hooks/use_custom_sections';
import { CARDACTIONS } from '../../../utils/cardactions';

export function MaterialActionsMenu({
  children,
  material,
  actions,
  is_open,
  set_is_open,
  appearance,
  is_board_menu_open,
  set_is_board_menu_open,
  is_sections_menu_open,
  set_is_sections_menu_open = () => {},
  recent_boards,
  onUpdateMaterial,
}) {
  const { send_notification } = useNotificationsStore();
  const { customSections, getSectionIdByMaterialId } = useCustomSections();
  const navigate = useNavigate();
  const location = useLocation();
  const [is_board, set_is_board] = useState(false);
  const [is_compare_page, set_is_compare_page] = useState(false);
  const { active_modal } = useModalStore();

  useEffect(() => {
    const url = window.location.href;
    set_is_board(url.includes('boards'));
    set_is_compare_page(url.includes('compare'));
  }, []);

  const open_tote_page = () => {
    // TODO: needs to be refactored to pass in the appropriate preivous route
    navigate('/tote', { state: { previous_route: location?.pathname?.replaceAll('/', '') } });
  };

  const body = (
    <>
      {active_modal !== 'material' ? (
        <MenuItem
          key="select_item"
          onClick={e => {
            e.stopPropagation();
            if (actions?.activate_multi_select) {
              actions.activate_multi_select();
            }

            if (actions?.select_material) {
              actions.select_material(material?.id);
            }
          }}
        >
          Select Item
        </MenuItem>
      ) : undefined}
      <MenuItem
        key="add_to_tote"
        className="menu-item"
        onClick={e => {
          e.stopPropagation();
          const result = actions?.add_material_to_tote(material?.id);
          send_notification(
            'success',
            result ? '1 Item(s) Added to your Tote' : 'Item Already Exists in Tote',

            open_tote_page,

            'Open Tote'
          );
        }}
      >
        Add to Tote
      </MenuItem>
      <Menu
        placement={active_modal === 'material' ? 'left-start' : 'right-start'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -2],
            },
          },
          {
            name: 'flip',
            enabled: true,
          },
        ]}
        className={`${appearance === 'dark' ? 'eds--light' : 'eds--dark'}`}
        bodySlot={
          <>
            {recent_boards?.map(board => {
              return (
                <MenuItem
                  key={board?.paletteId}
                  onClick={e => {
                    e.stopPropagation();
                    set_is_open(false);
                    actions.add_to_board(board);
                  }}
                >
                  {board?.name}
                </MenuItem>
              );
            })}
            <MenuItem
              key="more_boards"
              onClick={e => {
                e.stopPropagation();

                if (actions?.add_to) {
                  actions.add_to();
                }
                set_is_open(!is_open);
              }}
            >
              <Text color="text-link">More Board(s)</Text>
            </MenuItem>
          </>
        }
        isOpen={is_board_menu_open}
        appearance={appearance}
      >
        <MenuItem
          className="menu-item"
          afterSlot={<Icon name="CaretRight" />}
          onClick={e => {
            e.stopPropagation();
            set_is_sections_menu_open(false);
            set_is_board_menu_open(!is_board_menu_open);
          }}
        >
          Add to Board
        </MenuItem>
      </Menu>
      {actions?.move_to_section && (
        <Menu
          placement={active_modal === 'material' ? 'left-start' : 'right-start'}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -2],
              },
            },
            {
              name: 'flip',
              enabled: true,
            },
          ]}
          className={`${appearance === 'dark' ? 'eds--light' : 'eds--dark'}`}
          bodySlot={
            <>
              {customSections?.map(section => {
                return (
                  <MenuItem
                    key={section?.id}
                    onClick={e => {
                      e.stopPropagation();
                      set_is_open(false);
                      const existingSection = getSectionIdByMaterialId(material.id);
                      onUpdateMaterial(material.id, CARDACTIONS.MOVETOSECTION, {
                        to: section.id,
                        from: existingSection,
                      });
                    }}
                  >
                    {section?.label}
                  </MenuItem>
                );
              })}
            </>
          }
          isOpen={is_sections_menu_open}
          appearance={appearance}
        >
          <MenuItem
            key="move_to_section"
            className="menu-item"
            afterSlot={<Icon name="CaretRight" />}
            onClick={e => {
              e.stopPropagation();
              set_is_board_menu_open(false);
              set_is_sections_menu_open(!is_sections_menu_open);
            }}
          >
            Move to Section
          </MenuItem>
        </Menu>
      )}
      {is_board && actions?.remove_material_from_board ? (
        <MenuItem
          key="remove_from_board"
          onClick={e => {
            actions.remove_material_from_board(material?.id);
            e.stopPropagation();
          }}
        >
          Remove from Board
        </MenuItem>
      ) : undefined}
      <MenuItem
        key="export"
        className="menu-item"
        onClick={e => {
          actions?.export(material);
          e.stopPropagation();
        }}
      >
        Export
      </MenuItem>
      {active_modal === 'material' && (
        <MenuItem
          key="print"
          className="menu-item"
          onClick={e => {
            actions?.print(material);
            e.stopPropagation();
          }}
        >
          Print
        </MenuItem>
      )}
      {is_compare_page && actions?.remove_material_from_comparison ? (
        <MenuItem
          key="remove_material"
          onClick={e => {
            actions.remove_material_from_comparison(material?.id);
            e.stopPropagation();
          }}
        >
          Remove Material
        </MenuItem>
      ) : undefined}
    </>
  );
  return (
    <Menu
      className={`${appearance === 'dark' ? 'eds--light' : 'eds--dark'}
        ${active_modal === 'material' ? 'material-card__menu' : ''}`}
      onClickOutside={e => {
        e.stopPropagation();
        set_is_open(!is_open);
        set_is_board_menu_open(!is_board_menu_open);
      }}
      isOpen={is_open}
      bodySlot={body}
      appearance={appearance}
    >
      {children}
    </Menu>
  );
}
