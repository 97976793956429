import { Modal, ButtonGroup, Button, RadioGroup, Radio } from '@nike/eds';
import useColorScheme from '../../hooks/use_color_scheme';
import { useModalStore } from '../../hooks/use_modal';
import { useSelectStore } from '../../hooks/use_select';
import { useState } from 'react';
import { useCustomSections } from '../../hooks/use_custom_sections';

export function MoveToModal() {
  const { appearance } = useColorScheme();
  const { dismiss_modal, active_modal, modal_data } = useModalStore();
  const show = active_modal === 'move_to';
  const { materials, onMoveToSectionFinished } = modal_data;
  const {
    customSections,
    setCustomSectionMaterials,
    getCustomSectionItems,
    removeMaterialsFromSections,
  } = useCustomSections();
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const { deactivate_multi_select } = useSelectStore();

  const confirm_move = () => {
    removeMaterialsFromSections(materials.map(material => material?.id));
    if (selectedSectionId !== 'unsectioned') {
      const newArr = [...getCustomSectionItems(selectedSectionId), ...materials];
      setCustomSectionMaterials(selectedSectionId, newArr);
    }
    onMoveToSectionFinished();
    dismiss_modal();
    setSelectedSectionId(null);
    deactivate_multi_select();
  };

  return (
    <div className="move-to_modal">
      <Modal
        className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
        isOpen={show}
        onDismiss={dismiss_modal}
        hideFade={true}
        headerSlot="Move to Section"
        footerSlot={
          <ButtonGroup>
            <Button onClick={confirm_move}>Confirm</Button>
            <Button variant="secondary" onClick={dismiss_modal}>
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <RadioGroup
          className="eds-spacing--m-12"
          name="section-radio-group"
          valueSelected={selectedSectionId}
          onChange={e => setSelectedSectionId(e.target.value)}
        >
          {customSections?.length > 0 &&
            customSections.map(section => (
              <Radio
                label={section?.label}
                value={section?.id}
                id={section?.id}
                key={`move_to_radio_${section?.id}`}
              />
            ))}
          <Radio
            label={'Other Materials'}
            value={'unsectioned'}
            id={'unsectioned'}
            key={'move_to_radio_unsectioned'}
          />
        </RadioGroup>
      </Modal>
    </div>
  );
}
