import { Table, TableCell, TableHeading, Checkbox, Spinner } from '@nike/eds';
import { useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

export function AddBoardTable({
  status,
  boards,
  fetchNextPage,
  hasNextPage,
  selected_rows,
  set_selected_rows,
  select_all,
  set_select_all,
}) {
  useEffect(() => {
    if (select_all) {
      const all_boards = boards?.map(board => {
        return board;
      });
      set_selected_rows(all_boards);
    } else {
      set_selected_rows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select_all]);

  // undo the select all checkbox if everything is unchecked
  useEffect(() => {
    if (!selected_rows?.length) {
      set_select_all(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_rows]);

  const headers = [
    {
      Header: (
        <Checkbox
          label={`select-all-boards`}
          hideLabel
          id={`select-all-boards`}
          checked={select_all}
          onClick={() => set_select_all(!select_all)}
        ></Checkbox>
      ),
    },
    {
      Header: 'Board Name',
    },
    {
      Header: 'Board Creator',
    },
  ];

  function select_row(board) {
    // check to see if the board exists and then remove it if it does
    if (selected_rows?.find(row => row.paletteId === board.paletteId)) {
      set_selected_rows(selected_rows?.filter(item => item?.paletteId !== board?.paletteId));
    } else {
      set_selected_rows([...selected_rows, board]);
    }
  }

  return (
    <div className="add-board__table">
      {/* loading state */}
      {!boards?.length && status === 'loading' ? (
        <div className="add-board__table-loader eds-spacing--mt-24">
          <Spinner size="large" />
        </div>
      ) : undefined}
      {/* infinite scroll */}
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        useWindow={false}
        loader={
          <div className="infinite-loading-spinner" key={0}>
            <Spinner size="large" />
          </div>
        }
      >
        <Table>
          <thead>
            <tr>
              {headers.map((column, header_index) => (
                <TableHeading key={`add-board-header-${header_index}`}>
                  {column.Header}
                </TableHeading>
              ))}
            </tr>
          </thead>
          <tbody>
            {boards?.length
              ? boards.map(board => (
                  <tr key={board?.paletteId}>
                    <TableCell>
                      <Checkbox
                        label={`select-${board?.name}`}
                        hideLabel
                        id={`select-${board?.name}`}
                        checked={selected_rows?.find(row => row.paletteId === board.paletteId)}
                        onClick={() => select_row(board)}
                      ></Checkbox>
                    </TableCell>
                    <TableCell>{board?.name}</TableCell>
                    <TableCell>{board?.createdByUserId}</TableCell>
                  </tr>
                ))
              : undefined}
          </tbody>
        </Table>
      </InfiniteScroll>
    </div>
  );
}
