import { Text, SidePanel, Button, ButtonGroup } from '@nike/eds';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import camelCase from 'lodash.camelcase';
import { usePaletteFilterStore } from '../hooks/use_palette_filters';
import './styles/filter_list.css';
import { track } from '../utils/analytics';

export function PaletteFilterList({ show_filters, set_show_filters }) {
  const { list, clear_filters } = usePaletteFilterStore();
  const [local_filter_list, set_local_filter_list] = useState(list);
  const [general_filters, set_general_filters] = useState([]);
  const [count, set_filter_count] = useState({ total: 0, general_count: 0, advanced_count: 0 });

  useEffect(() => {
    set_general_filters(Object.values(local_filter_list));

    set_filter_count(get_filter_count(local_filter_list));
  }, [local_filter_list]);

  useEffect(() => {
    set_local_filter_list(cloneDeep(list));
  }, [list]);

  return (
    <SidePanel
      isOpen={show_filters}
      onDismiss={() => set_show_filters(false)}
      headerSlot={
        <>
          {/* TODO: add all active filters to the Filters text */}
          <Text as={'h2'} className="eds-spacing--mb-32" font="title-4">
            Filters {count?.total > 0 ? `(${count?.total})` : ''}
          </Text>
        </>
      }
      footerSlot={
        <ButtonGroup>
          <Button variant="secondary" onClick={clear_filters}>
            Clear All
          </Button>
        </ButtonGroup>
      }
    >
      <div className="filter-list">
        <div className="filter-list__body">
          <Filters filters={general_filters} />
        </div>
      </div>
    </SidePanel>
  );
}

function Filters({ filters, update_local_value }) {
  return (
    <>
      {filters?.map(filter => {
        const props = { ...filter?.metadata?.props } || {};
        const label = filter?.display_name;
        // used for any select drop downs & dynamic checkbox groups
        const options =
          props?.facets?.map(item => {
            return {
              value: item.name,
              label: filter?.metadata?.label_overrides?.[item.name] || item.name,
            };
          }) || [];
        const values = filter?.metadata?.props?.value;

        const data_massager = filter?.metadata?.data_massager;
        const filter_type_total = get_filter_type_count(values);
        return (
          <div key={label} className="filter">
            <Text font="subtitle-1" className="filter-label">
              {values?.length ? `${label} (${filter_type_total})` : label}
            </Text>
            <div className="filter-input">
              {filter?.template({
                ...props,
                options,
                onChange: event => {
                  data_massager(event, update_local_value, filter?.metadata?.props?.value);
                  track({
                    eventName: 'filterPalettes',
                    fieldName: camelCase(filter?.display_name),
                    numFilters: get_value_count(filter, event),
                  });
                },
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

// used for logging the number of filters applied
function get_value_count(filter, event) {
  if (filter?.facet_type === 'select') {
    return event?.length;
  } else if (filter?.facet_type === 'toggle') {
    return event?.target?.checked === true ? 1 : 0;
  } else if (filter?.facet_type === 'checkbox') {
    return event?.target?.checked === true ? 1 : 0;
  } else if (filter?.facet_type === 'min_max') {
    return event?.minValue && event?.maxValue ? 2 : event?.minValue || event?.maxValue ? 1 : 0;
  } else if (filter?.facet_type === 'checkbox_group') {
    const previous_value_count = filter?.metadata?.props?.value?.length;
    const current_value_count = event?.target?.checked === true ? 1 : -1;

    return previous_value_count + current_value_count;
  }
}

function get_filter_count(filter_list) {
  let total = 0;

  Object.values(filter_list).forEach(filter => {
    const value = filter?.metadata?.props?.value;
    const facet_type = filter?.facet_type;

    if (facet_type === 'toggle') {
      if (value === true) {
        total += 1;
      }
    } else if (facet_type === 'select' && value?.length > 0) {
      const count = value?.length;
      total += count;
    } else if (facet_type === 'checkbox_group') {
      if (Array.isArray(value) && value?.length > 0) {
        total += value?.length;
      } else if (!Array.isArray(value) && value !== undefined) {
        total += 1;
      }
    } else if (facet_type === 'radio_group') {
      if (Array.isArray(value) && value?.length > 0) {
        total += value?.length;
      } else if (!Array.isArray(value) && value !== undefined) {
        total += 1;
      }
    }
  });

  return { total };
}
function get_filter_type_count(values) {
  if (typeof values === 'string') {
    return 1;
  } else if (typeof values === 'object') {
    return values?.length;
  }
}
