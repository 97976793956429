import { useState, useEffect } from 'react';
import {
  useSearchParams,
  useParams,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Text, Link } from '@nike/eds';

import './material_collection.css';

import { usePaletteEntriesHook } from '../../hooks/use_palettes';
import { useMaterialsHook, useLibrarySubTypeFacets } from '../../hooks/use_materials';
import { useLibraryFilterStore } from '../../hooks/use_library_filters';
import { useSelectStore } from '../../hooks/use_select';
import { useBoardMutation } from '../../hooks/use_boards';
import { useModalStore } from '../../hooks/use_modal';
import { useExportStore } from '../../hooks/use_export';
import { useSectionsStore } from '../../hooks/use_sections';
import { useLibrarySortsStore } from '../../hooks/use_library_sorts';
import { useFeaturesStore } from '../../hooks/use_features';
import { useToteStore } from '../../hooks/use_tote';

import useColorScheme from '../../hooks/use_color_scheme';

import { Grid } from '../common/grid';
import { MaterialCard } from '../common/material_card';
import { ErrorBoundary } from '../common/error_boundary';

import { Header } from '../header';
import { BreadCrumb } from '../header/bread_crumb';
import { NoBoardAccess } from '../no_board_access';
import { DragSelectProvider } from '../drag_select';

import { MaterialCollectionModals } from './material_collection_modals';
import { MaterialCollectionPanels } from './material_collection_panels';
import { Sections } from './material_collection_sections';
import { auto_section_type_options } from '../../hooks/use_sections';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useFacetStore } from '../../hooks/use_facets';

export function MaterialCollection({ title, source = 'all_materials' }) {
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';
  const { multi_select, set_multi_select, update_selected_materials, selected_materials } =
    useSelectStore();
  const { add_material_to_tote, set_show_tote_modal } = useToteStore();
  const { set_active_modal, set_modal_data, set_trigger_location } = useModalStore();
  // Get the palette_id param from the URL.
  const { palette_id } = useParams();
  let palette_data = useLoaderData();
  const location = useLocation();
  const navigate = useNavigate();
  const useDataHook = source === 'all_materials' ? useMaterialsHook : usePaletteEntriesHook;
  const { set_library_facets } = useFacetStore();
  const [palette_summary, set_palette_summary] = useState(palette_data);
  const [materials, set_materials] = useState([]);
  const [page_cursor, set_page_cursor] = useState(0);
  const [show_sorts, set_show_sorts] = useState(false);
  const [show_filters, set_show_filters] = useState(false);
  const [show_display_settings, set_show_display_settings] = useState(false);
  const [show_material, set_show_material] = useState(false);
  const [active_material, set_active_material] = useState({});
  const [active_tab, set_active_tab] = useState('general');
  const [show_snack, set_show_snack] = useState(false);
  const [active_card, set_active_card] = useState();
  const [show_manage_board, set_show_manage_board] = useState(false);
  const [show_new_board_modal, set_show_new_board_modal] = useState(true);
  const [board_mutation_response, set_board_mutation_response] = useState();
  const [show_sections_panel, set_show_sections_panel] = useState(false);
  const library_filter_store = useLibraryFilterStore();
  const [board_response, set_board_response] = useState();
  const board_mutation = useBoardMutation(set_board_response);
  const { send_notification, dismiss_notification } = useNotificationsStore();

  const {
    list,
    search_query,
    get_filter_params,
    load_filters_from_search_params,
    load_chips,
    update_filter_value,
    clear_filters,
  } = library_filter_store;
  const { sorts, selected_sort, get_sort_params, load_sorts_from_search_params } =
    useLibrarySortsStore();
  const { features } = useFeaturesStore();
  const {
    sections,
    section_type,
    set_sections,
    raw_sections,
    set_raw_sections,
    set_section_type,
    set_selected_auto_section,
  } = useSectionsStore();

  const { set_export_materials, set_export_all, set_total_materials, set_palette_id, set_source } =
    useExportStore();

  const [search_params, set_search_params] = useSearchParams();
  const [is_add_to_board_open, set_is_add_to_board_open] = useState(false);
  const [total, set_total] = useState(0);
  const [params, set_params] = useState();
  const [palette_name, set_palette_name] = useState('');
  const [disable_filters, set_disable_filters] = useState(false);
  const is_board_page = palette_id && title.includes('Boards') ? true : false;
  const show_sections =
    is_board_page &&
    palette_summary?.activeSectionsType &&
    palette_summary?.activeSectionsType !== 'default'
      ? true
      : false;
  const [fetch_all_materials] = useState(show_sections ? true : false);

  // sub_type_facet_params is used to dynamically update the sub type facet options in the filters
  const [sub_type_facet_params, set_sub_type_facet_params] = useState(null);

  useEffect(() => {
    clear_filters();
    if (location?.pathname === '/library') {
      update_filter_value('material_status', 'true');
    }
  }, [clear_filters, location?.pathname, update_filter_value]);

  // update the palette summary data when it changes from the loader data which is basically needed when rerouting from the same base route /boards/:id to another /boards/:id
  useEffect(() => {
    set_palette_summary(palette_data);
  }, [palette_data]);

  // board section useEffects
  useEffect(() => {
    const okay_to_update =
      (palette_summary?.activeSectionsType !== board_response?.activeSectionsType ||
        JSON.stringify(board_response?.sections) !== JSON.stringify(palette_summary?.sections)) &&
      board_response?.paletteId;

    if (okay_to_update) {
      set_palette_summary({ ...palette_summary, ...board_response });
      set_sections(
        board_response?.sections,
        materials?.map(item => item?.id)
      );
    }
    set_total(materials?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_response]);

  useEffect(() => {
    const okay_to_update =
      materials?.length > 0 &&
      (raw_sections?.length > 0 || section_type === undefined) && // means we've loaded the sections
      palette_summary?.paletteId; // means we've loaded the palette

    if (okay_to_update) {
      board_mutation.mutate({
        palette_id: palette_summary?.paletteId,
        ...palette_summary,
        activeSectionsType: section_type,
        ids: materials?.map(item => item?.id),
        sections: raw_sections,
        method: 'put',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw_sections, section_type]);

  useEffect(() => {
    // since updated palette summary data can come from a mutation and not just routing we need this to reference
    //  palette_summary which will keep the latest changes a user made like a name change or entitlements reflected in the UI
    const name = palette_summary?.name;
    const path_name = location?.pathname;

    if (name && (path_name.includes('/palettes/') || path_name.includes('/boards/'))) {
      set_palette_name(name);
    } else {
      // reset the palette name on non-palette routes to prevent weirdness w/ the breadcrumb
      set_palette_name(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palette_summary, location]);

  // when the filter list is updated we will update our search parameters
  // this will then trigger the bellow useEffect
  useEffect(() => {
    const filter_params = get_filter_params();
    const sort_params = get_sort_params();
    const params = { ...filter_params, ...sort_params };
    // remove certain parameters from the window url
    delete params['fields.includes'];
    delete params['size'];
    delete params['from'];
    delete params['active'];
    set_sub_type_facet_params(
      params?.materialType4 ? { materialType4: params?.materialType4 } : null
    );
    set_search_params({ ...params });
  }, [
    list,
    search_query,
    get_filter_params,
    set_search_params,
    sorts,
    selected_sort,
    get_sort_params,
  ]);

  // load filters from the search params and set them in the filter store
  // when we set the params that will triger the useMaterialsHook to run loading in new materials
  useEffect(() => {
    load_filters_from_search_params(search_params);
    load_sorts_from_search_params(search_params);
    const groupedParams = { ...get_filter_params(), ...get_sort_params() };
    const searchField = groupedParams?.searchField;

    if (searchField) {
      // remove the searchField from the params since it's not a real filter param we need to pass to the API
      delete groupedParams?.searchField;
      set_params({ ...groupedParams, query: undefined, [searchField]: groupedParams?.query });
    } else {
      set_params({ ...groupedParams });
    }
  }, [
    search_params,
    load_filters_from_search_params,
    load_sorts_from_search_params,
    get_filter_params,
    get_sort_params,
  ]);

  // TODO: pull in params from filter store and pass in
  const { status, data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useDataHook({ ...params, palette_id });

  // whenever the search params change we need to dynamically update facet options
  const sub_type_facets_hook = useLibrarySubTypeFacets({ ...sub_type_facet_params });
  useEffect(() => {
    const facets_data = sub_type_facets_hook?.data;
    if (facets_data) {
      set_library_facets(facets_data);
    }
  }, [sub_type_facets_hook?.data, set_library_facets, source]);

  useEffect(() => {
    if (multi_select) {
      set_show_snack(true);
    }
  }, [multi_select]);

  useEffect(() => {
    set_materials(data?.pages?.map(item => item.results).flat());
    set_page_cursor(data?.pages?.length || 0);
    set_total(data?.pages?.[0]?.pagination?.total);
  }, [data]);

  // refetch loader data
  useEffect(() => {
    if (board_mutation_response) {
      set_palette_summary({
        ...board_mutation_response,
        currentUserAccess: palette_data?.currentUserAccess,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_mutation_response]);

  useEffect(() => {
    if (palette_summary?.sections?.length) {
      set_sections(
        raw_sections,
        materials?.map(item => item?.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section_type, raw_sections, set_sections]);

  // set initial values for sections
  useEffect(() => {
    if (
      palette_summary?.sections?.length &&
      palette_summary?.activeSectionsType &&
      palette_summary?.activeSectionsType !== 'default'
    ) {
      set_section_type(palette_summary?.activeSectionsType);
      set_selected_auto_section(
        auto_section_type_options?.find(item => item.value === palette_summary?.activeSectionsType)
      );
      set_sections(
        palette_summary?.sections || [],
        materials?.map(item => item?.id)
      );
      set_disable_filters(true);
    } else {
      set_disable_filters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palette_summary, materials]);

  useEffect(() => {
    if (!show_sections_panel && palette_summary?.sections?.length) {
      set_sections(
        palette_summary?.sections || [],
        materials?.map(item => item?.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show_sections_panel, palette_summary]);

  const header_actions = {
    show_filter: () => {
      set_show_filters(!show_filters);
      set_show_material(false);
    },
    show_display_settings: () => {
      set_show_display_settings(!show_display_settings);
      set_show_material(false);
    },
    show_sorts: () => {
      set_show_sorts(!show_sorts);
      set_show_material(false);
    },
    toggle_multi_select: () => {
      set_multi_select(!multi_select);
      update_selected_materials([]);
      set_show_snack(false);
    },
    disable_filters: () => {
      set_disable_filters(true);
    },
    export_all: () => {
      set_active_modal('export');
      set_export_materials(materials);
      set_export_all(true);
      set_total_materials(total);
      set_palette_id(palette_id);
      set_source(is_board_page ? 'DPC' : 'PCX');
      set_trigger_location('exportAll');
    },
    handle_sections: () => {
      set_show_sections_panel(!show_sections_panel);
      set_show_material(false);
    },
  };
  // only show the settings icon for an opened board right now
  if (palette_name && title.includes('Boards')) {
    header_actions.handle_settings = () => {
      set_show_manage_board(!show_manage_board);
    };
  }

  const handle_go_to_board = (e, board) => {
    e.stopPropagation();
    dismiss_notification();
    navigate(`/boards/${board?.paletteId}`);
  };

  const MaterialCollectionHeader = () => {
    return (
      <Header
        title={title}
        palette_data={palette_summary}
        go_back={() => navigate(`/${title?.toLowerCase()}`)}
        previous_route={title?.toLowerCase()}
        current_route={palette_name}
        total={total}
        actions={header_actions}
        multi_select={multi_select}
        filters={load_chips() || []}
        disable_filters={disable_filters}
        set_disable_filters={set_disable_filters}
        update_filter_value={update_filter_value}
        show_search={!is_board_page}
        icon_row={{
          filter: true,
          sort: true,
          select: true,
          display_settings: true,
          sections:
            is_board_page &&
            (palette_summary?.currentUserAccess.includes('ADMIN') ||
              palette_summary?.currentUserAccess.includes('EDIT'))
              ? true
              : false,
        }}
        filter_store={library_filter_store}
      />
    );
  };
  if (status === 'error') {
    return <ErrorBoundary error={error} dark={dark} header={<MaterialCollectionHeader />} />;
  }

  if (palette_id && !palette_summary?.currentUserAccess?.length) {
    return (
      <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`} data-testid="page">
        <Header
          title={palette_name}
          show_search={false}
          show_tote={false}
          header_bottom={() => undefined}
          header_top={() => (
            <div>
              <BreadCrumb title={palette_summary?.name} />
              <Text font="subtitle-1" color="secondary" className="eds-spacing--mt-16 truncate">
                Created By: {palette_summary?.createdByUserId || '👻 Unknown User 👻'}
              </Text>
            </div>
          )}
          icon_row={{ filter: true, sort: false, select: false, display_settings: false }}
        />
        <div className="page-contents">
          <NoBoardAccess board={palette_summary} />
        </div>
      </div>
    );
  }
  // since useInfiniteQuery returns pages and we want the page results we're gonna just handle that in local state
  if (data?.pages?.length > page_cursor) {
    set_materials(data?.pages?.map(item => item.results).flat());
    // set the current page cursor so we know where we're at and if we need to continue fetching
    set_page_cursor(data?.pages?.length || 0);
  }

  function remove_selections() {
    update_selected_materials([]);
    set_multi_select(false);
    set_show_snack(false);
  }
  function open_material(id) {
    if (features?.material_details_panel) {
      set_active_material(materials?.find(mat => mat?.id === id));
      if (!show_material) {
        set_show_material(true);
      }
    }
  }
  function next_material() {
    const current = materials?.findIndex(mat => mat?.id === active_material?.id);
    const new_index = current === materials?.length - 1 ? 0 : current + 1;

    set_active_material(materials[new_index]);

    if (!show_material) {
      set_show_material(true);
    }
  }
  function previous_material() {
    const current = materials?.findIndex(mat => mat?.id === active_material?.id);
    const new_index = current === 0 ? materials?.length - 1 : current - 1;

    set_active_material(materials[new_index]);

    if (!show_material) {
      set_show_material(true);
    }
  }

  const has_user_permissions =
    palette_summary?.currentUserAccess?.includes('WRITE') ||
    palette_summary?.currentUserAccess?.includes('ADMIN');

  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`} data-testid="page">
      <div className="page-header">
        <MaterialCollectionHeader disable_filters={disable_filters} />
      </div>
      <div className="page-contents" id="multi_select_area">
        {show_sections ? (
          <Sections
            sections={sections}
            materials={materials}
            status={status}
            update_selected_materials={update_selected_materials}
            is_board_page={is_board_page}
            multi_select={multi_select}
            selected_materials={selected_materials}
            active_card={active_card}
            set_active_card={set_active_card}
            open_material={open_material}
            set_active_modal={set_active_modal}
            set_modal_data={set_modal_data}
            set_export_materials={set_export_materials}
            set_total_materials={set_total_materials}
            set_show_tote_modal={set_show_tote_modal}
            add_material_to_tote={add_material_to_tote}
            set_multi_select={set_multi_select}
            palette_id={palette_id}
            palette_summary={palette_summary}
            board_mutation={board_mutation}
            set_materials={set_materials}
            set_sections={set_raw_sections}
            // infinite scroll props
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : undefined}
        {materials?.length && !show_sections ? (
          <DragSelectProvider multi_select={multi_select}>
            <Grid
              multi_select={multi_select}
              select_materials={update_selected_materials}
              selected_materials={selected_materials}
              status={status}
              data={materials}
              set_data={updated_data => {
                if (palette_id) {
                  const ids = updated_data.map(item => item.id);
                  // trigger the board mutation to update a board
                  board_mutation.mutate({ palette_id, ids, method: 'put' });
                }
                set_materials(updated_data);
              }}
              dnd={is_board_page && has_user_permissions}
              // this keys allows the drag select component properly update when we need it to
              // the material_card_ref is defined in the Grid component and then passed into the "card" as the 2nd parameter
              card={(material, material_cad_ref, select_item) => (
                <li
                  key={`collection-card-${material?.id}-${material?.name}`}
                  className="material-card__selectable"
                  ref={material_cad_ref}
                  id={material?.id}
                >
                  <MaterialCard
                    material={material}
                    multi_select={multi_select}
                    selected={selected_materials.includes(material?.id)}
                    active={active_card === material?.id}
                    set_active_card={set_active_card}
                    show_card_actions={true}
                    actions={{
                      select_material: select_item,
                      open: open_material,
                      activate_multi_select: () => set_multi_select(true),
                      add_material_to_tote,
                      add_to: () => {
                        set_active_modal('add_to');
                        set_trigger_location('materialCardMenuModal');
                        set_modal_data({ materials: [material?.id] });
                      },
                      add_to_board: async board => {
                        try {
                          await add_materials_to_boards({
                            boards: [board],
                            material_ids: [material?.id],
                            trigger_location: 'material_card',
                          });
                          send_notification(
                            'success',
                            'Material Added to Board',
                            () => dismiss_notification(),
                            <>
                              <Link onClick={e => handle_go_to_board(e, board)}>Go to Board</Link>
                            </>
                          );
                        } catch (error) {
                          console.error(`Error adding material to board:`, error.message);
                          send_notification('error', 'Error Adding Material to Board');
                        }
                      },
                      export: material => {
                        set_active_modal('export');
                        set_export_materials([material]);
                        set_trigger_location('materialCardMenu');
                        set_total_materials(1);
                      },
                      open_tote: () => set_show_tote_modal(true),
                      remove_material_from_board: material => {
                        if (palette_id) {
                          const new_materials = materials
                            .filter(item => item.id !== material)
                            ?.map(item => item);
                          set_materials(new_materials);
                          const ids = new_materials.map(item => item.id);
                          const updated_sections = palette_summary?.sections.map(section => {
                            return {
                              ...section,
                              ids: section.ids.filter(id => id !== material),
                            };
                          });

                          board_mutation.mutate({
                            palette_id,
                            ids,
                            sections: updated_sections,
                            method: 'put',
                          });
                        }
                      },
                    }}
                  />
                </li>
              )}
              gap={24}
              // infinite scroll props
              fetchNextPage={fetch_all_materials ? () => true : fetchNextPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
            />
          </DragSelectProvider>
        ) : null}
        <MaterialCollectionModals
          has_user_permissions={has_user_permissions}
          materials={materials}
          is_add_to_board_open={is_add_to_board_open}
          set_is_add_to_board_open={set_is_add_to_board_open}
          is_new_board={is_board_page && !palette_data?.has_materials}
          // empty board modal
          show_new_board_modal={show_new_board_modal}
          set_show_new_board_modal={set_show_new_board_modal}
          // manage board modal
          show_manage_board={show_manage_board}
          palette_summary={palette_summary}
          set_show_manage_board={set_show_manage_board}
          palette_name={palette_name}
          board_mutation_response={board_mutation_response}
          set_board_mutation_response={set_board_mutation_response}
        />
        <MaterialCollectionPanels
          // material details side panel
          show_material={show_material}
          set_show_material={set_show_material}
          active_material={active_material}
          next_material={next_material}
          previous_material={previous_material}
          // library sort side panel
          show_sorts={show_sorts}
          set_show_sorts={set_show_sorts}
          // sections side panel
          show_sections_panel={show_sections_panel}
          set_show_sections_panel={set_show_sections_panel}
          palette_summary={palette_summary}
          set_palette_summary={set_palette_summary}
          materials={materials}
          // filter list side panel
          load_chips={load_chips}
          active_tab={active_tab}
          set_active_tab={set_active_tab}
          show_filters={show_filters}
          set_show_filters={set_show_filters}
          // display settings side panel
          show_display_settings={show_display_settings}
          set_show_display_settings={set_show_display_settings}
          // material snackbar
          dark={dark}
          selected_materials={selected_materials}
          remove_selections={remove_selections}
          show_snack={show_snack}
          set_active_modal={set_active_modal}
          set_modal_data={set_modal_data}
          set_trigger_location={set_trigger_location}
          palette_name={palette_name}
          title={title}
        />
      </div>
    </div>
  );
}
