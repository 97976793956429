import { Toggle, IconButton } from '@nike/eds';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import useColorScheme from '../../hooks/use_color_scheme';
import { CompareMaterials } from '../../components/compare_materials/compare_materials';
import { BreadCrumb } from '../../components/header/bread_crumb';
import { Header } from '../../components/header';
import { useCompareMaterialsHook } from '../../hooks/use_materials';
import { MaterialSnackbar } from '../../components/common/material_snackbar';
import { DisplaySettings } from '../../components/display_settings';
import './compare.css';

export default function Compare({ title }) {
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';
  const location = useLocation();
  const [materials, set_materials] = useState([]);
  const [highlight_differences, set_highlight_differences] = useState(false);
  const [highlight_similarities, set_highlight_similarities] = useState(false);
  const [show_snack, set_show_snack] = useState(false);
  const [selected_materials, set_selected_materials] = useState([]);
  const [show_display_settings, set_show_display_settings] = useState(false);
  const [material_ids, set_material_ids] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [previous_route, set_previous_route] = useState(location);
  const navigate = useNavigate();

  useEffect(() => {
    const ids = location?.search?.split('?ids=')[1];
    set_material_ids(ids?.split(','));
    if (location?.state?.previous) {
      set_previous_route(location?.state?.previous);
    }
  }, [location]);

  const { data, status } = useCompareMaterialsHook(material_ids);

  const arr = Array.from({ length: material_ids.length }, () => ({ material_to_add: true }));

  useEffect(() => {
    if (status === 'success') {
      let results = data?.results;

      if (results?.length && !materials?.length) {
        const materials_to_compare = arr.map((item, index) => {
          const material = results[index];

          return material || { ...item };
        });

        set_materials(materials_to_compare);
      }
    }
  }, [data, status]); // eslint-disable-line

  useEffect(() => {
    if (selected_materials?.length) {
      set_show_snack(true);
    } else {
      set_show_snack(false);
    }
  }, [selected_materials]);

  const remove_materials = materials => {
    set_materials([]);
    const updated_materials = material_ids.filter(item => !materials.includes(item));
    set_material_ids(updated_materials);

    navigate(`/compare?ids=${updated_materials?.toString()}`, {
      state: { previous: location?.state?.previous }, // pass along the old state to persist the add & back to actions within the compare flow
    });
  };

  const go_back = () => {
    navigate(previous_route?.pathname || '/library');
  };

  return (
    <div className={`compare page ${dark ? 'eds--dark' : 'eds--light'}`}>
      <div className="page-header">
        <CompareMaterialsHeader
          title={title}
          go_back={go_back}
          previous_route={previous_route?.name || 'Library'}
          actions={{
            set_highlight_differences,
            set_highlight_similarities,
            toggle_display_settings: () => {
              set_show_display_settings(!show_display_settings);
            },
          }}
          highlight_differences={highlight_differences}
          highlight_similarities={highlight_similarities}
        />
      </div>
      <div className="page-contents">
        <CompareMaterials
          set_selected_materials={set_selected_materials}
          selected_materials={selected_materials}
          materials={materials}
          highlight_differences={highlight_differences}
          highlight_similarities={highlight_similarities}
          remove_material={remove_materials}
        />
        <MaterialSnackbar
          dark={dark}
          selected_materials={selected_materials}
          remove_selections={() => set_selected_materials([])}
          show_snack={show_snack}
          actions={{
            // add_to: () => {
            //   set_active_modal('add_to');
            //   set_modal_data({ materials: selected_materials });
            // },
            remove_material_from_comparison: remove_materials,
          }}
        />
      </div>
      <DisplaySettings
        show_display_settings={show_display_settings}
        set_show_display_settings={set_show_display_settings}
        card_material_data={false}
        thumbnail_size={false}
      />
    </div>
  );
}

function CompareMaterialsHeader({
  title,
  previous_route,
  actions,
  highlight_similarities,
  highlight_differences,
  go_back,
}) {
  return (
    <Header
      header_top={() => (
        <>
          <BreadCrumb
            title={title}
            current_route={title}
            previous_route={previous_route}
            go_back={go_back}
          />
          <div className="searchbar-search__container">
            <div className="searchbar-tote-container">
              <div className="compare-materials__similarities">
                <Toggle
                  className="compare-materials__header-actions eds-spacing--mr-16"
                  label="Highlight Similarities"
                  onClick={() => actions?.set_highlight_similarities(!highlight_similarities)}
                  checked={highlight_similarities}
                />
              </div>
              <div className="compare-materials__differences">
                <Toggle
                  className="compare-materials__header-actions eds-spacing--mr-16"
                  label="Highlight Differences"
                  onClick={() => actions?.set_highlight_differences(!highlight_differences)}
                  checked={highlight_differences}
                />
              </div>
              <IconButton
                icon="Show"
                label="Show"
                variant="ghost"
                onClick={actions?.toggle_display_settings}
              />
            </div>
          </div>
        </>
      )}
      header_bottom={() => undefined}
    />
  );
}
