import { Button, ButtonGroup, IconButton, Radio, RadioGroup, SidePanel, Text } from '@nike/eds';
import cloneDeep from 'lodash.clonedeep';
import camelCase from 'lodash.camelcase';
import { useEffect, useState } from 'react';
import { useLibrarySortsStore } from '../hooks/use_library_sorts';
import '../components/styles/library_sorts_side_panel.css';
import { track } from '../utils/analytics';

export function LibrarySortsSidePanel({ show_sorts, set_show_sorts }) {
  const { selected_sort, sorts, update_state } = useLibrarySortsStore();
  const [local_selected_sort, set_local_selected_sort] = useState(selected_sort);
  const [local_sorts, set_local_sorts] = useState(cloneDeep(sorts));

  useEffect(() => {
    set_local_selected_sort(selected_sort);
  }, [selected_sort]);

  useEffect(() => {
    set_local_sorts(cloneDeep(sorts));
  }, [sorts]);

  const update_local_value = (sort_id, sort_direction) => {
    set_local_selected_sort(sort_id);
    const new_local_sorts = cloneDeep(local_sorts);
    new_local_sorts[sort_id].sort_direction = sort_direction;
    set_local_sorts(new_local_sorts);
  };

  const handle_apply_click = () => {
    update_state({ selected_sort: local_selected_sort, sorts: local_sorts });

    track({
      eventName: 'sortMaterials',
      fieldName: camelCase(sorts[local_selected_sort].display_name),
      sortDirection: sorts[local_selected_sort].sort_direction,
    });
    set_show_sorts(false);
  };

  const handle_cancel_click = () => {
    set_local_selected_sort(selected_sort);
    set_local_sorts(cloneDeep(sorts));
    set_show_sorts(false);
  };

  return (
    <SidePanel
      hasScrim
      isOpen={show_sorts}
      onDismiss={() => set_show_sorts(false)}
      headerSlot={
        <>
          <Text as={'h2'} className="eds-spacing--mb-32" font="title-4">
            Sort By
          </Text>
        </>
      }
      footerSlot={
        <ButtonGroup>
          <Button onClick={handle_apply_click} size="medium">
            Apply
          </Button>
          <Button onClick={handle_cancel_click} size="medium" variant="secondary">
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <Sorts
        selected_sort={local_selected_sort}
        set_selected_sort={set_local_selected_sort}
        sorts={local_sorts}
        update_local_value={update_local_value}
      />
    </SidePanel>
  );
}

function Sorts({ sorts, selected_sort, set_selected_sort, update_local_value }) {
  const handleNewRadioOption = value => {
    set_selected_sort(value?.target?.value);
  };

  return (
    <>
      <RadioGroup
        name="library-sorts-radio-group"
        valueSelected={selected_sort}
        onChange={handleNewRadioOption}
      >
        {Object.values(sorts).map(sort => {
          return (
            <Radio
              value={sort?.id}
              id={sort?.id}
              key={sort?.id}
              label={<RadioLabel sort={sort} update_local_value={update_local_value} />}
              className="sort-radio-option"
            />
          );
        })}
      </RadioGroup>
    </>
  );
}

function RadioLabel({ sort, update_local_value }) {
  return (
    <>
      <div className="sort-radio-option-label">
        <Text font="body-2" className="display-name">
          {sort?.display_name}
        </Text>
        <div className="arrow-container">
          <Text font="body-2" className="sort-label left">
            {sort?.left_side_label}
          </Text>
          <IconButton
            variant="ghost"
            size="small"
            icon={sort?.sort_direction === 'asc' ? 'ArrowRight' : 'ArrowLeft'}
            disableRipple={true}
            className="arrow-icon"
            onClick={() =>
              update_local_value(sort?.id, sort?.sort_direction === 'asc' ? 'desc' : 'asc')
            }
          />
          <Text font="body-2" className="sort-label right">
            {sort?.right_side_label}
          </Text>
        </div>
      </div>
    </>
  );
}
