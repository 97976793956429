import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { uniq } from 'lodash';

export const autoSectionTypeOptions = [
  { label: 'Type', value: 'type', material_mapping: 'materialType4' },
  { label: 'Sub-Type', value: 'sub_type', material_mapping: 'materialType5' },
];

function autoSectionsStore(set, get) {
  return {
    activeSectionType: '',
    autoSections: [],
    sectionedMaterials: new Map(),
    clearAllAutoSections() {
      return set({ autoSections: [] });
    },
    getAutoSections() {
      return get().autoSections;
    },
    getAutoSectionItems(sectionId) {
      return get().sectionedMaterials.get(sectionId);
    },
    getActiveAutoSections() {
      return get().autoSections.filter(section => section.type === get().activeSectionType);
    },
    setActiveSectionType(type) {
      return set({ activeSectionType: type });
    },
    getActiveSectionType() {
      return get().activeSectionType;
    },
    setAutoSections(sections) {
      return set({ autoSections: sections });
    },
    getAutoSectionedMaterials(sectionId) {
      return get().sectionedMaterials.get(sectionId);
    },
    setAutoSectionedMaterials(sectionId, materials) {
      const sectionedMaterials = get().sectionedMaterials;
      sectionedMaterials.set(sectionId, materials);
      return set({ sectionedMaterials });
    },
    initializeAutoSections(materials, sections) {
      const autoSections = [];
      const sectionedMaterials = new Map();
      autoSectionTypeOptions.forEach(option => {
        // uniq list of values for the current option materialMapping
        const existingAutoSections = sections
          ?.filter(section => section.type === option.value)
          ?.sort((sectionA, sectionB) => {
            const orderA = sectionA.order;
            const orderB = sectionB.order;
            return orderA < orderB ? -1 : 1;
          });

        // create section values and sort either by existing auto section order or alphabetically if that does not exist
        const sectionValues = uniq(
          materials.map(material => material[option.material_mapping])
        ).sort((valueA, valueB) => {
          if (existingAutoSections?.length > 0) {
            const orderA = existingAutoSections.findIndex(section => section.name === valueA);
            const orderB = existingAutoSections.findIndex(section => section.name === valueB);

            if (orderA === -1 || orderB === -1) {
              return -1;
            } else {
              return orderA < orderB ? -1 : 1;
            }
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });

        sectionValues.forEach((value, index) => {
          // create new section for each section value
          const sectionId = uuidv4();
          autoSections.push({
            id: sectionId,
            label: value,
            type: option.value,
          });

          const matchingExistingSection = existingAutoSections?.find(
            section => section.name === value
          );

          // set sectioned materials for current section
          const newMaterials = materials
            .filter(material => material[option.material_mapping] === value)
            .sort((materialA, materialB) => {
              if (matchingExistingSection) {
                // sort by order of ids that exists on the section data
                const orderA = matchingExistingSection.ids.findIndex(id => id === materialA.id);
                const orderB = matchingExistingSection.ids.findIndex(id => id === materialB.id);

                if (orderA === -1 || orderB === -1) {
                  // if the material is not found in the existing section, return -1
                  return -1;
                } else {
                  return orderA < orderB ? -1 : 1;
                }
              } else {
                // just return default order
                return -1;
              }
            });

          sectionedMaterials.set(sectionId, newMaterials);
        });
      });
      return set({ autoSections, sectionedMaterials });
    },
    getAutoSectionsForPost() {
      return autoSectionTypeOptions
        .map(option => {
          const autoSections = get().autoSections.filter(section => section.type === option.value);
          return autoSections.map((autoSection, index) => {
            return {
              order: `${index}`,
              name: autoSection.label,
              type: option.value,
              id: autoSection.id,
              ids: get()
                .sectionedMaterials.get(autoSection.id)
                .map(material => material?.id),
            };
          });
        })
        .flat();
    },
    removeAutoMaterial(materialId, sectionId) {
      return set(state => {
        const sectionedMaterials = state.sectionedMaterials.get(sectionId);
        const newSectionedMaterials = sectionedMaterials.filter(
          material => material?.id !== materialId
        );
        const newSections = new Map(state.sectionedMaterials);
        newSections.set(sectionId, newSectionedMaterials);
        return {
          sectionedMaterials: newSections,
        };
      });
    },
  };
}

export const useAutoSections = create(autoSectionsStore);
