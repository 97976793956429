import { Button, IconButton, Icon } from '@nike/eds';
import { useLibrarySortsStore } from '../../hooks/use_library_sorts';
import { useFeaturesStore } from '../../hooks/use_features';
import './header.css';

export const IconRow = ({ actions, multi_select, icons, disable_filters }) => {
  const { features } = useFeaturesStore();
  const { selected_sort, sorts } = useLibrarySortsStore();

  return (
    <div className="icon-row">
      {icons?.sort && (
        <Button
          className="sort-button eds-spacing--mr-8 eds-spacing--mb-8"
          variant="ghost"
          size="small"
          onClick={() => actions.show_sorts()}
          disabled={disable_filters}
        >
          {sorts[selected_sort].display_name}
          <Icon
            name={
              sorts[selected_sort].sort_direction === 'asc' ? 'SortAscending' : 'SortDescending'
            }
          />
        </Button>
      )}
      {!features['card_action_overlay'] && icons?.select && (
        <IconButton
          icon="Stages"
          label="Stages"
          variant="ghost"
          onClick={actions?.toggle_multi_select}
          data-testid="toggle_muli_select"
          className={multi_select ? 'eds-color--blue-50' : 'eds-color--text-primary'}
        />
      )}
      {icons?.filter && (
        <IconButton
          icon="Filter"
          label="Filter"
          variant="ghost"
          onClick={actions?.show_filter}
          data-testid="toggle_display_settings"
          className="eds-color--text-primary"
          disabled={disable_filters}
        />
      )}
      {icons?.create_board && (
        <Button
          className="open-create-modal__button eds-spacing--mr-8 eds-spacing--mb-8"
          variant="ghost"
          onClick={actions.open_create_board_modal}
        >
          <Icon name="CreateRule"></Icon>
        </Button>
      )}
      {icons?.display_settings && (
        <IconButton
          icon="Show"
          label="Show"
          variant="ghost"
          onClick={actions?.show_display_settings}
          data-testid="toggle_display_settings"
          className="eds-color--text-primary"
        />
      )}
      {icons?.sections ? (
        <IconButton
          icon="MultiSelect"
          label="Sections"
          variant="ghost"
          onClick={actions?.handle_sections}
          className="eds-color--text-primary"
        />
      ) : undefined}
      {icons?.settings && (
        <IconButton
          icon="Settings"
          label="Settings"
          variant="ghost"
          onClick={actions.handle_settings}
          className="eds-color--text-primary"
        />
      )}
    </div>
  );
};
