import { fetch_materials_by_ids } from '../hooks/use_materials';
import { v4 as uuidv4 } from 'uuid';
import uniq from 'lodash.uniq';
import { update_board, add_materials_to_board } from '../hooks/use_boards';
import { auto_section_type_options } from '../hooks/use_sections';
import { track } from './analytics';
import { general_user_groups_mapping } from '../components/board_access_management/mappings';
import { use_engine_store } from '../hooks/use_engine';

const { active_engine } = use_engine_store.getState();
const all_engine_user_group = general_user_groups_mapping[active_engine];

function check_access(board, access_level) {
  return board?.entitlements?.filter(
    item => item?.permissions?.includes(access_level) && item?.type === 'user'
  )?.length;
}

let scoped_trigger_location = '';

export const add_materials_to_boards = async ({ boards, material_ids, trigger_location }) => {
  // since the add to boards modal flow can be triggered from multiple locations we need to know where it was triggered from
  // and since we're passing in that value when that utility is called we can store it here and pass it up
  if (trigger_location) {
    scoped_trigger_location = trigger_location;
  }

  let result = false;
  const add_to_boards_session = uuidv4();
  const materials_with_data = [];

  if (!boards?.length || !material_ids?.length) {
    return;
  }

  await Promise.all(
    boards?.map(async board => {
      // const new_board = true;
      const engine_wide_access =
        board?.entitlements?.findIndex(item => item?.name === all_engine_user_group) === -1 ||
        !board?.paletteId
          ? false
          : true;
      let action_from = '';
      // Set the value of the action_from variable based on the combination of the number of boards and the trigger location.
      // Since this method can only receive multiple boards from the "Add to Boards" modal, we can assume that it's the "modal" option for the given trigger location.
      switch (scoped_trigger_location) {
        case 'material_card':
          if (boards?.length > 1) {
            action_from = 'materialCardMenuModal';
          } else {
            action_from = 'materialCardMenu';
          }
          break;
        case 'tote':
          if (boards?.length > 1) {
            action_from = 'materialToteMenuModal';
          } else {
            action_from = 'materialToteMenu';
          }
          break;
        case 'material_details':
          if (boards?.length > 1) {
            action_from = 'materialDetailsMenuModal';
          } else {
            action_from = 'materialDetailsMenu';
          }
          break;

        case 'snackbar':
          action_from = 'multiSelectSnackbar';
          break;

        default:
          action_from = 'unknown';
          break;
      }

      track({
        eventName: 'addToBoard',
        actionFrom: action_from,
        addToBoardsSession: add_to_boards_session,
        totalMaterials: material_ids?.length || 0,
        boardType: 'existing',
        totalBoards: boards?.length || 1,
        allUserAccess: engine_wide_access,
        individualViewAccess: check_access(board, 'READ'),
        individualEditAccess: check_access(board, 'WRITE'),
        individualAdminAccess: check_access(board, 'ADMIN'),
      });

      if (
        board?.activeSectionsType &&
        board?.activeSectionsType !== 'custom' &&
        board?.activeSectionsType !== 'default'
      ) {
        const auto_section_material_mappings = auto_section_type_options.reduce((acc, current) => {
          acc[current.value] = current.material_mapping;
          return acc;
        }, {});

        // for auto section boards we need to make sure the material gets put into the correct section and even add new sections if necessary
        if (materials_with_data.length === 0) {
          // we need full material data so fetch it if we dont already have it
          const res = await fetch_materials_by_ids({ ids: material_ids, from: 0 });
          materials_with_data.push(...res?.results);
        }

        const board_section_type = board?.activeSectionsType;
        const new_sections = [...(board?.sections || [])];

        // for each auto section type we will try and add materials into the correct section or add new sections if we need to
        // do this to get the material mapping for pulling the correct name for the section
        const material_mapping = auto_section_material_mappings[board_section_type];

        materials_with_data.forEach(material => {
          const section_type_value = material?.[material_mapping];
          const existing_section = new_sections?.find(
            section => section?.type === board_section_type && section?.name === section_type_value
          );

          if (existing_section) {
            // add material to existing section
            existing_section.ids = uniq([...existing_section.ids, material?.id]);
          } else {
            // create new section to contain this material
            new_sections.push({
              name: section_type_value,
              id: uuidv4(),
              order: (
                (Math.max(
                  ...(board?.sections || [])
                    ?.filter(section => section?.type === board_section_type)
                    .map(section => parseInt(section.order))
                ) || 0) + 1
              ).toString(),
              ids: [material?.id],
              type: board_section_type,
            });
          }
        });

        const new_material_ids = new_sections.reduce((acc, current) => {
          acc.push(...current.ids);
          return acc;
        }, []);

        const palette_id = board.paletteId;
        const ids = uniq([...new_material_ids]);
        await update_board({ ...board, palette_id, ids, sections: new_sections });

        if (ids && palette_id && new_sections) {
          result = true;
        } else {
          result = false;
        }
      } else {
        // for default and custom section boards we just need to add the materials to the board
        let palette_id = board.paletteId;
        const ids = uniq([...material_ids]);

        await add_materials_to_board({ palette_id, ids });
        if (ids && palette_id) {
          result = true;
        } else {
          result = false;
        }
      }
    })
  );

  return result;
};
