import { useDroppable } from '@dnd-kit/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { setNodeRef, isOver } = useDroppable({
    id: props.id,
    data: props.data,
  });

  const style = { border: isOver && props.showOutline ? `1px solid green` : null };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}
