import { Button, ButtonGroup, Modal, Text, Divider } from '@nike/eds';
import useColorScheme from '../hooks/use_color_scheme';
import star_icon from '../assets/star_icon.svg';

import './styles/new_board_modal.css';

export const NewBoardModal = ({ show, on_dismiss, on_click }) => {
  const { appearance } = useColorScheme();

  return (
    <Modal
      isOpen={show}
      onDismiss={on_dismiss}
      hideFade={true}
      className={`new-board-modal ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}
      }`}
      headerSlot={<NewBoardModalHeader />}
    >
      <div>
        <Text className={'new-board-modal__body'} font={'body-3'} as={'h3'}>
          Start by adding materials to your board. You can browse the <br />
          library and add materials one-by-one as you find them, or, <br />
          you can multi-select materials and add them in groups.
        </Text>
        <Divider className="eds-spacing--mt-24" />
        <ButtonGroup className="new-board-modal__footer eds-spacing--mt-24">
          <Button onClick={on_click}>Browse Library</Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

function NewBoardModalHeader() {
  return (
    <div className="new-board-modal__header">
      <img src={star_icon} alt="star" />
      <h1 className="eds-type-title-1">Ahhh, a fresh new board!</h1>
    </div>
  );
}
