import { Snack, Snackbar, Button, Text, Icon, Link } from '@nike/eds';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useToteStore } from '../../hooks/use_tote';
import { useModalStore } from '../../hooks/use_modal';
import './styles/material_snackbar.css';
import { useFeaturesStore } from '../../hooks/use_features';
import { track } from '../../utils/analytics';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';

export const MaterialSnackbar = ({
  show_snack,
  remove_selections,
  selected_materials,
  dark,
  compare_materials,
  enable_move_to,
  onMoveToSectionFinished = () => {},
  actions,
}) => {
  const [open, setOpen] = useState(false);

  const { send_notification } = useNotificationsStore();
  const { add_material_to_tote } = useToteStore();
  const { set_active_modal, set_modal_data } = useModalStore();
  const { features } = useFeaturesStore();
  const navigate = useNavigate();
  const location = useLocation();

  const open_tote_page = () => {
    // TODO: needs to be refactored to pass in the appropriate previous route
    navigate('/tote', { state: { previous_route: location?.pathname?.replaceAll('/', '') } });
    remove_selections();
  };

  const add_to_tote = () => {
    const results = selected_materials.map(materialId => {
      const res = add_material_to_tote(materialId);
      return res;
    });

    let message;
    if (selected_materials?.length > 1) {
      const materials_already_existed_in_tote = results.filter(res => !res);
      const materials_added_to_tote = results.filter(res => res);
      message =
        materials_already_existed_in_tote?.length > 0
          ? `${materials_added_to_tote?.length} Item(s) Added to your Tote. ${materials_already_existed_in_tote?.length} Item(s) Already Existed`
          : `${selected_materials?.length} Item(s) Added to your Tote`;
    } else {
      message = results[0] ? '1 Item(s) Added to your Tote' : 'Item Already Exists in Tote';
    }

    send_notification('success', message, open_tote_page, 'Open Tote');
  };

  // TODO: ADD LOGS HERE
  const openOptions = () => {
    console.log({ selected_materials });
    setOpen(!open);
  };

  const disable_compare = selected_materials?.length > 5 || selected_materials?.length < 2;

  const [is_compare_page, set_is_compare_page] = useState(false);
  useEffect(() => {
    const url = window.location.href;
    set_is_compare_page(url.includes('compare'));
  }, []);

  const open_move_to_modal = () => {
    set_modal_data({ materials: selected_materials, onMoveToSectionFinished });
    set_active_modal('move_to');
  };

  return (
    <Snackbar className={`material-snackbar ${dark ? 'eds--light' : 'eds--dark'}`}>
      {show_snack && (
        <Snack id="material-snack" onDismiss={remove_selections} status="success">
          <div className="material-snack__content">
            {selected_materials?.length ? (
              <Text font="subtitle-2">{selected_materials?.length} Items Selected</Text>
            ) : (
              <Text font="subtitle-2">Click on the Item Cards to Select</Text>
            )}
            {/* yes these are reversed on purpose */}
            <div className={`${dark ? 'eds--light' : 'eds--dark'} material-snack__links`}>
              {selected_materials?.length ? (
                <div className="material-snack__links">
                  <Button
                    variant="ghost"
                    onClick={e => {
                      track({
                        eventName: 'compareMaterials',
                        actionFrom: 'multiSelectSnackbar',
                        totalMaterials: selected_materials?.length,
                      });
                      compare_materials(e);
                    }}
                    disabled={disable_compare}
                  >
                    {!open && !is_compare_page && (
                      <Text font="subtitle-2" className={disable_compare ? 'disabled' : ''}>
                        Compare
                      </Text>
                    )}
                  </Button>
                  {is_compare_page && actions?.remove_material_from_comparison && (
                    <Button
                      variant="ghost"
                      onClick={e => {
                        actions.remove_material_from_comparison(selected_materials);
                        e.stopPropagation();
                      }}
                    >
                      Remove
                    </Button>
                  )}
                  {!open && (
                    <>
                      <Button variant="ghost" onClick={openOptions}>
                        Add To...
                      </Button>
                      {enable_move_to && (
                        <Button variant="ghost" onClick={open_move_to_modal}>
                          Move To...
                        </Button>
                      )}
                    </>
                  )}
                  {open && (
                    <>
                      <Link
                        className="disabled"
                        variant="secondary"
                        font="subtitle-2"
                        onClick={openOptions}
                      >
                        <Icon className="disabled" name="CaretLeft" size="m" />
                        Add To...
                      </Link>
                      <Link font="subtitle-2" variant="secondary" onClick={add_to_tote}>
                        Tote
                      </Link>
                      <Link
                        font="subtitle-2"
                        variant="secondary"
                        onClick={e => {
                          actions?.add_to(e);
                          add_materials_to_boards({
                            ids: selected_materials,
                            trigger_location: 'snackbar',
                          });
                        }}
                      >
                        Boards
                      </Link>
                      {features?.favorites ? (
                        <Link font="subtitle-2" variant="secondary">
                          Favorites
                        </Link>
                      ) : undefined}
                    </>
                  )}
                </div>
              ) : (
                <div className="material-snack__links">
                  <Button variant="ghost" disabled>
                    <Text className="disabled" font="subtitle-2">
                      Compare
                    </Text>
                  </Button>
                  <Button variant="ghost" disabled>
                    <Text className="disabled" font="subtitle-2">
                      Add To...
                    </Text>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Snack>
      )}
    </Snackbar>
  );
};
