import { Text, Table, TableCell, Tooltip, Icon } from '@nike/eds';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { track } from '../../utils/analytics';

const CopyableText = ({ font, as, className, children, field, materialID }) => {
  const { send_notification } = useNotificationsStore();

  const handle_text_copy_click = () => {
    navigator.clipboard.writeText(children);
    send_notification('success', 'Copied to Clipboard');
    track({ eventName: 'copyText', fieldName: field, page: 'materialDetails', materialID });
  };

  return (
    <Tooltip
      bodySlot={
        <div>
          <Icon name="CopyPaste" variant="ghost" />
        </div>
      }
      placement="top"
      delay={0}
    >
      <Text font={font} as={as} className={`${className} pointer`} onClick={handle_text_copy_click}>
        {children}
      </Text>
    </Tooltip>
  );
};

export function Specifications({ material, active_engine }) {
  const specifications_left = [
    {
      label: 'Thickness (mm)',
      key: 'thicknessMm',
      border: 'none',
    },
    {
      label: 'Width (cm)',
      key: 'widthCm',
    },
    {
      label: 'Weight (g/m2)',
      key: 'weightGramsPerSquareMeter',
    },
    {
      label: 'Gauge (Needles per inch)',
      key: 'gaugeInch',
    },
    {
      label: 'Artwork Technique',
      key: 'artworkTechnique',
    },
    {
      label: 'Finishing Process',
      key: 'finishProcess',
    },
  ];

  const specifications_right = [
    {
      label: 'Visual Effect',
      key: 'visualEffect',
      border: 'none',
    },
    {
      label: 'Fabric Face Designation',
      key: 'fabricFaceDesignationApplicationLocation',
    },
    {
      label: 'Fabric Face Designation Description',
      key: 'fabricFaceDesignationDescription',
    },
    {
      label: 'Inner Height (cm)',
      key: 'innerHeightMm',
    },
    {
      label: 'Inner Width (cm)',
      key: 'innerWidthMm',
    },
    {
      label: 'Dye Method',
      key: 'dyeMethod',
    },
    {
      label: 'Trim Shape',
      key: 'componentShapeName',
    },
    {
      label: 'Yarn Size',
      key: 'yarnSize',
    },
    {
      label: 'Yarn Number System',
      key: 'yarnPlyNumberSystem',
    },
  ];

  return (
    <div className="material-details__section eds-spacing--mb-36 eds-spacing--pt-36">
      <Text font="title-6" as="h6">
        Specifications
      </Text>
      <div className="material-details__section-specifications">
        <Table className="material-details__details-table">
          <tbody>
            {specifications_left.map(row => {
              if (
                (active_engine === 'APPAREL' && ['Gauge (Needles per inch)'].includes(row.label)) ||
                (active_engine === 'ACCESSORIES' &&
                  ['Gauge (Needles per inch)', 'Artwork Technique'].includes(row.label)) ||
                (active_engine === 'FOOTWEAR' && ['Artwork Technique'].includes(row.label))
              ) {
                return null;
              }
              return (
                <tr className={row?.border === 'none' ? 'no-border' : ''} key={row?.label}>
                  <TableCell>
                    <Text font="body-2">{row?.label}</Text>
                  </TableCell>
                  <TableCell>
                    <CopyableText font="body-2" field={row?.key} materialID={material?.id}>
                      {row?.label === 'Artwork Technique'
                        ? material?.material?.suppliedMaterials
                            ?.flatMap(suppliedMaterial =>
                              suppliedMaterial?.artworkGraphics?.map(
                                graphic => graphic?.artworkTechnique
                              )
                            )
                            .filter(Boolean)
                            .join(', ') || '- -'
                        : material?.material?.[row?.key] !== undefined
                        ? material?.material?.[row?.key]
                        : '- -'}
                    </CopyableText>
                  </TableCell>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Table className="material-details__details-table right">
          <tbody>
            {specifications_right.map(row => {
              if (
                (active_engine === 'APPAREL' &&
                  [
                    'Inner Height (cm)',
                    'Inner Width (cm)',
                    'Dye Method',
                    'Trim Shape',
                    'Yarn Size',
                    'Yarn Number System',
                  ].includes(row.label)) ||
                (active_engine === 'ACCESSORIES' &&
                  [
                    'Fabric Face Designation',
                    'Fabric Face Designation Description',
                    'Yarn Size',
                    'Yarn Number System',
                  ].includes(row.label)) ||
                (active_engine === 'FOOTWEAR' &&
                  ['Visual Effect', 'Inner Height (cm)', 'Inner Width (cm)', 'Trim Shape'].includes(
                    row.label
                  ))
              ) {
                return null;
              }
              return (
                <tr className={row?.border === 'none' ? 'no-border' : ''} key={row?.label}>
                  <TableCell>
                    <Text font="body-2">{row?.label}</Text>
                  </TableCell>
                  <TableCell>
                    <CopyableText font="body-2" field={row?.key} materialID={material?.id}>
                      {row?.label === 'Visual Effect'
                        ? material?.material?.[row?.key]?.join(', ') || '- -'
                        : material?.material?.[row?.key] || '- -'}
                    </CopyableText>
                  </TableCell>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
