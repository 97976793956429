import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useNotificationsStore = create(
  devtools(
    set => ({
      show_notification: false,
      status: '',
      message: '',
      action: () => {},
      actionText: '',
      autoDismissDuration: 5000,
      hideDismiss: true,
      thumbs_up: false,
      send_notification: (
        status,
        message,
        action = () => {},
        actionText = '',
        hideDismiss = true,
        autoDimissDuration = 5000,
        thumbs_up
      ) => {
        set(() => {
          return {
            show_notification: true,
            message,
            status,
            action,
            actionText,
            hideDismiss,
            autoDimissDuration,
            thumbs_up,
          };
        });
      },
      dismiss_notification: () => {
        set(() => {
          return {
            show_notification: false,
            status: '',
            message: '',
            action: () => {},
            actionText: '',
            hideDismiss: true,
            autoDimissDuration: 5000,
            thumbs_up: false,
          };
        });
      },
    }),
    { name: 'notifications' }
  )
);
