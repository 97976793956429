import { createWriteStream } from 'streamsaver';
import config from '../config';
import http_client from './fetch';

export const get_zip = async params => {
  try {
    const url = new URL(`${config.apis.via.zip}`, config.api_base).toString();
    const result = await http_client({
      url,
      method: 'post',
      data: {
        ...params,
      },
    });
    let zip_id = result?.data;
    return zip_id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const get_download_status = async id => {
  try {
    const url = new URL(`${config.apis.via.zip}/${id}`, config.api_base).toString();
    const result = await http_client({
      url,
      method: 'get',
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export async function download_zip(location, fileName) {
  return fetch(location).then(res => {
    const fileStream = createWriteStream(`${fileName}.zip`);
    const writer = fileStream.getWriter();
    if (res.body.pipeTo) {
      writer.releaseLock();
      return res.body.pipeTo(fileStream);
    }
    const reader = res.body.getReader();
    const pump = () =>
      reader
        .read()
        .then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));
    return pump();
  });
}

export async function download_artifact(artifact_id, name) {
  const url = new URL(`${config.apis.via.artifacts}/${artifact_id}`, config.api_base).toString();
  const result = await http_client.get(url, { responseType: 'blob' });
  const object_url = URL.createObjectURL(result.data);
  const link = document.createElement('a');
  link.href = object_url;
  link.download = name;
  link.click();
  URL.revokeObjectURL(object_url);
  link.remove();
  return result;
}
