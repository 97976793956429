import { useState } from 'react';
import useColorScheme from '../hooks/use_color_scheme';
import { Chip, Icon } from '@nike/eds';

export default function Presets({
  presets,
  setPresets,
  openPreset,
  editPreset,
  isEditing,
  selectedPreset,
  selectPreset,
}) {
  const { appearance } = useColorScheme();
  const [preset_name, set_preset_name] = useState(openPreset?.name);

  const handle_update_name = name => {
    const updatedPresets = presets.map(preset => {
      if (preset === openPreset) {
        return {
          ...preset,
          name: name,
        };
      }
      return preset;
    });
    setPresets(updatedPresets);
  };

  return (
    <div data-testid="presets">
      <div>
        {isEditing && (
          <div className="preset__chips-container">
            <div className="preset__input-container">
              <div className="eds-text-field">
                <input
                  className="eds-text-field__input [eds-text-field__input--error] "
                  id="edit-preset-name"
                  type="text"
                  aria-describedby="element-id-meta"
                  defaultValue={preset_name}
                  onChange={event => {
                    set_preset_name(event.target.value);
                  }}
                />
              </div>
              <Icon
                className="edit-preset"
                name="Check"
                onClick={() => {
                  handle_update_name(preset_name);
                  editPreset(!isEditing);
                }}
              />
              <Icon
                className="edit-preset"
                name="Close"
                onClick={() => {
                  editPreset(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      {!isEditing && (
        <div className="preset__chips-container">
          {presets?.map((preset, index) => {
            return (
              <Chip
                key={`Preset-chip-${index}`}
                className="preset-chip eds-spacing--mr-8 eds-spacing--mb-8"
                style={
                  selectedPreset === preset
                    ? {
                        backgroundColor:
                          appearance === 'dark'
                            ? 'var(--eds-color-white)'
                            : 'var(--eds-color-black)',
                        color:
                          appearance === 'dark'
                            ? 'var(--eds-color-black)'
                            : 'var(--eds-color-white)',
                      }
                    : {}
                }
                beforeSlot={<Icon name="Bookmark" />}
                afterSlot={
                  <Icon
                    name="Ellipsis"
                    className="edit-preset__icon"
                    onClick={() => {
                      editPreset(preset);
                    }}
                  />
                }
                onClick={() => {
                  selectPreset(preset);
                }}
              >
                {preset.name} ({preset.filters.length})
              </Chip>
            );
          })}
        </div>
      )}
      {selectedPreset ? (
        <div>
          <div className="preset__filters-container">
            <h2 className="filters-header" font="subtitle-1">
              Filters Included
            </h2>
            {selectedPreset?.filters?.map((filter, index) => {
              return (
                <Chip
                  key={`Preset-filter-chip-${index}`}
                  className="eds-spacing--mr-8 eds-spacing--mb-8"
                  afterSlot={<Icon name="Close" onClick={() => console.log('remove me please')} />}
                >
                  {filter?.display_name}
                  {filter?.value?.length ? `: ${filter?.value?.toString()}` : ''}
                </Chip>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
