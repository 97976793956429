import { create } from 'zustand';

function sectionGroupStore(set, get) {
  return {
    expandedSections: ['unsectioned'],
    activeGroup: 'custom_sections',
    setActiveGroup(newGroup) {
      return set(state => ({ activeGroup: newGroup }));
    },
    expandSection(sectionId) {
      return set(state => ({ expandedSections: [...get().expandedSections, sectionId] }));
    },
    foldSection(sectionId) {
      return set(state => ({
        expandedSections: get().expandedSections.filter(exid => sectionId !== exid),
      }));
    },
  };
}

export const useSectionGroups = create(sectionGroupStore);
