import cloneDeep from 'lodash.clonedeep';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useLibrarySortsStore = create(
  devtools(
    (set, get) => ({
      selected_sort: 'date_added',
      sorts: {
        date_added: {
          id: 'date_added',
          order: 0,
          display_name: 'Date Added',
          sort_column_asc: 'material.createTimestamp',
          sort_column_desc: 'material.createTimestamp',
          sort_direction: 'desc',
          left_side_label: 'Oldest',
          right_side_label: 'Newest',
          inverse: true,
        },
        lead_time: {
          id: 'lead_time',
          order: 1,
          display_name: 'Lead Time',
          sort_column_asc: 'material.minLeadTimeProduction',
          sort_column_desc: 'material.maxLeadTimeProduction',
          sort_direction: 'asc',
          left_side_label: 'Shortest',
          right_side_label: 'Longest',
          inverse: false,
        },
        price: {
          id: 'price',
          order: 2,
          display_name: 'Price',
          sort_column_asc: 'material.minPrice',
          sort_column_desc: 'material.maxPrice',
          sort_direction: 'asc',
          left_side_label: 'Lowest',
          right_side_label: 'Highest',
          inverse: false,
        },
        weight: {
          id: 'weight',
          order: 3,
          display_name: 'Weight',
          sort_column_asc: 'material.weightGramsPerSquareMeter',
          sort_column_desc: 'material.weightGramsPerSquareMeter',
          sort_direction: 'asc',
          left_side_label: 'Lightest',
          right_side_label: 'Heaviest',
          inverse: false,
        },
      },
      update_state: state => {
        set(() => state);
      },
      get_sort_params: () => {
        const sort_params = {};
        const selected_sort = get().sorts[get().selected_sort];
        sort_params['sortDirection'] = selected_sort.sort_direction;
        sort_params['sortColumn'] =
          selected_sort.sort_direction === 'asc'
            ? selected_sort.sort_column_asc
            : selected_sort.sort_column_desc;

        return sort_params;
      },
      load_sorts_from_search_params: search_params => {
        const search_params_object = Object.fromEntries([...search_params]);
        const new_sorts_state = {
          selected_sort: get().selected_sort,
          sorts: cloneDeep(get().sorts),
        };

        const sort_id = get().get_sort_id(search_params_object['sortColumn']);

        if (sort_id) {
          new_sorts_state['selected_sort'] = sort_id;
          new_sorts_state.sorts[sort_id].sort_direction = search_params_object['sortDirection'];
        }

        get().update_state(new_sorts_state);
      },
      get_sort_id: sort_column => {
        const sort_id = Object.values(get().sorts).find(
          sort => sort?.sort_column_asc === sort_column || sort?.sort_column_desc === sort_column
        )?.id;
        return sort_id;
      },
    }),
    { name: 'library_sorts' }
  )
);
