import { Modal, Text } from '@nike/eds';

import '../manage_board/manage_board_modal.css';
import './create_board_modal.css';
import useColorScheme from '../../hooks/use_color_scheme';
import { use_engine_store } from '../../hooks/use_engine';
import { BoardAccessManagement } from '../board_access_management/board_access_management';
import { BoardName } from '../manage_board/board_name';
import { CreateBoardActions } from './create_board_actions';
import { ManageTeamMembers } from '../manage_board/manage_team_members';
import { NewTeamMembers } from '../manage_board/new_team_members';
import {
  general_user_groups_mapping,
  admin_user_groups_mapping,
} from '../board_access_management/mappings';
import { use_auth_store } from '../../hooks/use_auth';
import { useModalStore } from '../../hooks/use_modal';

export const CreateBoardModal = ({ update_board_response, set_update_board_response }) => {
  const { appearance } = useColorScheme();
  const { active_engine } = use_engine_store();
  const { user } = use_auth_store();
  const { active_modal, dismiss_modal, modal_data } = useModalStore();
  const show = active_modal === 'create_board';

  const all_engine_user_group = general_user_groups_mapping[active_engine];
  const all_engine_admin_group = admin_user_groups_mapping[active_engine];
  const palette_summary = {
    createdByUserId: user?.preferred_username,
    entitlements: [
      { type: 'group', permissions: ['READ'], name: all_engine_user_group },
      { type: 'group', permissions: ['READ', 'WRITE', 'ADMIN'], name: all_engine_admin_group },
    ],
  };

  return (
    <BoardAccessManagement
      on_success={modal_data?.on_success}
      materials={modal_data?.materials}
      is_open={show}
      dismiss={dismiss_modal}
      palette_summary={palette_summary}
      update_board_response={update_board_response}
      set_update_board_response={set_update_board_response}
      render={({
        create,
        reset,
        update_entitlements,
        add_entitled_users,
        is_admin,
        // all_general_users,
        // all_admin_users,
        // general_users,
        // admin_users,
        ad_group_entitilement_name,
        is_general_user_group,
        // entitled_users,
        // user_groups,
        // admin_groups,
        // ad_groups,
        engine_wide_access,
        palette_summary,
        general_user_icons_mapping,
        all_engine_user_group,
        all_engine_admin_group,
        palette_name,
        set_palette_name,
        user_lookup_options,
        set_new_team_members_entitlement,
        new_team_members_entitlement,
        new_team_members,
        set_new_team_members,
        team_members_table,
        entitlements,
        owner,
        active_engine,
        options,
        general_user_options,
        loading,
      }) => {
        return (
          <div className="create-board">
            <Modal
              key={`${active_modal}_${show}`}
              className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'} `}
              isOpen={show}
              onDismiss={dismiss_modal}
              hideFade={true}
              headerSlot={<Text>Create Board</Text>}
              footerSlot={
                <CreateBoardActions
                  primary_action_text={
                    modal_data?.materials?.length ? 'Create and Add' : 'Create Board'
                  }
                  create={create}
                  reset={reset}
                  disableCreate={!palette_name ? true : false}
                  loading={loading}
                />
              }
            >
              <div className="manage-board__content">
                <BoardName
                  has_user_permissions={true}
                  palette_summary={palette_summary}
                  is_admin={true}
                  palette_name={palette_name}
                  set_palette_name={set_palette_name}
                />
                <NewTeamMembers
                  has_user_permissions={true}
                  new_team_members={new_team_members}
                  set_new_team_members={set_new_team_members}
                  user_lookup_options={user_lookup_options}
                  new_team_members_entitlement={new_team_members_entitlement}
                  set_new_team_members_entitlement={set_new_team_members_entitlement}
                  add_entitled_users={add_entitled_users}
                  options={options}
                />
                <ManageTeamMembers
                  has_user_permissions={true}
                  team_members_table={team_members_table}
                  entitlements={entitlements}
                  owner={owner}
                  engine_wide_access={engine_wide_access}
                  is_general_user_group={is_general_user_group}
                  active_engine={active_engine}
                  ad_group_entitilement_name={ad_group_entitilement_name}
                  update_entitlements={update_entitlements}
                  is_admin={true}
                  general_user_options={general_user_options}
                  general_user_icons_mapping={general_user_icons_mapping}
                  options={options}
                />
              </div>
            </Modal>
          </div>
        );
      }}
    />
  );
};
