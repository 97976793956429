import { MaterialImageCarousel } from './material_image_carousel';
import { Details } from './material_details';
import { Specifications } from './material_specifications';
import { Suppliers } from './material_suppliers';
import { use_engine_store } from '../../hooks/use_engine';

import './material_details.css';

export const MaterialDetails = ({ material, hideCarousel, show_image_viewer }) => {
  const { active_engine } = use_engine_store.getState();

  return (
    <div className="material-details eds-spacing--px-32 eds-spacing--pb-32">
      {hideCarousel || (
        <MaterialImageCarousel
          material={material}
          key={`material_image_carousel_${material?.id}`}
          show_image_viewer={show_image_viewer}
        />
      )}
      <Details material={material} active_engine={active_engine} />
      <Specifications material={material} active_engine={active_engine} />
      <Suppliers material={material} active_engine={active_engine} />
    </div>
  );
};
