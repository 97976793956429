import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const use_auth_store = create(
  // needs to persist for the auth flow to work w/ the new react-router apis
  persist(
    set => ({
      user: {},
      set_user: async data => {
        set({ user: data });
      },
    }),
    { name: 'auth' }
  )
);
