import { useState } from 'react';
import { Icon, IconButton, Menu, MenuItem, Text, TextField } from '@nike/eds';
import { DndContext, DragOverlay, useDraggable } from '@dnd-kit/core';
import Droppable from '../droppable';
import { arrayMove } from '@dnd-kit/sortable';
import useColorScheme from '../../hooks/use_color_scheme';

function matchingSection(targetId) {
  return section => {
    return targetId === section?.id;
  };
}

export function CustomSections({
  sections = [],
  reorderSections,
  isAddingSection,
  addNewSection,
  updateSection,
  cancelEdit,
  removeCustomSection,
}) {
  const [activeId, setActiveId] = useState(null);

  function handleDragStart(event) {
    setActiveId(event.active?.id);
  }

  function handleDragEnd(event) {
    const dropTargetId = event.over?.id;
    const dragTargetId = event.active?.id;

    const oldIndex = sections.findIndex(matchingSection(dragTargetId));
    const newIndex = sections.findIndex(matchingSection(dropTargetId));
    const newArr = arrayMove(sections, oldIndex, newIndex);
    reorderSections(newArr);
  }

  return (
    <div className="custom-sections">
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        className="custom-sections-dnd-context"
      >
        {sections?.map(section => (
          <Droppable key={section.id} id={section.id} data={section}>
            <CustomSection
              key={section?.id}
              section={section}
              updateSection={updateSection}
              removeSection={removeCustomSection}
            />
          </Droppable>
        ))}
        <DragOverlay>
          {sections
            ?.filter(section => section.id === activeId)
            .map(section => (
              <CustomSection section={section} key={`drag-overlay-custom-section-${section?.id}`} />
            ))}
        </DragOverlay>
      </DndContext>
      {sections.length > 0 && (
        <CustomSection key="other-materials-custom-section" editable={false} />
      )}
      {isAddingSection && (
        <NewCustomSection addNewSection={addNewSection} cancelEdit={cancelEdit} />
      )}
      {!isAddingSection && sections.length === 0 && (
        <div className="custom-sections__empty">
          <Icon name="Plus" size="l" className="eds-spacing--mb-24 eds-color--default" />
          <Text font="title-5">Click below to add your first section</Text>
        </div>
      )}
    </div>
  );
}

function CustomSection({ section, updateSection, removeSection, editable = true }) {
  const { attributes, listeners, setNodeRef } = useDraggable({ id: section?.id });
  const [menuOpen, setMenuOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [label, setLabel] = useState(editable ? section?.label : 'Other Materials');
  const { appearance } = useColorScheme();

  const cancelEdit = () => {
    setLabel(section?.label);
    setEditing(false);
  };

  const onEditClick = () => {
    setMenuOpen(false);
    setEditing(true);
  };

  const onKeyDownEvent = event => {
    if (event.key === 'Enter' && label) {
      updateSection(section?.id, label);
      setEditing(false);
    }
  };

  const update = () => {
    updateSection(section?.id, label);
    setEditing(false);
  };

  const onDissolveSectionClick = () => {
    setMenuOpen(false);
    removeSection(section?.id);
  };

  return (
    <div className="custom-section" ref={setNodeRef}>
      {!editing && editable && (
        <Menu
          isOpen={menuOpen}
          placement="right"
          onClickOutside={() => setMenuOpen(false)}
          className={appearance === 'dark' ? 'eds--light' : 'eds--dark'}
          bodySlot={
            <>
              <MenuItem onClick={onEditClick}>Rename</MenuItem>
              <MenuItem onClick={onDissolveSectionClick}>Dissolve Section</MenuItem>
            </>
          }
        >
          <IconButton
            icon="Edit"
            variant="ghost"
            className="eds-spacing--mr-16"
            onClick={() => setMenuOpen(true)}
          />
        </Menu>
      )}
      <div className="custom-section__label">
        <TextField
          disabled={!editing}
          value={label}
          onChange={event => setLabel(event.target.value)}
          onKeyDown={onKeyDownEvent}
        />
      </div>
      {editing && (
        <>
          <IconButton icon="Check" onClick={update} variant="ghost" />
          <IconButton icon="Replay" onClick={cancelEdit} variant="ghost" />
        </>
      )}
      {!editing && editable && (
        <div {...listeners} {...attributes} role="button" className="custom-section__drag-handle">
          <Icon
            name="EllipsisVertical"
            size="m"
            color={appearance === 'dark' ? 'white' : 'black'}
            style={{ marginRight: '-7px' }}
          />
          <Icon
            name="EllipsisVertical"
            size="m"
            color={appearance === 'dark' ? 'white' : 'black'}
            style={{ marginLeft: '-7px' }}
          />
        </div>
      )}
    </div>
  );
}

function NewCustomSection({ addNewSection, cancelEdit }) {
  const [newLabel, setNewLabel] = useState('');

  const onKeyDownEvent = event => {
    if (event.key === 'Enter' && newLabel) {
      addNewSection(newLabel);
    }
  };

  return (
    <div className="custom-section">
      <div className="custom-section__label">
        <TextField
          className="custom-section-input"
          id="new-custom-section-input"
          value={newLabel}
          onChange={e => setNewLabel(e.target.value)}
          onKeyDown={e => onKeyDownEvent(e)}
        />
      </div>
      <IconButton icon="Check" onClick={() => addNewSection(newLabel)} variant="ghost" />
      <IconButton icon="Close" onClick={cancelEdit} variant="ghost" />
    </div>
  );
}
