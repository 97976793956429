import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// maintain all  material items within tote
// A tote should be user specific and span across sessions

export const useToteStore = create(
  persist(
    (set, get) => ({
      show_tote_modal: false,
      tote: [],
      update_tote: data => {
        set({ tote: data });
      },
      add_material_to_tote: materialId => {
        const tote = [...get().tote];
        let didnt_exist = false;
        if (!tote.includes(materialId)) {
          tote.push(materialId);
          didnt_exist = true;
        }
        set({ tote });
        return didnt_exist;
      },
      remove_material_from_tote: materialId => {
        const tote = get().tote;
        const material_index = tote.findIndex(id => id === materialId);

        if (material_index >= 0) {
          tote.splice(material_index, 1);
        }
        set({ tote });
      },
      set_show_tote_modal: show => {
        set({ show_tote_modal: show });
      },
    }),
    { name: 'tote_materials' }
  )
);
