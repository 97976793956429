import { useState, useEffect } from 'react';
import { Text, Divider, CheckboxGroup, Checkbox, Icon } from '@nike/eds';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import '../styles/display_settings.css';

const get_item_style = (isDragging, draggableStyle) => ({
  color: isDragging ? 'var(--eds-color-text-default)' : 'transparent',
});

export function MaterialData({ set_local_material_data, local_material_data }) {
  const [list, set_list] = useState(
    Object.values(local_material_data)
      .sort((a, b) => a.order - b.order)
      .map(item => item.id)
  );

  // resets list state
  useEffect(() => {
    set_list(
      Object.values(local_material_data)
        .sort((a, b) => a.order - b.order)
        .map(item => item.id)
    );
  }, [local_material_data]);

  const handle_drag_end = result => {
    if (!result.destination) {
      return; // Item was dropped outside of a droppable area
    }

    const { source, destination } = result;
    const updated_list = Array.from(list); // allows us to mutate the list array before setting it to states
    const [removed] = updated_list.splice(source.index, 1);

    updated_list.splice(destination.index, 0, removed);
    set_list(updated_list);

    // reorder the normalized list of objects by the order of the list array
    const normalized_reordered_list = Object.values(local_material_data).reduce((object, key) => {
      object[key.id] = { ...local_material_data[key.id], order: updated_list.indexOf(key.id) };

      return object;
    }, {});

    set_local_material_data(normalized_reordered_list);
  };

  const toggle_material_data = e => {
    set_local_material_data({
      ...local_material_data,
      [e.target?.id]: {
        ...local_material_data[e.target?.id],
        checked: e.target?.checked,
      },
    });
  };

  return (
    <div className="eds-spacing--mt-24">
      <Text className="eds-spacing--mb-8" font="subtitle-1">
        Material Data
      </Text>
      <Divider color="text-secondary" className="eds-spacing--mb-24" />
      <Text className="eds-spacing--mb-8" font="body-3" color="text-secondary">
        Data to be displayed below item thumbnail image.
      </Text>
      <DragDropContext onDragEnd={handle_drag_end}>
        <Droppable droppableId="list">
          {provided => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="material-data__list"
            >
              {Object.values(local_material_data)
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <CheckboxGroup
                        className="material-data__list"
                        onChange={toggle_material_data}
                      >
                        <li
                          className="material-data__item"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Checkbox
                            id={item.id}
                            key={item.display_name}
                            disabled={item?.disabled}
                            checked={item?.checked}
                            value={item?.facet_property_name}
                            label={item?.display_name}
                          />
                          <div
                            className="material-data__item-icon"
                            style={get_item_style(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Icon name="EllipsisVertical" size="m" />
                          </div>
                        </li>
                      </CheckboxGroup>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
