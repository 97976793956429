export const CARDACTIONS = {
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  ADDTO: 'addto',
  ADDTOBOARD: 'addtoboard',
  ADDTOTOTE: 'addtotote',
  EXPORT: 'export',
  REMOVE: 'remove',
  OPEN: 'open',
  OPENTOTE: 'opentote',
  MOVETOSECTION: 'movetosection',
};
