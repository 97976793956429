import { Icon } from '@nike/eds';
import { useState } from 'react';
import './section_label.css';

export default function SectionLabel({ label, onToggle, isExpanded }) {
  const [caretName, setCaretName] = useState(isExpanded ? 'CaretUp' : 'CaretDown');

  const updateIcon = () => {
    setCaretName(prevCaretName => (prevCaretName === 'CaretDown' ? 'CaretUp' : 'CaretDown'));
    onToggle(caretName);
  };

  return (
    <div
      className="eds-spacing--mx-56 eds-spacing--my-16"
      style={{
        background: 'var(--eds-color-background-default)',
        boxShadow: 'var(--eds-elevation-shadow-2)',
        cursor: 'pointer',
        font: 'var(--eds-type-title-4)',
        padding: '8px 24px',
        color: 'var(--eds-color-text-default)',
      }}
      onClick={updateIcon}
    >
      {label}
      <div className="caret-container">
        <Icon name={caretName} size="m" />
      </div>
    </div>
  );
}
