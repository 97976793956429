import { ManageBoardModal } from '../manage_board/manage_board_modal';
import EmptyBoard from '../empty_board/empty_board';
import { AddToBoardModal } from '../add_to_board/add_to_board_modal';

export function MaterialCollectionModals({
  // add to board modal
  is_add_to_board_open,
  set_is_add_to_board_open,
  is_new_board,
  // empty board modal
  show_new_board_modal,
  set_show_new_board_modal,
  // manage board modal
  show_manage_board,
  palette_summary,
  set_show_manage_board,
  palette_name,
  board_mutation_response,
  set_board_mutation_response,
  has_user_permissions,
}) {
  return (
    <>
      <AddToBoardModal is_open={is_add_to_board_open} set_is_open={set_is_add_to_board_open} />
      {is_new_board ? (
        <EmptyBoard show_modal={show_new_board_modal} set_show_modal={set_show_new_board_modal} />
      ) : undefined}
      {show_manage_board ? (
        <ManageBoardModal
          key={`${palette_summary?.paletteId}_${palette_summary?.updatedTimestamp}`}
          on_dismiss={() => {
            set_show_manage_board(false);
          }}
          is_open={show_manage_board}
          set_is_open={set_show_manage_board}
          palette_summary={palette_summary}
          title={palette_name}
          update_board_response={board_mutation_response}
          set_update_board_response={set_board_mutation_response}
          has_user_permissions={has_user_permissions}
        />
      ) : undefined}
    </>
  );
}
