import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import uniq from 'lodash.uniq';
import { v4 as uuidv4 } from 'uuid';

export const auto_section_type_options = [
  { label: 'Type', value: 'type', material_mapping: 'materialType4' },
  { label: 'Sub-Type', value: 'sub_type', material_mapping: 'materialType5' },
];

export const useSectionsStore = create(
  devtools(
    (set, get) => ({
      raw_sections: [],
      sections: [],
      un_sectioned_ids: [],
      selected_auto_section: undefined,
      section_type: undefined,
      get_sections: () => {
        const section_type = get().section_type;
        const sections = get().sections;
        const filtered_sections = sections.filter(section => section?.type !== section_type);

        return filtered_sections;
      },
      set_sections: (raw_sections, ids) => {
        const section_type = get().section_type || 'custom';

        const sections = raw_sections || get().raw_sections;
        const filtered_sections = sections
          .filter(section => section?.type === section_type)
          ?.sort((a, b) => a?.order - b?.order);

        if (raw_sections?.length > 0) {
          set({ raw_sections: raw_sections });
        }
        const all_sectioned_materials = filtered_sections?.reduce((acc, section) => {
          return [...acc, ...section?.ids];
        }, []);

        const other_materials = ids?.filter(id => !all_sectioned_materials?.includes(id));
        set({ un_sectioned_ids: other_materials });
        const other_section = {
          ids: other_materials,
          name: 'Other Materials',
          order: '9999',
          type: 'other',
          id: uuidv4(),
        };

        if (section_type !== 'default') {
          set({ sections: [...filtered_sections, other_section] });
        } else {
          set({ sections: [] });
        }
      },
      apply_auto_sections: materials => {
        const { raw_sections, selected_auto_section } = get();
        if (materials?.length > 0) {
          const new_sections = [...raw_sections].filter(
            section => section?.type !== selected_auto_section.value
          );
          const auto_type_sections = uniq(
            materials
              ?.map(material => material?.[selected_auto_section?.material_mapping])
              .filter(section => section)
          )
            .sort()
            .map((section_name, index) => {
              return {
                name: section_name,
                id: uuidv4(),
                ids: materials
                  ?.filter(
                    material => material?.[selected_auto_section?.material_mapping] === section_name
                  )
                  ?.map(material => material?.id),
                type: selected_auto_section.value,
                order: index.toString(),
              };
            });

          const other_materials = materials?.filter(
            material => !material?.[selected_auto_section?.material_mapping]
          );
          if (other_materials?.length > 0) {
            const other_section = new_sections.find(section => section.type === 'other');
            other_section.ids = other_materials?.map(material => material?.id);
          }

          new_sections.push(...auto_type_sections);
          set({
            raw_sections: new_sections.sort(sectionA => (sectionA?.type === 'other' ? 1 : -1)),
          });
        }
      },
      update_section: section => {
        const sections = get().raw_sections;
        const new_sections = [...sections];
        const index = new_sections.findIndex(sec => sec?.id === section?.id);

        if (index === -1) {
          const add_section = get().add_section;

          add_section(section);
        } else {
          new_sections[index] = section;
          set({ raw_sections: new_sections });
        }
      },
      remove_section: section => {
        const sections = get().raw_sections;
        const new_sections = [...sections]?.filter(sec => sec?.id !== section?.id);

        let section_type = get().section_type;
        if (section?.type === 'custom') {
          const existingCustomSection = new_sections.find(section => section?.type === 'custom');
          if (!existingCustomSection) {
            section_type = 'default';
          }
        }

        set({ raw_sections: new_sections, section_type });
      },
      set_section_type: data => {
        set({ section_type: data });
      },
      set_un_sectioned_ids: data => {
        set({ un_sectioned_ids: data });
      },
      set_raw_sections: data => {
        set({ raw_sections: data });
      },
      set_selected_auto_section: data => {
        set({ selected_auto_section: data });
      },
      add_section: section => {
        const sections = get().raw_sections?.filter(sec => sec?.type); // only grab sections w/ a type

        set({ raw_sections: [...sections, { ...section }], section_type: 'custom' });
      },
      move_materials_to_section: (destination_section, materialIds) => {
        const new_sections = [...get().raw_sections];
        new_sections.forEach(section => {
          if (section?.type === 'custom' || section?.type === 'other') {
            if (section?.id === destination_section?.id) {
              section.ids = [...section.ids, ...materialIds];
            } else {
              section.ids = section.ids.filter(id => !materialIds.includes(id));
            }
          }
        });
        set({ raw_sections: new_sections });
      },
    }),
    { name: 'sections', version: 1 }
  )
);
