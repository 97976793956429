import { create } from 'zustand';

function customSectionStore(set, get) {
  return {
    customSections: [],
    sectionedMaterials: new Map(),
    setHydrated(isHydrated) {
      return set({ hydrated: isHydrated });
    },
    getCustomSections() {
      return get().customSections;
    },
    getCustomSectionItems(sectionId) {
      return get().sectionedMaterials.get(sectionId);
    },
    addSection(sectionId, label) {
      return set(state => {
        return {
          customSections: [...state.customSections, { id: sectionId, label }],
          sectionedMaterials: state.sectionedMaterials.set(sectionId, []),
        };
      });
    },
    updateSectionLabel(sectionId, label) {
      return set(state => {
        const newSections = state.customSections.map(section => {
          if (section.id === sectionId) {
            return { ...section, label };
          }
          return section;
        });
        return {
          customSections: newSections,
        };
      });
    },
    removeMaterialsFromSections(materialIds) {
      return set(state => {
        const newSectionedMaterials = new Map();
        state.customSections.forEach(section => {
          const sectionMaterials = get().sectionedMaterials.get(section.id);
          const newSectionMaterials = sectionMaterials.filter(
            material => !materialIds.includes(material?.id)
          );
          newSectionedMaterials.set(section.id, newSectionMaterials);
        });
        return {
          sectionedMaterials: newSectionedMaterials,
        };
      });
    },
    removeMaterial(materialId, sectionId) {
      return set(state => {
        const sectionedMaterials = state.sectionedMaterials.get(sectionId);
        const newSectionedMaterials = sectionedMaterials.filter(
          material => material?.id !== materialId
        );
        const newSections = new Map(state.sectionedMaterials);
        newSections.set(sectionId, newSectionedMaterials);
        return {
          sectionedMaterials: newSections,
        };
      });
    },
    removeSection(sectionId) {
      return set(state => {
        const newSections = state.customSections.filter(section => section.id !== sectionId);
        const newSectionedMaterials = new Map(state.sectionedMaterials);
        newSectionedMaterials.delete(sectionId);
        return {
          customSections: newSections,
          sectionedMaterials: newSectionedMaterials,
        };
      });
    },
    reset() {
      return set(state => {
        const localSectionedMaterials = state.sectionedMaterials;
        localSectionedMaterials.clear();
        return {
          sectionedMaterials: localSectionedMaterials,
          customSections: [],
        };
      });
    },
    setCustomSections(newCustomSections) {
      return set({ customSections: newCustomSections });
    },
    getSectionIdByMaterialId(materialId) {
      const keys = Array.from(get().sectionedMaterials.keys());
      const matchingSectionId = keys.find(key => {
        return get()
          .sectionedMaterials.get(key)
          .find(material => material?.id === materialId);
      });

      return matchingSectionId;
    },
    getCustomSectionsForPost() {
      return get().customSections.map((customSection, index) => {
        return {
          order: `${index}`,
          name: customSection.label,
          type: 'custom',
          id: customSection.id,
          ids: get()
            .sectionedMaterials.get(customSection.id)
            .map(material => material?.id),
        };
      });
    },
    setCustomSectionMaterials(sectionId, materials) {
      const newSectionedMaterials = get().sectionedMaterials;
      newSectionedMaterials.set(sectionId, materials);
      return set({ sectionedMaterials: newSectionedMaterials });
    },
    initializeCustomSections(materials, sections) {
      const newSections = [];
      const newSectionedMaterials = new Map();
      const customSections = sections.filter(section => section.type === 'custom');
      customSections.forEach(section => {
        const sectionMaterials = section.ids.map(id =>
          materials.find(material => material.id === id)
        );
        newSections.push({ id: section.id, label: section.name });
        newSectionedMaterials.set(section.id, sectionMaterials);
      });
      return set({ customSections: newSections, sectionedMaterials: newSectionedMaterials });
    },
    moveMaterialsToSection(sectionId, materials) {},
  };
}

export const useCustomSections = create(customSectionStore);
