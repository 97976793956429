import { Text } from '@nike/eds';
import { Blurhash } from 'react-blurhash';
import { useArtifactImageHook } from '../../hooks/use_artifact_image';
import not_found from '../../assets/no_image_found.svg';
import './styles/common.css';
import { get_optimal_3d_image } from '../../utils/get_optimal_3d_image';
import useColorScheme from '../../hooks/use_color_scheme';
import './styles/material_image.css';
import { track } from '../../utils/analytics';

function find_image_asset(assets, view_type) {
  if (view_type === '3D') {
    return get_optimal_3d_image(assets);
  } else {
    view_type = view_type === 'Drape' ? 'Fold/Drape' : view_type;
    const asset = assets?.find(
      item =>
        item?.active === true &&
        item?.assetClass === 'material2d' &&
        item?.assetMetadata?.subClass === 'vml' &&
        item?.assetMetadata?.viewType === view_type
    );
    return asset;
  }
}

function find_image_thumbnail(asset) {
  if (asset?.artifactReferences?.length > 0) {
    return asset?.artifactReferences?.find(
      artifact => artifact?.metadata?.viewType === 'Thumbnail'
    );
  } else if (asset?.contentUrl) {
    return asset;
  } else {
    return null;
  }
}

function find_image_original(asset) {
  if (asset?.artifactReferences?.length > 0) {
    return asset?.artifactReferences?.find(artifact => artifact?.metadata?.viewType === 'Original');
  } else if (asset?.contentUrl) {
    return asset;
  } else {
    return null;
  }
}

function find_image_artifact(assets, view_type) {
  if (view_type === '3D') {
    return get_optimal_3d_image(assets);
  } else {
    view_type = view_type === 'Drape' ? 'Fold/Drape' : view_type;
    const asset = assets?.find(
      item =>
        item?.active === true &&
        item?.assetClass === 'material2d' &&
        item?.assetMetadata?.subClass === 'vml' &&
        item?.assetMetadata?.viewType === view_type
    );

    return find_image_thumbnail(asset);
  }
}

function find_optimal_image_for_viewer(assets, view_type) {
  if (view_type === '3D') {
    return get_optimal_3d_image(assets);
  } else {
    view_type = view_type === 'Drape' ? 'Fold/Drape' : view_type;
    const asset = assets?.find(
      item =>
        item?.active === true &&
        item?.assetClass === 'material2d' &&
        item?.assetMetadata?.subClass === 'vml' &&
        item?.assetMetadata?.viewType === view_type
    );

    return find_image_original(asset);
  }
}

function find_any_image_thumbnail(assets, name) {
  const asset_2d = ['Face', 'Back', 'Fold/Drape'];

  const asset = assets?.find(
    item =>
      item?.active === true &&
      item?.assetClass === 'material2d' &&
      item?.assetMetadata?.subClass === 'vml' &&
      asset_2d.includes(item?.assetMetadata?.viewType)
  );
  const has_3d_image = get_optimal_3d_image(assets);
  const image_of_asset = find_image_thumbnail(asset || has_3d_image);

  return image_of_asset;
}

export function MaterialImage({
  material,
  view_type,
  width,
  height,
  image_type,
  on_image_click,
  on_container_click,
  can_zoom,
}) {
  const image_asset = find_image_asset(material?.assets, view_type);

  const thumbnail_artifact =
    view_type === 'any'
      ? find_any_image_thumbnail(material?.assets, material?.name)
      : find_image_artifact(material?.assets, view_type);
  const blurhash = thumbnail_artifact?.metadata?.blurhash;
  const artifact_url =
    image_type === 'largeImage' && view_type !== '3D'
      ? image_asset?.largeImage
      : thumbnail_artifact?.contentUrl;
  const { status, data } = useArtifactImageHook(artifact_url);

  const caption_below_image = view_type === 'Video' ? 'No Video' : 'No Image';

  function handle_image_click() {
    const optimal_image = find_optimal_image_for_viewer(material?.assets, view_type);
    on_image_click(optimal_image?.contentUrl || artifact_url, material?.name, view_type);

    track({ eventName: 'interacts2dViewer', viewType: view_type, materialID: material?.id });
  }

  if (status === 'error' || !artifact_url) {
    return (
      <MaterialImagePlaceholder
        width={width}
        height={height}
        caption_below_image={caption_below_image}
        on_container_click={on_container_click}
      />
    );
  }

  if (status === 'loading' && artifact_url.length) {
    return (
      <div data-testid="material-image-loading" className="material-image__loading">
        {/* some image artifacts don't have a blurhash */}
        {blurhash && (
          <Blurhash
            className="material-image__blurhash"
            data-testid="material-image__blurhash"
            hash={blurhash}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        )}
      </div>
    );
  }

  return (
    <div className="material-image-container" onClick={on_container_click}>
      <img
        data-testid="material-image"
        className={`material-image${can_zoom && ' can-zoom'}`}
        id={`material-image-${view_type}-${material?.id}`}
        src={data}
        alt={view_type}
        style={{ height, width }}
        onClick={() => on_image_click && handle_image_click()}
      />
    </div>
  );
}

export function MaterialImagePlaceholder({
  width,
  height,
  caption_below_image,
  on_container_click,
}) {
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';

  return (
    <div
      data-testid="material-image-error"
      className={`${dark ? 'eds--dark' : 'eds--light'} material-image__error eds-spacing--mb-8`}
      style={{
        width,
        height,
      }}
      onClick={on_container_click}
    >
      <Text font="title-5" className="truncate" color="grey-2">
        {caption_below_image}
      </Text>
      <img src={not_found} alt="no asset available" data-testid="material-image-error__image" />
      <Text font="title-5" className="truncate" color="grey-2">
        Available
      </Text>
    </div>
  );
}
