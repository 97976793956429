export const compare_materials_table_headers = [
  {
    id: 'type',
    order: 0,
    checked: true,
    display_name: 'Type',
    get_material_data: `data => data?.materialType4`,
  },
  {
    id: 'sub_type',
    order: 1,
    checked: true,
    display_name: 'Sub-Type',
    get_material_data: `data => data?.materialType5`,
  },
  {
    id: 'cost',
    order: 2,
    checked: true,
    display_name: 'Cost',
    get_material_data: `data => {
      if (data?.material?.minPrice && data?.material?.maxPrice) {
        if (data?.material?.minPrice === data?.material?.maxPrice) {
          return '$' + data?.material?.minPrice;
        }
        return '$' + data?.material?.minPrice + ' - ' + data?.material?.minPrice;
      }
    }`,
  },
  {
    id: 'content',
    order: 3,
    checked: true,
    display_name: 'Content',
    get_material_data: `data => {
      const all_contents = data?.material?.matContent?.map(item => {
        const content = item?.matContentPercentage + '% ' + item?.matContentType;

        return content;
      }).join(', ')

      return all_contents
    }`,
  },
  {
    id: 'thickness',
    order: 4,
    checked: true,
    display_name: 'Thickness (mm)',
    get_material_data: `data => {
      if (data?.material?.thicknessMm) {
        return data?.material?.thicknessMm + ' mm';
      }
    }`,
  },
  {
    id: 'width',
    order: 5,
    checked: true,
    display_name: 'Width (cm)',
    get_material_data: `data => {
      if (data?.material?.widthCm) {
        return data?.material?.widthCm + ' cm';
      }
    }`,
  },
  {
    id: 'weight',
    order: 6,
    checked: true,
    display_name: 'Weight (g/m2)',
    get_material_data: `data => {
        if (data?.material?.weightGramsPerSquareMeter) {
          return data?.material?.weightGramsPerSquareMeter + ' g/m2'; 
        }
      }
    `,
  },
  {
    id: 'gauge',
    order: 7,
    checked: true,
    display_name: 'Gauge',
    get_material_data: `data => data?.material?.gaugeInch`,
  },
  {
    id: 'finish_process',
    order: 8,
    checked: true,
    display_name: 'Finish Process',
    get_material_data: `data => data?.material?.finishProcess?.join(', ')`,
  },
  {
    id: 'dye_method',
    order: 9,
    checked: true,
    display_name: 'Dye Method',
    get_material_data: `data => data?.material?.dyeMethod?.join(', ')`,
  },
];

export const material_card_data = [
  {
    id: 'pcx_id',
    display_name: 'PCX ID',
    get_material_data: `data => data?.material?.id`,
  },
  {
    id: 'material_name',
    display_name: 'Material Name',
    get_material_data: `data => data?.material?.name`,
  },
];

export const compare_materials_image_placeholder = [];
