import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Select, Checkbox, CheckboxGroup } from '@nike/eds';
import uniq from 'lodash.uniq';
import { Collapse } from '../components/common/collapse';
import {
  get_filter_chips,
  get_filters_from_search_params,
  set_facet_params,
  clear_all_filters,
} from '../utils/filters';
import cloneDeep from 'lodash.clonedeep';

// the metadata object will hold everything that has to deal w/ altering the basic behaviour of the template

export const usePaletteFilterStore = create(
  devtools(
    (set, get) => ({
      search_query: '',
      list: {
        season: {
          id: 'season',
          order: 1,
          list_type: 'general',
          display_name: 'Season',
          facet_property_name: 'season',
          facet_type: 'select',
          template: props => (
            <Collapse>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                options={props?.options}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => update('season', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        palette_type: {
          id: 'palette_type',
          order: 2,
          list_type: 'general',
          display_name: 'Palette Type',
          facet_property_name: 'paletteGroup',
          facet_type: 'checkbox_group',
          template: props => (
            <Collapse>
              <CheckboxGroup onChange={props?.onChange}>
                {props?.options?.map(option => {
                  return (
                    <Checkbox
                      key={option?.value}
                      checked={props?.value?.includes(option?.value)}
                      value={option?.value}
                      label={option?.label}
                    />
                  );
                })}
              </CheckboxGroup>
            </Collapse>
          ),
          metadata: {
            label_overrides: {
              category: 'Category',
              principal: 'Principal',
              trend: 'Trend',
            },
            data_massager: (value, update = get().update_filter_value, old_value) => {
              const val = value?.target?.value;
              const checked = value?.target?.checked;

              if (old_value?.length) {
                if (checked) {
                  update('palette_type', uniq([...old_value, val]));
                } else {
                  update(
                    'palette_type',
                    old_value.filter(item => item !== val)
                  );
                }
              } else {
                update('palette_type', [val]);
              }
            },
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        pcx_palette_type: {
          id: 'pcx_palette_type',
          order: 3,
          list_type: 'general',
          display_name: 'PCX Palette Type',
          facet_property_name: 'paletteType',
          facet_type: 'checkbox_group',
          template: props => (
            <Collapse>
              <CheckboxGroup onChange={props?.onChange}>
                {props?.options?.map(option => {
                  return (
                    <Checkbox
                      key={option?.value}
                      checked={props?.value?.includes(option?.value)}
                      value={option?.value}
                      label={option?.label}
                    />
                  );
                })}
              </CheckboxGroup>
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value, old_value) => {
              const val = value?.target?.value;
              const checked = value?.target?.checked;

              if (old_value?.length) {
                if (checked) {
                  update('pcx_palette_type', uniq([...old_value, val]));
                } else {
                  update(
                    'pcx_palette_type',
                    old_value.filter(item => item !== val)
                  );
                }
              } else {
                update('pcx_palette_type', [val]);
              }
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
      },
      update_list: list => {
        set(state => {
          return {
            ...state,
            list,
          };
        });
      },
      update_search_query: search_query => {
        set(state => {
          return {
            ...state,
            search_query,
          };
        });
      },
      update_state: state => {
        set(s => {
          return state;
        });
      },
      update: (key, data) => {
        set(state => ({
          ...state,
          list: {
            ...state.list,
            [key]: {
              ...data,
            },
          },
        }));
      },
      update_filter_value: (filter_key, value) => {
        set(state => {
          return {
            ...state,
            list: {
              ...state.list,
              [filter_key]: {
                ...state.list[filter_key],
                metadata: {
                  ...state.list[filter_key].metadata,
                  props: {
                    ...state.list[filter_key].metadata.props,
                    value,
                  },
                },
              },
            },
          };
        });
      },
      clear_filters: () => {
        const list = cloneDeep(get().list);
        clear_all_filters(list);
        get().update_list(list);
      },
      get_filter_params: () => {
        const list = get().list;
        const search_query = get().search_query;
        const params = {
          // sortColumn: 'material.createTimestamp',
          // sortDirection: 'desc',
          source: 'PCX',
          paletteClass: 'material',
          size: 10,
          active: true,
        };

        if (search_query) {
          params['query'] = search_query;
        }

        set_facet_params(list, params);

        return params;
      },
      get_filter_id(key) {
        if (key === 'query') return 'search_query';

        const id = Object.values(get().list).find(filter => filter.facet_property_name === key)?.id;

        return id;
      },
      load_chips: () => {
        const list = get().list;
        const update_method = get().update_filter_value;
        const chips = get_filter_chips(list, update_method);

        return chips;
      },
      load_filters_from_search_params: search_params => {
        const list = get().list;
        const get_filter_id_helper = get().get_filter_id;
        const update_state = get().update_state;

        get_filters_from_search_params(search_params, list, get_filter_id_helper, update_state);
      },
    }),
    { name: 'palette_filters' }
  )
);
