import Droppable from '../droppable';
import SectionLabel from '../section_label';
import './dnd_section.css';
import { useState, useRef } from 'react';
import DNDCard from './dnd_card';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';

export function OtherMaterials({
  items,
  id,
  label,
  sectionIndex,
  shouldExpand = true,
  enableMoveToSection = false,
  enableEdit = false,
  onClick,
  onChange,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef(null);
  const { sizes, size } = useDisplaySettingsStore();

  const handleToggle = () => {
    onClick(id);
  };

  if (containerRef.current) {
    // to help with item overflow onto new rows
    const additionalPadding = 256;
    // Set a CSS variable with the height of the container
    containerRef.current.style.setProperty(
      '--container-height',
      `${containerRef.current.scrollHeight + additionalPadding}px`
    );
  }

  if (!isExpanded && shouldExpand) {
    setIsExpanded(true);
  } else if (isExpanded && !shouldExpand) {
    setIsExpanded(false);
  }

  const sizeInPixels = sizes[size];

  return (
    <Droppable id={id} data={{ section: true, sectionId: id, isEmpty: items.length === 0 }}>
      <div className="eds-spacing--pb-24 eds-spacing--pt-12">
        {label ? (
          <SectionLabel
            label={`${label} ${items ? `(${items.length})` : '(0)'}`}
            onToggle={handleToggle}
            isExpanded={isExpanded}
          />
        ) : undefined}
        <div
          ref={containerRef}
          className={`section-container ${isExpanded ? 'expanded' : ''}`}
          style={{
            gridTemplateColumns: `repeat(auto-fill, minmax(${sizeInPixels}px, 1fr))`,
          }}
        >
          {items &&
            items.map((item, index) => (
              <DNDCard
                item={item}
                key={item.id}
                itemId={item.id}
                itemIndex={index}
                sectionIndex={sectionIndex}
                sectionId={id}
                name={item.name}
                isVisible={isExpanded}
                assets={item.assets}
                enableMoveToSection={enableMoveToSection}
                onCardAction={onChange}
                enableEdit={enableEdit}
              />
            ))}
        </div>
      </div>
    </Droppable>
  );
}
