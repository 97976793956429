import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { MaterialDetails } from '../../components/material_details';
import useColorScheme from '../../hooks/use_color_scheme';
import { MaterialHeader } from '../../components/material_details/material_header';
import './material.css';
import { track } from '../../utils/analytics';

export default function Material() {
  const { appearance } = useColorScheme();
  const material = useLoaderData();

  useEffect(() => {
    track({ eventName: 'viewMaterialDetailsPage', materialID: material?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`material page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}>
      <div className="page-contents">
        <MaterialHeader material={material} />
        <MaterialDetails material={material} />
      </div>
    </div>
  );
}
