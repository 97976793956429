import { Link, Text } from '@nike/eds';
import { useNavigate } from 'react-router-dom';

import { Collapse } from '../common/collapse';
import { Grid } from '../common/grid';
import { MaterialCard } from '../common/material_card';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useModalStore } from '../../hooks/use_modal';

export function Section({
  section,
  sections,
  materials,
  all_materials,
  palette_summary,
  ids,
  status,
  update_selected_materials,
  is_board_page,
  selected_materials,
  active_card,
  set_active_card,
  open_material,
  set_active_modal,
  set_modal_data,
  set_export_materials,
  set_total_materials,
  set_show_tote_modal,
  add_material_to_tote,
  set_multi_select,
  multi_select,
  palette_id,
  board_mutation,
  set_materials,
  set_sections,
  // infinite scroll props
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}) {
  const open_section = materials?.length > 0 ? true : false;
  const { send_notification, dismiss_notification } = useNotificationsStore();
  const { set_trigger_location } = useModalStore();
  const navigate = useNavigate();

  const handle_go_to_board = (e, board) => {
    e.stopPropagation();
    dismiss_notification();
    navigate(`/boards/${board?.paletteId}`);
  };

  const has_user_permissions =
    palette_summary?.currentUserAccess?.includes('WRITE') ||
    palette_summary?.currentUserAccess?.includes('ADMIN');

  return (
    <div className="board-section eds-spacing--mb-16">
      <Text font="title-4" className="board-section__name">{`${section?.name} (${
        section?.ids?.length || 0
      })`}</Text>
      <Collapse open={open_section}>
        {materials?.length ? (
          <Grid
            multi_select={multi_select}
            select_materials={update_selected_materials}
            selected_materials={selected_materials}
            status={status}
            data={materials}
            set_data={updated_data => {
              if (palette_id) {
                const updated_ids = updated_data.map(item => item.id);

                const updated_sections = palette_summary?.sections?.map(item => {
                  const is_the_one = item?.type === section?.type && item?.name === section?.name;
                  console.log({ section, item, is_the_one });

                  if (is_the_one) {
                    return { ...item, ids: updated_ids };
                  } else {
                    return { ...item };
                  }
                });

                // TODO: reorder the section ids
                // trigger the board mutation to update a board
                board_mutation.mutate({
                  ...palette_summary,
                  ids: ids,
                  activeSectionsType: section?.type,
                  palette_id,
                  sections: updated_sections,
                  method: 'put',
                });
                set_sections(updated_sections);
              }
              // set_materials(updated_sections);
            }}
            dnd={is_board_page && has_user_permissions}
            // this keys allows the drag select component properly update when we need it to
            // the material_card_ref is defined in the Grid component and then passed into the "card" as the 2nd parameter
            card={(material, material_cad_ref, select_item) => (
              <li
                key={material?.id}
                className="material-card__selectable"
                ref={material_cad_ref}
                id={material?.id}
              >
                <MaterialCard
                  material={material}
                  multi_select={multi_select}
                  selected={selected_materials?.includes(material?.id)}
                  active={active_card === material?.id}
                  set_active_card={set_active_card}
                  show_card_actions={true}
                  palette_edit_access={(palette_summary?.currentUserAccess || []).includes('WRITE')}
                  actions={{
                    select_material: select_item,
                    open: open_material,
                    activate_multi_select: () => set_multi_select(true),
                    add_material_to_tote,
                    add_to: () => {
                      set_active_modal('add_to');
                      set_trigger_location('materialCardMenuModal');
                      set_modal_data({ materials: [material?.id] });
                    },
                    add_to_board: async board => {
                      try {
                        await add_materials_to_boards({
                          boards: [board],
                          material_ids: [material?.id],
                          trigger_location: 'material_card',
                        });
                        send_notification(
                          'success',
                          'Material Added to Board',
                          () => dismiss_notification(),
                          <>
                            <Link onClick={e => handle_go_to_board(e, board)}>Go to Board</Link>
                          </>
                        );
                      } catch (error) {
                        console.error(`Error adding material to board:`, error.message);
                        send_notification('error', 'Error Adding Material to Board');
                      }
                    },
                    export: material => {
                      if (!has_user_permissions) return;
                      set_active_modal('export');
                      set_export_materials([material]);
                      set_total_materials(1);
                      set_trigger_location('materialCardMenu');
                    },
                    open_tote: () => set_show_tote_modal(true),
                    remove_material_from_board: material => {
                      if (!has_user_permissions) return;
                      if (palette_id) {
                        // ids has all of the materials and not just the ones in the section so we need to filter out the one we want to remove
                        const new_board_materials = all_materials
                          ?.filter(item => item.id !== material)
                          ?.map(item => item);

                        set_materials(new_board_materials);
                        // we need to use the sections that are in the palette so we're not saving the catchall  `Other Materials` section as a section on the palette
                        const new_board_sections = palette_summary?.sections?.map(item => {
                          return {
                            ...item,
                            ids: item?.ids?.filter(id => id !== material) || [],
                          };
                        });
                        const new_ids = ids.filter(item => item !== material);
                        board_mutation.mutate({
                          ...palette_summary,
                          palette_id,
                          ids: new_ids,
                          sections: new_board_sections,
                          method: 'put',
                        });
                      }
                    },
                  }}
                />
              </li>
            )}
            // card_height={240}
            // card_width={180}
            gap={24}
            // infinite scroll props
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : undefined}
      </Collapse>
    </div>
  );
}
