import { MaterialSnackbar } from '../common/material_snackbar';
import { FilterList } from '../filter_list';
import { DisplaySettings } from '../display_settings';
import { LibrarySortsSidePanel } from '../library_sorts_side_panel';
import { MaterialDetailsSidePanel } from '../material_details/material_details_side_panel';
import { SectionSidePanel } from '../sections_side_panel/sections_side_panel';
import { useLocation, useNavigate } from 'react-router-dom';

export function MaterialCollectionPanels({
  // material details side panel
  show_material,
  set_show_material,
  active_material,
  next_material,
  previous_material,
  // library sort side panel
  show_sorts,
  set_show_sorts,
  // sections side panel
  show_sections_panel,
  set_show_sections_panel,
  palette_summary,
  set_palette_summary,
  materials,
  // filter list side panel
  load_chips,
  active_tab,
  set_active_tab,
  show_filters,
  set_show_filters,
  // display settings side panel
  show_display_settings,
  set_show_display_settings,
  // material snackbar
  dark,
  selected_materials,
  remove_selections,
  show_snack,
  set_active_modal,
  set_modal_data,
  set_trigger_location,
  palette_name,
  title,
  onSectionReorder,
  onSectionAdded,
  onDissolveSections,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <MaterialDetailsSidePanel
        show_material={show_material}
        set_show_material={set_show_material}
        material={active_material}
        next_material={next_material}
        previous_material={previous_material}
      />
      <LibrarySortsSidePanel show_sorts={show_sorts} set_show_sorts={set_show_sorts} />
      <SectionSidePanel
        show={show_sections_panel}
        set_show={set_show_sections_panel}
        palette_summary={palette_summary}
        set_palette_summary={set_palette_summary}
        materials={materials}
        onSectionAdded={onSectionAdded}
        onDissolve={onDissolveSections}
        onSectionReorder={onSectionReorder}
      />
      <FilterList
        active_tab={active_tab}
        set_active_tab={set_active_tab}
        show_filters={show_filters}
        set_show_filters={set_show_filters}
      />
      <DisplaySettings
        compare_table_data={false}
        show_display_settings={show_display_settings}
        set_show_display_settings={set_show_display_settings}
      />
      <MaterialSnackbar
        dark={dark}
        selected_materials={selected_materials}
        remove_selections={remove_selections}
        show_snack={show_snack}
        enable_move_to={
          palette_summary?.currentUserAccess?.includes('WRITE') &&
          palette_summary?.activeSectionsType === 'custom'
        }
        actions={{
          add_to: () => {
            set_active_modal('add_to');
            set_trigger_location('multiSelectSnackbar');
            set_modal_data({ materials: selected_materials, on_success: remove_selections });
          },
        }}
        // TODO: might need to rethink previous_route for palettes/boards
        compare_materials={event => {
          event.stopPropagation();
          navigate(`/compare?ids=${selected_materials?.toString()}`, {
            state: {
              id: selected_materials,
              previous: { ...location, name: palette_name },
              previous_route:
                title?.toLowerCase() === 'material library' ? 'library' : title?.toLowerCase(),
            },
          });
        }}
      />
    </>
  );
}
