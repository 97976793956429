import { Text, Divider, Select } from '@nike/eds';
import '../styles/display_settings.css';

export function ThumbnailView({ local_view_type, set_local_view_type }) {
  return (
    <div className="eds-spacing--mt-24">
      <Text className="eds-spacing--mb-8" font="subtitle-1">
        Thumbnail View
      </Text>
      <Divider color="text-secondary" className="eds-spacing--mb-24" />
      <Select
        className="thumbnail-view"
        placeholder="Default"
        options={[
          { value: 'Face', label: 'Face' },
          { value: 'Back', label: 'Back' },
          { value: 'Drape', label: 'Drape' },
          { value: '3D', label: '3D' },
        ]}
        value={{ value: local_view_type, label: local_view_type }}
        onChange={e => set_local_view_type(e?.value)}
      />
    </div>
  );
}
