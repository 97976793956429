import React, { createContext, useState, useEffect, useContext } from 'react';
import DragSelect from 'dragselect';
import { useSelectStore } from '../hooks/use_select';

const Context = createContext(undefined);

function DragSelectProvider({
  children,
  settings = {
    selectables: document.getElementsByClassName('material-card__selectable'),
    selectedClass: 'material-card__selected-blank',
  },
}) {
  const [ds, set_ds] = useState();
  const { multi_select } = useSelectStore();
  useEffect(() => {
    set_ds(prevState => {
      if (prevState) {
        return prevState;
      }

      return new DragSelect({});
    });
    return () => {
      if (ds) {
        ds.stop();
        set_ds(undefined);
      }
    };
  }, [ds]);

  useEffect(() => {
    ds?.setSettings({
      multiSelectMode: true,
      draggability: true,
      area: multi_select
        ? // set this to page contents so that we can select between sections in boards
          // before it was just pullin the first grid container and setting it to that
          // that was causing an issue of allowing us to select materials between sections
          document.getElementById('multi_select_area')
        : document.getElementById('hidden_select_area'),
      ...settings,
    });
  }, [ds, settings, multi_select]);
  // clear out selected items when we toggle off multi_select mode
  useEffect(() => {
    // TODO: this might be where we can fix the issue w/ selecting the first item
    // when multi select mode is turned on
    if (!multi_select && ds) {
      ds.clearSelection();
    }
  }, [multi_select, ds]);

  return <Context.Provider value={ds}>{children}</Context.Provider>;
}

function useDragSelect() {
  return useContext(Context);
}

export { DragSelectProvider, useDragSelect };
