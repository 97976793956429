import { Text, SidePanel, Icon } from '@nike/eds';
import { useEffect, useState } from 'react';
import { useLibraryFilterStore } from '../hooks/use_library_filters';
import './styles/filter_list.css';
import { get_filter_chips } from '../utils/filters';
import { v4 as uuidv4 } from 'uuid';
import { fetch_preferences, set_preferences } from '../hooks/use_user';
import Presets from './presets';
import Filters from './filters';
import FilterListFooter from './filter_list_footer';
import FilterListHeader from './filter_list_header';

export function FilterList({ active_tab, set_active_tab, show_filters, set_show_filters }) {
  const { list, clear_filters } = useLibraryFilterStore();
  const [general_filters, set_general_filters] = useState([]);
  const [advanced_filters, set_advanced_filters] = useState([]);
  const [presets, set_presets] = useState([]);
  const [isEditingPreset, setIsEditingPreset] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [open_preset, set_open_preset] = useState();
  const [count, set_filter_count] = useState({ total: 0, general_count: 0, advanced_count: 0 });

  useEffect(() => {
    set_general_filters(Object.values(list).filter(filter => filter.list_type === 'general'));
    set_advanced_filters(Object.values(list).filter(filter => filter.list_type === 'advanced'));
    set_filter_count(get_filter_count(list));
  }, [list]);

  useEffect(() => {
    fetch_preferences().then(data => {
      if (data?.material_filters) {
        set_presets(JSON.parse(data.material_filters));
      }
    });
  }, []);

  const filtersMap = {
    advanced: advanced_filters,
    general: general_filters,
    presets: presets,
  };

  const handle_save_preset = () => {
    const new_preset = {
      id: uuidv4(),
      name: `New Preset ${presets.length + 1}`,
      filters: get_filter_chips(list),
    };
    set_open_preset(new_preset);
    set_active_tab('presets');

    set_presets([...presets, new_preset]);
    const post_data = [...presets, new_preset];
    set_preferences('material_filters', post_data);
  };

  const switchTabs = e => set_active_tab(e.target.id);
  const savePreset = e => (active_tab !== 'presets' ? handle_save_preset() : null);
  const handle_clear_all_click = () => {
    clear_filters();
  };

  return (
    <SidePanel
      isOpen={show_filters}
      onDismiss={() => set_show_filters(false)}
      headerSlot={
        <FilterListHeader count={count} activeTab={active_tab} onSelectTab={switchTabs} />
      }
      footerSlot={
        <FilterListFooter onClear={handle_clear_all_click} count={count} onSave={savePreset} />
      }
    >
      <div className="filter-list">
        <div className="filter-list__body">
          {active_tab === 'presets' && !presets.length ? (
            <div className="presets-copy" data-testid="presets-placeholder">
              <Icon size="m" name="Bookmark" />
              <Text font="title-5" className="eds-spacing--px-24">
                You don't have any filter presets saved... yet...
              </Text>
              <Text font="body-3" className="eds-color--secondary eds-spacing--pt-12">
                To save a filter preset, activate a combination of filters and save that filter set
                as a Preset. You can name that preset and will see them listed here under the
                "Preset" tab
              </Text>
            </div>
          ) : active_tab === 'presets' ? (
            <Presets
              presets={presets}
              setPresets={set_presets}
              openPreset={open_preset}
              editPreset={setIsEditingPreset}
              isEditing={isEditingPreset}
              selectPreset={setSelectedPreset}
              selectedPreset={selectedPreset}
            />
          ) : (
            <Filters filters={filtersMap[active_tab]} />
          )}
        </div>
      </div>
    </SidePanel>
  );
}

function get_filter_count(filter_list) {
  let total = 0;
  let general_count = 0;
  let advanced_count = 0;

  Object.values(filter_list).forEach(filter => {
    const value = filter?.metadata?.props?.value;
    const key = filter?.id;
    const facet_type = filter?.facet_type;

    if (key === 'material_status' && value !== undefined) {
      total += 1;
      general_count += 1;
    } else if (facet_type === 'toggle') {
      if ((key.includes('has_no') || key.includes('has_all_asset')) && value === false) {
        total += 1;
        filter?.list_type === 'general' ? (general_count += 1) : (advanced_count += 1);
      } else if (value === true) {
        total += 1;
        filter?.list_type === 'general' ? (general_count += 1) : (advanced_count += 1);
      }
    } else if (facet_type === 'select' && value?.length > 0) {
      const count = value?.length;
      total += count;
      filter?.list_type === 'general' ? (general_count += count) : (advanced_count += count);
    } else if (facet_type === 'min_max') {
      if (value?.minValue || value?.maxValue) {
        total += 1;
        filter?.list_type === 'general' ? (general_count += 1) : (advanced_count += 1);
      }
    } else if (facet_type === 'checkbox') {
      if (Array.isArray(value) && value?.length > 0) {
        total += value?.length;
        filter?.list_type === 'general'
          ? (general_count += value?.length)
          : (advanced_count += value?.length);
      } else if (!Array.isArray(value) && value !== undefined) {
        total += 1;
        filter?.list_type === 'general' ? (general_count += 1) : (advanced_count += 1);
      }
    } else if (facet_type === 'checkbox_group') {
      if (Array.isArray(value) && value?.length > 0) {
        total += value?.length;
        filter?.list_type === 'general'
          ? (general_count += value?.length)
          : (advanced_count += value?.length);
      } else if (!Array.isArray(value) && value !== undefined) {
        total += 1;
        filter?.list_type === 'general' ? (general_count += 1) : (advanced_count += 1);
      }
    }
  });

  return { total, general_count, advanced_count };
}
