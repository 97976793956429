import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Grid } from '../../components/common/grid';
import { BoardFilterList } from '../../components/board_filter_list';
import { Header } from '../../components/header';
import { getBoardSummaries, useBoardsHook } from '../../hooks/use_boards';
import { useBoardFilterStore } from '../../hooks/use_board_filters';
import { useLibraryFilterStore } from '../../hooks/use_library_filters';
import useColorScheme from '../../hooks/use_color_scheme';
import { BoardCard } from '../../components/common/board_card';
import { use_engine_store } from '../../hooks/use_engine';
import { use_auth_store } from '../../hooks/use_auth';
import { useFacetStore } from '../../hooks/use_facets';
import { useModalStore } from '../../hooks/use_modal';
import { useCustomSections } from '../../hooks/use_custom_sections';

async function boards_facets() {
  try {
    const { active_engine } = use_engine_store.getState();
    const { board_facets, set_board_facets } = useFacetStore.getState();
    const res = await getBoardSummaries({
      active: true,
      paletteClass: 'material',
      size: 1,
      source: 'DPC',
    });
    const summary = res?.results?.[0];
    const facets = res?.facets;

    if (!Object.values(board_facets)?.length || active_engine !== board_facets?.engine) {
      facets.engine = active_engine;

      await set_board_facets(facets);
    }

    return summary;
  } catch (error) {
    console.warn(error?.message);
  }
}

export default function Boards({ title }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';
  const [boards, set_boards] = useState([]);
  const [page_cursor, set_page_cursor] = useState(0);
  const [total, set_total] = useState(0);
  const [show_filters, set_show_filters] = useState(false);
  const [active_tab, set_active_tab] = useState('general');
  const { user } = use_auth_store();

  useEffect(() => {
    boards_facets();
  }, []);

  const { reset } = useCustomSections();
  const { set_active_modal } = useModalStore();
  const board_filter_store = useBoardFilterStore();
  const {
    list,
    update_filter_value,
    load_chips,
    search_query,
    get_filter_params,
    load_filters_from_search_params,
  } = board_filter_store;
  const library_filter_store = useLibraryFilterStore();

  const [search_params, set_search_params] = useSearchParams();
  const [params, set_params] = useState();
  const [boards_page_title, set_board_page_title] = useState();
  // when the filter list is updated we will update our search parameters
  // this will then trigger the bellow useEffect
  useEffect(() => {
    const params = get_filter_params();
    const engine_store = use_engine_store.getState();
    const active_engine = engine_store?.active_engine;

    params.division = `${active_engine} DIVISION`;
    // remove fields.includes from window url
    delete params['fields.includes'];
    set_search_params({ ...params });
  }, [list, search_query, get_filter_params, set_search_params]);

  // load filters from the search params and set them in the filter store
  // when we set the params that will triger the useMaterialsHook to run loading in new materials
  useEffect(() => {
    load_filters_from_search_params(search_params);
    const params = get_filter_params();
    const engine_store = use_engine_store.getState();
    const active_engine = engine_store?.active_engine;

    params.division = `${active_engine} DIVISION`;
    set_params({ ...params });
  }, [search_params, load_filters_from_search_params, get_filter_params]);

  const { status, data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useBoardsHook(params);

  useEffect(() => {
    set_boards(data?.pages?.map(item => item.results).flat());
    set_page_cursor(data?.pages?.length || 0);
    set_total(data?.pages?.[0]?.pagination?.total);
  }, [data]);

  // since useInfiniteQuery returns pages and we want the page results we're gonna just handle that in local state
  if (data?.pages?.length > page_cursor) {
    set_boards(data?.pages?.map(item => item.results).flat());
    set_page_cursor(data?.pages?.length || 0);
  }

  const filter_params = get_filter_params();

  useEffect(() => {
    const selected_board_page =
      filter_params?.createdByUserId === user.preferred_username ? `My ${title}` : `All ${title}`;

    set_board_page_title(selected_board_page);
  }, [filter_params, user, set_board_page_title, title]);

  const header_actions = {
    open_create_board_modal: () => {
      set_active_modal('create_board');
    },
  };

  function BoardHeader({ header_filters }) {
    return (
      <Header
        actions={{
          show_filter: () => set_show_filters(!show_filters),
          open_create_board_modal: () => set_active_modal('create_board'),
        }}
        filters={load_chips() || []}
        update_filter_value={update_filter_value}
        filter_params={filter_params}
        dark={dark}
        title={boards_page_title}
        total={total}
        filter_store={board_filter_store}
        header_filters={header_filters}
        icon_row={{ filter: true, create_board: true }}
      />
    );
  }

  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`}>
      <div className="page-header">
        {boards_page_title === 'My Boards' ? (
          <BoardHeader
            header_filters={() => (
              <div className="bottom-left-container eds-spacing--pt-8">
                <div className="filters-text-container"></div>
              </div>
            )}
          />
        ) : (
          <BoardHeader actions={{ header_actions }} />
        )}
      </div>
      <div className="page-contents">
        <Grid
          status={status}
          data={boards}
          card={board => (
            <li
              key={`${board?.paletteId}-${board?.name}`}
              className={`${board?.paletteId}-${board?.name}`}
            >
              <BoardCard
                palette={board}
                actions={{
                  open: () => {
                    // reset local section data
                    reset();
                    library_filter_store.clear_filters();
                    navigate(`/boards/${board.paletteId}`, {
                      state: {
                        palette_name: board?.name,
                        previous: location,
                      },
                    });
                  },
                }}
              ></BoardCard>
            </li>
          )}
          card_size={380}
          gap={48}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
        <BoardFilterList
          active_tab={active_tab}
          set_active_tab={set_active_tab}
          show_filters={show_filters}
          set_show_filters={set_show_filters}
        />
      </div>
    </div>
  );
}
