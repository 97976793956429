import { useState } from 'react';
import http_client from '../../utils/fetch';
import Draggable from '../draggable';
import Droppable from '../droppable';
import { MaterialCard } from './material_card';
import './dnd_card.css';
import { CARDACTIONS } from '../../utils/cardactions';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';
import { useSelectStore } from '../../hooks/use_select';

export default function DNDCard({
  item,
  itemId,
  sectionId,
  assets,
  onCardAction,
  enableMoveToSection,
  enableEdit,
  selectRef,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const { view_type } = useDisplaySettingsStore();
  const { multi_select, selected_materials } = useSelectStore();

  const assetClass3D = ['trim3d', 'airbag3d', 'material3d'];
  const subClass3D = [
    'mayavray',
    'axf',
    'substance',
    'substancevizoo',
    'apparezfabl',
    'clo',
    'apparelu3m',
    'airmi',
  ];

  const realAssets = assets.filter(asset => assetClass3D.includes(asset.assetClass));

  const validThumbnails = assets?.filter(
    asset =>
      asset.active && asset.assetClass === 'material2d' && asset.assetMetadata.subClass === 'vml'
  );

  const face = validThumbnails.find(thumbnail => thumbnail.assetMetadata.viewType === 'Face');
  const back = validThumbnails.find(thumbnail => thumbnail.assetMetadata.viewType === 'Back');
  const fold = validThumbnails.find(thumbnail => thumbnail.assetMetadata.viewType === 'Fold/Drape');
  const realThumbnails = realAssets.filter(asset =>
    subClass3D.includes(asset.assetMetadata.subClass)
  );

  const availableThumbnails = [face, fold, back, ...realThumbnails].filter(thumbnail => thumbnail);
  if (availableThumbnails.length > 0 && !imgSrc) {
    const desiredThumbnail = availableThumbnails.find(
      thumbnail =>
        (view_type === '3D' && thumbnail.assetClass === 'material3d') ||
        view_type === thumbnail.assetMetadata.viewType
    );

    if (desiredThumbnail) {
      http_client.get(desiredThumbnail.thumbnail, { responseType: 'blob' }).then(res => {
        const url = URL.createObjectURL(res.data);
        setImgSrc(url);
      });
    }
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="dnd-card material-card__selectable"
      ref={selectRef}
      id={itemId}
    >
      <Draggable
        key={itemId}
        id={itemId}
        data={{ id: itemId, sectionId, material: item, view_type }}
        disabled={enableEdit ? false : true}
      >
        <Droppable id={itemId} data={{ id: itemId, sectionId }}>
          <MaterialCard
            actions={{
              select_material: () => onCardAction(itemId, CARDACTIONS.SELECT),
              open: () => onCardAction(itemId, CARDACTIONS.OPEN),
              activate_multi_select: () => onCardAction(itemId, CARDACTIONS.MULTISELECT),
              add_material_to_tote: () => onCardAction(itemId, CARDACTIONS.ADDTOTOTE),
              add_to: () => {
                onCardAction(itemId, CARDACTIONS.ADDTO);
              },
              add_to_board: board => {
                onCardAction(itemId, CARDACTIONS.ADDTOBOARD, board);
              },
              export: material => {
                onCardAction(itemId, CARDACTIONS.EXPORT, material);
              },
              open_tote: () => onCardAction(itemId, CARDACTIONS.OPENTOTE),
              remove_material_from_board: enableEdit
                ? material => {
                    onCardAction(itemId, CARDACTIONS.REMOVE, material);
                  }
                : undefined,
              move_to_section: enableMoveToSection
                ? () => {
                    onCardAction(itemId, CARDACTIONS.MOVETOSECTION, sectionId);
                  }
                : undefined,
            }}
            active={isHovered}
            material={item}
            show_card_actions={true}
            onUpdateMaterial={onCardAction}
            selected={selected_materials.includes(itemId)}
            multi_select={multi_select}
          />
        </Droppable>
      </Draggable>
    </div>
  );
}
