import { useState, useEffect } from 'react';

import { useListBoardsHook } from '../../hooks/use_boards';
import { use_engine_store } from '../../hooks/use_engine';
import { use_auth_store } from '../../hooks/use_auth';
import './add_to_board_modal.css';
import { AddBoardSearch } from './add_to_board_search';
import { AddBoardTable } from './add_to_board_table';

export function AddToBoard({ set_defaults, set_disabled, selected_boards, set_selected_boards }) {
  const { user } = use_auth_store();
  const [board_name, set_board_name] = useState();
  const [select_all, set_select_all] = useState(false);
  const [board_creator, set_board_creator] = useState({
    label: user?.preferred_username,
    value: user?.preferred_username,
  });
  const [board_create_options, set_board_creator_options] = useState([]);
  const [boards, set_boards] = useState([]);
  const { active_engine } = use_engine_store();
  const [params, set_params] = useState({
    sortColumn: 'updatedTimestamp',
    sortDirection: 'desc',
    division: `${active_engine} DIVISION`,
    size: 25,
  });

  useEffect(() => {
    if (set_defaults) {
      set_board_creator({
        label: user?.preferred_username,
        value: user?.preferred_username,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [set_defaults]);

  useEffect(() => {
    if (selected_boards?.length) {
      set_disabled(false);
    } else {
      set_disabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_boards]);

  useEffect(() => {
    // TODO: add debounce
    set_params({
      ...params,
      query: board_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_name]);

  useEffect(() => {
    set_params({
      ...params,
      createdByUserId: board_creator?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_creator]);

  const { status, data, fetchNextPage, hasNextPage } = useListBoardsHook(params);

  // use facet to set the select options
  useEffect(() => {
    if (data?.pages?.[0]?.facets?.createdByUserId?.length) {
      const users = data.pages[0].facets.createdByUserId.map(item => {
        return { label: item?.name, value: item?.name };
      });

      if (!board_creator?.value) {
        set_board_creator_options(users);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, board_creator]);

  // update the boards based on the paginated data
  useEffect(() => {
    set_boards(data?.pages?.map(item => item.results).flat());
  }, [data]);
  return (
    <div className="add-board__content">
      <AddBoardSearch
        board_name={board_name}
        board_creator={board_creator}
        set_board_name={set_board_name}
        set_board_creator={set_board_creator}
        board_create_options={board_create_options}
      />
      <AddBoardTable
        status={status}
        boards={boards}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        selected_rows={selected_boards}
        set_selected_rows={set_selected_boards}
        select_all={select_all}
        set_select_all={set_select_all}
      />
    </div>
  );
}
