import { Card, Text } from '@nike/eds';
import { MaterialImage, MaterialImagePlaceholder } from './material_image';
import './styles/common.css';

export function MaterialCollectionCard({
  palette,
  actions,
  topIconSlot,
  subTitleSlot,
  footerSlot,
}) {
  const chips = Array.from({ length: 10 }).map(
    (item, index) => palette?.entries?.[index] || { id: `${palette?.id}_${index}` }
  );

  return (
    <Card
      key={palette?.id}
      data-testid={palette?.id}
      style={{ padding: 0, borderRadius: 0 }}
      padding={0}
      className="material-collection-card"
    >
      <div className="palette-card__header">
        <div className="eds-spacing--p-24 palette-card__text">
          <Text font="title-5" className="truncate">
            {palette?.name}
          </Text>
          {subTitleSlot ? (
            subTitleSlot
          ) : (
            <Text font="subtitle-1" className="truncate" color="text-secondary">
              {palette?.paletteType}
            </Text>
          )}
        </div>
        <div className="eds-spacing--p-24 palette-card__icons">
          {topIconSlot ? topIconSlot : null}
        </div>
      </div>
      <div className="palette-card__body">
        {chips.map(entry => {
          // since there are assets we can use their thumbnail image
          if (entry?.assets?.length) {
            return (
              <div
                className="palette-card__body--chip"
                key={entry?.id}
                data-testid="palette-card__body--image"
              >
                <MaterialImage
                  material={entry}
                  view_type="any"
                  width={56}
                  height={56}
                  can_zoom={false}
                />
              </div>
            );
          } else {
            // fallback if there are no assets in the palette entry
            return (
              <div
                className="palette-card__body--chip"
                key={entry?.id}
                data-testid="palette-card__body--chip"
              >
                <MaterialImagePlaceholder width={26} height={26} key={entry?.id} />
              </div>
            );
          }
        })}
      </div>
      {footerSlot && <div className="palette-card__footer">{footerSlot}</div>}
    </Card>
  );
}
