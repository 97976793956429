import {
  Modal,
  Text,
  Button,
  ButtonGroup,
  Link,
  TextField,
  TabGroup,
  Tab,
  Popover,
  CheckboxGroup,
  Checkbox,
  useIndeterminateCheckbox,
} from '@nike/eds';

import useColorScheme from '../../hooks/use_color_scheme';
import './export_modal.css';
import { useEffect, useState } from 'react';
import { useModalStore } from '../../hooks/use_modal';
import { useExportStore } from '../../hooks/use_export';
import { useNotificationsStore } from '../../hooks/use_notifications';

export default function ExportModal() {
  const { active_modal, dismiss_modal } = useModalStore();
  const { total_materials, materials, export_type, set_export_type, export_materials } =
    useExportStore();
  const show = active_modal === 'export';
  const { appearance } = useColorScheme();
  const [active_id, set_active_id] = useState('csv');
  const [stage_2_active_id, set_stage_2_active_id] = useState('photos');
  const [show_more_materials, set_show_more_materials] = useState(false);
  const [stage, set_stage] = useState(0);
  const [export_file_name, set_export_file_name] = useState('');
  const [error, set_error] = useState(false);
  const [error_message, set_error_message] = useState('');
  const [is_popover_open, set_is_popover_open] = useState(false);
  const [hover_timeout, set_hover_timeout] = useState(null);
  const { send_notification, dismiss_notification } = useNotificationsStore();

  useEffect(() => {
    set_active_id(export_type);
  }, [export_type, set_active_id]);

  // IMAGE VIEW CHECKBOX OPTIONS
  const [is_face_checked, set_is_face_checked] = useState(false);
  const [is_back_checked, set_is_back_checked] = useState(false);
  const [is_drape_checked, set_is_drape_checked] = useState(false);
  const [is_video_checked, set_is_video_checked] = useState(false);

  // 3D File Export Checkbox Options
  const clo_checkbox_options = [
    { id: 'zfab' },
    { id: 'obj' },
    { id: 'zpac' },
    { id: 'grp' },
    { id: 'trm' },
  ];
  const clo_inderterminate_checkbox = useIndeterminateCheckbox(clo_checkbox_options);
  const handle_clo_checkbox_change = id => {
    if (id === 'all') {
      clo_inderterminate_checkbox.setAllChecked(!clo_inderterminate_checkbox.allChecked);
    } else {
      clo_inderterminate_checkbox.setOneChecked({
        value: true,
        id,
        action: clo_inderterminate_checkbox.checkedArr.includes(id) ? 'remove' : 'add',
      });
    }
  };

  const browzwear_checkbox_options = [{ id: 'u3m' }, { id: 'obj' }];
  const browzwear_indeterminate_checkbox = useIndeterminateCheckbox(browzwear_checkbox_options);
  const handle_browzwear_checkbox_change = id => {
    if (id === 'all') {
      browzwear_indeterminate_checkbox.setAllChecked(!browzwear_indeterminate_checkbox.allChecked);
    } else {
      browzwear_indeterminate_checkbox.setOneChecked({
        value: true,
        id,
        action: browzwear_indeterminate_checkbox.checkedArr.includes(id) ? 'remove' : 'add',
      });
    }
  };

  const [substance_checked, set_substance_checked] = useState(false);
  const [texture_maps_checked, set_texture_maps_checked] = useState(false);
  const [stl_checked, set_stl_checked] = useState(false);

  const get_total_3d_options_checked = () => {
    let total = 0;
    total += substance_checked ? 1 : 0;
    total += texture_maps_checked ? 1 : 0;
    total += stl_checked ? 1 : 0;
    total += clo_inderterminate_checkbox.checkedArr.length;
    total += browzwear_indeterminate_checkbox.checkedArr.length;
    return total;
  };

  const get_total_photo_options_checked = () => {
    let total = 0;
    total += is_face_checked ? 1 : 0;
    total += is_back_checked ? 1 : 0;
    total += is_drape_checked ? 1 : 0;
    return total;
  };

  const get_total_video_options_checked = () => {
    let total = is_video_checked ? 1 : 0;
    return total;
  };

  useEffect(() => {
    const default_filename = () => {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = String(new Date().getDate()).padStart(2, '0');
      const current_date = `${year}_${month}_${day}`;

      if (total_materials === 1) {
        let filename = `PCX_${materials?.[0]?.id || materials?.id}_${
          materials?.[0]?.name || materials?.name
        }`;
        filename = filename.replace(/[\\/:*?"<>|[\]{}'-,.-]/g, '_');
        return filename;
      } else if (total_materials > 1) {
        return `materialHubExport_${current_date}`;
      } else {
        return '';
      }
    };

    set_export_file_name(default_filename());
  }, [total_materials, materials]);

  const get_material_names = () => {
    const mat_names = materials
      .map(material => material.name)
      .slice(0, 50)
      .join(', ');
    return mat_names;
  };

  const handle_input = e => {
    const input = e.target.value;

    //input validation
    const special_characters = /[\\/:*?"<>|[\]{}'-]/g;
    const regex = new RegExp(special_characters);

    if (regex.test(input)) {
      set_error(true);
      set_error_message(
        'Special characters cannot be used in naming this file. Please adjust accordingly.'
      );
    } else if (!input) {
      set_error(true);
      set_error_message('Please enter a name for this CSV file in order to export selection.');
    } else {
      set_error(false);
      set_error_message('');
    }
    set_export_file_name(input);
  };

  const toggle_show_more_materials = () => {
    set_show_more_materials(!show_more_materials);
  };

  function get_display_style() {
    if (total_materials > 1) {
      if (show_more_materials) {
        return 'export-modal__name--show';
      } else {
        return 'export-modal__name--hide';
      }
    } else {
      return '';
    }
  }

  const handle_mouse_enter = () => {
    if (!export_file_name && total_materials > 100) {
      clearTimeout(hover_timeout);
      set_is_popover_open(true);
    }
  };

  const handle_mouse_leave = () => {
    set_hover_timeout(
      setTimeout(() => {
        set_is_popover_open(false);
      }, 500)
    );
  };

  const reset = () => {
    set_is_face_checked(false);
    set_is_back_checked(false);
    set_is_drape_checked(false);
    set_is_video_checked(false);
    set_stl_checked(false);
    set_substance_checked(false);
    set_texture_maps_checked(false);
    clo_inderterminate_checkbox.setAllChecked(false);
    browzwear_indeterminate_checkbox.setAllChecked(false);
    set_export_type('csv');
    set_active_id('csv');
    set_stage_2_active_id('photos');
  };

  async function handle_export() {
    dismiss_modal();
    try {
      await export_materials({
        export_type: active_id,
        file_name: export_file_name,
        options:
          stage === 1
            ? {
                photo: {
                  face: is_face_checked,
                  back: is_back_checked,
                  drape: is_drape_checked,
                },
                video: {
                  mp4: is_video_checked,
                },
                three_d: {
                  substance: substance_checked,
                  texture_maps: texture_maps_checked,
                  clo: clo_inderterminate_checkbox.checkedArr,
                  browzwear: browzwear_indeterminate_checkbox.checkedArr,
                  stl: stl_checked,
                },
              }
            : null,
      });
      send_notification('success', 'Export was successful.', () => {}, '', true, 5000, true);
    } catch (error) {
      console.log(error);
      send_notification('error', 'Export failed. Please try again.', () => dismiss_notification());
    }
  }

  function disable_export() {
    return (
      !export_file_name ||
      total_materials > 10000 ||
      (stage === 1 &&
        get_total_photo_options_checked() === 0 &&
        get_total_3d_options_checked() === 0 &&
        get_total_video_options_checked() === 0)
    );
  }

  return (
    <div className="export-modal">
      {show ? (
        <Modal
          className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
          isOpen={show}
          hideFade={true}
          onDismiss={() => {
            dismiss_modal();
            set_stage(0);
            reset();
          }}
          headerSlot={`Export`}
          footerSlot={
            <div className="export-modal__footer-buttons">
              <ButtonGroup className="export-modal__footer">
                {export_type === 'csv' ? (
                  <Button onClick={handle_export}>Export</Button>
                ) : (
                  <div onMouseEnter={handle_mouse_enter} onMouseLeave={handle_mouse_leave}>
                    <Popover
                      isDark
                      isOpen={is_popover_open}
                      placement={'bottom-start'}
                      bodySlot={
                        <div className="no-access-tooltip-content">
                          <Text font="body-3">
                            Material Asset Downloads are limited to 100 materials. Please reach out
                            to
                            <Link to="mailto:vml@nike.com"> vml@nike.com </Link> if you need to
                            download more than that at one time.
                          </Text>
                        </div>
                      }
                    >
                      <Button
                        disabled={disable_export()}
                        onClick={() => {
                          if (stage === 0) {
                            set_stage(1);
                          } else {
                            handle_export();
                          }
                        }}
                      >
                        {stage === 0 ? 'Continue' : 'Export'}
                      </Button>
                    </Popover>
                  </div>
                )}
                <Button
                  variant="secondary"
                  onClick={() => {
                    dismiss_modal();
                    set_stage(0);
                    reset();
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
              {stage === 1 ? (
                <Link variant="secondary" onClick={() => set_stage(0)}>
                  Go Back
                </Link>
              ) : null}
            </div>
          }
        >
          <div className="export-modal__name eds-spacing--mb-24">
            <Text className="eds-type--title-5">
              {total_materials > 1 ? `${total_materials} Materials Included` : null}
            </Text>
          </div>
          <div className="export-modal__display-names-container">
            <div className={'export-modal__name eds-spacing--mb-24 ' + get_display_style()}>
              <Text className={'eds-type--subtitle-1'}>{get_material_names()}</Text>
            </div>
            <div className="export-modal__show-hide-button">
              {!show_more_materials && total_materials > 1 ? (
                <Link variant="ghost" onClick={() => toggle_show_more_materials()}>
                  ... See More
                </Link>
              ) : total_materials > 1 ? (
                <Link variant="ghost" onClick={() => toggle_show_more_materials()}>
                  See Less
                </Link>
              ) : null}
            </div>
          </div>
          {stage === 0 ? (
            <>
              <div className="eds-spacing--mb-24">
                <TabGroup
                  name="tab-button-group-1"
                  activeId={active_id}
                  onChange={e => {
                    const newId = e.target.id;
                    set_active_id(newId);
                    set_export_type(newId);
                  }}
                >
                  <Tab id="csv">CSV</Tab>
                  <Tab id="image-views">Image Views</Tab>
                </TabGroup>
              </div>
              <div className="export-modal__input-field eds-spacing--mb-24">
                <TextField
                  id="id"
                  type="text" // default type
                  value={export_file_name}
                  onChange={handle_input}
                  subtitle="File Name"
                  hasErrors={error}
                  errorMessage={error_message}
                  placeholder={'Enter File Name'}
                  afterSlot={export_type === 'csv' ? '.csv' : '.zip'}
                />
              </div>
            </>
          ) : (
            <>
              <div className="export-modal eds-spacing--mb-24">
                <TabGroup
                  name="tab-button-group-2"
                  activeId={stage_2_active_id}
                  onChange={e => {
                    const newId = e.target.id;
                    set_stage_2_active_id(newId);
                  }}
                >
                  <Tab id="photos">
                    Photos{' '}
                    {is_face_checked + is_back_checked + is_drape_checked > 0
                      ? `(${is_face_checked + is_back_checked + is_drape_checked})`
                      : ''}
                  </Tab>
                  <Tab id="videos">
                    Videos {is_video_checked > 0 ? `(${Number(is_video_checked)})` : ''}
                  </Tab>
                  <Tab id="3d">
                    3D Files
                    {get_total_3d_options_checked() > 0
                      ? ` (${get_total_3d_options_checked()})`
                      : ''}
                  </Tab>
                </TabGroup>
              </div>
              <div className="export-modal__image-views-container">
                {stage_2_active_id === 'photos' ? (
                  <CheckboxGroup
                    name="checkbox-group"
                    onChange={e => {
                      const isChecked = e.target.checked;
                      if (e.target.value === 'face') {
                        set_is_face_checked(isChecked);
                      } else if (e.target.value === 'back') {
                        set_is_back_checked(isChecked);
                      } else if (e.target.value === 'drape') {
                        set_is_drape_checked(isChecked);
                      }
                    }}
                  >
                    <Checkbox label="Face" value="face" id="checkbox1" checked={is_face_checked} />
                    <Checkbox label="Back" value="back" id="checkbox2" checked={is_back_checked} />
                    <Checkbox
                      label="Drape"
                      value="drape"
                      id="checkbox3"
                      checked={is_drape_checked}
                    />
                  </CheckboxGroup>
                ) : stage_2_active_id === 'videos' ? (
                  <CheckboxGroup
                    name="checkbox-group"
                    onChange={e => {
                      const isChecked = e.target.checked;
                      if (e.target.value === 'video') {
                        set_is_video_checked(isChecked);
                      }
                    }}
                  >
                    <Checkbox
                      label="MP4 Video"
                      value="video"
                      id="checkbox4"
                      checked={is_video_checked}
                    />
                  </CheckboxGroup>
                ) : (
                  <>
                    <Checkbox
                      label="Substance (SBSAR)"
                      checked={substance_checked}
                      onClick={() => set_substance_checked(!substance_checked)}
                    />
                    <Checkbox
                      label="Texture Maps (multiple PNGs)"
                      checked={texture_maps_checked}
                      onClick={() => set_texture_maps_checked(!texture_maps_checked)}
                    />
                    <CheckboxGroup
                      hideLabel
                      onChange={event => handle_clo_checkbox_change(event.target.id)}
                    >
                      <Checkbox
                        label="CLO"
                        checked={clo_inderterminate_checkbox.allChecked}
                        id="all"
                        indeterminate={
                          clo_inderterminate_checkbox.checkedArr.length > 0 &&
                          clo_inderterminate_checkbox.checkedArr.length <
                            clo_checkbox_options.length
                        }
                      />
                      <CheckboxGroup hideLabel>
                        {clo_checkbox_options.map(option => (
                          <Checkbox
                            label={option.id.toUpperCase()}
                            id={option.id}
                            checked={clo_inderterminate_checkbox.checkedArr.includes(option.id)}
                            key={`clo-${option.id}`}
                          />
                        ))}
                      </CheckboxGroup>
                    </CheckboxGroup>
                    <CheckboxGroup
                      hideLabel
                      onChange={event => handle_browzwear_checkbox_change(event.target.id)}
                    >
                      <Checkbox
                        label="Browzwear"
                        checked={browzwear_indeterminate_checkbox.allChecked}
                        id="all"
                        indeterminate={
                          browzwear_indeterminate_checkbox.checkedArr.length > 0 &&
                          browzwear_indeterminate_checkbox.checkedArr.length <
                            browzwear_checkbox_options.length
                        }
                      />
                      <CheckboxGroup hideLabel>
                        {browzwear_checkbox_options.map(option => (
                          <Checkbox
                            label={option.id.toUpperCase()}
                            id={option.id}
                            checked={browzwear_indeterminate_checkbox.checkedArr.includes(
                              option.id
                            )}
                            key={`browzwear-${option.id}`}
                          />
                        ))}
                      </CheckboxGroup>
                    </CheckboxGroup>
                    <Checkbox
                      label="STL"
                      checked={stl_checked}
                      onClick={() => set_stl_checked(!stl_checked)}
                    />
                  </>
                )}
              </div>
            </>
          )}
          <div className="export-modal__disclaimer">
            <Text font={'legal-1'} className="eds-spacing--mt-8">
              Please note that exported assets will be saved to your default download location on
              your computer. If you encounter any inconsistencies or errors in the exported data or
              assets, please contact our team for assistance and/or clarification.
            </Text>
          </div>
        </Modal>
      ) : undefined}
    </div>
  );
}
