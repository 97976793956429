import { Icon, Table, TableHeading, Checkbox, Text } from '@nike/eds';
import ImportFile from '../import_file/import_file';
import './upload_queue.css';

export default function UploadQueue({ onImportFiles, fileQueue, selectAll, handleSelectAll }) {
  return (
    <div className="queue eds-border--primary">
      {fileQueue.length === 0 && (
        <div className="queue-list">
          <Icon name="Upload" size="l" />
          <div className="eds-spacing--mb-12">
            Queue is empty, click to upload or drag and drop.
          </div>
          <ImportFile onChange={onImportFiles} />
        </div>
      )}
      {fileQueue.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeading>
                <Checkbox checked={selectAll} onChange={e => handleSelectAll(e.target.checked)} />
              </TableHeading>
              <TableHeading>
                <div>
                  <Text as="p">Material Name</Text>
                  <Text as="p" color="text-secondary">
                    Material ID
                  </Text>
                </div>
              </TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>Engine</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>Type</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>Sub-Type</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>View</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>New/Existing</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}>Upload Status</TableHeading>
              <TableHeading style={{ verticalAlign: 'middle' }}></TableHeading>
            </tr>
          </thead>
          <tbody>{fileQueue}</tbody>
        </Table>
      ) : null}
    </div>
  );
}
