import { Tab, TabGroup, Text } from '@nike/eds';
import { useFeaturesStore } from '../hooks/use_features';

export default function FilterListHeader({ count, activeTab, onSelectTab }) {
  const { features } = useFeaturesStore();
  return (
    <div>
      {/* TODO: add all active filters to the Filters text */}
      <Text as={'h2'} className="eds-spacing--mb-32" font="title-4">
        Filters {count?.total > 0 ? `(${count?.total})` : ''}
      </Text>
      <TabGroup
        className="eds-spacing--mb-32"
        name="filters"
        activeId={activeTab}
        onChange={onSelectTab}
      >
        {/* TODO: add filter count to each of these tabs */}
        <Tab id="general" data-testid="filter-tab">
          General
          {count?.general_count > 0 ? (
            <span data-testid="general-filters-count">&nbsp;({count?.general_count})</span>
          ) : (
            ''
          )}
        </Tab>
        <Tab id="advanced" data-testid="filter-tab">
          Advanced{' '}
          {count?.advanced_count > 0 ? (
            <span data-testid="advanced-filters-count">&nbsp;({count?.advanced_count})</span>
          ) : (
            ''
          )}
        </Tab>
        {features?.presets ? (
          <Tab id="presets" data-testid="filter-tab">
            Presets
          </Tab>
        ) : undefined}
      </TabGroup>
    </div>
  );
}
