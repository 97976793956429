import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useFeaturesStore = create(
  devtools(
    set => ({
      features: {},
      update_features: data => {
        set({ features: data });
      },
    }),
    { name: 'features', version: 1 }
  )
);
