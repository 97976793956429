import { Text, IconButton, Popover, Tooltip, Icon } from '@nike/eds';
import { useState } from 'react';

import { ColorWindowPebbleRange } from '../common/color_window_pebble_range';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useToteStore } from '../../hooks/use_tote';
import { MaterialActionsMenu } from '../common/material_actions_menu/material_actions_menu';
import { useModalStore } from '../../hooks/use_modal';
import { useRecentBoardsHook } from '../../hooks/use_boards';
import { use_auth_store } from '../../hooks/use_auth';
import { use_engine_store } from '../../hooks/use_engine';
import { usePrintStore } from '../../hooks/use_print';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import { useFeaturesStore } from '../../hooks/use_features';
import { track } from '../../utils/analytics';
import { get_secondary_material_id } from '../../utils/get_secondary_material_id';

export function MaterialHeader({ material, actions }) {
  const { send_notification } = useNotificationsStore();
  const { add_material_to_tote } = useToteStore();
  const [is_open, set_is_open] = useState(false);
  const [is_board_menu_open, set_is_board_menu_open] = useState(true);
  const { set_active_modal, set_modal_data, set_trigger_location } = useModalStore();
  const { user } = use_auth_store();
  const { active_engine } = use_engine_store();
  const { update_print_materials, set_total_print_materials } = usePrintStore();
  const { features } = useFeaturesStore();

  const { label, value } = get_secondary_material_id(material, active_engine);

  const { data } = useRecentBoardsHook({
    createdByUserId: user?.preferred_username,
    division: `${active_engine} DIVISION`,
  });

  const handle_copy_click = () => {
    track({ eventName: 'copyLink', materialID: material?.id });

    const material_link = `${window.location?.origin}/material/${material?.id}`;
    navigator.clipboard.writeText(material_link);
    send_notification('success', 'Link Copied to Clipboard');
  };

  const CopyableText = ({ font, as, className, children }) => {
    const { send_notification } = useNotificationsStore();

    const handle_text_copy_click = () => {
      navigator.clipboard.writeText(children);
      send_notification('success', 'Copied to Clipboard');
    };

    return (
      <Tooltip
        bodySlot={
          <div>
            <Icon name="CopyPaste" variant="ghost" />
          </div>
        }
        placement="top"
        delay={0}
      >
        <span style={{ display: 'inline-block' }}>
          <Text
            font={font}
            as={as}
            className={`${className} pointer`}
            onClick={handle_text_copy_click}
          >
            {children}
          </Text>
        </span>
      </Tooltip>
    );
  };

  return (
    <div className="material-details__header">
      <div className="material-details__header-top eds-spacing--mb-32 eds-spacing--mr-16">
        <div className="material-details__header-text truncate">
          <div>
            <CopyableText font="body-1" as="h2" className="truncate">
              {`PCX ${material?.id} ${label && value ? `/ ${label} ${value}` : ''}`}
            </CopyableText>
          </div>
          <div>
            <CopyableText font="title-3" as="h1" className="truncate">
              {material?.name}
            </CopyableText>
          </div>
        </div>
        <div className="material-details__header-actions eds-spacing--pr-16">
          <MaterialActionsMenu
            material={material}
            recent_boards={data?.results || []}
            is_open={is_open}
            set_is_open={set_is_open}
            is_board_menu_open={is_board_menu_open}
            set_is_board_menu_open={set_is_board_menu_open}
            actions={{
              activate_multi_select: () => actions?.set_multi_select(true),
              add_material_to_tote,
              export: () => {
                set_is_open(false);
                set_active_modal('export');
                set_trigger_location('materialDetailsMenu');
                set_modal_data({ materials: [material] });
              },
              add_to_board: async board => {
                try {
                  await add_materials_to_boards({
                    boards: [board],
                    material_ids: [material?.id],
                    trigger_location: 'material_details',
                  });
                  send_notification('success', 'Material Added to Board');
                } catch (error) {
                  console.error(`Error adding material to board:`, error.message);
                  send_notification('error', 'Error Adding Material to Board');
                }
              },
              add_to: () => {
                set_is_open(false);
                set_active_modal('add_to');
                set_trigger_location('materialDetailsMenuModal');
                set_modal_data({ materials: [material?.id] });
              },
              print: () => {
                set_is_open(false);
                update_print_materials([material]);
                set_total_print_materials(1);
                set_active_modal('print');
              },
            }}
          />
          <Popover isOpen={is_open} isDark={true} placement="bottom">
            <IconButton
              icon="Ellipsis"
              variant="ghost"
              disableRipple
              onClick={e => {
                set_is_open(!is_open);
                set_is_board_menu_open(!is_board_menu_open);
                e.stopPropagation();
              }}
            />
          </Popover>

          {features?.favorites ? (
            <IconButton icon="Favorite" variant="ghost" disableRipple />
          ) : undefined}
          <IconButton icon="Link" variant="ghost" disableRipple onClick={handle_copy_click} />
        </div>
      </div>
      <div className="material-details__header-warning">
        <ColorWindowPebbleRange material={material} show_details />
      </div>
    </div>
  );
}
