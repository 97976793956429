import { Text } from '@nike/eds';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ material_data, material }) {
  return (
    <div className="material-card__body">
      <div className="material-card__text-full">
        {material_data.map(item => {
          // eslint-disable-next-line no-eval
          const get_material_data = eval(item?.get_material_data);

          return (
            <Text
              font={item.id === 'material_name' ? 'subtitle-2' : 'legal-1'}
              className="truncate"
              key={`${material.id}_${item.id}`}
            >
              {get_material_data(material) || ''}
            </Text>
          );
        })}
      </div>
    </div>
  );
}
