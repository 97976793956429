import http_client from './fetch';
import config from '../config';
import { useNotificationsStore } from '../hooks/use_notifications';
import { track } from './analytics';
const { send_notification, dismiss_notification } = useNotificationsStore.getState();

export const print_post = ([print_type, data]) => {
  track({
    eventName: 'print',
    printType: print_type,
    totalMaterials: data?.query?.materialIds?.length,
  });

  const url = new URL(
    `${
      print_type === 'tearsheet'
        ? config.apis.export.material.tearsheet
        : config.apis.export.material.label
    }`,
    config.api_base
  ).toString();

  return http_client({
    url,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
    data,
  })
    .then(res => res.data)
    .catch(error => {
      dismiss_notification();
      send_notification('error', 'Print failed. Please try again.');
      console.log(error);
    });
};
