import { LoginCallback, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

import { okta_auth } from '../utils/okta_auth';

export const Login = ({ sign_out, user }) => {
  const navigate = useNavigate();

  const restore_original_uri = async (okta_auth, original_uri) => {
    const relative_url = toRelativeUrl(original_uri ?? '/', window.location.origin);

    // patch fix for the auth redirect loop that's happening w/ the latest react router api's
    if (!user?.token?.length) {
      okta_auth?.setOriginalUri(relative_url);
      okta_auth?.signInWithRedirect();
    }

    navigate(relative_url);
  };

  return (
    <Security oktaAuth={okta_auth} restoreOriginalUri={restore_original_uri}>
      {
        <div style={{ width: '100vw', height: '100vh' }}>
          <LoginCallback />
        </div>
      }
    </Security>
  );
};
