const defaults = {
  feature_flags: {
    material_details_panel: false,
    partial_non_modal_dialog: false,
    golden_eye: false,
    fetch_api: false,
    card_action_overlay: true,
    beta: true,
    just_tour_it: false,
    click_to_copy_icon: false,
    favorites: false,
    presets: false,
  },
  apis: {
    workflows: {
      getByIds: '/sf-logger/getByIds',
    },
    export: {
      csv_generate: '/export/v1/dpc/csv-generate',
      material: {
        label: '/export/v1/dpc/material/label',
        tearsheet: '/export/v1/dpc/material/tearsheet',
      },
    },
    search: {
      all_assets: '/search/v2/dpc/allassets',
      all_materials: '/search/v1/dpc/allmaterials',
      history: '/search/v1/dpc/history',
      jobs: '/search/v1/dpc/jobs',
      moassets: '/search/v2/dpc/moassets',
      externalpos: '/search/v2/dpc/externalpos',
      externalcolors: '/search/v1/dpc/externalcolors',
      assets: '/search/v1/dpc/assets',
      palette_entries: '/search/v2/dpc/paletteentries',
      palette_summaries: '/search/v1/dpc/palettesummaries',
      user: '/search/v1/dpc/',
    },
    via: {
      assets: '/via/v1/dpc/assets',
      artifacts: '/via/v1/dpc/artifacts',
      palettes: '/via/v1/dpc/palettes',
      productImages: '/via/v1/dpc/productImages',
      images: '/via/v1/dpc/images/product',
      silhouette: '/via/v1/dpc/images/silhouette',
      zip: '/via/v1/dpc/zip',
      zipAsset: '/via/v1/dpc/zip/asset',
    },
    jobs: {
      job: '/job/v1/dpc/jobs',
    },
    pdh: {
      base: '/pdh/v1',
    },
    user: {
      lookup: '/user/v1/dpc/users/lookup',
      groups: '/user/v1/dpc/groups',
      preferences: '/user/v1/dpc/preferences',
    },
  },
  client_id: 'nike.dpc.material-library',
};

const config = {
  dev: {
    env: 'dev',
    base_url: 'http://localhost:3210',
    api_base: 'https://api.test.dpc.nike.io',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    feature_flags: {
      ...defaults.feature_flags,
      just_tour_it: false,
      favorites: true,
      presets: true,
    },
  },
  test: {
    env: 'test',
    base_url: 'https://material-library.test.dpc.nike.io',
    api_base: 'https://api.test.dpc.nike.io',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
    feature_flags: {
      ...defaults.feature_flags,
      presets: true,
    },
  },
  prod: {
    env: 'prod',
    base_url: 'https://material-library.prod.dpc.nike.io',
    api_base: 'https://api.prod.dpc.nike.io',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
    feature_flags: {
      ...defaults.feature_flags,
      material_details_panel: false,
      partial_non_modal_dialog: false,
      golden_eye: false,
      fetch_api: false,
      card_action_overlay: true,
      beta: true,
      just_tour_it: false,
      favorites: false,
      presets: false,
    },
  },
  // this allows for using vs-code's chrome debugger
  devtool: 'source-map',
};

let env = 'dev';
const href = window && window.location.href;
if (href.includes('.prod.') || process.env.NODE_ENV === 'production') env = 'prod';
if (href.includes('.test.')) env = 'test';

export default Object.assign({}, defaults, config[env]);
