import { useState, useEffect } from 'react';
import { Text, Divider, CheckboxGroup, Checkbox, Icon } from '@nike/eds';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useCompareMaterialsStore } from '../../hooks/use_compare_materials';

import '../styles/display_settings.css';

const get_item_style = (isDragging, draggableStyle) => ({
  color: isDragging ? 'var(--eds-color-text-default)' : 'transparent',
});

export function TableData({ set_table_data, table_data }) {
  const { pinned_row_ids, get_pinned_rows, get_unpinned_rows } = useCompareMaterialsStore();

  // eslint-disable-next-line no-unused-vars
  const [pinned_rows, set_pinned_rows] = useState(get_pinned_rows());

  useEffect(() => {
    set_pinned_rows(get_pinned_rows());
  }, [pinned_row_ids, get_pinned_rows, get_unpinned_rows]);

  const [list, set_list] = useState(
    Object.values(table_data)
      .sort((a, b) => a.order - b.order)
      .map(item => item.id)
  );

  // resets list state
  useEffect(() => {
    set_list(
      Object.values(table_data)
        .sort((a, b) => a.order - b.order)
        .map(item => item.id)
    );
  }, [table_data]);

  const handle_drag_end = result => {
    if (!result.destination) {
      return; // Item was dropped outside of a droppable area
    }

    const { source, destination } = result;
    const updated_list = Array.from(list); // allows us to mutate the list array before setting it to states
    const [removed] = updated_list.splice(source.index, 1);

    updated_list.splice(destination.index, 0, removed);
    set_list(updated_list);

    // reorder the normalized list of objects by the order of the list array
    const normalized_reordered_list = Object.values(table_data).reduce((object, key) => {
      object[key.id] = { ...table_data[key.id], order: updated_list.indexOf(key.id) };

      return object;
    }, {});

    set_table_data(normalized_reordered_list);
  };

  const toggle_table_data = e => {
    set_table_data({
      ...table_data,
      [e.target?.id]: {
        ...table_data[e.target?.id],
        checked: e.target?.checked,
      },
    });
  };

  return (
    <div className="eds-spacing--mt-24">
      <Text className="eds-spacing--mb-8" font="subtitle-1">
        Table Data
      </Text>
      <Divider color="text-secondary" className="eds-spacing--mb-24" />
      <Text className="eds-spacing--mb-8" font="body-3" color="text-secondary">
        Check what you want to see as part of the material compare table and drag the data into your
        desired order.
      </Text>
      <DragDropContext onDragEnd={handle_drag_end}>
        <Droppable droppableId="list">
          {provided => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="material-data__list"
            >
              {Object.values(table_data)
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={item?.disabled}
                  >
                    {(provided, snapshot) => (
                      <CheckboxGroup className="material-data__list" onChange={toggle_table_data}>
                        <li
                          className="material-data__item"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Checkbox
                            id={item.id}
                            key={item.display_name}
                            disabled={item?.disabled}
                            checked={item?.checked}
                            value={item?.facet_property_name}
                            label={item?.display_name}
                          />
                          <div
                            className="material-data__item-icon"
                            style={get_item_style(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {/* we can just queue off of the item being disabled to figure out if it's pinned or not */}
                            {item?.disabled ? (
                              <Text className="pin-icon pinned">📌</Text>
                            ) : (
                              <Icon name="EllipsisVertical" size="m" />
                            )}
                          </div>
                        </li>
                      </CheckboxGroup>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
