import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePrintStore = create(
  persist(
    set => ({
      print_materials: [],
      total_materials: 0,
      update_print_materials: data => {
        set({ print_materials: data });
      },
      set_total_print_materials: total => {
        set({ total_materials: total });
      },
    }),
    { name: 'print_materials' }
  )
);
