import { Text } from '@nike/eds';
import React from 'react';

export const ErrorBoundary = ({ error, dark, header }) => {
  // TODO: handle things differently based on error types
  // const error_types = {
  //   401: 'Request failed with status code 401',
  // };

  console.warn(error?.message);
  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`} data-testid="error-boundary">
      <div className="page-header">{header}</div>
      <div className="centered">
        <Text font="body-1" className="eds-spacing--mb-24">
          well that was unexpected
        </Text>
        <Text font="display-1">😬</Text>
      </div>
    </div>
  );
};
