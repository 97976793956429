import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { use_engine_store } from '../hooks/use_engine';
import { http_client } from '../utils/fetch';
import config from '../config';
import chunk from 'lodash.chunk';

export const all_material_fields = [
  'assets.artifactReferences',
  'assetCount',
  'assetLastModifiedDate',
  'assetLastModifiedUser',
  'assets',
  'hasMaterial2dAsset',
  'hasMaterial3dAsset',
  'hasMaterialVideoAsset',
  'principalMaterial',
  'supplier',
  'visualEffect',
  'suppliedMaterialState',
  'matContentTypeSource',
  'initialSeason',
  'targetCycleYear',
  'constructionType',
  'textileVariation',
  'suppliedMaterialDevelopmentTeam',
  'finishProcess',
  'location',
  'manufacturingCountryofOrigin',
  'createTimestamp',
  'physicalSampleDateAdded',
  'physicalSampleAvailableIndicator',
  'id',
  'material',
  'materialType',
  'materialTypel',
  'materialType2',
  'materialType3',
  'materialType4',
  'materialType5',
  'name',
  'partition',
  '_docVersion',
  '_score',
  '_skipLocking',
  '_status',
];

export function useMaterialsHook(params) {
  return useInfiniteQuery({
    queryKey: ['all_materials', params],
    queryFn: async ({ pageParam = 0 }) => await fetch_materials({ ...params, from: pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'

      return next_page;
    },
  });
}

export function useCompareMaterialsHook(ids) {
  return useQuery({
    queryKey: ['compare_materials', ids],
    queryFn: async () => await fetch_materials_by_ids({ ids }),
    enabled: ids?.length ? true : false, // only make this call if there's ids in the tote
  });
}

export function useLibrarySubTypeFacets(params) {
  return useQuery({
    queryKey: ['library_facets', params],
    queryFn: async () => {
      const res = await fetch_materials({ ...params, size: 1 });
      return { materialType5: res.facets?.materialType5 };
    },
  });
}

export async function fetch_materials(params) {
  const url = new URL(config.apis.search.all_materials, config.api_base).toString();
  const engine_store = use_engine_store?.getState();

  const res = await http_client({
    url,
    method: 'get',
    params: {
      ...params,
      materialType3: get_material3(engine_store?.active_engine),
      notMaterialType4: 'Packaging',
      'fields.includes': all_material_fields.join(','),
    },
  });

  return res.data;
}

export async function fetch_all_materials(params) {
  let from = 0;
  const size = 50;
  const initialResult = await fetch_materials({ ...params, from, size });
  let materials = initialResult.results;
  let pagination = initialResult.pagination;
  from += size;

  while (from < pagination.total) {
    const result = await fetch_materials({ ...params, from, size });
    materials = [...materials, ...result.results];
    pagination = result.pagination;
    from += size;
  }

  return materials;
}

export async function fetch_materials_by_ids(params) {
  const { ids, from } = params;
  const newParams = {
    from,
    'fields.includes': all_material_fields.join(','),
    size: ids?.length,
  };

  if (ids.length === 1) {
    newParams['id'] = ids[0];
  } else {
    newParams['ids'] = ids.join(',');
  }
  const url = new URL(config.apis.search.all_materials, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: newParams,
  });

  return res.data;
}

export async function fetch_all_materials_by_ids(ids) {
  const id_chunks = chunk(ids, 10);
  const results = await Promise.all(
    id_chunks.map(async ids => await fetch_materials_by_ids({ ids, from: 0 }))
  );
  return results.map(result => result.results).flat();
}

function get_material3(engine) {
  let material3;

  switch (engine) {
    case 'FOOTWEAR':
      material3 = 'Footwear';
      break;

    case 'APPAREL':
      material3 = 'Apparel';
      break;

    case 'ACCESSORIES':
      material3 = 'Bags & Equipment';
      break;

    default:
      break;
  }

  return material3;
}
