import { Button, ButtonGroup, IconButton, Text, Tooltip } from '@nike/eds';
import useColorScheme from '../hooks/use_color_scheme';

export default function FilterListFooter({ onClear, onSave, count }) {
  const { appearance } = useColorScheme();
  return (
    <ButtonGroup className="filter-list__footer-buttons">
      <Button
        data-testid="clear-all"
        variant="secondary"
        size="medium"
        onClick={onClear}
        disabled={count?.total < 1}
      >
        Clear All
      </Button>
      {count.total < 2 ? (
        <Tooltip
          isDark={appearance === 'dark' ? false : true}
          bodySlot={
            <Text style={{ width: '156px', lineHeight: '23px' }}>
              You must have 2 or more filters applied to save as a preset.
            </Text>
          }
          placement="top"
          delay={0}
        >
          <div className="save-presets__container--disabled">
            <IconButton icon="Bookmark" variant="ghost" disabled />
            <span>Save as Preset</span>
          </div>
        </Tooltip>
      ) : (
        <div data-testid="save-button" className="save-presets__container" onClick={onSave}>
          <IconButton icon="Bookmark" variant="ghost" />
          <span>Save as Preset</span>
        </div>
      )}
    </ButtonGroup>
  );
}
