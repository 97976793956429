import { OktaAuth } from '@okta/okta-auth-js';
import config from '../config';

export const okta_auth = new OktaAuth({
  issuer: config.issuer,
  clientId: config.client_id,
  redirectUri: `${config.base_url}/implicit/callback`,
  scopes: [
    'default',
    'openid',
    'profile',
    'email',
    'product_design:aurora.gateway.internal::read:',
    'product_design:aurora.search.allmaterials.v1::read:',
    'product_design:aurora.search.paletteentries.v2::read:',
    'product_design:aurora.search.palettesummaries.v1::read:',
    'product_design:aurora.search.allassets.v2::read:',
  ],
  pkce: true,
});
