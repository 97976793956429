import { Icon, Text, Card, Button, Divider } from '@nike/eds';
import { useEffect, useState } from 'react';

import './styles/no_board_access.css';
import { use_auth_store } from '../hooks/use_auth';
import { useNavigate } from 'react-router-dom';

export const NoBoardAccess = ({ board = 'Some Cooool Board' }) => {
  const { user } = use_auth_store();
  const navigate = useNavigate();
  const [admin_users, set_admin_users] = useState([]);

  useEffect(() => {
    const emails = board?.entitlements
      ?.filter(user => {
        if (user?.permissions.includes('ADMIN') && user?.type === 'user') {
          return user;
        }

        return undefined;
      })
      ?.map(item => item.name);

    set_admin_users(emails);
  }, [board]);

  function send_email(board_id, board_name, emails, user_name) {
    if (window) {
      const base_url = window.location.origin;
      const board_url = `${base_url}/boards/${board_id}`;
      const subject = `Request for Access to ${board_name} MaterialHub Board`;
      const body = encodeURIComponent(
        `Hello, \n\nCan you please help to provide access for me to the ${board_name} board in MaterialHub?\n\n${board_url}\n\n\nThank you,\n${user_name}`
      );
      const mailToLink = `mailto:${emails.join(';')}?subject=${subject}&body=${body}`;

      window.location.replace(mailToLink);
    }
  }

  return (
    <Card className="no-access__board eds-spacing--p-32 eds-elevation--4 eds--radii-4">
      <Icon name="Lock" size="l" />
      <Text font="title-4" className="eds-spacing--mt-24">
        Request Access to Board
      </Text>
      <Text font="body-2" className="eds-spacing--mt-24">
        You can access {board?.name} once your request is approved by the board creator or by a
        board admin.
      </Text>
      <Divider className="eds-spacing--mt-24" />
      <div className="eds-spacing--mt-24 no-access__board-actions">
        <Button
          variant="primary"
          className="eds-spacing--mr-16"
          onClick={() => send_email(board?.paletteId, board?.name, admin_users, user?.name)}
        >
          Request Access
        </Button>
        <Button variant="secondary" onClick={() => navigate('/boards')}>
          Browse Boards
        </Button>
      </div>
    </Card>
  );
};
