import axios from 'axios';
import axiosRetry from 'axios-retry';

export const http_client = axios.create();

axiosRetry(http_client, {
  retries: 3,
  retryCondition: error => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      error.code === 'ECONNABORTED' ||
      error.code === 'ERR_NETWORK'
    );
  },
  shouldResetTimeout: true,
  retryDelay: retryCount => {
    return retryCount * 1000;
  },
});

http_client.interceptors.request.use(async req => {
  // adds 30 second timeouts to all requests
  if (!req.timeout) {
    req.timeout = 30000;
  }

  // adds auth to headers
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken
    ?.accessToken;
  req.headers['Content-Type'] = 'application/json;charset=UTF-8';
  req.headers.Authorization = `Bearer ${accessToken}`;

  return req;
});

export default http_client;
