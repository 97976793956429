import { IconButton, Link } from '@nike/eds';
import MaterialCardBody from './material_card_body';
import { material_card_data } from './compare_table_data';
import { MaterialCard } from '../common/material_card';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeaturesStore } from '../../hooks/use_features';
import { useToteStore } from '../../hooks/use_tote';
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import Draggable from '../draggable';
import Droppable from '../droppable';
import { useState } from 'react';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useModalStore } from '../../hooks/use_modal';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  selected_materials,
  set_selected_materials,
  set_active_material,
  show_material,
  set_show_material,
  set_open_material_id,
  materials,
  reorder_columns,
  column_order,
  column_width,
  set_active_modal,
  set_modal_data,
  active_card,
  set_active_card,
  set_is_add_to_board_open,
  remove_material,
}) {
  const { set_show_tote_modal, add_material_to_tote } = useToteStore();
  const { features } = useFeaturesStore();
  const { send_notification, dismiss_notification } = useNotificationsStore();
  const { set_trigger_location } = useModalStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeDragTarget, set_active_drag_target] = useState(null);
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 2 } }));

  function select_item(material) {
    let selected = selected_materials;

    if (selected_materials?.includes(material)) {
      set_selected_materials([...selected_materials?.filter(item => item !== material)]);
    } else {
      selected.push(material);
      set_selected_materials([...selected]);
    }
  }

  function add_materials() {
    const previous_route = location?.state?.previous?.pathname || '/library';

    navigate(`${previous_route}`);
  }

  function open_material(id) {
    if (features?.material_details_panel) {
      set_active_material(materials?.find(mat => mat?.id === id));
      if (!show_material) {
        set_show_material(true);
      }
    } else {
      set_open_material_id(id);
    }
  }

  function handle_drag_end(event) {
    const dropTarget = event.over.data.current;
    const dragTarget = event.active.data.current;

    set_active_drag_target(null);
    if (dropTarget.id !== dragTarget.id) {
      reorder_columns(dropTarget, dragTarget);
    }
  }

  function handle_drag_start(event) {
    set_active_drag_target(event.active.data.current);
  }

  const handle_go_to_board = (e, board) => {
    e.stopPropagation();
    dismiss_notification();
    navigate(`/boards/${board?.paletteId}`, { state: { previous: location } });
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handle_drag_end} onDragStart={handle_drag_start}>
      {materials.map((material, index) => {
        return (
          <div
            className="material-dnd-container"
            key={`${material?.id}_${index}`}
            style={{
              order: column_order.indexOf(material?.id),
              width: column_width,
            }}
          >
            <Droppable id={material?.id} data={material}>
              <Draggable id={material?.id} data={material}>
                <MaterialCard
                  material={material}
                  multi_select={false}
                  selected={selected_materials.includes(material?.id)}
                  active={active_card === material?.id}
                  set_active_card={set_active_card}
                  show_card_actions={true}
                  actions={{
                    select_material: select_item,
                    open: open_material,
                    add_material_to_tote: add_material_to_tote,
                    add_to_board: async board => {
                      try {
                        await add_materials_to_boards({
                          boards: [board],
                          material_ids: [material?.id],
                        });
                        send_notification(
                          'success',
                          'Material Added to Board',
                          () => dismiss_notification(),
                          <>
                            <Link onClick={e => handle_go_to_board(e, board)}>Go to Board</Link>
                          </>
                        );
                      } catch (error) {
                        console.error(`Error adding material to board:`, error.message);
                        send_notification('error', 'Error Adding Material to Board');
                      }
                    },
                    open_tote: () => set_show_tote_modal(true),
                    remove_material_from_comparison: remove_material,
                    export: () => {
                      set_active_modal('export');
                      set_modal_data({ materials: [material] });
                      set_trigger_location('materialCardMenu');
                    },
                  }}
                  show_data={true}
                  material_body={material => (
                    <MaterialCardBody material={material} material_data={material_card_data} />
                  )}
                />
              </Draggable>
            </Droppable>
          </div>
        );
      })}

      {materials.length < 5 && (
        <div
          style={{
            order: column_order.length,
            width: column_width,
            padding: '24px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              marginTop: '84px',
            }}
          >
            <IconButton icon="Plus" onClick={add_materials} />
          </div>
        </div>
      )}
      <DragOverlay>
        {activeDragTarget ? (
          <MaterialCard
            material={activeDragTarget}
            multi_select={false}
            selected={selected_materials.includes(activeDragTarget?.id)}
            active={active_card === activeDragTarget?.id}
            show_card_actions={true}
            actions={{
              select_material: select_item,
              open: open_material,
              add_material_to_tote: add_material_to_tote,
              add_to_board: async board => {
                try {
                  await add_materials_to_boards({
                    boards: [board],
                    material_ids: [activeDragTarget?.id],
                    trigger_location: 'material_card',
                  });
                  send_notification(
                    'success',
                    'Material Added to Board',
                    () => dismiss_notification(),
                    <>
                      <Link onClick={e => handle_go_to_board(e, board)}>Go to Board</Link>
                    </>
                  );
                } catch (error) {
                  console.error(`Error adding material to board:`, error.message);
                  send_notification('error', 'Error Adding Material to Board');
                }
              },
              open_tote: () => set_show_tote_modal(true),
              remove_material_from_comparison: remove_material,
              export: () => {
                set_active_modal('export');
                set_modal_data({ materials: [activeDragTarget] });
                set_trigger_location('materialCardMenu');
              },
            }}
            show_data={true}
            material_body={material => (
              <MaterialCardBody material={material} material_data={material_card_data} />
            )}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
