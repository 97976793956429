import { SidePanel, IconButton } from '@nike/eds';

import { MaterialDetails } from './index';
import { MaterialHeader } from './material_header';

export function MaterialDetailsSidePanel({
  show_material,
  set_show_material,
  material,
  next_material,
  previous_material,
}) {
  return (
    <div className="material-details__panel">
      <SidePanel
        // key={material?.id}
        hasScrim={false}
        displayCheckOverride
        isOpen={show_material}
        onDismiss={() => set_show_material(false)}
        headerSlot={
          <div className="material-details__panel-header">
            <div className="material-details__panel-nav eds-spacing--mr-24">
              <IconButton variant="ghost" icon="CaretUp" size="s" onClick={previous_material} />
              <IconButton variant="ghost" icon="CaretDown" size="s" onClick={next_material} />
            </div>{' '}
            <MaterialHeader material={material} />{' '}
          </div>
        }
      >
        <MaterialDetails hideCarousel={true} material={material} />
      </SidePanel>
    </div>
  );
}
