import { Modal, Text } from '@nike/eds';

import './manage_board_modal.css';
import useColorScheme from '../../hooks/use_color_scheme';
import { BoardAccessManagement } from '../board_access_management/board_access_management';
import { ArchiveBoardModal } from './archive_board_modal';
import { BoardName } from './board_name';
import { ManageBoardActions } from './manage_board_actions';
import { ManageTeamMembers } from './manage_team_members';
import { NewTeamMembers } from './new_team_members';

export const ManageBoardModal = ({
  is_open,
  set_is_open,
  palette_summary,
  on_dismiss,
  update_board_response,
  set_update_board_response,
  has_user_permissions,
}) => {
  const { appearance } = useColorScheme();

  return (
    <BoardAccessManagement
      has_user_permissions={has_user_permissions}
      is_open={is_open}
      dismiss={() => set_is_open(false)}
      palette_summary={palette_summary}
      update_board_response={update_board_response}
      set_update_board_response={set_update_board_response}
      render={({
        loading,
        update,
        reset,
        update_entitlements,
        add_entitled_users,
        is_admin,
        ad_group_entitilement_name,
        is_general_user_group,
        engine_wide_access,
        palette_summary,
        general_user_icons_mapping,

        show_archiving,
        set_show_archiving,
        to_archive,
        set_to_archive,
        palette_name,
        set_palette_name,
        user_lookup_options,
        set_new_team_members_entitlement,
        new_team_members_entitlement,
        new_team_members,
        set_new_team_members,
        team_members_table,
        board_name_ref,
        entitlements,
        owner,
        active_engine,
        options,
        general_user_options,
        has_user_permissions,
      }) => {
        return to_archive && show_archiving ? (
          <ArchiveBoardModal
            has_user_permissions={has_user_permissions}
            appearance={appearance}
            set_show_archiving={set_show_archiving}
            is_open={is_open}
            on_dismiss={on_dismiss}
            update={update}
            board_name={palette_name}
          />
        ) : (
          <div className="manage-board">
            <Modal
              className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'} `}
              isOpen={is_open}
              onDismiss={on_dismiss}
              hideFade={true}
              headerSlot={<Text>Manage Board</Text>}
              footerSlot={
                <ManageBoardActions
                  has_user_permissions={has_user_permissions}
                  loading={loading}
                  is_admin={is_admin}
                  to_archive={to_archive}
                  set_show_archiving={set_show_archiving}
                  update={update}
                  reset={reset}
                  set_to_archive={set_to_archive}
                />
              }
            >
              <div className="manage-board__content">
                <BoardName
                  has_user_permissions={has_user_permissions}
                  palette_summary={palette_summary}
                  is_admin={is_admin}
                  palette_name={palette_name}
                  set_palette_name={set_palette_name}
                  board_name_ref={board_name_ref}
                />
                <NewTeamMembers
                  has_user_permissions={has_user_permissions}
                  palette_summary={palette_summary}
                  is_admin={is_admin}
                  new_team_members={new_team_members}
                  set_new_team_members={set_new_team_members}
                  user_lookup_options={user_lookup_options}
                  new_team_members_entitlement={new_team_members_entitlement}
                  set_new_team_members_entitlement={set_new_team_members_entitlement}
                  add_entitled_users={add_entitled_users}
                  options={options}
                />
                <ManageTeamMembers
                  has_user_permissions={has_user_permissions}
                  team_members_table={team_members_table}
                  entitlements={entitlements}
                  owner={owner}
                  engine_wide_access={engine_wide_access}
                  is_general_user_group={is_general_user_group}
                  active_engine={active_engine}
                  ad_group_entitilement_name={ad_group_entitilement_name}
                  update_entitlements={update_entitlements}
                  is_admin={is_admin}
                  general_user_options={general_user_options}
                  general_user_icons_mapping={general_user_icons_mapping}
                  options={options}
                />
              </div>
            </Modal>
          </div>
        );
      }}
    />
  );
};
