import { useState } from 'react';
import { SidePanel, Text, TabGroup, Tab, Icon, Button, ButtonGroup } from '@nike/eds';
import { v4 as uuidv4 } from 'uuid';
import { AutoSections } from './auto_sections';
import { CustomSections } from './custom_sections';

import { useCustomSections } from '../../hooks/use_custom_sections';
import './sections_side_panel.css';
import { useLibraryFilterStore } from '../../hooks/use_library_filters';
import { useSectionGroups } from '../../hooks/use_section_groups';
import { useAutoSections } from '../../hooks/use_auto_sections';

export function SectionSidePanel({ show, set_show, onBoardNeedsUpdate }) {
  const { clear_filters, load_chips } = useLibraryFilterStore();

  const { activeGroup, setActiveGroup, expandSection } = useSectionGroups();

  const {
    activeSectionType,
    getAutoSections,
    setAutoSections,
    getActiveAutoSections,
    getActiveSectionType,
    setActiveSectionType,
  } = useAutoSections();

  const {
    customSections,
    reset,
    addSection,
    setCustomSections,
    updateSectionLabel,
    removeSection,
  } = useCustomSections();

  const handleReorder = newArray => {
    setCustomSections(newArray);
    onBoardNeedsUpdate();
  };

  const handleAutoSectionReorder = sections => {
    setAutoSections(sections);
    onBoardNeedsUpdate();
  };

  const [isAddingSection, setIsAddingSection] = useState(false);

  function editSection() {
    setIsAddingSection(true);
    setTimeout(() => {
      document.getElementById('new-custom-section-input')?.focus();
    }, 100);
  }

  function cancelEdit() {
    setIsAddingSection(false);
  }

  function updateSection(id, label) {
    updateSectionLabel(id, label);
    onBoardNeedsUpdate();
  }

  function addNewSection(label) {
    const id = uuidv4();
    // update palette entry
    addSection(id, label);
    expandSection(id);
    onBoardNeedsUpdate();
    setIsAddingSection(false);
  }

  function removeCustomSection(id) {
    removeSection(id);
    onBoardNeedsUpdate();
  }

  function handle_active_tab_change(tab) {
    setActiveGroup(tab);
    if (tab === 'custom_sections') {
      const existing_filters = load_chips();
      if (existing_filters?.length > 0) {
        // need to clear out all filters before applying sections to make sure we have every material
        clear_filters();
      }
      // use timeout to ensure all materials are loaded in before setting sections
      setTimeout(
        () => {
          onBoardNeedsUpdate();
        },
        existing_filters?.length > 0 ? 1000 : 0
      );
    } else if (tab === 'auto_sections') {
      const autoSections = getAutoSections();
      autoSections.forEach(autoSection => {
        expandSection(autoSection.id);
      });

      const activeSectionType = getActiveSectionType();

      if (activeSectionType) {
        const existing_filters = load_chips();
        if (existing_filters?.length > 0) {
          // need to clear out all filters before applying sections to make sure we have every material
          clear_filters();
        }
        // use timeout to ensure all materials are loaded in before setting sections
        setTimeout(
          () => {
            onBoardNeedsUpdate();
          },
          existing_filters?.length > 0 ? 1000 : 0
        );
      }
    }
  }

  function dissolve_sections() {
    if (activeGroup === 'custom_sections') {
      reset();
    } else {
      setActiveSectionType('');
    }
    onBoardNeedsUpdate();
  }

  // add a new custom section
  return (
    <div className="sections-panel">
      <SidePanel
        hasScrim
        hideFade
        isOpen={show}
        onDismiss={() => set_show(false)}
        headerSlot={
          <>
            <Text as={'h2'} className="eds-spacing--mb-32" font="title-4">
              Board Sections
            </Text>
          </>
        }
        footerSlot={
          <FooterAction
            active_tab={activeGroup}
            edit_section={editSection}
            dissolve_sections={dissolve_sections}
            sections={activeGroup === 'auto_sections' ? getAutoSections() : customSections}
          />
        }
      >
        <div className="sections-panel__body">
          <Text font="body-2" className="eds-spacing--mb-36">
            Start to organize your board by creating sections and moving materials into those
            sections. You can add a section below, name the section, and rearrange once you have
            multiple.
          </Text>
          <TabGroup
            variant="toggle-small"
            name="sections_type"
            activeId={activeGroup === 'default' ? 'custom_sections' : activeGroup}
            onChange={event => handle_active_tab_change(event?.target?.id)}
            className="eds-spacing--mb-28"
          >
            <Tab id="auto_sections">Auto Sections</Tab>
            <Tab id="custom_sections">Custom Sections</Tab>
          </TabGroup>
          {activeGroup === 'auto_sections' ? (
            <AutoSections
              sections={getAutoSections()}
              activeAutoSections={getActiveAutoSections()}
              activeSectionType={activeSectionType}
              reorderSections={handleAutoSectionReorder}
              onActiveAutoSectionTypeChange={() => handle_active_tab_change('auto_sections')}
            />
          ) : (
            <CustomSections
              sections={customSections}
              reorderSections={handleReorder}
              isAddingSection={isAddingSection}
              addNewSection={addNewSection}
              cancelEdit={cancelEdit}
              updateSection={updateSection}
              removeCustomSection={removeCustomSection}
            />
          )}
        </div>
      </SidePanel>
    </div>
  );
}

function FooterAction({ active_tab, edit_section, disabled, dissolve_sections, sections = [] }) {
  return (
    <ButtonGroup>
      {active_tab !== 'auto_sections' && (
        <Button onClick={edit_section} size="medium" variant="primary" disabled={disabled}>
          {' '}
          <Icon name="Plus" /> Add Section{' '}
        </Button>
      )}
      <Button
        size="medium"
        variant="secondary"
        onClick={dissolve_sections}
        disabled={sections?.length === 0}
      >
        Dissolve Sections
      </Button>
    </ButtonGroup>
  );
}
