import { Modal, Text, Button, ButtonGroup, Link, Divider } from '@nike/eds';

import useColorScheme from '../../hooks/use_color_scheme';
import './print_modal.css';
import { useState } from 'react';
import { useModalStore } from '../../hooks/use_modal';
import { usePrintStore } from '../../hooks/use_print';
import { use_auth_store } from '../../hooks/use_auth';
import { print_post } from '../../utils/print';
import printJS from 'print-js';
import { useNotificationsStore } from '../../hooks/use_notifications';
import print_label from '../../assets/print_label.svg';
import print_label_selected from '../../assets/print_label_selected.svg';
import print_tearsheet from '../../assets/print_tearsheet.svg';
import print_tearsheet_selected from '../../assets/print_tearsheet_selected.svg';

export default function PrintModal() {
  const { active_modal, dismiss_modal } = useModalStore();
  const { print_materials, total_materials } = usePrintStore();
  const { send_notification, dismiss_notification } = useNotificationsStore();
  const show = active_modal === 'print';
  const { appearance } = useColorScheme();
  const [label_or_tearsheet, set_label_or_tearsheet] = useState('label');
  const [show_more_materials, set_show_more_materials] = useState(false);
  const { user } = use_auth_store();

  const get_material_names = () => {
    const mat_names = print_materials
      .map(material => material.name)
      .slice(0, 50)
      .join(', ');
    return mat_names;
  };

  const toggle_show_more_materials = () => {
    set_show_more_materials(!show_more_materials);
  };

  function get_display_style() {
    if (total_materials > 1) {
      if (show_more_materials) {
        return 'print-modal__name--show';
      } else {
        return 'print-modal__name--hide';
      }
    } else {
      return '';
    }
  }

  const handle_print = () => {
    dismiss_modal();
    send_notification('loading', 'Print is processing...');
    const post_config = {
      user: user.sub,
      query: {
        materialIds: print_materials.map(material => material.id),
      },
    };
    print_post([label_or_tearsheet, post_config]).then(res => {
      const pdf = new Blob([res], {
        type: 'application/pdf',
      });
      const pdf_url = window.URL.createObjectURL(pdf);
      printJS(pdf_url);
      dismiss_notification();
    });
  };

  return (
    <div className="print-modal">
      {show ? (
        <Modal
          className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
          isOpen={show}
          hideFade={true}
          onDismiss={() => {
            dismiss_modal();
            set_label_or_tearsheet('tearsheet');
          }}
          headerSlot={`Print`}
          footerSlot={
            <ButtonGroup className="print-modal__footer">
              <Button onClick={() => handle_print()}>Continue</Button>
              <Button
                variant="secondary"
                onClick={() => {
                  dismiss_modal();
                  set_label_or_tearsheet('tearsheet');
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          }
        >
          <div className="print-modal__name ">
            <Text className="eds-type--title-5 eds-spacing--mb-8">
              {total_materials > 1 ? `${total_materials} Materials Included` : null}
            </Text>
          </div>
          <div className="print-modal__display-names-container eds-spacing--mb-24">
            <div className={'print-modal__name  ' + get_display_style()}>
              <Text className={'eds-type--subtitle-1'}>{get_material_names()}</Text>
            </div>
            <div className="print-modal__show-hide-button">
              {!show_more_materials && total_materials > 1 ? (
                <Link variant="ghost" onClick={() => toggle_show_more_materials()}>
                  ... See More
                </Link>
              ) : total_materials > 1 ? (
                <Link variant="ghost" onClick={() => toggle_show_more_materials()}>
                  See Less
                </Link>
              ) : null}
            </div>
          </div>
          <div className="print-selection--container">
            <div className="button-container">
              <button
                className="print-modal__toggle-buttons"
                onClick={() => set_label_or_tearsheet('label')}
                title="Label"
              >
                {label_or_tearsheet === 'label' ? (
                  <img src={print_label_selected} alt="label" />
                ) : (
                  <img src={print_label} alt="label" />
                )}
              </button>
              <span>Label</span>
            </div>
            <div className="button-container">
              <button
                className="print-modal__toggle-buttons"
                onClick={() => set_label_or_tearsheet('tearsheet')}
                title="Tear Sheet"
              >
                {label_or_tearsheet === 'tearsheet' ? (
                  <img src={print_tearsheet_selected} alt="tearsheet" />
                ) : (
                  <img src={print_tearsheet} alt="tearsheet" />
                )}
              </button>
              <span>Tearsheet</span>
            </div>
          </div>
          <div>
            <Text className="print-modal__disclaimer" font={'legal-1'}>
              Please note that your printing options or configuration may vary depending on the
              browser you are using. We recommend checking your browser’s printing settings and
              experimenting in order to achieve your desired printing outcome.
            </Text>
          </div>
          <div className="eds-spacing--mt-24 print-modal__divider">
            <Divider />
          </div>
        </Modal>
      ) : undefined}
    </div>
  );
}
