import { Table, TableCell, Icon, Text, Select, Divider } from '@nike/eds';

export function ManageTeamMembers({
  team_members_table,
  entitlements,
  owner,
  engine_wide_access,
  is_general_user_group,
  active_engine,
  ad_group_entitilement_name,
  update_entitlements,
  is_admin,
  general_user_icons_mapping,
  general_user_options,
  options,
  has_user_permissions,
}) {
  const rows = entitlements?.map(entitlement => {
    const is_owner = entitlement?.name === owner;

    const is_user = entitlement?.name?.includes('@nike.com');
    // a little hairy but it works, would be good to refactor this
    const default_value =
      is_general_user_group(entitlement?.name) && !engine_wide_access
        ? general_user_options[1]
        : is_general_user_group(entitlement?.name) && engine_wide_access
        ? general_user_options[0]
        : entitlement?.permissions?.includes('ADMIN')
        ? options[2]
        : entitlement?.permissions?.includes('WRITE')
        ? options[1]
        : options[0];

    return [
      <TableCell key={`${entitlement?.name}_0`}>
        {/* TODO: make owner icon the crown?? */}
        {is_user && (
          <Icon
            name="Profile"
            backgroundShape="circle"
            backgroundColor="var(--eds-color-grey-1)"
            size="s"
            color="var(--eds-color-white)"
          />
        )}
        {is_general_user_group(entitlement?.name) && (
          <Icon
            name={general_user_icons_mapping[active_engine]}
            backgroundShape="circle"
            backgroundColor="var(--eds-color-grey-1)"
            size="s"
            color="var(--eds-color-white)"
          />
        )}
        {!is_user && !is_general_user_group(entitlement?.name) && (
          <Icon
            name="Club"
            backgroundShape="circle"
            backgroundColor="var(--eds-color-grey-1)"
            size="s"
            color="var(--eds-color-white)"
          />
        )}
      </TableCell>,
      <TableCell key={`${entitlement?.name}_1`}>
        {is_user ? entitlement?.name : ad_group_entitilement_name(entitlement?.name)}
      </TableCell>,
      <TableCell style={{ width: '77%' }} key={`${entitlement?.name}_2`}>
        <div className="manage-board__entitlements-cell">
          {is_owner ? (
            <Text className="manage-board__entitlements-owner">Owner/Admin</Text>
          ) : (
            <Select
              menuPlacement="top"
              id={`entitlements_${entitlement?.name}`}
              noBorder={true}
              isSearchable={false}
              options={
                is_general_user_group(entitlement?.name)
                  ? general_user_options
                  : [...options, { value: undefined, label: 'Remove' }]
              }
              defaultValue={default_value}
              onChange={event =>
                update_entitlements({
                  name: entitlement?.name,
                  permissions: event?.value,
                  type: is_general_user_group(entitlement?.name) ? 'group' : 'user',
                })
              }
              isDisabled={!has_user_permissions}
            />
          )}
        </div>
      </TableCell>,
    ];
  });

  return (
    <div className="manage-board__team" ref={team_members_table}>
      <div className="manage-board__team-header">
        <Icon name="Club" color="var(--eds-color-text-default)" />
        <Text font={'title-6'} as={'h2'}>
          Team Members
        </Text>
      </div>
      <Divider className="eds-spacing--mb-24" />
      <div className="manage-board__team-table">
        <Table>
          <tbody>
            {rows?.map((row, index) => (
              <tr key={index}>
                {row?.map(cell => {
                  return cell;
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div></div>
    </div>
  );
}
