import { useState } from 'react';
import useColorScheme from '../../hooks/use_color_scheme';
import { Header } from '../../components/header';
import { DNDGrid } from '../../components/common/dnd_grid';

export default function Playground({ title }) {
  const { appearance } = useColorScheme();
  const [items, setItems] = useState([
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
  ]);

  return (
    <div className={`page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'} settings`}>
      <div className="page-header">
        <Header
          title={title}
          show_search={false}
          show_tote={false}
          header_bottom={() => undefined}
          icon_row={{ sort: false, select: false, display_settings: false }}
        />
      </div>
      <div className="page-contents grid">
        <DNDGrid data={items} set_data={setItems} />
      </div>
    </div>
  );
}
