import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Select } from '@nike/eds';
import { Collapse } from '../components/common/collapse';
import {
  get_filter_chips,
  get_filters_from_search_params,
  set_facet_params,
  clear_all_filters,
} from '../utils/filters';
import cloneDeep from 'lodash.clonedeep';

// the metadata object will hold everything that has to deal w/ altering the basic behaviour of the template

export const useBoardFilterStore = create(
  devtools(
    (set, get) => ({
      search_query: '',
      list: {
        createdByUserId: {
          id: 'createdByUserId',
          order: 0,
          list_type: 'general',
          display_name: 'Board Creator',
          facet_property_name: 'createdByUserId',
          facet_type: 'select',
          template: props => (
            <Collapse>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                options={props?.options}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('createdByUserId', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
      },
      update_list: list => {
        set(state => {
          return {
            ...state,
            list,
          };
        });
      },
      update_search_query: search_query => {
        set(state => {
          return {
            ...state,
            search_query,
          };
        });
      },
      update_state: state => {
        set(s => {
          return state;
        });
      },
      update: (key, data) => {
        set(state => ({
          ...state,
          list: {
            ...state.list,
            [key]: {
              ...data,
            },
          },
        }));
      },
      update_filter_value: (filter_key, value) => {
        set(state => {
          return {
            ...state,
            list: {
              ...state.list,
              [filter_key]: {
                ...state.list[filter_key],
                metadata: {
                  ...state.list[filter_key].metadata,
                  props: {
                    ...state.list[filter_key].metadata.props,
                    value,
                  },
                },
              },
            },
          };
        });
      },
      clear_filters: () => {
        const list = cloneDeep(get().list);
        clear_all_filters(list);
        get().update_list(list);
      },
      get_filter_params: () => {
        const list = get().list;
        const search_query = get().search_query;
        const params = {
          sortColumn: 'updatedTimestamp',
          sortDirection: 'desc',
          source: 'DPC',
          paletteClass: 'material',
          size: 10,
          active: true,
        };

        if (search_query) {
          params['query'] = search_query;
        }

        set_facet_params(list, params);

        return params;
      },
      get_filter_id(key) {
        if (key === 'query') return 'search_query';

        const id = Object.values(get().list).find(filter => filter.facet_property_name === key)?.id;

        return id;
      },
      load_chips: () => {
        const list = get().list;
        const update_method = get().update_filter_value;
        const chips = get_filter_chips(list, update_method);

        return chips;
      },
      load_filters_from_search_params: search_params => {
        const list = get().list;
        const get_filter_id_helper = get().get_filter_id;
        const update_state = get().update_state;

        get_filters_from_search_params(search_params, list, get_filter_id_helper, update_state);
      },
    }),
    { name: 'board_filters' }
  )
);
