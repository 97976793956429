import { Icon, Button, Text } from '@nike/eds';
import { use_auth_store } from '../../hooks/use_auth';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { MaterialCollectionCard } from './material_collection_card';
import { track } from '../../utils/analytics';

export function BoardCard({ palette, actions }) {
  const { user } = use_auth_store();

  function SubTitle() {
    return (
      <Text font="subtitle-1" className="truncate" color="text-secondary">
        {palette?.totalMaterials} Material(s)
      </Text>
    );
  }

  function BoardTopIcons() {
    const get_icon_name = () => {
      if (palette?.private === false) {
        return 'Global';
      }
      const entitlements = palette?.entitlements || [];
      const user_has_access =
        palette?.createdByUserId === user.preferred_username ||
        entitlements.find(u => u.name === user.preferred_username);
      const more_than_one_user =
        entitlements?.filter(u => u?.name?.includes('@nike.com'))?.length > 1;

      if (palette?.private === true && user_has_access && more_than_one_user) {
        return 'Club';
      } else {
        return '';
      }
    };

    return (
      <>
        {user.preferred_username === palette.createdByUserId && (
          <Icon size="m" name="Coach" className="eds-name-spacing--mr-8" />
        )}
        <Icon name={get_icon_name()} size="m" className="eds-name-spacing--mr-8" />
      </>
    );
  }

  function BoardFooter() {
    return (
      <>
        <Button variant="secondary" size="small" onClick={actions?.open}>
          Open Board
        </Button>
        <Button variant="ghost" size="small" onClick={handle_copy_click}>
          Copy Link <Icon name="Link" />
        </Button>
      </>
    );
  }

  const { send_notification } = useNotificationsStore();

  const handle_copy_click = () => {
    track({ eventName: 'copyLink' });

    const board_link = `${window.location?.origin}/boards/${palette.paletteId}`;
    navigator.clipboard.writeText(board_link);
    send_notification('success', 'Link Copied to Clipboard');
  };

  return (
    <MaterialCollectionCard
      palette={palette}
      topIconSlot={<BoardTopIcons />}
      subTitleSlot={<SubTitle />}
      footerSlot={<BoardFooter />}
    />
  );
}
