import { Divider } from '@nike/eds';
import './info_modal.css';
import useColorScheme from '../../hooks/use_color_scheme';
import packageJson from '../../../package.json';

export default function InfoModal({ show, onClose }) {
  const { appearance } = useColorScheme();
  const theme = appearance === 'light' ? 'eds--dark' : 'eds--light';
  return (
    show && (
      <div className={`eds-elevation--3 eds-spacing--px-16 eds-type--body-3 ${theme} info `}>
        <div className="info-title eds-spacing--py-12 eds-color--default">
          MaterialHUB Help Center
          <div className="eds-color--secondary">App Version: {packageJson?.version}</div>
        </div>
        <Divider />
        <div className="info-item eds-spacing--pt-16">
          <div className="eds-color--default">Slack Channel</div>
          <div className="info-item-link">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://app.slack.com/client/ED6QX9KRV/C06RQ4ZTKV4"
            >
              #help-nike-materialhub
            </a>
          </div>
        </div>
        <div className="info-item eds-spacing--pt-16">
          <div className="eds-color--default">Email Support</div>
          <div className="info-item-link eds-type--legal-1">
            <a rel="noreferrer" href="mailto:materialhub@nike.com">
              materialHUB@nike.com
            </a>
          </div>
        </div>
        <div className="info-item eds-spacing--pt-16 eds-spacing--pb-16">
          <div className="eds-color--default">Learning Resources</div>
          <div className="info-item-link eds-type--legal-1 ">
            <a rel="noreferrer" target="_blank" href="https://app.zunos.com/#/board/?id=10940">
              MaterialHUB Online Training
            </a>
          </div>
          <div className="info-item-link eds-type--legal-1">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://nike.box.com/s/04f20mtjurz9ltck76stgngrodxp6lkh"
            >
              Help logging in
            </a>
          </div>
        </div>
        <Divider />
        <div
          onClick={onClose}
          className="eds-spacing--py-12 eds-color--link eds-type--legal-1 action"
        >
          Close
        </div>
      </div>
    )
  );
}
