import { create } from 'zustand';
import { useInfiniteQuery } from '@tanstack/react-query';
import { http_client } from '../utils/fetch';
import config from '../config';
import { use_auth_store } from './use_auth';
import { persist } from 'zustand/middleware';

const { user } = use_auth_store.getState();

export function getFileType(file) {
  return file.name.split('.')[1];
}

export function getViewType(file) {
  return file.name.split('.')[0]?.split('_')[1];
}

export function getUploadStatus(file, asset, material) {
  const validFileTypes = ['mp4', 'jpg', 'png', 'MP4', 'JPG', 'PNG', 'jpeg', 'JPEG'];
  const validViewTypes = ['DR', 'BK', 'TH', 'FC'];
  const fileType = getFileType(file);
  const viewType = getViewType(file);

  if (!asset) {
    return 'Material ID does not exist.';
  }

  if (material) {
    return 'File already exists.';
  } else if (file.size > 100000000) {
    return 'File too large.';
  } else if (!validFileTypes.includes(fileType)) {
    return 'Unsupported file format.';
  } else if (viewType && !validViewTypes.includes(viewType)) {
    return 'Invalid view type.';
  } else if (asset && !material) {
    return 'Ready to upload.';
  }
}

export function toMaterialData(file, materialsMap) {
  const pcxId = file.name.split('.')[0].split('_')[0];
  const associatedAsset = materialsMap ? materialsMap.get(pcxId) : null;

  const fileType = getFileType(file);
  const viewType = getViewType(file);
  const view = getView(viewType, fileType);

  const associatedMaterial = associatedAsset?.assets?.find(asset => {
    const assetData = asset.assetMetadata;

    return (
      (assetData?.viewType === 'Video' &&
        ['MP4', 'mp4'].includes(fileType) &&
        assetData?.subClass === 'vml') ||
      (assetData?.viewType === view && assetData?.subClass === 'vml') ||
      (!['MP4', 'mp4'].includes(fileType) &&
        !view &&
        assetData?.viewType === 'Face' &&
        assetData.subClass === 'vml')
    );
  });

  const uploadStatus = getUploadStatus(file, associatedAsset, associatedMaterial);
  return {
    asset: associatedAsset,
    pcxMaterialId: pcxId,
    fullName: associatedAsset?.name ? associatedAsset.name : file.name?.split('.')[0],
    fileType: file.name.split('.')[1],
    viewType,
    uploadStatus: uploadStatus,
    materialType3: associatedAsset?.materialType3,
    materialType4: associatedAsset?.materialType4,
    materialType5: associatedAsset?.materialType5,
    textColor: uploadStatus === 'Ready to upload.' ? 'text-success' : 'text-danger',
    isValid: uploadStatus === 'Ready to upload.' || uploadStatus === 'File already exists.',
    exists: !!associatedMaterial,
    file,
    material: associatedMaterial,
    isUploading: false,
  };
}

export function getView(viewType, fileType) {
  if (fileType === 'mp4' || fileType === 'MP4') {
    return 'Video';
  }

  switch (viewType) {
    case 'DR':
      return 'Fold/Drape';
    case 'BK':
      return 'Back';
    case 'TH':
      return 'Thickness';
    case 'FC':
    case 'blank':
    case undefined:
      return 'Face';
    default:
      return false;
  }
}

export function getMaterialIds(files) {
  return files.map(f => toMaterialData(f, null)).map(m => m.pcxMaterialId);
}

export async function getUploadHistory(params) {
  const url = new URL(config.apis.search.all_assets, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: {
      'terms.assetClass[0]': 'material2d',
      'terms.assetClass[1]': 'materialvideo',
      'terms.version.createdByUserId': user?.preferred_username,
      'sorts.version.createTimestamp': 'desc',
      'fields.includes[0]': 'links.linkData',
      'fields.includes[1]': '*',
      'pagination.size': 10,
      ...params,
    },
  });

  return res?.data;
}

export function useUploadHistoryHook(params) {
  return useInfiniteQuery({
    queryKey: ['all_upload_history', params],
    queryFn: async ({ pageParam = 0 }) => {
      // since allassets endpoint supports pagination a little differently, we need to pass the pageParam as a stringified query parameter
      return await getUploadHistory({ ...params, 'pagination.from': pageParam });
    },
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'

      return next_page;
    },
  });
}

export const useFiles = create(
  persist(
    set => ({
      fileHistory: [],
      setHistory: files => {
        set(state => ({ fileHistory: files, ...state }));
      },
      setFiles: async files => {
        set(state => {
          const currentHistory = state.fileHistory;
          const newFiles = files.filter(
            file =>
              !currentHistory.some(historyFile => historyFile.pcxMaterialId === file.pcxMaterialId)
          );
          const updatedHistory = [...currentHistory, ...newFiles];
          return {
            fileHistory: updatedHistory,
          };
        });
      },
    }),
    {
      name: 'file_history',
      getStorage: () => localStorage,
    }
  )
);
