import { Text, Divider, TabGroup, Tab } from '@nike/eds';
import '../styles/display_settings.css';

export function ThumbnailSize({ local_size, set_local_size }) {
  return (
    <div>
      <Text className="eds-spacing--mb-8" font="subtitle-1">
        Thumbnail Size
      </Text>
      <Divider color="text-secondary" className="eds-spacing--mb-24" />
      <TabGroup
        className="eds-spacing--mb-32"
        name="size"
        activeId={local_size}
        onChange={e => set_local_size(e.target?.id)}
        variant="toggle-medium"
      >
        <Tab id="small">Small</Tab>
        <Tab id="medium">Medium</Tab>
        <Tab id="large">Large</Tab>
      </TabGroup>
    </div>
  );
}
