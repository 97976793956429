import './hover_text.css';

export default function HoverText({ text, dataTip, dataFor }) {
  return (
    <div className="hover-text-container" data-tip={dataTip} data-for={dataFor}>
      <div className="hover-text">
        <div>{text}</div>
        <div className="arrow-down"></div>
      </div>
      <div className="fixed-text">
        <p className="eds-type--subtitle-2">{text}</p>
      </div>
    </div>
  );
}
