import { ButtonGroup, Button, Toggle, Spinner } from '@nike/eds';

export function ManageBoardActions({
  is_admin,
  to_archive,
  set_show_archiving,
  update,
  reset,
  set_to_archive,
  loading,
  has_user_permissions,
}) {
  return (
    <div className="manage-board__actions">
      <ButtonGroup>
        <Button
          disabled={!has_user_permissions || loading}
          size="medium"
          variant="primary"
          onClick={() => {
            if (to_archive) {
              set_show_archiving(true);
            } else {
              update();
            }
          }}
        >
          {loading ? <Spinner /> : 'Update'}
        </Button>
        <Button
          disabled={!has_user_permissions || loading}
          size="medium"
          variant="secondary"
          onClick={reset}
        >
          Cancel
        </Button>
      </ButtonGroup>
      <div className="manage-board__archive">
        <span>Archive board</span>
        <Toggle
          label=""
          disabled={!has_user_permissions || loading}
          onChange={() => set_to_archive(!to_archive)}
          checked={to_archive ? true : false}
        />
      </div>
    </div>
  );
}
