import { DragSelectProvider } from '../drag_select';
import { Section } from './material_collection_section';

export function Sections({
  sections,
  materials,
  status,
  update_selected_materials,
  is_board_page,
  multi_select,
  selected_materials,
  active_card,
  set_active_card,
  open_material,
  set_active_modal,
  set_modal_data,
  set_export_materials,
  set_total_materials,
  set_show_tote_modal,
  add_material_to_tote,
  set_multi_select,
  palette_id,
  palette_summary,
  board_mutation,
  set_materials,
  set_sections,
  // infinite scroll props
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}) {
  return (
    <div className="board-sections eds-spacing--pl-56 eds-spacing--pt-24 eds-spacing--pb-24 eds-spacing--pr-48">
      {sections?.length > 0 && (
        <DragSelectProvider multi_select={multi_select}>
          {sections?.map(section => (
            // TODO: find out which of these props we can just import in instead of passing down
            <Section
              key={`${section?.name}_${section?.order}`}
              section={section}
              sections={sections}
              materials={(section?.ids || []).map(id => materials?.find(item => id === item?.id))}
              all_materials={materials}
              ids={materials?.map(item => item?.id)}
              status={status}
              update_selected_materials={update_selected_materials}
              is_board_page={is_board_page}
              selected_materials={selected_materials}
              active_card={active_card}
              set_active_card={set_active_card}
              open_material={open_material}
              set_active_modal={set_active_modal}
              set_modal_data={set_modal_data}
              set_export_materials={set_export_materials}
              set_total_materials={set_total_materials}
              set_show_tote_modal={set_show_tote_modal}
              add_material_to_tote={add_material_to_tote}
              set_multi_select={set_multi_select}
              multi_select={multi_select}
              palette_id={palette_id}
              palette_summary={palette_summary}
              board_mutation={board_mutation}
              set_materials={set_materials}
              set_sections={set_sections}
              // infinite scroll props
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
            />
          ))}
        </DragSelectProvider>
      )}
    </div>
  );
}
