export function get_secondary_material_id(material, active_engine) {
  let label = 'PDM';
  let value = material?.material?.legacyMaterialId;
  if (active_engine === 'APPAREL') {
    label = 'PPS';
    const ppsItem = material?.material?.suppliedMaterials?.find(
      item => item.ppsItemNumber !== undefined
    );
    value = ppsItem?.ppsItemNumber;
  } else if (active_engine === 'ACCESSORIES') {
    label = 'FLEX';
  }

  return { label, value };
}
