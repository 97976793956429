import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text, Icon, Divider, Button, TextField, Link, IconButton, Select } from '@nike/eds';
import './header.css';
import { Results } from './header_results';
import { IconRow } from './header_icon_row';
import { FilterChips } from './filter_chips';
import { useToteStore } from '../../hooks/use_tote';
import { BreadCrumb } from './bread_crumb';
import { useModalStore } from '../../hooks/use_modal';
import { use_engine_store } from '../../hooks/use_engine';
import { track } from '../../utils/analytics';
import { searchOptions, useLibraryFilterStore } from '../../hooks/use_library_filters';

export function Header({
  title,
  palette_data,
  total = 0,
  actions,
  multi_select,
  filters,
  icon_row,
  show_search = true,
  show_tote = true,
  current_route,
  previous_route,
  go_back,
  filter_store,
  disable_filters = false,
  className = '',
  header_filters = props => <HeaderFilters {...props} />,
  header_top = props => <HeaderTop {...props} />,
  header_bottom = props => <HeaderBottom {...props} />,
}) {
  total = total.toLocaleString();
  const { dismiss_modal } = useModalStore();
  const { get_active_engine_title } = use_engine_store();
  const navigate = useNavigate();
  const location = useLocation();
  const active_engine_title = get_active_engine_title();
  const headerClass = `header ${className}`;

  // eslint-disable-next-line no-unused-vars
  const [palette_summary, set_palette_summary] = useState(palette_data);
  // eslint-disable-next-line no-unused-vars
  const [palette_name, set_palette_name] = useState('');
  const [show_more, toggle_show_more] = useState(false);
  const [has_hidden_chips, toggle_has_hidden_chips] = useState(false);
  // setting default value from the store prevents the UI from jumping around to an empty string and then the value from the URL
  const [local_search_value, set_local_search_value] = useState(filter_store?.search_query || '');

  useEffect(() => {
    const name = palette_data?.name;
    const path_name = location?.pathname;

    if (name && (path_name.includes('/palettes/') || path_name.includes('/boards/'))) {
      set_palette_name(name);
    } else {
      // reset the palette name on non-palette routes to prevent weirdness w/ the breadcrumb
      set_palette_name(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palette_summary, location]);

  useEffect(() => {
    const path = window?.location?.pathname;
    if (
      path === '/' ||
      path === '/library' ||
      path.startsWith('/palettes') ||
      path.startsWith('/boards')
    ) {
      set_local_search_value(filter_store?.search_query);
    }
  }, [filter_store?.search_query]);

  const update_search_query = event => {
    if (event?.keyCode === 13) {
      const query = event?.target?.value;

      run_search(query);
    }
  };

  function handle_tote() {
    if (palette_summary?.name) {
      navigate('/tote', { state: { previous_route: palette_summary?.name } });
    } else {
      navigate('/tote', { state: { previous_route: title } });
    }
  }

  const run_search = query => {
    const path = window?.location?.pathname;

    track({
      eventName: path?.includes('palettes')
        ? 'searchPalettes'
        : path?.includes('boards')
        ? 'searchBoards'
        : 'searchMaterials',
      searchTerm: query,
    });

    if (
      path === '/' ||
      path === '/library' ||
      path.startsWith('/palettes') ||
      path.startsWith('/boards')
    ) {
      filter_store.update_search_query(query);
    }
  };

  const handle_clear_all_filters = () => {
    const path = window?.location?.pathname;
    if (
      path === '/' ||
      path === '/library' ||
      path.startsWith('/palettes') ||
      path.startsWith('/boards')
    ) {
      filter_store.clear_filters();
    }
  };

  const clear_search_input = () => {
    set_local_search_value('');
    run_search('');
  };

  return (
    <>
      <div className={`eds-app-header header-height upper-header ${headerClass}`}>
        {header_top({
          title,
          total,
          active_engine_title,
          current_route,
          previous_route,
          go_back,
          show_search: show_search,
          local_search_value,
          set_local_search_value,
          update_search_query,
          filter_store,
          clear_search_input,
          show_tote: show_tote,
          handle_tote,
          hide_tote_modal: dismiss_modal,
          palette_summary,
          disable_filters,
        })}
      </div>
      <div className={`eds-app-header header-bottom eds-spacing--pb-16 ${headerClass}`}>
        {header_bottom({
          filters,
          disable_filters,
          handle_clear_all_filters,
          show_more,
          actions,
          toggle_has_hidden_chips,
          has_hidden_chips,
          toggle_show_more,
          multi_select,
          icon_row,
          header_filters,
          current_route,
        })}
      </div>
    </>
  );
}

function HeaderTop({
  title,
  total,
  active_engine_title,
  current_route,
  previous_route,
  go_back,
  show_search,
  local_search_value,
  set_local_search_value,
  update_search_query,
  filter_store,
  clear_search_input,
  show_tote,
  handle_tote,
  disable_filters,
}) {
  const { tote } = useToteStore();
  const { setSelectedSearchOption, selected_search_option } = useLibraryFilterStore();
  const is_nested_route = previous_route && current_route;

  const handleSelectChange = selectedOption => {
    setSelectedSearchOption(selectedOption.value);
  };

  function page_title(title) {
    let page_title;
    if (title) {
      if (title.includes('Settings')) {
        page_title = title;
      } else if (title.includes('Library') || title.includes('Palettes')) {
        page_title = `${active_engine_title} ${title} (${total})`;
      } else if (title.includes('All Boards')) {
        page_title = `All ${active_engine_title} Boards (${total})`;
      } else if (title.includes('My Boards')) {
        page_title = `My ${active_engine_title} Boards (${total})`;
      } else if (title.includes('Tote')) {
        page_title = `Tote (${tote.length})`;
      } else {
        page_title = title;
      }
    }
    return page_title;
  }

  function getFilteredSearchOptions(activeEngineTitle) {
    switch (activeEngineTitle) {
      case 'Footwear':
        return searchOptions.filter(option =>
          ['query', 'materialId', 'legacyMaterialId'].includes(option.value)
        );
      case 'Apparel':
        return searchOptions.filter(option =>
          ['query', 'materialId', 'ppsItemNumber'].includes(option.value)
        );
      case 'Accessories':
        return searchOptions.filter(option =>
          ['query', 'materialId', 'legacyMaterialNumberEq'].includes(option.value)
        );
      default:
        return searchOptions;
    }
  }

  const filteredSearchOptions = getFilteredSearchOptions(active_engine_title);

  return (
    <>
      {is_nested_route ? (
        <BreadCrumb
          title={title}
          total={total}
          current_route={current_route}
          previous_route={previous_route}
          go_back={go_back}
        />
      ) : (
        <div className="header-title truncate">
          <Text font="title-4" as={'h1'} className="truncate">
            {page_title(title)}
          </Text>
        </div>
      )}
      <div>
        <div className="searchbar-search__container">
          <div className="searchbar-tote-container">
            {show_search && (
              <div style={{ width: '200px' }}>
                <Select
                  id="single-example"
                  options={filteredSearchOptions}
                  defaultValue={{ value: 'query', label: 'Freeform' }}
                  value={searchOptions.find(option => option.value === selected_search_option)}
                  onChange={handleSelectChange}
                />
              </div>
            )}
            {show_search && (
              <TextField
                style={{
                  backgroundColor: 'var(eds-color-background-secondary)',
                  height: 'var(--eds-space-40)',
                  width: 240,
                }}
                id="material_search"
                value={local_search_value}
                disabled={disable_filters}
                onChange={event => set_local_search_value(event?.target?.value)}
                onKeyUp={update_search_query}
                // maxChars={maxChars}
                errorMessage="Max character count exceeded"
                beforeSlot={<Icon name="Search" />}
                afterSlot={
                  filter_store?.search_query?.length ? (
                    <IconButton
                      icon="Close"
                      className="search-close-icon"
                      onClick={() => clear_search_input()}
                      variant="ghost"
                      size="small"
                    />
                  ) : (
                    <div className="search-close-icon" />
                  )
                }
                //   hasErrors={value.length > 10}
                placeholder="Search"
                noBorder
              />
            )}
            {show_tote && (
              <div className="tote-spacing eds-spacing--ml-8">
                {tote?.length > 0 && <div className="tote-materials-indicator">{tote.length}</div>}
                <IconButton
                  icon="Bag"
                  label="Bag"
                  variant="ghost"
                  onClick={handle_tote}
                  className="eds-color--text-primary"
                ></IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function HeaderBottom({
  filters,
  handle_clear_all_filters,
  show_more,
  actions,
  toggle_has_hidden_chips,
  has_hidden_chips,
  toggle_show_more,
  multi_select,
  icon_row,
  header_filters,
  disable_filters,
  current_route,
}) {
  return (
    <>
      {header_filters({
        filters,
        disable_filters,
        handle_clear_all_filters,
        show_more,
        actions,
        toggle_has_hidden_chips,
        has_hidden_chips,
        toggle_show_more,
        current_route,
      })}
      <div className="bottom-right-container">
        <div className="bottom-right-icon-container">
          <IconRow
            actions={actions}
            multi_select={multi_select}
            icons={icon_row}
            current_route={current_route}
            disable_filters={disable_filters}
          />
        </div>
        <Results export_all={actions?.export_all} />
      </div>
    </>
  );
}

function HeaderFilters({
  filters,
  disable_filters,
  handle_clear_all_filters,
  show_more,
  actions,
  toggle_has_hidden_chips,
  has_hidden_chips,
  toggle_show_more,
}) {
  return (
    <div className="bottom-left-container" key={filters?.toString()}>
      <div className="filters-text-container">
        {!disable_filters && (
          <Text font="subtitle-1" className="filter-text">
            Filters {filters?.length ? `(${filters.length})` : ''}
          </Text>
        )}
        {filters?.length > 0 && (
          <Link
            className="clear-all-link eds-link--hover select"
            variant="secondary"
            font={'body-3'}
            onClick={handle_clear_all_filters}
            disabled={disable_filters ? true : false}
          >
            Clear All
          </Link>
        )}
      </div>
      <Divider vertical className="eds-spacing--ml-16 eds-spacing--mr-16" />
      <FilterChips
        show_more={show_more}
        toggle_has_hidden_chips={toggle_has_hidden_chips}
        has_hidden_chips={has_hidden_chips}
        filters={filters}
        disabled={disable_filters ? true : false}
        toggle_button={() => (
          <Button
            className="eds-spacing--ml-8"
            style={{
              alignSelf: 'flex-start',
              minWidth: 'fit-content',
            }}
            variant="secondary"
            size={'small'}
            onClick={() => toggle_show_more(!show_more)}
          >
            See less
          </Button>
        )}
      />
      {has_hidden_chips && !show_more && (
        <Button
          className="eds-spacing--ml-8"
          style={{
            alignSelf: show_more ? 'flex-end' : 'flex-start',
            minWidth: 'fit-content',
          }}
          variant="secondary"
          size={'small'}
          onClick={() => toggle_show_more(!show_more)}
        >
          See all
        </Button>
      )}
    </div>
  );
}
