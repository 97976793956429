import { Text } from '@nike/eds';
import './import_file.css';

export default function ImportFile(props) {
  return (
    <div className="button-container" style={{ ...props.style }}>
      <input
        type="file"
        id="upload"
        name="upload"
        accept="image/png, image/jpeg, .mp4"
        hidden
        onChange={e => props.onChange(e.target.files)}
        multiple
      />
      <label className="label-button eds-border--primary" htmlFor="upload">
        <Text color="black" font="legal-1">
          Browse Files
        </Text>
      </label>
    </div>
  );
}
