import { Modal, Button, ButtonGroup, Icon, Text, Link } from '@nike/eds';
import { useState } from 'react';

import useColorScheme from '../../hooks/use_color_scheme';
import { useModalStore } from '../../hooks/use_modal';
import './add_to_board_modal.css';

import { AddToBoard } from './add_to_board';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { useNavigate } from 'react-router-dom';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';

export function AddToBoardModal() {
  const { appearance } = useColorScheme();
  const [disabled, set_disabled] = useState(false);
  const [selected_boards, set_selected_boards] = useState([]);
  const { dismiss_modal, active_modal, modal_data, set_active_modal } = useModalStore();
  const navigate = useNavigate();
  const show = active_modal === 'add_to';
  const { materials, on_success } = modal_data;

  const { send_notification, dismiss_notification } = useNotificationsStore();
  function reset() {
    set_selected_boards([]);
    dismiss_modal();
  }

  const handle_click = async () => {
    await add_materials_to_boards({
      boards: selected_boards,
      material_ids: [...materials],
      // don't need to pass along the trigger location because this can be triggered from multiple locations so gonna handle it inside that add_materials_to_boards instead....
    });

    if (on_success) {
      on_success();
    }
    dismiss_modal();
  };

  const handle_go_to_board = () => {
    navigate(`/boards/${selected_boards?.[0]?.paletteId}`);
  };

  const notification = e => {
    e.stopPropagation();
    const result = handle_click();

    selected_boards.length > 1
      ? send_notification(
          'success',
          result ? 'Adding Materials was Successful' : 'Adding Materials was Unsuccessful'
        )
      : // add a little timeout to allow the board to actually have the added materials
        // should probably add a poll for board entries here but for now this will do
        setTimeout(
          () =>
            send_notification(
              'success',
              result ? 'Adding Materials was Successful' : 'Adding Materials was Unsuccessful',
              () => {
                e.stopPropagation();
                dismiss_notification();
              },
              <>
                <Link onClick={handle_go_to_board}>Go to Board</Link>
              </>
            ),
          500
        );
  };

  return (
    <div className="add-board">
      {show ? (
        <Modal
          className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'} `}
          isOpen={show}
          onDismiss={dismiss_modal}
          hideFade={true}
          headerSlot="Add to Board(s)"
          footerSlot={
            <div className="add-board__actions">
              <ButtonGroup>
                <Button disabled={disabled} onClick={notification}>
                  Add to Board(s)
                </Button>
                <Button variant="secondary" onClick={reset}>
                  Cancel
                </Button>
              </ButtonGroup>

              <Button variant="ghost" onClick={() => set_active_modal('create_board')}>
                <Icon name="PlusCircle"> </Icon>
                <Text>Create New Board</Text>
              </Button>
            </div>
          }
        >
          <AddToBoard
            set_defaults={show}
            set_disabled={set_disabled}
            selected_boards={selected_boards}
            set_selected_boards={set_selected_boards}
          />
        </Modal>
      ) : undefined}
    </div>
  );
}
