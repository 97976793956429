import { Text, Table, TableCell, Tooltip, Icon } from '@nike/eds';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { track } from '../../utils/analytics';

const CopyableText = ({ font, as, className, children, field, materialID }) => {
  const { send_notification } = useNotificationsStore();

  const handle_text_copy_click = () => {
    navigator.clipboard.writeText(children);
    send_notification('success', 'Copied to Clipboard');
    track({
      eventName: 'copyText',
      fieldName: field,
      page: 'materialDetails',
      materialID: materialID,
    });
  };

  return (
    <Tooltip
      bodySlot={
        <div>
          <Icon name="CopyPaste" variant="ghost" />
        </div>
      }
      placement="top"
      delay={0}
    >
      <Text font={font} as={as} className={`${className} pointer`} onClick={handle_text_copy_click}>
        {children}
      </Text>
    </Tooltip>
  );
};

export function Details({ material, active_engine }) {
  const details_left = [
    {
      label: 'Status',
      key: 'matItemStatusIndicator',
      border: 'none',
    },
    {
      label: 'Type',
      key: 'materialType4',
    },
    {
      label: 'Sub-Type',
      key: 'materialType5',
    },
    {
      label: 'Content',
      key: 'content',
    },
    {
      label: 'Physical Sample in Studio',
      key: 'physicalSampleAvailableIndicator',
    },
  ];

  const details_right = [
    {
      label: 'PCX Number',
      key: 'pcxNumber',
      border: 'none',
    },
    {
      label: 'Date Added',
      key: 'createTimestamp',
    },
    {
      label: 'Initial Season',
      key: 'initialSeason',
    },
    {
      label: 'Construction Type',
      key: 'constructionType',
    },
    {
      label: 'Material Benefit',
      key: 'materialBenefits',
    },
  ];

  return (
    <div className="material-details__section eds-spacing--mb-36 eds-spacing--pt-36">
      <Text font="title-6" as="h6">
        Details
      </Text>
      <div className="material-details__section-details">
        <Table className="material-details__details-table">
          <tbody>
            {details_left.map(row => {
              if (row?.label === 'Physical Sample in Studio' && active_engine === 'FOOTWEAR') {
                return null;
              }
              return (
                <tr className={row?.border === 'none' ? 'no-border' : ''} key={row?.label}>
                  <TableCell>
                    <Text font="subtitle-1">{row?.label}</Text>
                  </TableCell>
                  <TableCell>
                    <CopyableText font="subtitle-1" field={row?.key} materialID={material?.id}>
                      {(() => {
                        if (row?.label === 'Status') {
                          return material?.material?.[row?.key] === true
                            ? 'Active'
                            : material?.material?.[row?.key] === false
                            ? 'Inactive'
                            : '- -';
                        } else if (row?.label === 'Content') {
                          return (
                            material?.material?.matContent
                              ?.map(
                                content =>
                                  `${content?.matContentPercentage}% ${content?.matContentTypeSource}`
                              )
                              .join(', ') || '- -'
                          );
                        } else if (row?.label === 'Physical Sample in Studio') {
                          return material?.material?.suppliedMaterials?.some(
                            suppliedMaterial => suppliedMaterial?.physicalSampleAvailableIndicator
                          )
                            ? 'Yes'
                            : 'No';
                        } else {
                          return material?.[row?.key];
                        }
                      })()}
                    </CopyableText>
                  </TableCell>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Table className="material-details__details-table right">
          <tbody>
            {details_right.map(row => {
              if (row?.label === 'Construction Type' && active_engine === 'ACCESSORIES') {
                return null;
              } else if (row?.label === 'Material Benefit' && active_engine !== 'APPAREL') {
                return null;
              }
              return (
                <tr className={row?.border === 'none' ? 'no-border' : ''} key={row?.label}>
                  <TableCell>
                    <Text font="subtitle-1">{row?.label}</Text>
                  </TableCell>
                  <TableCell>
                    <CopyableText font="subtitle-1" field={row?.key} materialID={material?.id}>
                      {(() => {
                        if (row?.label === 'PCX Number') {
                          return material?.id ? material?.id : '- -';
                        } else if (row?.label === 'Date Added') {
                          return new Date(material?.material?.createTimestamp).toLocaleDateString();
                        } else if (row?.label === 'Initial Season') {
                          return active_engine === 'FOOTWEAR'
                            ? material?.material?.matInitCycleYear || '- -'
                            : material?.material?.targetCycleYear || '- -';
                        } else if (row?.label === 'Construction Type') {
                          return material?.material &&
                            Object.keys(material?.material).find(key =>
                              key.includes('Construction')
                            )
                            ? material?.material[
                                Object.keys(material?.material).find(key =>
                                  key.includes('Construction')
                                )
                              ]
                            : '- -';
                        } else if (row?.label === 'Material Benefit') {
                          return material?.material?.materialBenefits
                            ? material?.material?.materialBenefits.join(', ')
                            : '- -';
                        } else {
                          return material?.[row?.key] || '- -';
                        }
                      })()}
                    </CopyableText>
                  </TableCell>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
