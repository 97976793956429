import { useState, useRef, useEffect } from 'react';
import { Icon } from '@nike/eds';
import { Chip } from '@nike/eds';

export function FilterChips({
  toggle_has_hidden_chips,
  show_more,
  filters,
  toggle_button,
  disabled,
}) {
  const [hidden_chips, set_hidden_chips] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const refs = useRef([]);

  useEffect(() => {
    try {
      check_for_hidden_chips();
    } catch (error) {
      console.warn(error);
    }
  }, [filters, dimensions]);

  useEffect(() => {
    window.addEventListener('resize', handle_resize, false);
  }, []);

  useEffect(() => {
    if (hidden_chips > 0) {
      toggle_has_hidden_chips(true);
    } else {
      toggle_has_hidden_chips(false);
    }
  }, [hidden_chips, toggle_has_hidden_chips]);

  function check_for_hidden_chips() {
    function is_element_visible(element) {
      const container = element?.parentNode;
      const containerStyle = getComputedStyle(container);

      if (
        containerStyle?.overflow === 'hidden' ||
        containerStyle?.overflowX === 'hidden' ||
        containerStyle?.overflowY === 'hidden'
      ) {
        var containerRect = container.getBoundingClientRect();
        var elementRect = element.getBoundingClientRect();
        const visibility_check =
          elementRect.top >= containerRect.top &&
          elementRect.bottom <= containerRect.bottom &&
          elementRect.left >= containerRect.left &&
          elementRect.right <= containerRect.right;

        return visibility_check;
      }

      return true;
    }

    let new_hidden_chips = 0;

    refs.current.forEach(item => {
      const is_visible = is_element_visible(item);

      if (!is_visible) {
        new_hidden_chips++;
      }
    });

    set_hidden_chips(new_hidden_chips);
  }

  function handle_resize(event) {
    event.preventDefault();

    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  return (
    <ul className={`filter-chips ${show_more ? 'filter-chips__more' : 'filter-chips__less'}`}>
      {filters?.map((filter, index) => {
        return (
          <div
            key={`filter-chip__${index}`}
            ref={element => {
              refs.current[index] = element;
            }}
          >
            <Chip
              className="eds-spacing--mr-8 eds-spacing--mb-8"
              afterSlot={
                <Icon
                  name="Close"
                  onClick={disabled ? undefined : filter?.remove}
                  disabled={disabled}
                />
              }
              disabled={disabled}
            >
              {filter?.display_name}
              {filter?.value?.length ? `: ${filter?.value?.toString()}` : ''}
            </Chip>
          </div>
        );
      })}
      {show_more && toggle_button()}
    </ul>
  );
}
