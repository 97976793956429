import { ReactSVG } from 'react-svg';
import { Button, Icon } from '@nike/eds';
import principal_palette from '../../assets/principal_palette.svg';
import './styles/common.css';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { MaterialCollectionCard } from './material_collection_card';
import { track } from '../../utils/analytics';

export function PaletteCard({ palette, actions }) {
  const { send_notification } = useNotificationsStore();

  const handle_copy_click = () => {
    track({ eventName: 'copyLink' });

    const palette_link = `${window.location?.origin}/palettes/${palette?.id}`;
    navigator.clipboard.writeText(palette_link);
    send_notification('success', 'Link Copied to Clipboard');
  };

  function PaletteFooter() {
    return (
      <>
        <Button variant="secondary" size="small" onClick={actions?.open}>
          Open Palette
        </Button>
        <Button variant="ghost" size="small" onClick={handle_copy_click}>
          Copy Link <Icon name="Link" />
        </Button>
      </>
    );
  }

  return (
    <MaterialCollectionCard
      palette={palette}
      topIconSlot={
        palette?.paletteGroup === 'principal' && (
          <ReactSVG
            src={principal_palette}
            className="palette-card__principal"
            data-testid={`${palette?.id}_principal`}
          />
        )
      }
      footerSlot={<PaletteFooter />}
    />
  );
}
