import { Icon, Button } from '@nike/eds';
import { useState, useEffect } from 'react';
import './tote_footer.css';
import { ToteActionsMenu } from '../tote_actions_menu/tote_actions_menu';
import { useRecentBoardsHook } from '../../../hooks/use_boards';
import { use_auth_store } from '../../../hooks/use_auth';
import { use_engine_store } from '../../../hooks/use_engine';
import { useNavigate } from 'react-router-dom';

export function ToteFooter({ empty_tote, add_to, add_to_board, export_tote, print, materials }) {
  const [is_open, set_is_open] = useState(false);
  const [is_board_menu_open, set_is_board_menu_open] = useState(false);
  const [is_disabled, set_is_disabled] = useState(false);

  const { user } = use_auth_store();
  const { active_engine } = use_engine_store();
  const navigate = useNavigate();

  const { data } = useRecentBoardsHook({
    createdByUserId: user?.preferred_username,
    division: `${active_engine} DIVISION`,
  });

  useEffect(() => {
    if (materials?.length === 0) {
      set_is_disabled(true);
    } else {
      set_is_disabled(false);
    }
  }, [materials]);

  return (
    <div className="footer_icon_row">
      <div className="icon_button_group">
        <ToteActionsMenu
          add_to={add_to}
          add_to_board={add_to_board}
          empty_tote={empty_tote}
          is_board_menu_open={is_board_menu_open}
          set_is_board_menu_open={set_is_board_menu_open}
          is_open={is_open}
          set_is_open={set_is_open}
          recent_boards={data?.results || []}
          materials={materials}
        >
          <Button
            variant="primary"
            disabled={is_disabled}
            onClick={e => {
              set_is_open(!is_open);
              set_is_board_menu_open(!is_board_menu_open);
              e.stopPropagation();
            }}
          >
            <Icon name="PlusCircle" />
            Add To...
          </Button>
        </ToteActionsMenu>
        <Button
          variant="secondary"
          disabled={is_disabled || materials?.length < 2 || materials?.length > 5}
          onClick={() =>
            navigate('/compare?ids=' + materials.map(m => m.id).join(','), {
              state: {
                previous: {
                  pathname: '/tote',
                  name: 'Tote',
                },
              },
            })
          }
        >
          <Icon name="Compare" />
          Compare
        </Button>
        <Button variant="secondary" disabled={is_disabled} onClick={export_tote}>
          <Icon name="Share" />
          Export
        </Button>
        <Button variant="secondary" disabled={is_disabled} onClick={print}>
          <Icon name="Print" />
          Print
        </Button>
      </div>
      <Button variant="secondary" disabled={is_disabled} onClick={empty_tote}>
        Clear Tote
      </Button>
    </div>
  );
}
