import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { capitalize } from 'lodash';
import config from '../config';
import http_client from '../utils/fetch';
import { useModalStore } from './use_modal';
import { use_engine_store } from './use_engine';
import { useLibraryFilterStore } from './use_library_filters';
import { fetch_all_materials } from './use_materials';
import { fetch_all_palette_entries } from './use_palettes';
import { fetch_all_board_entries } from './use_boards';
import get from 'lodash.get';
import setWith from 'lodash.setwith';
import { download_artifact, download_zip, get_download_status, get_zip } from '../utils/download';
import { useNotificationsStore } from './use_notifications';
import { track } from '../utils/analytics';

export const exportStatus = {
  exporting: 'exporting',
  success: 'success',
  failed: 'failed',
};

const library_filter_store = useLibraryFilterStore.getState();

const { get_filter_params } = library_filter_store;
const { send_notification, dismiss_notification } = useNotificationsStore.getState();
const { set_active_modal } = useModalStore.getState();

export const useExportStore = create(
  devtools((set, get) => ({
    export_all: false,
    materials: [],
    total_materials: 0,
    export_type: 'csv',
    palette_id: undefined,
    source: undefined,
    progress: 0,
    set_export_materials: materials => {
      set({ materials: materials });
    },
    set_export_type: type => {
      set({ export_type: type });
    },
    set_export_progress: progress => {
      set({ progress });
    },
    set_total_materials: total => {
      set({ total_materials: total });
    },
    set_export_all: export_all => {
      set({ export_all });
    },
    set_palette_id: palette_id => {
      set({ palette_id });
    },
    set_source: source => {
      set({ source });
    },
    export_materials: async ({
      export_type = 'csv',
      file_name = 'material_library_export',
      options = {},
    }) => {
      try {
        const { trigger_location, set_trigger_location } = useModalStore.getState();
        const { materials, source, palette_id, export_all, total_materials } = get();

        send_notification('loading', 'Exporting Materials...');

        track({
          eventName: 'export',
          exportCSV: export_type === 'csv' ? true : false,
          exportPhoto:
            options?.photo?.face || options?.photo?.back || options?.photo?.drape ? true : false,
          exportVideo: options?.video?.mp4 ? true : false,
          export3D:
            options?.three_d?.browzwear?.length ||
            options?.three_d?.clo?.length ||
            options?.three_d?.stl ||
            options?.three_d?.substance ||
            options?.three_d?.texture_maps
              ? true
              : false,
          totalMaterials: total_materials || 0,
          actionFrom: trigger_location,
          options,
        });

        set_trigger_location(undefined); // reset trigger location after tracking event is sent

        const filter_params = get_filter_params();
        // remove certain parameters
        delete filter_params['fields.includes'];
        delete filter_params['size'];
        delete filter_params['sortColumn'];
        delete filter_params['sortDirection'];

        const progress = 0;

        set({ progress });

        if (export_type === 'csv') {
          await export_csv({
            file_name,
            palette_id,
            source,
            materials: export_all ? [] : materials, // if export_all is true, then export all materials and not just the selected ones
            query: filter_params,
          });
        } else if (export_type === 'image-views') {
          await export_image_views({
            file_name,
            palette_id,
            source,
            materials: export_all ? [] : materials,
            query: filter_params,
            options,
          });
        }
        send_notification('success', 'Export was successful', () => {}, '', true, 5000, true);
      } catch (error) {
        send_notification(
          'error',
          'Export failed. Please try again.',
          () => {
            dismiss_notification();
            set_active_modal('export');
          },
          'Open Export',
          false
        );
      }
    },
  }))
);

/**
 * Exports user selected 3d files from materials
 * @param {Array.<Object>} materials - List of materials to export, if empty then we need to fetch all materials
 * @param {Object} query - Query parameters to filter materials, used if materials is empty
 * @param {Object} options - Selected 3d file options for export
 * @param {String} source - Source of materials, used if materials is empty ('PCX' || 'DPC')
 * @param {String} palette_id - Palette id of materials if any, used if materials list is empty
 */
async function export_image_views({ file_name, materials, query, options, source, palette_id }) {
  if (materials.length === 0) {
    if (palette_id && source === 'PCX') {
      materials = await fetch_all_palette_entries({ ...query, palette_id });
    } else if (palette_id && source === 'DPC') {
      materials = await fetch_all_board_entries({ ...query, palette_id });
    } else {
      materials = await fetch_all_materials({ ...query, source });
    }
  }

  const total_artifacts = [];

  const add_artifacts_to_accumulator = (accumulator, folder_path, artifacts) => {
    const new_artifacts = get(accumulator, folder_path.join('.'), []).concat(...artifacts);
    setWith(accumulator, folder_path.join('.'), new_artifacts, Object);
  };

  const add_texture_maps_to_accumulator = (accumulator, artifact, material) => {
    const original_file_path = artifact?.metadata?.originalFilePath || 'Texture Maps';
    let folder_array = ['3D', 'Texture Maps', material?.id, ...original_file_path.split(/\//g)];
    folder_array = folder_array.slice(0, folder_array.length - 1);
    const existingValue = get(accumulator, folder_array.join('.'), []);
    setWith(accumulator, folder_array.join('.'), [...existingValue, artifact], Object);
  };

  const files_to_export = materials.reduce((files, material) => {
    const { photo, video, three_d } = options;

    // photo artifacts
    if (photo?.face) {
      const face_artifacts = get_face_artifacts(material);
      if (face_artifacts?.length > 0) {
        total_artifacts.push(...face_artifacts);
        add_artifacts_to_accumulator(files, ['Photos'], face_artifacts);
      }
    }

    if (photo?.back) {
      const back_artifacts = get_back_artifacts(material);
      if (back_artifacts?.length > 0) {
        total_artifacts.push(...back_artifacts);
        add_artifacts_to_accumulator(files, ['Photos'], back_artifacts);
      }
    }

    if (photo?.drape) {
      const drape_artifacts = get_drape_artifacts(material);
      if (drape_artifacts?.length > 0) {
        total_artifacts.push(...drape_artifacts);
        add_artifacts_to_accumulator(files, ['Photos'], drape_artifacts);
      }
    }

    // video artifacts

    if (video?.mp4) {
      const mp4_artifacts = get_mp4_artifacts(material);
      if (mp4_artifacts?.length > 0) {
        total_artifacts.push(...mp4_artifacts);
        add_artifacts_to_accumulator(files, ['Videos'], mp4_artifacts);
      }
    }

    // 3D artifacts
    // get substance files from the material if user selected the option
    if (three_d?.substance) {
      const substance_artifacts = get_substance_artifacts(material);
      if (substance_artifacts?.length > 0) {
        total_artifacts.push(...substance_artifacts);
        add_artifacts_to_accumulator(files, ['3D', 'Substance'], substance_artifacts);
      }
    }

    // get texture map files from material if user selected the option
    if (three_d?.texture_maps) {
      const texture_map_artifacts = get_texture_map_artifacts(material);
      if (texture_map_artifacts?.length > 0) {
        total_artifacts.push(...texture_map_artifacts);
        texture_map_artifacts.forEach(artifact =>
          add_texture_maps_to_accumulator(files, artifact, material)
        );
      }
    }

    // get clo files from material if user selected the option
    if (three_d?.clo?.length > 0) {
      const clo_artifacts = get_clo_artifacts(material, three_d?.clo);
      if (clo_artifacts?.length > 0) {
        total_artifacts.push(...clo_artifacts);
        add_artifacts_to_accumulator(files, ['3D', 'CLO'], clo_artifacts);
      }
    }

    // get browzwear files from material if user selected the option
    if (three_d?.browzwear?.length > 0) {
      const browzwear_artifacts = get_browzwear_artifacts(material, three_d?.browzwear);
      if (browzwear_artifacts?.length > 0) {
        total_artifacts.push(...browzwear_artifacts);
        add_artifacts_to_accumulator(files, ['3D', 'Browzwear'], browzwear_artifacts);
      }
    }

    // get stl files from material if user selected the option
    if (three_d.stl) {
      const stl_artifacts = get_stl_artifacts(material);
      if (stl_artifacts?.length > 0) {
        total_artifacts.push(...stl_artifacts);
        add_artifacts_to_accumulator(files, ['3D', 'STL'], stl_artifacts);
      }
    }

    return files;
  }, {});

  // if there is only one artifact dont create a zip folder and just download it
  if (total_artifacts?.length === 1) {
    const result = await download_artifact(total_artifacts[0].artifactId, total_artifacts[0].name);
    return result;
  } else if (total_artifacts?.length > 1) {
    const zip_payload = get_zip_payload(files_to_export, file_name);
    const zip = await get_zip(zip_payload);
    const zip_location = await poll_for_zip(zip.zipId);
    await download_zip(zip_location, file_name);
    return zip;
  } else {
    return { status: 400 };
  }
}

const poll_for_zip = async (zip_id, attempt = 0) => {
  const zipStatus = await get_download_status(zip_id);
  if (!zipStatus.location && zipStatus.status === 'RUNNING') {
    return new Promise(resolve =>
      setTimeout(() => resolve(poll_for_zip(zip_id, attempt + 1)), 1000)
    );
  }
  return zipStatus?.location;
};

async function export_csv({ file_name, palette_id, source, materials, query }) {
  const url = new URL(config.apis.export.csv_generate, config.api_base).toString();
  const field_mapping = {};
  const active_engine = use_engine_store.getState().active_engine;

  if (active_engine.toUpperCase() === 'FOOTWEAR') {
    field_mapping['PCX #'] = 'id';
    field_mapping['PDM #'] = 'material.legacyMaterialId';
    field_mapping['Material Name'] = 'name';
    field_mapping['Material Type'] = 'materialType4';
    field_mapping['Material Sub-Type'] = 'materialType5';
    field_mapping['Material Status'] = 'material.matItemStatusIndicator';
    field_mapping['Initial Season'] = 'material.matInitCycleYear';
    field_mapping['Has 2D Image'] = 'hasMaterial2dAsset';
    field_mapping['Has 3D Asset'] = 'hasMaterial3dAsset';
    field_mapping['Has Video'] = 'hasMaterialVideoAsset';
    field_mapping['Has Asset'] = 'hasAtLeastOneActiveAsset';
    field_mapping['Min Price'] = 'material.minPrice';
    field_mapping['Max Price'] = 'material.maxPrice';
    field_mapping['Price Units'] = 'material.priceUnits';
    field_mapping['End Use'] = 'material.suppliedMaterials[0].endUse';
    field_mapping['Material Benefits'] = 'material.suppliedMaterials[0].materialBenefits';
    field_mapping['PCX SM # (1)'] = 'material.suppliedMaterials[0].id';
    field_mapping['Supplier Name (1)'] = 'material.suppliedMaterials[0].supplier';
    field_mapping['MCS (1)'] = 'material.suppliedMaterials[0].mcsNumber';
    field_mapping['Supplied Material State (1)'] = 'material.suppliedMaterials[0].state';
    field_mapping['PCX SM # (2)'] = 'material.suppliedMaterials[1].id';
    field_mapping['Supplier Name (2)'] = 'material.suppliedMaterials[1].supplier';
    field_mapping['MCS (2)'] = 'material.suppliedMaterials[1].mcsNumber';
    field_mapping['Supplied Material State (2)'] = 'material.suppliedMaterials[1].state';
    field_mapping['PCX SM # (3)'] = 'material.suppliedMaterials[2].id';
    field_mapping['Supplier Name (3)'] = 'material.suppliedMaterials[2].supplier';
    field_mapping['MCS (3)'] = 'material.suppliedMaterials[2].mcsNumber';
    field_mapping['Supplied Material State (3)'] = 'material.suppliedMaterials[2].state';
    field_mapping['Material Description'] = 'material.description';
  } else if (active_engine.toUpperCase() === 'APPAREL') {
    field_mapping['PCX #'] = 'id';
    field_mapping['Material Name'] = 'name';
    field_mapping['Material Type'] = 'materialType4';
    field_mapping['Material Sub-Type'] = 'materialType5';
    field_mapping['Material Status'] = 'material.matItemStatusIndicator';
    field_mapping['Target Season'] = 'material.targetCycleYear';
    field_mapping['Has 2D Image'] = 'hasMaterial2dAsset';
    field_mapping['Has 3D Asset'] = 'hasMaterial3dAsset';
    field_mapping['Has Video'] = 'hasMaterialVideoAsset';
    field_mapping['Has Asset'] = 'hasAtLeastOneActiveAsset';
    field_mapping['Min Price'] = 'material.minPrice';
    field_mapping['Max Price'] = 'material.maxPrice';
    field_mapping['Price Units'] = 'material.priceUnits';
    field_mapping['End Use'] = 'material.suppliedMaterials[0].endUse';
    field_mapping['Material Benefits'] = 'material.suppliedMaterials[0].materialBenefits';
    field_mapping['PCX SM # (1)'] = 'material.suppliedMaterials[0].id';
    field_mapping['PPS # (1)'] = 'material.suppliedMaterials[0].ppsItemNumber';
    field_mapping['Supplier Name (1)'] = 'material.suppliedMaterials[0].supplier';
    field_mapping['Supplied Material State (1)'] = 'material.suppliedMaterials[0].state';
    field_mapping['PCX SM # (2)'] = 'material.suppliedMaterials[1].id';
    field_mapping['PPS # (2)'] = 'material.suppliedMaterials[1].ppsItemNumber';
    field_mapping['Supplier Name (2)'] = 'material.suppliedMaterials[1].supplier';
    field_mapping['Supplied Material State (2)'] = 'material.suppliedMaterials[1].state';
    field_mapping['PCX SM # (3)'] = 'material.suppliedMaterials[2].id';
    field_mapping['PPS # (3)'] = 'material.suppliedMaterials[2].ppsItemNumber';
    field_mapping['Supplier Name (3)'] = 'material.suppliedMaterials[2].supplier';
    field_mapping['Supplied Material State (3)'] = 'material.suppliedMaterials[2].state';
    field_mapping['Material Description'] = 'material.description';
  } else if (active_engine.toUpperCase() === 'ACCESSORIES') {
    field_mapping['PCX #'] = 'id';
    field_mapping['Flex #'] = 'material.legacyMaterialNumberEq';
    field_mapping['Material Name'] = 'name';
    field_mapping['Material Type'] = 'materialType4';
    field_mapping['Material Sub-Type'] = 'materialType5';
    field_mapping['Material Status'] = 'material.matItemStatusIndicator';
    field_mapping['Target Season'] = 'material.targetCycleYear';
    field_mapping['Has 2D Image'] = 'hasMaterial2dAsset';
    field_mapping['Has 3D Asset'] = 'hasMaterial3dAsset';
    field_mapping['Has Video'] = 'hasMaterialVideoAsset';
    field_mapping['Has Asset'] = 'hasAtLeastOneActiveAsset';
    field_mapping['Min Price'] = 'material.minPrice';
    field_mapping['Max Price'] = 'material.maxPrice';
    field_mapping['Price Units'] = 'material.priceUnits';
    field_mapping['End Use'] = 'material.suppliedMaterials[0].endUse';
    field_mapping['Material Benefits'] = 'material.suppliedMaterials[0].materialBenefits';
    field_mapping['PCX SM # (1)'] = 'material.suppliedMaterials[0].id';
    field_mapping['Supplier Name (1)'] = 'material.suppliedMaterials[0].supplier';
    field_mapping['Supplied Material State (1)'] = 'material.suppliedMaterials[0].state';
    field_mapping['Material Description'] = 'material.description';
  }
  const data = {
    fieldMapping: field_mapping,
  };

  // all materials from a palette/board
  if (palette_id) {
    data.searchFunction = 'paletteentries_v2';
    data.params = { paletteId: palette_id };
    data.query = {
      ...query,
      paletteClass: 'material',
      appView: active_engine.toLowerCase(),
      source: source,
    };
    // selection of materials like from tote, compare or just selected materials
  } else if (materials.length > 0 && !palette_id) {
    data.searchFunction = 'allmaterials';
    data.query = {
      ids: [...materials.map(material => material.id), '00000'], // we add a dummy id to the end of the array so that the query string is valid
    };
    // all materials based on filters applied
  } else {
    data.searchFunction = 'allmaterials';
    data.query = {
      ...query,
      materialType3: capitalize(active_engine),
    };
  }

  // dismiss export modal
  useModalStore.getState().dismiss_modal();
  const result = await http_client({
    url,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/csv',
    },
    data,
  });

  // download CSV
  download_file(result.data, 'application/csv', file_name + '.csv');

  return result;
}

export function download_file(data, type, name) {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  console.log({ data, type, name });

  document.body.appendChild(a);
  a.style = 'display: none';
  a.target = '_self';
  a.href = url;
  a.download = name;
  a.id = 'download_link';

  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

const get_face_artifacts = material => {
  if (material.assets?.length > 0) {
    const face_asset = material.assets.find(asset => asset?.assetMetadata?.viewType === 'Face');

    if (face_asset?.artifactReferences?.length > 0) {
      face_asset.artifactReferences = face_asset.artifactReferences.filter(
        item => item.metadata.viewType === 'Original' && item.fileType === 'PNG'
      );
    }

    return face_asset?.artifactReferences || [];
  }
};

const get_back_artifacts = material => {
  if (material.assets?.length > 0) {
    const back_asset = material.assets.find(asset => asset?.assetMetadata?.viewType === 'Back');

    if (back_asset?.artifactReferences?.length > 0) {
      back_asset.artifactReferences = back_asset.artifactReferences.filter(
        item => item.metadata.viewType === 'Original' && item.fileType === 'PNG'
      );
    }
    return back_asset?.artifactReferences || [];
  }
};

const get_drape_artifacts = material => {
  if (material.assets?.length > 0) {
    const drape_asset = material.assets.find(
      asset =>
        asset?.assetMetadata?.viewType === 'Drape' ||
        asset?.assetMetadata?.viewType === 'Fold/Drape'
    );

    if (drape_asset?.artifactReferences?.length > 0) {
      drape_asset.artifactReferences = drape_asset.artifactReferences.filter(
        item => item.metadata.viewType === 'Original' && item.fileType === 'PNG'
      );
    }
    return drape_asset?.artifactReferences || [];
  }
};

const get_mp4_artifacts = material => {
  if (material.assets?.length > 0) {
    const mp4_assets = material.assets.find(asset => asset?.assetMetadata?.viewType === 'Video');

    if (mp4_assets?.artifactReferences?.length > 0) {
      mp4_assets.artifactReferences = mp4_assets.artifactReferences.filter(
        item => item.metadata.viewType === 'Original' && item.fileType === 'MP4'
      );
    }
    return mp4_assets?.artifactReferences || [];
  }
};

const get_substance_artifacts = material => {
  if (material.assets?.length > 0) {
    // first filter material assets for any substance asset
    // assetClass === 'material3d' && subClass === 'substance' && (lifecycleState === 'READY' || lifecycleState === 'PUBLISHED')
    const substance_assets =
      material.assets.filter(asset => {
        const assetClass = asset?.assetClass;
        const subClass = asset?.assetMetadata?.subClass;
        const lifecycleState = asset?.lifecycle?.lifecycleState;
        return (
          assetClass === 'material3d' &&
          subClass === 'substance' &&
          asset?.active &&
          (lifecycleState === 'READY' || lifecycleState === 'PUBLISHED') &&
          asset?.artifactReferences?.length > 0
        );
      }) || [];

    // loop over any substance assets we got to find artifacts that we can download
    // for each asset ideally find an artifact with schema === 'standard' and prefered_client_artifact === true
    // if that doesnt exist return any artifact with fileType === 'SBSAR'
    const substance_artifacts = substance_assets
      .map(asset => {
        const ideal_sbsar_artifacts = asset?.artifactReferences?.filter(
          artifact =>
            artifact?.fileType === 'SBSAR' &&
            artifact?.metadata?.schema === 'standard' &&
            artifact?.metadata?.prefered_client_artifact === true
        );
        if (ideal_sbsar_artifacts?.length === 0) {
          const any_sbsar_artifact = asset?.artifactReferences?.filter(
            artifact => artifact?.fileType === 'SBSAR'
          );
          return any_sbsar_artifact;
        } else {
          return ideal_sbsar_artifacts;
        }
      })
      .filter(artifact => artifact);

    const substancevizoo_assets =
      material.assets.filter(asset => {
        return (
          asset?.assetClass === 'material3d' &&
          asset?.assetMetadata?.subClass === 'substancevizoo' &&
          asset?.active &&
          asset?.lifecycle?.lifecycleState === 'PUBLISHED'
        );
      }) || [];

    const substancevizoo_artifacts = substancevizoo_assets
      .map(asset => {
        const artifacts = asset?.artifactReferences?.filter(artifact => {
          return artifact?.fileType === 'SBSAR' && artifact?.metadata?.schema === 'standard';
        });
        return artifacts;
      })
      .flat()
      .filter(artifact => artifact);

    return [...substance_artifacts, ...substancevizoo_artifacts];
  }
};

const get_texture_map_artifacts = material => {
  if (material?.assets?.length > 0) {
    const texture_map_assets =
      material.assets.filter(asset => {
        return (
          asset?.assetClass === 'material3d' &&
          asset?.assetMetadata?.subClass === 'pbr' &&
          asset?.active &&
          (asset?.lifecycle?.lifecycleState === 'PUBLISHED' ||
            asset?.lifecycle?.lifecycleState === 'READY') &&
          asset?.artifactReferences?.length > 0
        );
      }) || [];

    const texture_map_artifacts = texture_map_assets
      .map(asset => {
        return asset?.artifactReferences?.filter(artifact => {
          return (
            artifact?.metadata?.viewType !== 'Thumbnail' &&
            !(artifact?.metadata?.viewType || '').toLowerCase().includes('tiling')
          );
        });
      })
      .flat()
      .filter(artifact => artifact);

    return texture_map_artifacts;
  }
};

const get_clo_artifacts = (material, clo_options) => {
  if (material?.assets?.length > 0) {
    const clo_assets = material?.assets.filter(asset => {
      return (
        (asset?.assetClass === 'material3d' &&
          asset?.assetMetadata?.subClass === 'apparelzfab' &&
          asset?.active &&
          (asset?.lifecycle?.lifecycleState === 'READY' ||
            asset?.lifecycle?.lifecycleState === 'PUBLISHED') &&
          asset?.artifactReferences?.length > 0) ||
        (asset?.assetClass === 'trim3d' &&
          asset?.assetMetadata?.subClass === 'clo' &&
          asset?.active &&
          asset?.artifactReferences?.length > 0)
      );
    });

    const clo_artifacts = clo_assets
      .map(asset => {
        const artifacts = asset?.artifactReferences?.filter(artifact => {
          return clo_options.includes(artifact.fileType.toLowerCase());
        });
        return artifacts;
      })
      .flat()
      .filter(artifact => artifact);

    return clo_artifacts;
  }
};

const get_browzwear_artifacts = (material, browzwear_options) => {
  if (material?.assets?.length > 0) {
    let apparel_u3m_artifacts = [];
    if (browzwear_options.includes('u3m')) {
      const apparel_u3m_assets = material?.assets.filter(asset => {
        return (
          asset?.assetClass === 'material3d' &&
          asset?.assetMetadata?.subClass === 'apparelu3m' &&
          asset?.active &&
          asset?.artifactReferences?.length > 0
        );
      });

      apparel_u3m_artifacts = apparel_u3m_assets
        .map(asset => {
          const artifacts = asset?.artifactReferences?.filter(artifact => {
            return artifact.fileType === 'U3MA';
          });
          return artifacts;
        })
        .flat();
    }

    const trim_3d_clo_assets = material?.assets.filter(asset => {
      return (
        asset?.assetClass === 'trim3d' &&
        asset?.assetMetadata?.subClass === 'clo' &&
        asset?.active &&
        asset?.artifactReferences?.length > 0
      );
    });

    const trim_3d_clo_artifacts = trim_3d_clo_assets
      .map(asset => {
        const artifacts = asset?.artifactReferences?.filter(artifact => {
          return browzwear_options.includes(artifact.fileType.toLowerCase());
        });
        return artifacts;
      })
      .flat();

    return [...apparel_u3m_artifacts, ...trim_3d_clo_artifacts];
  }
};

const get_stl_artifacts = material => {
  if (material?.assets?.length > 0) {
    const stl_assets = material?.assets.filter(asset => {
      return (
        asset?.assetClass === 'airbag3d' &&
        asset?.assetMetadata?.subClass === 'airmi' &&
        asset?.active &&
        asset?.artifactReferences?.length > 0
      );
    });

    const stl_artifacts = stl_assets
      .map(asset => {
        const artifacts = asset?.artifactReferences?.filter(artifact => {
          return artifact.fileType === 'STL';
        });
        return artifacts;
      })
      .flat();

    return stl_artifacts;
  }
};

// recursively get zip_payload from files_to_export
const get_zip_payload = (files_to_export, folder_name) => {
  if (Array.isArray(files_to_export)) {
    return {
      name: folder_name,
      items: files_to_export.map(artifact => ({
        name: artifact?.name,
        artifactId: artifact?.artifactId,
      })),
    };
  } else {
    return {
      name: folder_name,
      items: [
        ...Object.keys(files_to_export).map(key => {
          const folder_name = key;
          const files = files_to_export[key];
          return get_zip_payload(files, folder_name);
        }),
      ],
    };
  }
};
