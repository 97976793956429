import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Toggle, Select, Checkbox, CheckboxGroup } from '@nike/eds';
import { Collapse } from '../components/common/collapse';
import { MinMaxRangeInput } from '../components/common/min_max_range_input';
import {
  get_filter_chips,
  get_filters_from_search_params,
  set_facet_params,
  clear_all_filters,
} from '../utils/filters';
import cloneDeep from 'lodash.clonedeep';

// the metadata object will hold everything that has to deal w/ altering the basic behaviour of the template

export const searchOptions = [
  { value: 'query', label: 'Freeform' },
  { value: 'materialId', label: 'PCX ID' },
  { value: 'legacyMaterialId', label: 'PDM ID' },
  { value: 'ppsItemNumber', label: 'PPS Item #' },
  { value: 'legacyMaterialNumberEq', label: 'Flex #' },
];

export const useLibraryFilterStore = create(
  devtools(
    (set, get) => ({
      // search query can either be a string or list of strings
      search_query: '',
      selected_search_option: 'query',
      setSelectedSearchOption: option => set({ selected_search_option: option }),
      list: {
        material_status: {
          id: 'material_status',
          order: 0,
          list_type: 'general',
          display_name: 'Material Status',
          facet_property_name: 'matItemStatusIndicator',
          facet_type: 'checkbox',
          template: props => (
            <Collapse enable={props?.value}>
              <CheckboxGroup onChange={props?.onChange}>
                <Checkbox
                  {...props}
                  checked={props?.value === true}
                  disabled={props?.value === false}
                  value="true"
                  label="Active"
                />
                <Checkbox
                  {...props}
                  checked={props?.value === false}
                  disabled={props?.value === true}
                  value="false"
                  label="Inactive"
                />
              </CheckboxGroup>
            </Collapse>
          ),
          metadata: {
            default: true,
            label_overrides: {
              true: 'Active',
              false: 'Inactive',
            },
            data_massager: (value, update = get().update_filter_value) => {
              const val = JSON.parse(value?.target?.value);
              const checked = value?.target?.checked;

              if ((val === true || val === false) && checked) {
                update('material_status', val);
              } else {
                update('material_status', undefined);
              }
            },
            props: {
              value: true,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_2d_asset: {
          id: 'has_2d_asset',
          order: 1,
          list_type: 'general',
          display_name: 'Has 2D Asset(s)',
          facet_property_name: 'hasMaterial2dAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => {
              const checked_value = value?.target?.checked ? true : undefined;

              update('has_2d_asset', checked_value);
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_3d_asset: {
          id: 'has_3d_asset',
          order: 2,
          list_type: 'general',
          display_name: 'Has 3D Asset(s)',
          facet_property_name: 'hasMaterial3dAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => {
              const checked_value = value?.target?.checked ? true : undefined;

              update('has_3d_asset', checked_value);
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_video_asset: {
          id: 'has_video_asset',
          order: 3,
          list_type: 'general',
          display_name: 'Has Video Asset',
          facet_property_name: 'hasMaterialVideoAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => {
              const checked_value = value?.target?.checked ? true : undefined;

              update('has_video_asset', checked_value);
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        only_principal_materials: {
          id: 'only_principal_materials',
          order: 4,
          list_type: 'general',
          display_name: 'Only Principal Materials',
          facet_property_name: 'principalMaterial',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => {
              const checked_value = value?.target?.checked ? true : undefined;

              update('only_principal_materials', checked_value);
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        type: {
          id: 'type',
          order: 5,
          list_type: 'general',
          display_name: 'Type',
          facet_property_name: 'materialType4',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => update('type', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        sub_type: {
          id: 'sub_type',
          order: 6,
          list_type: 'general',
          display_name: 'Sub-Type',
          facet_property_name: 'materialType5',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => update('sub_type', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        supplier: {
          id: 'supplier',
          order: 7,
          list_type: 'general',
          display_name: 'Supplier',
          facet_property_name: 'supplier',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => update('supplier', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        visual_effect: {
          id: 'visual_effect',
          order: 8,
          list_type: 'general',
          display_name: 'Visual Effect',
          facet_property_name: 'visualEffect',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('visual_effect', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        supplied_material_state: {
          id: 'supplied_material_state',
          order: 9,
          list_type: 'general',
          display_name: 'Supplied Material State',
          facet_property_name: 'suppliedMaterialState',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('supplied_material_state', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        weight: {
          id: 'weight',
          order: 10,
          list_type: 'general',
          display_name: 'Weight',
          facet_property_name: 'weight',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minWeight',
          maxProperty: 'maxWeight',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min} g`;
              } else if (!min && max) {
                return `< ${max} g`;
              } else {
                return `${min} - ${max} g`;
              }
            },
            data_massager: (value, update = get().update_filter_value) => update('weight', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: 'g',
              message: 'Grams measured per meter squared.',
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        price: {
          id: 'price',
          order: 11,
          list_type: 'general',
          display_name: 'Price',
          facet_property_name: 'price',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minPrice',
          maxProperty: 'maxPrice',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> $${min}`;
              } else if (!min && max) {
                return `< $${max}`;
              } else {
                return `$${min} - $${max}`;
              }
            },
            data_massager: (value, update = get().update_filter_value) => update('price', value),
            props: {
              preLabel: '$',
              postLabel: '',
              value: undefined,
              onChange: value => get().updateFilterValue('price', value),
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        content: {
          id: 'content',
          order: 12,
          list_type: 'general',
          display_name: 'Content',
          facet_property_name: 'matContentTypeSource',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                onChange={props?.onChange}
                value={props?.value}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => update('content', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        release_papers: {
          id: 'release_papers',
          order: 1,
          list_type: 'advanced',
          display_name: 'Release Papers',
          facet_property_name: 'materialType3',
          facet_type: 'checkbox_group',
          template: props => (
            <Collapse enable={props?.value.length > 0}>
              <CheckboxGroup onChange={props?.onChange}>
                <Checkbox
                  {...props}
                  checked={props?.value?.includes('Release Paper')}
                  value="Release Paper"
                  label="Active"
                />
              </CheckboxGroup>
            </Collapse>
          ),
          metadata: {
            label_overrides: {
              'Release Paper': 'Active',
            },
            data_massager: (value, update = get().update_filter_value) => {
              const checked = JSON.parse(value?.target?.checked);

              if (checked) {
                update('release_papers', [value?.target?.value]);
              } else {
                update('release_papers', []);
              }
            },
            props: {
              value: [],
            },
          },
          engines: ['footwear'],
        },
        initial_season: {
          id: 'initial_season',
          order: 2,
          list_type: 'advanced',
          display_name: 'Initial Season',
          facet_property_name: 'initialSeason',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('initial_season', value),
            props: {
              value: [],
            },
          },
          engines: ['footwear'],
        },
        target_season: {
          id: 'target_season',
          order: 3,
          list_type: 'advanced',
          display_name: 'Target Season',
          facet_property_name: 'targetCycleYear',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('target_season', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories'],
        },
        construction_type: {
          id: 'construction_type',
          order: 4,
          list_type: 'advanced',
          display_name: 'Construction Type',
          facet_property_name: 'constructionType',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('construction_type', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        textile_variation: {
          id: 'textile_variation',
          order: 5,
          list_type: 'advanced',
          display_name: 'Textile Variation',
          facet_property_name: 'textileVariation',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('textile_variation', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        thickness: {
          id: 'thickness',
          order: 6,
          list_type: 'advanced',
          display_name: 'Thickness (mm)',
          facet_property_name: 'thickness',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minThickness',
          maxProperty: 'maxThickness',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min} mm`;
              } else if (!min && max) {
                return `< ${max} mm`;
              } else {
                return `${min} - ${max} mm`;
              }
            },
            data_massager: (value, update = get().update_filter_value) =>
              update('thickness', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: 'mm',
              message: '',
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        material_development_team: {
          id: 'material_development_team',
          order: 7,
          list_type: 'advanced',
          display_name: 'Material Development Team',
          facet_property_name: 'suppliedMaterialDevelopmentTeam',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('material_development_team', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories'],
        },
        finish_process: {
          id: 'finish_process',
          order: 8,
          list_type: 'advanced',
          display_name: 'Finish Process',
          facet_property_name: 'finishProcess',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('finish_process', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        lead_time: {
          id: 'lead_time',
          order: 9,
          list_type: 'advanced',
          display_name: 'Production Lead Time',
          facet_property_name: 'leadTimeProduction',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minLeadTimeProduction',
          maxProperty: 'maxLeadTimeProduction',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min} days`;
              } else if (!min && max) {
                return `< ${max} days`;
              } else {
                return `${min} - ${max} days`;
              }
            },
            data_massager: (value, update = get().update_filter_value) =>
              update('lead_time', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: 'days',
              message: '',
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        min_production_order_quantity: {
          id: 'min_production_order_quantity',
          order: 10,
          list_type: 'advanced',
          display_name: 'Minimum Production Order Quantity',
          facet_property_name: 'minimumOrderQuantityProduction',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minMinimumOrderQuantityProduction',
          maxProperty: 'maxMinimumOrderQuantityProduction',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min}`;
              } else if (!min && max) {
                return `< ${max}`;
              } else {
                return `${min} - ${max}`;
              }
            },
            data_massager: (value, update = get().update_filter_value) =>
              update('min_production_order_quantity', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: '',
              message: '',
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        country_of_production: {
          id: 'country_of_production',
          order: 11,
          list_type: 'advanced',
          display_name: 'Country of Production',
          facet_property_name: 'location',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('country_of_production', value),
            props: {
              value: [],
            },
          },
          engines: ['footwear'],
        },
        manufacturing_country_of_origin: {
          id: 'manufacturing_country_of_origin',
          order: 12,
          list_type: 'advanced',
          display_name: 'Manufacturing Country of Origin',
          facet_property_name: 'manufacturingCountryofOrigin',
          facet_type: 'select',
          template: props => (
            <Collapse enable={props?.value === !undefined || props?.value.length > 0}>
              <Select
                {...props}
                isMulti
                isClearable
                closeMenuOnSelect={false}
                value={props?.value}
                onChange={props?.onChange}
              />
            </Collapse>
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('manufacturing_country_of_origin', value),
            props: {
              value: [],
            },
          },
          engines: ['apparel', 'accessories'],
        },
        date_added_to_pcx: {
          id: 'date_added_to_pcx',
          order: 13,
          list_type: 'advanced',
          display_name: 'Date Added to PCX',
          facet_property_name: 'createTimestamp',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minCreateTimestamp',
          maxProperty: 'maxCreateTimestamp',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min}`;
              } else if (!min && max) {
                return `< ${max}`;
              } else {
                return `${min} - ${max}`;
              }
            },
            data_massager: (value, update = get().update_filter_value) =>
              update('date_added_to_pcx', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: '',
              message: '',
              placeholder: 'date',
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        date_added_to_studio: {
          id: 'date_added_to_studio',
          order: 14,
          list_type: 'advanced',
          display_name: 'Date Added to Studio',
          facet_property_name: 'physicalSampleDateAdded',
          facet_type: 'min_max',
          template: props => (
            <Collapse enable={props?.value?.minValue || props?.value?.maxValue}>
              <MinMaxRangeInput {...props} value={props?.value} onChange={props?.onChange} />
            </Collapse>
          ),
          minProperty: 'minPhysicalSampleDateAdded',
          maxProperty: 'maxPhysicalSampleDateAdded',
          metadata: {
            // min is greater than and max is less than
            min_max_label_override: (min, max) => {
              if (min && !max) {
                return `> ${min}`;
              } else if (!min && max) {
                return `< ${max}`;
              } else {
                return `${min} - ${max}`;
              }
            },
            data_massager: (value, update = get().update_filter_value) =>
              update('date_added_to_studio', value),
            props: {
              value: {
                minValue: '',
                maxValue: '',
              },
              preLabel: '',
              postLabel: '',
              message: '',
              placeholder: 'date',
            },
          },
          engines: ['apparel', 'accessories'],
        },
        physical_sample_available_in_studio: {
          id: 'physical_sample_available_in_studio',
          order: 15,
          list_type: 'advanced',
          display_name: 'Physical Sample Available in Studio',
          facet_property_name: 'physicalSampleAvailableIndicator',
          facet_type: 'checkbox',
          template: props => (
            <Collapse enable={props?.value}>
              <CheckboxGroup onChange={props?.onChange}>
                <Checkbox
                  {...props}
                  checked={props?.value === true}
                  disabled={props?.value === false}
                  value="true"
                  label="Yes"
                />
                <Checkbox
                  {...props}
                  checked={props?.value === false}
                  disabled={props?.value === true}
                  value="false"
                  label="No"
                />
              </CheckboxGroup>
            </Collapse>
          ),
          metadata: {
            label_overrides: {
              true: 'Yes',
              false: 'No',
            },
            data_massager: (value, update = get().update_filter_value) => {
              const val = JSON.parse(value?.target?.value);
              const checked = value?.target?.checked;

              if ((val === true || val === false) && checked) {
                update('physical_sample_available_in_studio', val);
              } else {
                update('physical_sample_available_in_studio', undefined);
              }
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories'],
        },
        has_no_2d_asset: {
          id: 'has_no_2d_asset',
          order: 16,
          list_type: 'advanced',
          display_name: 'No 2D Asset(s)',
          facet_property_name: 'hasMaterial2dAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value === false} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('has_no_2d_asset', value?.target?.checked === true ? false : undefined),
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_no_3d_asset: {
          id: 'has_no_3d_asset',
          order: 17,
          list_type: 'advanced',
          display_name: 'No 3D Asset(s)',
          facet_property_name: 'hasMaterial3dAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value === false} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('has_no_3d_asset', value?.target?.checked === true ? false : undefined),
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_no_video_asset: {
          id: 'has_no_video_asset',
          order: 18,
          list_type: 'advanced',
          display_name: 'No Video Asset',
          facet_property_name: 'hasMaterialVideoAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value === false} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('has_no_video_asset', value?.target?.checked === true ? false : undefined),
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_no_asset: {
          id: 'has_no_asset',
          order: 19,
          list_type: 'advanced',
          display_name: 'No Asset(s)',
          facet_property_name: 'hasAtLeastOneAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value === false} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('has_no_asset', value?.target?.checked ? false : undefined),
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_asset: {
          id: 'has_asset',
          order: 20,
          list_type: 'advanced',
          display_name: 'Has Asset',
          facet_property_name: 'hasAtLeastOneAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) => {
              const checked_value = value?.target?.checked ? true : undefined;

              update('has_asset', checked_value);
            },
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
        has_all_asset: {
          id: 'has_all_asset',
          order: 21,
          list_type: 'advanced',
          display_name: 'Has All Assets',
          facet_property_name: 'missingAtLeastOneAsset',
          facet_type: 'toggle',
          template: props => (
            <Toggle {...props} checked={props?.value === false} onChange={props?.onChange} />
          ),
          metadata: {
            data_massager: (value, update = get().update_filter_value) =>
              update('has_all_asset', value?.target?.checked ? false : undefined),
            props: {
              value: undefined,
            },
          },
          engines: ['apparel', 'accessories', 'footwear'],
        },
      },
      update_list: list => {
        set(state => {
          return {
            ...state,
            list,
          };
        });
      },
      update_search_query: search_query => {
        set(state => {
          return {
            ...state,
            search_query,
          };
        });
      },
      update_state: state => {
        set(() => {
          return state;
        });
      },
      update: (key, data) => {
        set(state => ({
          ...state,
          list: {
            ...state.list,
            [key]: {
              ...data,
            },
          },
        }));
      },
      update_filter_value: (filter_key, value) => {
        set(state => {
          return {
            ...state,
            list: {
              ...state.list,
              [filter_key]: {
                ...state.list[filter_key],
                metadata: {
                  ...state.list[filter_key].metadata,
                  props: {
                    ...state.list[filter_key].metadata.props,
                    value,
                  },
                },
              },
            },
          };
        });
      },
      clear_filters: () => {
        const list = cloneDeep(get().list);
        clear_all_filters(list);
        get().update_list(list);
      },
      get_filter_params: () => {
        const list = get().list;
        const selected_search_option = get().selected_search_option;

        const search_query = get().search_query;

        let params = {
          sortColumn: 'material.createTimestamp',
          sortDirection: 'desc',
          size: 36,
        };

        if (search_query) {
          if (selected_search_option === 'query') {
            params['query'] = search_query;
          } else {
            params['query'] = search_query.split(/\s*,\s*|\s+/).join(',');
            params['searchField'] = selected_search_option;
          }
        }

        set_facet_params(list, params);

        return params;
      },
      load_chips: () => {
        const list = get().list;
        const update_method = get().update_filter_value;
        const chips = get_filter_chips(list, update_method);

        return chips;
      },
      load_filters_from_search_params: search_params => {
        // TODO: this code is a little hairy. Lets try and circle back later to see
        // if we can clean it up at all
        const list = get().list;
        const get_filter_id_helper = get().get_filter_id;
        const update_state = get().update_state;

        get_filters_from_search_params(search_params, list, get_filter_id_helper, update_state);
      },
      get_filter_id(key, value) {
        if (key.includes('min') || key.includes('max')) {
          const filter_id = Object.values(get().list).find(
            filter => filter?.minProperty === key || filter?.maxProperty === key
          )?.id;

          return filter_id;
        }

        switch (key) {
          case 'query':
            return 'search_query';
          case 'hasMaterial2dAsset':
            if (JSON.parse(value)) {
              return 'has_2d_asset';
            } else {
              return 'has_no_2d_asset';
            }
          case 'hasMaterial3dAsset':
            if (JSON.parse(value)) {
              return 'has_3d_asset';
            } else {
              return 'has_no_3d_asset';
            }
          case 'hasMaterialVideoAsset':
            if (JSON.parse(value)) {
              return 'has_video_asset';
            } else {
              return 'has_no_video_asset';
            }
          case 'hasAtLeastOneAsset':
            if (JSON.parse(value)) {
              return 'has_asset';
            } else {
              return 'has_no_asset';
            }
          case 'materialId':
            return 'materialId';

          case 'legacyMaterialId':
            return 'legacyMaterialId';
          case 'ppsItemNumber':
            return 'ppsItemNumber';
          case 'legacyMaterialNumberEq':
            return 'legacyMaterialNumberEq';
          default:
            const id = Object.values(get().list).find(
              filter => filter.facet_property_name === key
            )?.id;
            return id;
        }
      },
    }),
    { name: 'filter_list', version: 4 }
  )
);
