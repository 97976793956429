import { Text, Button } from '@nike/eds';
import { useLocation, useNavigate } from 'react-router-dom';

export function BreadCrumb({ current_route, previous_route, go_back, total }) {
  const location = useLocation();
  const navigate = useNavigate();
  // for pages that use useSearchParams we can't use navigate(-1) to go back
  // so we have to use the provided go_back method to do it
  function back() {
    if (go_back) {
      go_back();
    } else {
      navigate(-1);
    }
  }

  const previous =
    location?.state?.previous?.name || previous_route || location?.state?.previous_route;

  return (
    <div className="bread-crumb truncate">
      <div className="bread-crumb__content">
        {previous ? (
          <Button variant="ghost" className="bread-crumb__previous" onClick={back}>
            <Text font="title-4" as={'h1'}>
              {previous} /
            </Text>
          </Button>
        ) : undefined}
        <div className="bread-crumb__current">
          <Text font="title-4" as={'h1'} className="bread-crumb__current-text">
            {current_route} {total ? `(${total})` : ''}
          </Text>
        </div>
      </div>
    </div>
  );
}
