import { Modal, ButtonGroup, Button, Icon, Text } from '@nike/eds';

export function ArchiveBoardModal({
  appearance,
  is_open,
  on_dismiss,
  update,
  set_show_archiving,
  board_name,
}) {
  return (
    <div className="archiving-board">
      <Modal
        className={`${appearance === 'dark' ? 'eds--dark' : 'eds--light'} `}
        isOpen={is_open}
        onDismiss={on_dismiss}
        hideFade={true}
        headerSlot="Archiving Board"
        footerSlot={
          <div className="archiving-board__actions">
            <ButtonGroup>
              <Button size="medium" variant="primary" onClick={update}>
                Archive
              </Button>
              <Button
                size="medium"
                variant="secondary"
                onClick={() => {
                  set_show_archiving(false);
                }}
              >
                Go Back
              </Button>
            </ButtonGroup>
          </div>
        }
      >
        <div className="archiving-board__content eds-spacing--p-24 eds-spacing--mb-24">
          <Icon name="AlertCircle" className="eds-spacing--mr-8" />
          <Text font="subtitle-1">
            By continuing, you are choosing to Archive your board titled, "{board_name}".
          </Text>
        </div>
        <div className="eds-spacing--mb-24">
          <Text font="body-2">
            Archived Boards will not show in your man board views or accessible through filters in
            those views.
          </Text>
          {/* TODO: add this back in once we have the ability to look at archived boards */}
          {/* <Text font="body-2">
            Archived Boards will not show in your man board views or accessible through filters in
            those views. However, you can still access your Archived boards through [entry to
            archived boards].
          </Text> */}
        </div>
      </Modal>
    </div>
  );
}
