import { Link } from '@nike/eds';

export const Results = ({ export_all }) => {
  return (
    <div className="results-row">
      {export_all ? (
        <Link
          className="results-link eds-link--hover select"
          variant="secondary"
          font={'body-3'}
          data-testid="select_all_materials"
          onClick={export_all}
        >
          Export All
        </Link>
      ) : undefined}
    </div>
  );
};
