import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useSelectStore = create(
  devtools(
    (set, get) => ({
      multi_select: false,
      selected_materials: [],
      update_selected_materials: data => {
        set({ selected_materials: data });
      },
      set_multi_select: data => {
        set({ multi_select: data });
      },
      select_material: material => {
        const current_selection = get().selected_materials;
        const new_selection = current_selection.concat([material]);

        if (!current_selection.includes(material)) {
          set({ selected_materials: new_selection });
        }
      },
      deactivate_multi_select: () => {
        set({ multi_select: false, selected_materials: [] });
      },
    }),
    { name: 'select' }
  )
);
