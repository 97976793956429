import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const useDisplaySettingsStore = create(
  persist(
    devtools(
      set => ({
        size: 'small',
        sizes: {
          small: 184,
          medium: 220,
          large: 286,
        },
        view_type: 'Face',
        table_data: {
          type: {
            id: 'type',
            order: 0,
            checked: true,
            display_name: 'Type',
            get_material_data: `data => data?.materialType4`,
          },
          sub_type: {
            id: 'sub_type',
            order: 1,
            checked: true,
            display_name: 'Sub-Type',
            get_material_data: `data => data?.materialType5`,
          },
          cost: {
            id: 'cost',
            order: 2,
            checked: true,
            display_name: 'Cost',
            get_material_data: `data => {
              if (data?.material?.minPrice && data?.material?.maxPrice) {
                if (data?.material?.minPrice === data?.material?.maxPrice) {
                  return '$' + data?.material?.minPrice;
                }
                return '$' + data?.material?.minPrice + ' - ' + data?.material?.minPrice;
              }
            }`,
          },
          content: {
            id: 'content',
            order: 3,
            checked: true,
            display_name: 'Content',
            get_material_data: `data => {
              const all_contents = data?.material?.matContent?.map(item => {
                const content = item?.matContentPercentage + '% ' + item?.matContentType;
        
                return content;
              }).join(', ')
        
              return all_contents
            }`,
          },
          thickness: {
            id: 'thickness',
            order: 4,
            checked: true,
            display_name: 'Thickness (mm)',
            get_material_data: `data => {
              if (data?.material?.thicknessMm) {
                return data?.material?.thicknessMm + ' mm';
              }
            }`,
          },
          width: {
            id: 'width',
            order: 5,
            checked: true,
            display_name: 'Width (cm)',
            get_material_data: `data => {
              if (data?.material?.widthCm) {
                return data?.material?.widthCm + ' cm';
              }
            }`,
          },
          weight: {
            id: 'weight',
            order: 6,
            checked: true,
            display_name: 'Weight (g/m2)',
            get_material_data: `data => {
                if (data?.material?.weightGramsPerSquareMeter) {
                  return data?.material?.weightGramsPerSquareMeter + ' g/m2'; 
                }
              }
            `,
          },
          gauge: {
            id: 'gauge',
            order: 7,
            checked: true,
            display_name: 'Gauge',
            get_material_data: `data => data?.material?.gaugeInch`,
          },
          finish_process: {
            id: 'finish_process',
            order: 8,
            checked: true,
            display_name: 'Finish Process',
            get_material_data: `data => data?.material?.finishProcess?.join(', ')`,
          },
          dye_method: {
            id: 'dye_method',
            order: 9,
            checked: true,
            display_name: 'Dye Method',
            get_material_data: `data => data?.material?.dyeMethod?.join(', ')`,
          },
        },
        material_data: {
          type: {
            id: 'type',
            order: 0,
            checked: false,
            display_name: 'Type',
            get_material_data: `data => data?.materialType4`,
          },
          material_name: {
            id: 'material_name',
            order: 1,
            checked: true,
            disabled: true,
            display_name: 'Material Name',
            get_material_data: `data => data?.name`,
          },
          sub_type: {
            id: 'sub_type',
            order: 2,
            checked: false,
            display_name: 'Sub-Type',
            get_material_data: `data => data?.materialType5`,
          },
          description: {
            id: 'description',
            order: 3,
            checked: false,
            display_name: 'Description',
            get_material_data: `data => data.material?.description.split(';').join(', ')`,
          },
          price_range: {
            id: 'price_range',
            order: 4,
            checked: false,
            display_name: 'Price Range',
            get_material_data: `data => {
              if (data.material?.minPrice && data.material?.maxPrice) {
                if (data.material?.minPrice === data.material?.maxPrice) {
                  return '$' + data.material?.minPrice;
                }
                return '$' + data.material?.minPrice + ' - ' + data.material?.minPrice;
              }
            }`,
          },
          pcx_number: {
            id: 'pcx_number',
            order: 5,
            checked: false,
            display_name: 'PCX Number',
            get_material_data: `data => data.material?.materialId`,
          },
          pps_number: {
            id: 'pps_number',
            order: 6,
            checked: false,
            display_name: 'PPS/PDM/Flex Number',
            get_material_data: `data =>
            data.material?.legacyMaterialId ||
            data.material?.legacyMaterialNumberEq ||
            data.material?.suppliedMaterials?.map(item => item.ppsItemNumber).toString().replace(/[, ]+/g, " ").trim().split(' ').join(', ')`,
          },
          vendor: {
            id: 'vendor',
            order: 7,
            checked: false,
            display_name: 'Vendor(s)',
            get_material_data: `data =>
            data.material?.suppliedMaterials?.map(item => data?.materialType3?.toUpperCase() === 'FOOTWEAR' ? item.supplier : item.supplierLocationCode).toString().replace(/[, ]+/g, " ").trim().split(' ').join(', ')`,
          },
          // carbon_score: {
          //   id: 'carbon_score',
          //   order: 8,
          //   checked: false,
          //   display_name: 'Carbon Score',
          //   get_material_data: `(data) =>  data.wtf`,
          // },
          // sustainability_ranking: {
          //   id: 'sustainability_ranking',
          //   order: 9,
          //   checked: false,
          //   display_name: 'Sustainability Ranking',
          //   get_material_data: `(data) =>  data.wtf`,
          // },
        },
        update_table_data: (key, data) => {
          set(state => ({
            table_data: {
              ...state.table_data,
              [key]: {
                ...data,
              },
            },
          }));
        },
        set_table_data: data => {
          set({ table_data: data });
        },
        update_material_data: (key, data) => {
          set(state => ({
            material_data: {
              ...state.material_data,
              [key]: {
                ...data,
              },
            },
          }));
        },
        set_material_data: data => {
          set({ material_data: data });
        },
        set_size: data => {
          set({ size: data });
        },
        set_view_type: data => {
          set({ view_type: data });
        },
      }),
      {
        name: 'display_settings',
        version: 5, // will need to update the store version when it's persisted to invalidate the old values if/when anything in the store changes
      }
    ),
    {
      name: 'display_settings',
    }
  )
);
