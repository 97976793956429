import { Text, SidePanel } from '@nike/eds';
import '../styles/display_settings.css';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';
import { ThumbnailSize } from './thumbnail_size';
import { ThumbnailView } from './thumbnail_view';
import { MaterialData } from './material_data';
import { TableData } from './table_data';
import { track } from '../../utils/analytics';
import { useLocation } from 'react-router-dom';

export const DisplaySettings = ({
  show_display_settings,
  set_show_display_settings,
  thumbnail_size = ({ size, set_size }) => (
    <ThumbnailSize local_size={size} set_local_size={set_size} />
  ),
  thumbnail_view = ({ view_type, set_view_type }) => (
    <ThumbnailView local_view_type={view_type} set_local_view_type={set_view_type} />
  ),
  card_material_data = ({ material_data, set_material_data }) => (
    <MaterialData local_material_data={material_data} set_local_material_data={set_material_data} />
  ),
  compare_table_data = ({ table_data, set_table_data }) => (
    <TableData table_data={table_data} set_table_data={set_table_data} />
  ),
}) => {
  const location = useLocation();
  const {
    table_data,
    set_table_data,
    material_data,
    set_material_data,
    view_type,
    set_view_type,
    size,
    set_size,
  } = useDisplaySettingsStore();

  return (
    <SidePanel
      hasScrim={false}
      isOpen={show_display_settings}
      onDismiss={() => {
        set_show_display_settings(false);
        if (location?.pathname === '/compare') {
          track({
            eventName: 'compareDisplaySettings',
            thumbnailView: view_type,
            page: '/compare',
            materialData: Object.values(table_data)
              ?.filter(item => item?.checked)
              ?.sort((a, b) => a.order - b.order)
              ?.map(item => item?.display_name),
          });
        } else {
          track({
            eventName: 'materialDisplaySettings',
            thumbnailView: view_type,
            thumbnailSize: size,
            materialData: Object.values(material_data)
              ?.filter(item => item?.checked)
              ?.sort((a, b) => a.order - b.order)
              ?.map(item => item?.display_name),
          });
        }
      }}
      headerSlot={
        <>
          <Text as={'h2'} className="eds-spacing--mb-32" font="title-4">
            Display Settings
          </Text>
        </>
      }
    >
      <div className="display-settings">
        {thumbnail_size ? thumbnail_size({ size, set_size }) : undefined}
        {thumbnail_view ? thumbnail_view({ view_type, set_view_type }) : undefined}
        {card_material_data ? card_material_data({ material_data, set_material_data }) : undefined}
        {compare_table_data ? compare_table_data({ table_data, set_table_data }) : undefined}
      </div>
    </SidePanel>
  );
};
