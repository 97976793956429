import { Text, Table, TableHeading, TableCell, Icon, Tooltip } from '@nike/eds';
import { Fragment, useState } from 'react';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { track } from '../../utils/analytics';

const CopyableText = ({ font, as, className, children, field, materialID }) => {
  const { send_notification } = useNotificationsStore();

  const handle_text_copy_click = () => {
    navigator.clipboard.writeText(children);
    send_notification('success', 'Copied to Clipboard');
    track({ eventName: 'copyText', fieldName: field, page: 'materialDetails', materialID });
  };

  return (
    <Tooltip
      bodySlot={
        <div>
          <Icon name="CopyPaste" variant="ghost" />
        </div>
      }
      placement="top"
      delay={0}
    >
      <Text font={font} as={as} className={`${className} pointer`} onClick={handle_text_copy_click}>
        {children}
      </Text>
    </Tooltip>
  );
};

export function Suppliers({ material, active_engine }) {
  const [openRows, setOpenRows] = useState([]);
  let suppliers = material?.material?.suppliedMaterials || [];

  const statusOrder = ['Release', 'Test', 'Evaluate', 'Discover', 'Retired'];
  const stateOrder = [
    'Approved',
    'Approved-Conditional',
    'Approved-Exception',
    'Approved-Phase Out',
    'In Development',
    'Expired',
    'Dropped',
  ];

  const orderMap = {
    FOOTWEAR: statusOrder,
    APPAREL: stateOrder,
    ACCESSORIES: stateOrder,
  };

  const sortSuppliers = (a, b, order) => {
    if (active_engine === 'APPAREL') {
      if (a.primarySuppliedMaterialIndicator && !b.primarySuppliedMaterialIndicator) {
        return -1;
      }
      if (!a.primarySuppliedMaterialIndicator && b.primarySuppliedMaterialIndicator) {
        return 1;
      }
    }

    const indexA = order.indexOf(a.state);
    const indexB = order.indexOf(b.state);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

  if (orderMap[active_engine]) {
    suppliers = suppliers.sort((a, b) => sortSuppliers(a, b, orderMap[active_engine]));
  }

  const headers = [
    { Header: 'Supplier' },
    {
      Header: (
        <div className="header-icon">
          <Icon name="Feed" variant="ghost" />
        </div>
      ),
    },
    { Header: 'Status' },
    { Header: 'State' },
    { Header: 'Cost' },
    { Header: 'PCX SM' },
    ...(active_engine === 'FOOTWEAR' ? [{ Header: 'PDM SM' }] : []),
    ...(active_engine === 'APPAREL' ? [{ Header: 'PPS Item' }] : []),
    ...(active_engine === 'FOOTWEAR' ? [{ Header: 'Leadtime Production' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Purchasing' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Yarn' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Knit/Weave' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Dye/Finish/Packaging' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Samples Sample' }] : []),
    { Header: 'Minimum Production Order Quantity' },
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Minimum Production Color Quantity' }] : []),
    { Header: 'Minimum UOM' },
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Adhoc Desc' }] : []),
    ...(active_engine !== 'FOOTWEAR' ? [{ Header: 'Leadtime Comments' }] : []),
  ];
  // Format the price above to USD using the locale, style, and currency.
  const us_dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  function get_cost(row) {
    try {
      if (row?.suppliedMaterialPrices?.length) {
        return us_dollar.format(
          row?.suppliedMaterialPrices?.map(item => item?.usDollarPrice)?.[0]?.toString()
        );
      }
      return '- -';
    } catch (error) {
      return '';
    }
  }

  const handle_row_click = rowIndex => {
    track({ eventName: 'viewMaterialSupplierDetails', materialID: material?.id });

    setOpenRows(prevOpenRows =>
      prevOpenRows.includes(rowIndex)
        ? prevOpenRows.filter(index => index !== rowIndex)
        : [...prevOpenRows, rowIndex]
    );
  };

  function get_latest_price(prices) {
    if (!prices || prices.length === 0) return null;

    return prices.sort((a, b) => {
      const dateA = new Date(a.effectiveEndDate);
      const dateB = new Date(b.effectiveEndDate);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;

      const timestampA = new Date(a.changeTimestamp);
      const timestampB = new Date(b.changeTimestamp);
      if (timestampA > timestampB) return -1;
      if (timestampA < timestampB) return 1;

      return 0;
    })[0];
  }

  return (
    <div className="material-details__section-suppliers eds-spacing--mb-36 eds-spacing--pt-36">
      <Text font="title-6" as="h6" className="eds-spacing--mb-36">
        Supplier(s)
      </Text>
      <div className="material-details__suppliers-content">
        <Table className="material-details__suppliers-table">
          <thead>
            <tr>
              {headers.map((column, index) => (
                <TableHeading key={`supplier_header_${column.Header}_${index}`}>
                  <span className="eds-type--subtitle-1 eds-color--secondary">{column.Header}</span>
                </TableHeading>
              ))}
            </tr>
          </thead>
          <tbody>
            {suppliers.map((row, index) => (
              <Fragment key={`supplier_${row?.supplier}_${index}`}>
                <tr>
                  <TableCell className="supplier-column" onClick={() => handle_row_click(index)}>
                    <div>
                      <CopyableText materialID={material?.id} font="body-2" field="supplier">
                        {row?.supplier}
                      </CopyableText>
                      <CopyableText
                        materialID={material?.id}
                        font="body-3"
                        color="text-secondary"
                        field="supplierLocation"
                      >
                        {row?.supplierLocation ? row?.supplierLocation?.split('(')[0] : '- -'}
                      </CopyableText>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ width: '150px' }}
                    onClick={e => {
                      e.stopPropagation();
                      handle_row_click(index);
                    }}
                  >
                    <Icon
                      name={openRows.includes(index) ? 'CaretUp' : 'CaretDown'}
                      className="pointer"
                    />
                  </TableCell>
                  <TableCell>
                    <CopyableText materialID={material?.id} font="body-2" field="status">
                      {row?.status === true ? 'Active' : row?.status === false ? 'Inactive' : '- -'}
                    </CopyableText>
                  </TableCell>
                  <TableCell>
                    <CopyableText materialID={material?.id} font="body-2" field="state">
                      {row?.state ? row?.state : '- -'}
                    </CopyableText>
                  </TableCell>
                  <TableCell>
                    <CopyableText materialID={material?.id} font="body-2" field="cost">
                      {get_cost(row)}
                    </CopyableText>
                  </TableCell>
                  <TableCell>
                    <CopyableText materialID={material?.id} font="body-2" field="id">
                      {row?.id ? row?.id : '- -'}
                    </CopyableText>
                  </TableCell>
                  {active_engine === 'APPAREL' && (
                    <TableCell>
                      <CopyableText materialID={material?.id} font="body-2" field="ppsItemNumber">
                        {row?.ppsItemNumber ? row?.ppsItemNumber : '- -'}
                      </CopyableText>
                    </TableCell>
                  )}
                  {active_engine === 'FOOTWEAR' && (
                    <TableCell>
                      <CopyableText materialID={material?.id} font="body-2" field="legacyId">
                        {row?.legacyId ? row?.legacyId : '- -'}
                      </CopyableText>
                    </TableCell>
                  )}
                  {active_engine === 'FOOTWEAR' && (
                    <TableCell>
                      <CopyableText
                        materialID={material?.id}
                        font="body-2"
                        field="deliveryLeadTimeProductionQuantity"
                      >
                        {row?.deliveryLeadTimeProductionQuantity
                          ? row?.deliveryLeadTimeProductionQuantity
                          : '- -'}
                      </CopyableText>
                    </TableCell>
                  )}

                  {active_engine !== 'FOOTWEAR' && (
                    <>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimePurchasing"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimePurchasing ||
                            '- -'}
                        </CopyableText>
                      </TableCell>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeYarn"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeYarn || '- -'}
                        </CopyableText>
                      </TableCell>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeKnitWeave"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeKnitWeave ||
                            '- -'}
                        </CopyableText>
                      </TableCell>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeDyeFinishPack"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeDyeFinishPack ||
                            '- -'}
                        </CopyableText>
                      </TableCell>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeSalesSample"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeSalesSample ||
                            '- -'}
                        </CopyableText>
                      </TableCell>
                    </>
                  )}

                  <TableCell>
                    <CopyableText
                      materialID={material?.id}
                      key={index}
                      font="body-2"
                      field="minimumOrderQuantityProduction"
                    >
                      {row?.minimumOrderQuantityProduction
                        ? row?.minimumOrderQuantityProduction
                        : '- -'}
                    </CopyableText>
                  </TableCell>
                  {active_engine !== 'FOOTWEAR' && (
                    <>
                      <TableCell>
                        <CopyableText
                          key={index}
                          font="body-2"
                          field="minimumProductionOrderColorQuantity"
                        >
                          {row?.minimumProductionOrderColorQuantity
                            ? row?.minimumProductionOrderColorQuantity
                            : '- -'}
                        </CopyableText>
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <CopyableText
                      materialID={material?.id}
                      key={index}
                      font="body-2"
                      field="minimumOrderUOM"
                    >
                      {active_engine !== 'FOOTWEAR'
                        ? row?.minimumOrderUOM
                          ? row?.minimumOrderUOM
                          : '- -'
                        : row?.minimumOrderQuantityProductionUOM
                        ? row?.minimumOrderQuantityProductionUOM
                        : '- -'}
                    </CopyableText>
                  </TableCell>
                  {active_engine !== 'FOOTWEAR' && (
                    <>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeAdhoc"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeAdhoc || '- -'}
                        </CopyableText>
                      </TableCell>
                      <TableCell>
                        <CopyableText
                          materialID={material?.id}
                          key={index}
                          font="body-2"
                          field="leadTimeComment"
                        >
                          {get_latest_price(row?.suppliedMaterialPrices)?.leadTimeComment || '- -'}
                        </CopyableText>
                      </TableCell>
                    </>
                  )}
                </tr>
                {openRows.includes(index) && (
                  <tr key={`open_supplier_${row?.supplier}_${index}`}>
                    <TableCell colSpan={headers.length} style={{ padding: '0px' }}>
                      <Table className="full-width-table">
                        <thead>
                          <tr>
                            <TableHeading className="expanded-heading" />
                            <TableHeading className="expanded-heading description-heading">
                              {/* <Text font="subtitle-1" color="secondary"> */}
                              Description (from PCX)
                              {/* </Text> */}
                            </TableHeading>
                            <TableHeading className="expanded-heading description-cell">
                              {/* <Text font="subtitle-1" color="secondary"> */}
                              Comments (from PCX)
                              {/* </Text> */}
                            </TableHeading>
                            <TableHeading className="expanded-cell final" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableCell className="expanded-cell" />
                            <TableCell className="expanded-cell description-cell">
                              <CopyableText
                                materialID={material?.id}
                                font="body-2"
                                field="description"
                              >
                                {row?.state ? row?.description : '- -'}
                              </CopyableText>
                            </TableCell>
                            <TableCell className="expanded-cell description-cell">
                              <CopyableText
                                materialID={material?.id}
                                font="body-2"
                                field="comments"
                              >
                                {row?.state ? row?.comments : '- -'}
                              </CopyableText>
                            </TableCell>
                            <TableCell className="expanded-cell final" />
                          </tr>
                        </tbody>
                      </Table>
                    </TableCell>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
