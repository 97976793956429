import { Text, CheckboxGroup, Checkbox } from '@nike/eds';

import { useFeaturesStore } from '../../hooks/use_features';
import useColorScheme from '../../hooks/use_color_scheme';
import { Header } from '../../components/header';
import './features.css';

export default function Features({ title }) {
  const { appearance } = useColorScheme();
  const { features, update_features } = useFeaturesStore();

  const toggle_feature = (id, value) => {
    update_features({ ...features, [id]: value });
  };

  return (
    <div
      className={`features page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
      data-testid="access-denied"
    >
      <div className="page-header">
        <Header
          title={title}
          show_search={false}
          header_bottom={() => undefined}
          show_tote={false}
          icon_row={{ filter: false, sort: false, select: false, display_settings: false }}
        />
      </div>
      <div className="page-contents">
        <Text font="body-2" className="eds-spacing--mb-24" color="secondary">
          The following is a list of possible features we're testing out in Nike MaterialHUB
        </Text>
        <CheckboxGroup onChange={e => toggle_feature(e.target.id, e.target.checked)}>
          {Object.keys(features).map(key => {
            return <Checkbox key={key} label={key} id={key} checked={features[key]} />;
          })}
        </CheckboxGroup>
      </div>
    </div>
  );
}
