import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useUploadStore = create(
  persist(
    set => ({
      showBlockerModal: true,
      setShowBlockerModal: data => {
        set({ showBlockerModal: data });
      },
    }),
    { name: 'upload' }
  )
);
