import fetch from '../utils/fetch';

export async function getVideoSource(contentUrl) {
  try {
    if (contentUrl?.length) {
      const reqBody = {
        url: contentUrl,
        method: 'get',
        params: {
          redirect: false, // allows us to use a presigned url to essentially play the video as it's downloading
        },
      };
      const result = await fetch(reqBody);
      return result?.data?.location;
    }
  } catch (error) {
    console.warn(error);
  }
}
