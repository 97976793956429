import { useQuery } from '@tanstack/react-query';
import { http_client } from '../utils/fetch';

export function useArtifactImageHook(artifact_url) {
  return useQuery({
    queryKey: ['artifact_image', artifact_url],
    queryFn: async () => {
      return get_artifact_image_url(artifact_url);
    },
    enabled: artifact_url?.length ? true : false,
    refetchOnWindowFocus: false, // prevents artifact images from flashing no content while the window changes focus
    retry: false,
  });
}

export async function get_artifact_image_url(artifact_url) {
  if (artifact_url?.length) {
    const res = await http_client.get(artifact_url, { responseType: 'blob' });
    return URL.createObjectURL(res.data);
  } else {
    throw Error('No artifact url passed in to fetch an image for');
  }
}
