import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import config from '../config';
import http_client from '../utils/fetch';
import { get_sorted_assets } from '../utils/get_optimal_3d_image';

export async function getBoardSummaries(params) {
  const url = new URL(config.apis.search.palette_summaries, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: {
      ...params,
      paletteClass: 'material',
      source: 'DPC',
    },
  });
  return res?.data;
}

export async function getBoardEntries(params, id) {
  const url = new URL(`${config.apis.search.palette_entries}/${id}/`, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params,
  });

  return res?.data;
}

export function useBoardEntriesHook(params) {
  return useInfiniteQuery({
    queryKey: ['board_entry', params],
    queryFn: async ({ pageParam = 0 }) => await fetch_board({ ...params, from: pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined;

      return next_page;
    },
  });
}

export function useBoardsHook(params) {
  return useInfiniteQuery({
    queryKey: ['all_boards', params],
    queryFn: async ({ pageParam = 0 }) => await fetch_boards({ ...params, from: pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'

      return next_page;
    },
  });
}

export function useListBoardsHook(params) {
  return useInfiniteQuery({
    queryKey: ['all_board_list', params],
    queryFn: async ({ pageParam = 0 }) =>
      await getBoardSummaries({ ...params, from: pageParam, pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'
      return next_page;
    },
  });
}

export function useRecentBoardsHook(params) {
  return useQuery({
    queryKey: ['recent_boards', params],
    queryFn: async () => await fetch_recent_boards(params),
  });
}

export async function fetch_recent_boards(params) {
  const summaries = await getBoardSummaries({
    ...params,
    active: true,
    sortColumn: 'updatedTimestamp',
    sortDirection: 'desc',
    size: params?.size || 4,
    paletteClass: 'material',
  });

  return summaries;
}

export async function fetch_boards(params) {
  const summaries = await getBoardSummaries({ ...params });
  let board_with_assets = [];

  // for each palette summary grab the palette entry so we can see the assets in the palettes page
  for (const board of summaries.results) {
    // grab only 10 entries since that's all we're going to show in the palette page inside each palette card
    // TODO: figure out if there's a better way to make this request so we can get 10 entries w/ assets
    const board_assets_entry = await getBoardEntries(
      {
        size: 10,
        hasAtLeastOneAsset: true,
        paletteClass: 'material',
      },
      board?.paletteId
    );

    // this fetch helps grab the correct total amount of materials in each board with and without assets
    const board_entry = await getBoardEntries(
      {
        size: 10,
        paletteClass: 'material',
      },
      board?.paletteId
    );

    const total_materials = board_entry?.pagination?.total;
    // sometimes there's empty entries and some bad data in test so we're going to request more to get better results
    const board_entry_assets = board_assets_entry?.results
      ?.map(entry => {
        const assets = get_sorted_assets(entry?.assets);

        return {
          ...entry,
          assets: assets,
        };
      })
      .sort((entry_a, entry_b) => {
        if (entry_a?.assets?.length > entry_b?.assets?.length) {
          return -1;
        } else {
          return 1;
        }
      });

    board_with_assets.push({
      ...board,
      entries: board_entry_assets,
      totalMaterials: total_materials,
    });
  }

  return { ...summaries, results: board_with_assets };
}

export async function fetch_board(params) {
  const { palette_id, ...rest_of_params } = params;
  const board_entry = await getBoardEntries(
    {
      paletteClass: 'material',
      ...rest_of_params,
    },
    palette_id
  );
  return board_entry;
}

export async function update_board(params) {
  const { palette_id, entitlements, sections, ...rest } = params;

  const updated_board = {
    ...rest,
  };

  if (entitlements?.length) {
    updated_board.entitlements = entitlements;
  }
  // if (sections?.length) {
  updated_board.sections = sections;
  // }

  const url = new URL(
    `${config.apis.via.palettes}/material/${palette_id}/`,
    config.api_base
  ).toString();
  const res = await http_client({
    url,
    method: 'put',
    data: {
      ...updated_board,
    },
  });

  return res?.data;
}

export async function delete_board_items({ palette_id, ids }) {
  const url = new URL(
    `${config.apis.via.palettes}/material/${palette_id}/items`,
    config.api_base
  ).toString();
  const res = await http_client({
    url,
    method: 'delete',
    data: { ids },
  });
  return res?.data;
}

export async function add_materials_to_board({ palette_id, ids }) {
  const url = new URL(
    `${config.apis.via.palettes}/material/${palette_id}/items`,
    config.api_base
  ).toString();
  const res = await http_client({
    url,
    method: 'patch',
    data: { ids },
  });
  return res?.data;
}

export async function create_board(params) {
  const url = new URL(`${config.apis.via.palettes}/material/`, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'post',
    data: {
      ...params,
    },
  });
  return res?.data;
}

const delay = async ms => new Promise(res => setTimeout(res, ms));

export async function poll_for_board(paletteId) {
  let board_found = false;
  let attempt = 0;
  while (!board_found && attempt <= 60) {
    const res = await getBoardSummaries({
      paletteId,
      from: 0,
      size: 1,
      active: true,
      source: 'DPC',
      paletteClass: 'material',
    });
    attempt += 1;
    if (res.results?.length > 0) {
      board_found = true;
    } else {
      await delay(1000);
    }
  }
}

export function useBoardMutation(set_data = () => {}) {
  return useMutation({
    mutationFn: async variables => {
      const { method, ...params } = variables;

      if (method === 'put' && params?.palette_id) {
        return await update_board({ ...params });
      } else if (method === 'delete') {
        delete_board_items({ palette_id: params?.palette_id, ids: params?.ids });
      } else {
        return await create_board({ ...params });
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: (previous_data, data) => {
      // using these to refresh board data after it's been mutated
      // right now that refresh logic lives in material_collection
      set_data(data);
    },
  });
}

export async function fetch_all_board_entries(params) {
  let from = 0;
  const size = 50;
  const initialResult = await fetch_board({ ...params, from, size });
  let materials = initialResult.results;
  let pagination = initialResult.pagination;
  from += size;

  while (from < pagination.total) {
    const result = await fetch_board({ ...params, from, size });
    materials = [...materials, ...result.results];
    pagination = result.pagination;
    from += size;
  }

  return materials;
}
