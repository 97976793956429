import React from 'react';
import UploadQueue from '../../components/upload_queue/upload_queue';
import { Button, ButtonGroup } from '@nike/eds';

export default function UploadQueueContainer({
  handleFileImport,
  fileQueue,
  handleSelectAll,
  selectAll,
  handleClearAll,
  handleUpload,
  fileToFileRow,
  selectedFiles,
}) {
  return (
    <div className="upload-queue__container eds-spacing--mb-36">
      <UploadQueue
        onImportFiles={handleFileImport}
        fileQueue={fileQueue.map(fileToFileRow)}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
      />
      {fileQueue.length ? (
        <ButtonGroup className="queue-actions eds-spacing--mt-16">
          <Button
            size="small"
            disabled={selectedFiles.length < 1}
            variant="secondary"
            onClick={handleClearAll}
          >
            Clear Selected
          </Button>
          <Button
            onClick={handleUpload}
            size="small"
            disabled={selectedFiles.length < 1}
            variant="primary"
          >
            Upload Selected
          </Button>
        </ButtonGroup>
      ) : null}
    </div>
  );
}
