export const set_facet_params = (list, params) => {
  Object.keys(list).forEach(key => {
    const value = list[key]?.metadata?.props?.value;
    const facet = list[key].facet_property_name;
    const facet_type = list[key].facet_type;

    if (key === 'material_status' && value !== undefined) {
      params[facet] = value;
    } else if (facet_type === 'toggle') {
      if ((key.includes('has_no') || key.includes('has_all_asset')) && value === false) {
        params[facet] = false;
      } else if (value) {
        params[facet] = value;
      }
    } else if (facet_type === 'select' && value?.length > 0) {
      params[facet] = value.map(option => option.value).join(',');
    } else if (facet_type === 'min_max') {
      const min_facet_name = list[key].minProperty;
      const max_facet_name = list[key].maxProperty;

      if (value?.minValue) {
        params[min_facet_name] = value.minValue;
      }

      if (value?.maxValue) {
        params[max_facet_name] = value.maxValue;
      }
    } else if (facet_type === 'radio_group' && value) {
      params[facet] = value;
    } else if (facet_type === 'checkbox' && value !== undefined) {
      params[facet] = value;
    } else if (facet_type === 'checkbox_group' && value?.length > 0) {
      params[facet] = value?.map(option => option).join(',');
    }
  });
};

export const clear_all_filters = filter_list => {
  const default_values = {
    toggle: undefined,
    checkbox: undefined,
    checkbox_group: [],
    select: [],
    min_max: undefined,
    radio_group: '',
  };

  Object.values(filter_list).forEach(filter => {
    const facet_type = filter?.facet_type;
    filter.metadata.props.value = default_values[facet_type];
  });
};

export const get_filter_chips = (filter_list, update_method) => {
  const chips = [];
  // filter down list to items that have applied filters
  const list = Object.values(filter_list)
    .filter(filter_item => {
      const value = filter_item?.metadata?.props?.value;

      return (
        value?.maxValue?.length ||
        value?.minValue?.length ||
        value?.length ||
        value === true ||
        value === false
      );
    })
    .map(filter_item => {
      const value = filter_item?.metadata?.props?.value;
      const label_overrides = filter_item?.metadata?.label_overrides;
      const min_max_label_override = filter_item?.metadata?.min_max_label_override;

      return {
        id: filter_item?.id,
        value: min_max_label_override
          ? min_max_label_override(value?.minValue, value?.maxValue)
          : label_overrides?.[value] || value,
        display_name: filter_item?.display_name,
        label_overrides,
        min_max_label_override,
      };
    });

  // format the chips array to split up filters with multiple values
  for (const item of list) {
    if (typeof item?.value === 'object' && item?.value?.length) {
      const display_name = item?.display_name;
      const label_overrides = item?.label_overrides;

      for (const nested_value of item?.value) {
        if (nested_value?.label) {
          chips.push({
            display_name,
            value: nested_value?.label,
            remove: () => {
              update_method(
                item?.id,
                item?.value?.filter(e => e.label !== nested_value?.label)
              );
            },
          });
        } else {
          chips.push({
            display_name,
            value: label_overrides?.[nested_value] || nested_value,
            remove: () => {
              update_method(
                item?.id,
                item?.value?.filter(e => e !== nested_value)
              );
            },
          });
        }
      }
    } else {
      chips.push({
        ...item,
        remove: () => {
          update_method(item?.id, undefined);
        },
      });
    }
  }

  return chips;
};

export const get_filters_from_search_params = (
  search_params,
  list,
  get_filter_id,
  update_state
) => {
  const search_params_object = Object.fromEntries([...search_params]);
  let search_query = '';

  Object.keys(search_params_object).forEach(key => {
    const filter_id = get_filter_id(key, search_params_object[key]);
    const value = parse_search_param_value(list, filter_id, search_params_object[key]);

    if (filter_id === 'search_query') {
      search_query = value;
    } else if (list[filter_id] && value) {
      // handle the min/max structures next
      if (key.includes('min') || key.includes('max')) {
        // somehow this value here is getting set to null while we need it to be an object
        if (list[filter_id].metadata.props.value === undefined) {
          list[filter_id].metadata.props.value = {
            minValue: '',
            maxValue: '',
          };
        }
        if (key.includes('min')) {
          list[filter_id].metadata.props.value.minValue = value;
        } else if (key.includes('max')) {
          list[filter_id].metadata.props.value.maxValue = value;
        }
        // handle normal values that have a props.value structure first
      } else {
        list[filter_id].metadata.props.value = value;
      }
    }
  });
  update_state({ search_query, list });
};

// TODO: refactor this to work w/ both filter sets
export const parse_search_param_value = (list, filter_id, value) => {
  if (filter_id === 'search_query') return value;

  const filter = list[filter_id];

  if (filter?.facet_type === 'toggle') {
    return JSON.parse(value);
  } else if (filter?.facet_type === 'select') {
    return value.split(/,/g).map(value => ({ value: value, label: value }));
  } else if (filter?.facet_type === 'min_max') {
    return value;
  } else if (filter?.facet_type === 'checkbox') {
    return JSON.parse(value);
  } else if (filter?.facet_type === 'checkbox_group') {
    return value.split(/,/g).map(value => value);
  } else if (filter?.facet_type === 'radio_group') {
    return value;
  }
};
