import { ButtonGroup, Button, Spinner } from '@nike/eds';

export function CreateBoardActions({
  create,
  reset,
  disableCreate,
  loading,
  primary_action_text = 'Create Board',
}) {
  return (
    <div className="create-board__actions">
      <ButtonGroup>
        <Button
          size="medium"
          variant="primary"
          className="create"
          onClick={create}
          disabled={disableCreate || loading}
        >
          {loading ? <Spinner /> : primary_action_text}
        </Button>
        <Button size="medium" variant="secondary" onClick={reset} disabled={loading}>
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
}
