import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { useState } from 'react';
import http_client from '../../utils/fetch';
import { track } from '../../utils/analytics';
const stlLoader = new STLLoader();
const objLoader = new OBJLoader();

async function downloadContent(contentUrl) {
  const content = await http_client.get(contentUrl, { responseType: 'blob' });
  return content;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ material }) {
  const [geometry, setGeometry] = useState(null);
  const assets = material?.assets;
  const allArtifactReferences = assets?.map(a => a?.artifactReferences).flat();
  const objs = allArtifactReferences?.filter(a => ['OBJ', 'obj'].includes(a.fileType));
  const stls = allArtifactReferences?.filter(a => ['STL', 'stl'].includes(a.fileType));
  const stlToDownload = stls?.[0];
  if (stlToDownload && !geometry) {
    downloadContent(stlToDownload?.contentUrl)
      .then(res => {
        return res.data.arrayBuffer();
      })
      .then(arrayBuffer => {
        const parsed = stlLoader.parse(arrayBuffer);
        parsed.computeBoundingBox();
        const size = parsed.boundingBox.min.distanceTo(parsed.boundingBox.max);
        if (size > 100) {
          parsed.scale(0.2, 0.2, 0.2);
        }
        setGeometry(parsed);
      });
  }
  if (objs && objs.length > 0 && !geometry) {
    const promises = objs.map(objArtifact => downloadContent(objArtifact?.contentUrl));
    Promise.all(promises).then(responses => {
      Promise.all(responses.map(r => r.data.arrayBuffer())).then(arrayBuffers => {
        const firstBuffer = arrayBuffers[0];
        const decoder = new TextDecoder();
        const text = decoder.decode(firstBuffer);
        const parsed = objLoader.parse(text);
        const geometriesToMerge = parsed.children.filter(c => c.geometry).map(c => c.geometry);
        const merged = mergeGeometries(geometriesToMerge, false);
        merged.computeBoundingBox();
        const size = merged.boundingBox.min.distanceTo(merged.boundingBox.max);
        if (size > 100) {
          parsed.scale(0.2, 0.2, 0.2);
        }
        setGeometry(merged);
      });
    });
  }
  const track_user_interaction = () => {
    track({
      eventName: 'interacts3dObject',
      materialID: material?.id,
    });
  };

  return (
    <Canvas
      camera={{ fov: 25, near: 0.1, far: 1000, position: [0, 0, 10] }}
      onClick={track_user_interaction}
    >
      <ambientLight intensity={Math.PI / 2} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={1}
        decay={0}
        intensity={Math.PI / 2}
      />
      <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />

      <mesh position={[0, 0, -100]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 1]}>
        {geometry && (
          <mesh position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]} geometry={geometry}>
            <meshStandardMaterial />
          </mesh>
        )}
      </mesh>
      <OrbitControls
        target={[0, 0, -100]}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
      />
    </Canvas>
  );
}
