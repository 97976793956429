import { Snack, Snackbar, Button, Link, Spinner, Icon } from '@nike/eds';
import './styles/material_snackbar.css';

export const NotificationSnackbar = ({
  show_notification,
  dismiss_notification,
  status,
  message,
  action,
  actionText,
  dark,
  autoDismissDuration,
  hideDismiss,
  thumbs_up,
}) => {
  const get_dismiss_duration = () => {
    if (status === 'loading') {
      return 99999999999;
    } else if (!hideDismiss) {
      return null;
    } else {
      return autoDismissDuration;
    }
  };
  return (
    <Snackbar className={`notification-snackbar ${dark ? 'eds--light' : 'eds--dark'}`}>
      {show_notification && (
        <Snack
          id="notification-snack"
          onDismiss={dismiss_notification}
          status={status}
          hideDismiss={hideDismiss}
          customIcon={
            (status === 'loading' && <Spinner size="large" />) ||
            (thumbs_up && (
              <Icon
                backgroundShape="circle"
                color="white"
                backgroundColor="var(--eds-color-green-50)"
                name="ThumbsUp"
              />
            )) ||
            undefined
          }
          autoDismissDuration={get_dismiss_duration()}
        >
          <div className={`snack-body ${dark ? 'eds--light' : 'eds--dark'}`}>
            <Button variant="ghost">
              <p className="eds-type--body-2">{message}</p>
            </Button>
            {action && actionText && (
              <Link variant="secondary" className="snack-link" onClick={() => action()}>
                {actionText}
              </Link>
            )}
          </div>
        </Snack>
      )}
    </Snackbar>
  );
};
