import { Text, Icon, Menu, MenuItem, Divider, Button } from '@nike/eds';
import { useState } from 'react';

import './tote_actions_menu.css';
import { useModalStore } from '../../../hooks/use_modal';
import { useFeaturesStore } from '../../../hooks/use_features';

export function ToteActionsMenu({
  empty_tote,
  materials,
  children,
  appearance,
  is_board_menu_open,
  set_is_board_menu_open,
  recent_boards,
  add_to,
  add_to_board,
  set_is_open,
  is_open,
}) {
  const [is_second_menu_open, set_is_second_menu_open] = useState(false);
  const { set_active_modal, set_modal_data, set_trigger_location } = useModalStore();
  const { features } = useFeaturesStore();

  function close(e) {
    e.stopPropagation();
    set_is_open(!is_open);
    set_is_board_menu_open(!is_board_menu_open);
    set_is_second_menu_open(false);
  }

  const header = (
    <div>
      {is_second_menu_open ? <Text>Add To Board</Text> : <Text>Add To...</Text>}
      <Divider className="eds-spacing--mt-8 eds-spacing--mb-8" />
    </div>
  );

  const footer = (
    <>
      <Button variant="ghost" onClick={close}>
        <Text color="text-link" size="medium" variant="secondary">
          Close
        </Text>
      </Button>
      {is_second_menu_open && (
        <Button
          variant="ghost"
          onClick={e => {
            set_active_modal('create_board');
            set_trigger_location('materialToteMenuModal');
            set_modal_data({ materials: materials?.map(item => item?.id), on_success: empty_tote });
            close(e);
          }}
        >
          <Text className="footer" size="medium" variant="secondary">
            Create New Board
          </Text>
        </Button>
      )}
    </>
  );

  const body = !is_second_menu_open ? (
    <>
      <MenuItem
        onClick={e => {
          e.stopPropagation();
          set_is_second_menu_open(!is_second_menu_open);
        }}
      >
        <Icon className="eds-spacing--mr-8" name="GridView" />
        Board(s)
      </MenuItem>

      {features?.favorites ? (
        <MenuItem
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Icon className="eds-spacing--mr-8" name="Favorite" />
          Favorites
        </MenuItem>
      ) : undefined}
    </>
  ) : (
    <>
      {recent_boards?.map(board => {
        return (
          <MenuItem
            key={board?.paletteId}
            onClick={e => {
              e.stopPropagation();
              set_is_second_menu_open(!is_second_menu_open);
              set_is_board_menu_open(!is_board_menu_open);
              set_is_open(false);
              add_to_board(board);
            }}
          >
            {board?.name}
          </MenuItem>
        );
      })}
      <MenuItem
        onClick={e => {
          e.stopPropagation();
          add_to();
          close(e);
        }}
      >
        <Text color="text-link">More Board(s)</Text>
      </MenuItem>
    </>
  );

  return (
    <>
      <Menu
        headerSlot={header}
        className={`${appearance === 'dark' ? 'eds--light' : 'eds--dark'} ${
          is_second_menu_open ? 'second-menu' : ''
        }
            ${is_board_menu_open ? 'first-menu' : ''}`}
        onClickOutside={close}
        isOpen={is_open}
        bodySlot={body}
        appearance={appearance}
        footerSlot={footer}
        placement="top"
      >
        {children}
      </Menu>
    </>
  );
}
