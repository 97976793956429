import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';

import './App.css';
import { router } from './router';
import config from './config';
import { useNotificationsStore } from './hooks/use_notifications';
import { NotificationSnackbar } from './components/common/notification_snackbar';
import { useFeaturesStore } from './hooks/use_features';
import useColorScheme from './hooks/use_color_scheme';
import { track } from './utils/analytics';

const App = () => {
  const queryClient = new QueryClient();
  const {
    show_notification,
    dismiss_notification,
    status,
    message,
    action,
    actionText,
    hideDismiss,
    autoDismissDuration,
    thumbs_up,
  } = useNotificationsStore();
  const { features, update_features } = useFeaturesStore();
  const feature_flags = config?.feature_flags;
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';

  useEffect(() => {
    update_features(feature_flags);
  }, [feature_flags, update_features]);

  useEffect(() => {
    if (features?.partial_non_modal_dialog) {
      import(`./components/styles/eds_modal_override.css`);
    }
  }, [features]);

  useEffect(() => {
    track({ eventName: 'openApp' });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {/* This is for hiding that multi select overlay in the grid when we don't want it */}
      <div id="hidden_select_area"></div>
      <div
        className={`${
          features?.partial_non_modal_dialog ? 'app feature-partial_non_modal_dialog' : 'app'
        }`}
      >
        <RouterProvider router={router} />
        <NotificationSnackbar
          show_notification={show_notification}
          dismiss_notification={dismiss_notification}
          action={action}
          actionText={actionText}
          status={status}
          message={message}
          dark={dark}
          hideDismiss={hideDismiss}
          autoDismissDuration={autoDismissDuration}
          thumbs_up={thumbs_up}
        />
      </div>
    </QueryClientProvider>
  );
};

const AppWrapper = () => {
  return <App />;
};

export default AppWrapper;
