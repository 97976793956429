import { useState, useEffect } from 'react';
import { NikeApp } from '@nike/nike-design-system-icons';
import { VerticalNavbar, setActiveValueInNavItems } from '@nike/eds';

import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import InfoModal from './info/info_modal';
import { useBoardFilterStore } from '../hooks/use_board_filters';
import { usePaletteFilterStore } from '../hooks/use_palette_filters';
import useColorScheme from '../hooks/use_color_scheme';
import { useOktaAuth } from '@okta/okta-react';
import { AuthGroups } from '../utils/RequireOktaAuth';

export const SideNav = ({ user }) => {
  const { authState } = useOktaAuth();
  const groups = authState.idToken.claims.groups;
  const { appearance } = useColorScheme();

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;

  const board_filter_store = useBoardFilterStore();
  const palette_filter_store = usePaletteFilterStore();

  const [items, set_items] = useState([]);
  const [bottom_items, set_bottom_items] = useState([]);
  const [show_info_modal, set_show_info_modal] = useState(false);

  function hide_info_modal() {
    // hide info modal
    set_show_info_modal(false);

    // reset items to set active nav item to whatever the user was on before
    let matching_item = items.find(item => item.route === location?.pathname);
    if (location?.pathname === '/') {
      matching_item = { id: '1' };
    }
    const new_items = setActiveValueInNavItems(matching_item?.id, items);

    const matching_bottom_item = bottom_items.find(item => item.route === location?.pathname);
    const new_bottom_items = setActiveValueInNavItems(matching_bottom_item?.id, bottom_items);

    set_items(new_items);
    set_bottom_items(new_bottom_items);
  }

  useEffect(() => {
    set_items(
      [
        {
          id: '1',
          icon: 'Journal',
          label: 'Library',
          active: ['/library', '/'].includes(pathname),
          route: '/library',
        },
        {
          id: '2',
          icon: 'GridView',
          label: 'Boards',
          route: '/boards',
          active: false,
          subItems: [
            {
              id: 'all_boards',
              label: 'All Boards',
              active: false,
              route: '/boards',
            },
            {
              id: 'my_boards',
              label: 'My Boards',
              active: false,
              route: '/boards',
              query: {
                createdByUserId: user?.preferred_username,
              },
            },
          ],
        },
        {
          id: '3',
          icon: 'Columns',
          label: 'Palettes',
          active: pathname.includes('/palettes'), // allows us to make this active for /palettes/palette_id and /palettes
          route: '/palettes',
        },
        groups.includes(AuthGroups.AP_ADMIN) || groups.includes(AuthGroups.FW_ADMIN)
          ? {
              id: '4',
              label: 'Upload',
              icon: 'Upload',
              active: pathname === '/upload',
              route: '/upload',
            }
          : null,
      ].filter(item => item)
    );
    set_bottom_items([
      {
        id: '5',
        icon: 'InfoCircle',
        label: 'Info',
        active: false,
      },
      {
        id: '6',
        icon: 'Settings',
        label: 'Settings',
        active: pathname === '/settings',
        route: '/settings',
      },
      // {
      //   id: '6',
      //   icon: 'Profile',
      //   label: 'Profile',
      //   active: pathname === '/profile',
      //   route: '/profile',
      // },
    ]);
  }, [pathname, user.preferred_username, groups]);

  const handleNavigate = item => {
    //todo: could add clear library filter logic here, let's discuss!
    if (item.id === '5') {
      set_show_info_modal(!show_info_modal);
      return;
    } else if (item.id === 'my_boards' || item.id === 'all_boards' || item.id === '2') {
      // clear board filters on nav
      board_filter_store.clear_filters();
    } else if (item.id === '3') {
      // clear palette filters on nav
      palette_filter_store.clear_filters();
    }

    // there's an async issue with clearing filters and navigating, so we need to set a timeout to ensure the filters are cleared before navigating to the new route. This currently is noticeable when navigating from All Boards to My Boards
    setTimeout(() => {
      const new_items = setActiveValueInNavItems(item.id, items);
      const new_bottom_items = setActiveValueInNavItems(item.id, bottom_items);

      set_items(new_items);
      set_bottom_items(new_bottom_items);

      navigate({
        pathname: item?.route,
        search: createSearchParams(item?.query).toString(),
        state: {
          previous: location,
        },
      });

      set_items(new_items);
    }, 0);
  };

  return (
    <>
      <VerticalNavbar
        navLogoSlot={<NikeApp width={'56px'} height={'56px'} />}
        appName="Material Library"
        appSubtitle="Next Gen Material Library"
        onNavigate={handleNavigate}
        items={items}
        bottomItems={bottom_items}
      ></VerticalNavbar>
      <InfoModal appearance={appearance} show={show_info_modal} onClose={hide_info_modal} />
    </>
  );
};
