import { useDisplaySettingsStore } from './use_display_settings';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useCompareMaterialsStore = create(
  persist(
    (set, get) => ({
      pinned_row_ids: [],
      update_pinned_row_ids: data => {
        const { table_data, update_table_data } = useDisplaySettingsStore.getState();

        for (const key in table_data) {
          if (data?.includes(key)) {
            update_table_data(key, { ...table_data[key], disabled: true });
          } else {
            update_table_data(key, { ...table_data[key], disabled: false });
          }
        }

        set({ pinned_row_ids: data });
      },
      get_pinned_rows: () => {
        const compare_materials_table_headers = Object.values(
          useDisplaySettingsStore.getState().table_data
        );
        const pinned_row_ids = get().pinned_row_ids;

        return compare_materials_table_headers
          .filter(row => pinned_row_ids.includes(row.id))
          .map(item => {
            return { ...item, disabled: true };
          });
      },
      get_unpinned_rows: () => {
        const compare_materials_table_headers = Object.values(
          useDisplaySettingsStore.getState().table_data
        );
        const pinned_row_ids = get().pinned_row_ids;

        return compare_materials_table_headers
          .filter(row => !pinned_row_ids.includes(row.id))
          .map(item => {
            return { ...item, disabled: false };
          })
          .sort((a, b) => a?.order - b?.order);
      },
    }),
    { name: 'compare_materials' }
  )
);
