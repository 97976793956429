import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const use_engine_store = create(
  persist(
    (set, get) => ({
      engines: [], // list of engines the user has access to
      active_engine: '', // engine that user wants to filter results by
      set_active_engine: async data => {
        set({ active_engine: data });
      },
      set_engines: async data => {
        set({ engines: data });
      },
      get_active_engine_title: () => {
        const { active_engine } = get();
        if (active_engine) {
          return active_engine
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        } else {
          return '';
        }
      },
    }),
    { name: 'engine' }
  )
);
