import { Button, Select } from '@nike/eds';
import { useUserLookup } from '../../hooks/use_user';
import { useState } from 'react';

export function NewTeamMembers({
  new_team_members,
  is_admin,
  set_new_team_members,
  user_lookup_options,
  set_new_team_members_entitlement,
  new_team_members_entitlement,
  add_entitled_users,
  options,
  has_user_permissions,
}) {
  const [search_key, set_search_key] = useState('');
  const { data, loading } = useUserLookup(search_key);
  return (
    <div className="eds-spacing--mb-24 manage-board__emails">
      <div className="manage-board__email-input">
        <Select
          key={new_team_members?.toString()}
          id="manage-board__email-input"
          isMulti
          closeMenuOnSelect={false}
          isClearable={false}
          isDisabled={!has_user_permissions}
          placeholder="Name or Email"
          message="Team members added here will be sent an invitation to this board via email once created"
          onInputChange={value => set_search_key(value)}
          onChange={selection => set_new_team_members(selection)}
          options={data || []}
          value={new_team_members}
          isLoading={loading}
          noOptionsMessage={() => {
            if (loading) {
              return 'Searching...';
            } else if (!loading && search_key.length > 2) {
              return 'No results found';
            } else {
              return 'Search for a user by email.';
            }
          }}
        />
      </div>
      <div className="manage-board__email-entitlement">
        <Select
          id="manage-board__email-entitlements"
          noBorder={true}
          isSearchable={false}
          options={options}
          defaultValue={options[0]}
          isDisabled={!has_user_permissions}
          onChange={event => set_new_team_members_entitlement(event)}
          value={new_team_members_entitlement}
        />
      </div>
      <div className="manage-board__add-button">
        <Button
          disabled={!has_user_permissions}
          size="medium"
          variant="primary"
          onClick={add_entitled_users}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
