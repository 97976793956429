import { Text } from '@nike/eds';
import { CarouselStyled, NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import { useEffect, useState } from 'react';
import { MaterialImage } from '../common/material_image';
import * as videoApi from '../../utils/get_video_source';
import { useArtifactImageHook } from '../../hooks/use_artifact_image';
import { useFeaturesStore } from '../../hooks/use_features';
import 'viewerjs/dist/viewer.css';
import Material3DViewer from './material_3d_viewer';
import { track } from '../../utils/analytics';

function show3DViewer(material) {
  const assets = material?.assets;
  const artifacts = assets?.find(asset => asset.active)?.artifactReferences;
  const objs = artifacts?.filter(a => ['OBJ', 'obj'].includes(a.fileType));
  const stls = artifacts?.filter(a => ['STL', 'stl'].includes(a.fileType));
  return objs?.length > 0 || stls?.length > 0;
}

export function MaterialImageCarousel({ material, show_image_viewer }) {
  const image_types = [
    { label: 'Face', view_type: 'Face' },
    { label: 'Back', view_type: 'Back' },
    { label: 'Drape', view_type: 'Drape' },
    { label: 'Video', view_type: 'Video' },
    { label: '3D', view_type: '3D' },
  ];
  const [video_url, set_video_url] = useState('');
  const [video_thumbnail, set_video_thumbnail] = useState('');

  useEffect(() => {
    // eslint-disable-next-line
    getVideoUrl(material);
    // eslint-disable-next-line
  }, [material]);

  async function getVideoUrl(material) {
    set_video_url('');
    set_video_thumbnail('');

    const materialAsArray = material?.assets;
    if (!materialAsArray) {
      return;
    }
    const videoAsset = materialAsArray.find(
      item =>
        item?.active === true &&
        item?.assetClass === 'materialvideo' &&
        item?.assetMetadata?.subClass === 'vml'
    );

    let contentUrl = '';

    if (videoAsset?.artifactReferences?.length > 0) {
      const artifact = videoAsset.artifactReferences.find(
        item => item.fileType === 'MP4' && item?.metadata?.viewType === 'Original'
      );
      contentUrl = artifact.contentUrl;

      const videoPoster = videoAsset.artifactReferences.find(
        item => item?.metadata?.viewType === 'Poster'
      );
      set_video_thumbnail(videoPoster.contentUrl);
    }

    const result = await videoApi.getVideoSource(contentUrl);

    set_video_url(result);
    return video_url;
  }
  const { data } = useArtifactImageHook(video_thumbnail);
  const { features } = useFeaturesStore();

  return (
    <NikeDesignSystemProvider>
      <div className="material-details__carousel eds-spacing--mb-32" id="image-carousel">
        <CarouselStyled
          slidesToShow={{ xs: 1, m: 2, l: features?.material_details_panel ? 2 : 3 }}
          showPeek={{ xs: false }}
          navigationMode="buttonAbove"
        >
          {image_types.map(image => {
            if (image.view_type === '3D') {
              return (
                <div key={image?.view_type}>
                  {show3DViewer(material) && (
                    <div style={{ height: 'calc(100vh / 2.45)' }}>
                      <Material3DViewer material={material} />
                    </div>
                  )}
                  {!show3DViewer(material) && (
                    <div style={{ marginBottom: '-10px' }}>
                      <MaterialImage
                        material={material}
                        view_type={image.view_type}
                        image_type="largeImage"
                        on_image_click={show_image_viewer}
                        can_zoom={true}
                      />
                    </div>
                  )}
                  <Text font="subtitle-1" className="eds-spacing--mt-12">
                    {image.label}
                  </Text>
                </div>
              );
            }
            if (image.view_type !== 'Video')
              return (
                <div className="material-details__carousel-image" key={image?.view_type}>
                  <MaterialImage
                    material={material}
                    view_type={image.view_type}
                    image_type="largeImage"
                    on_image_click={show_image_viewer}
                    can_zoom={true}
                  />
                  <Text font="subtitle-1" className="eds-spacing--mt-12">
                    {image.label}
                  </Text>
                </div>
              );
            return (
              <div
                className={
                  video_url
                    ? 'material-details__carousel-video'
                    : 'material-details__carousel-image'
                }
                key={image?.view_type}
              >
                {video_url ? (
                  <video
                    onClick={() =>
                      track({ eventName: 'viewMaterialVideo', materialID: material?.id })
                    }
                    controls
                    playsInline
                    poster={data}
                    key={video_url}
                    crossOrigin="anonymous | use-credentials"
                    preload="auto"
                  >
                    <source src={video_url} />
                  </video>
                ) : (
                  <MaterialImage
                    material={material}
                    view_type={image.view_type}
                    image_type="largeImage"
                    can_zoom={true}
                    pan_strength={2.5}
                  />
                )}
                <Text font="subtitle-1" className="eds-spacing--mt-12">
                  {image.label}
                </Text>
              </div>
            );
          })}
        </CarouselStyled>
      </div>
    </NikeDesignSystemProvider>
  );
}
