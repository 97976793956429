import { useDraggable } from '@dnd-kit/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: props.data,
    disabled: props.disabled,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}
