import { Icon } from '@nike/eds';
import './styles/collapse.css';

export function Collapse({ children, enable, open, labelSlot }) {
  return (
    <details open={enable || open}>
      <summary>
        <span className="icon">
          <Icon name="CaretDown" size="m" />
        </span>
      {labelSlot && <span className="collapse-label">{labelSlot}</span>}
      </summary>
      <>{children}</>
    </details>
  );
}
