import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePalettesHook } from '../../hooks/use_palettes';
import useColorScheme from '../../hooks/use_color_scheme';
import { fetch_all_materials_by_ids } from '../../hooks/use_materials';
import { useToteStore } from '../../hooks/use_tote';
import { useFeaturesStore } from '../../hooks/use_features';
import { useSelectStore } from '../../hooks/use_select';
import { Grid } from '../../components/common/grid';
import { MaterialCard } from '../../components/common/material_card';
import { Header } from '../../components/header';
import { BreadCrumb } from '../../components/header/bread_crumb';
import { ErrorBoundary } from '../../components/common/error_boundary';
import { useModalStore } from '../../hooks/use_modal';
import { useExportStore } from '../../hooks/use_export';
import { usePrintStore } from '../../hooks/use_print';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import EmptyTote from './empty_tote';
import { ToteFooter } from '../../components/common/tote/tote_footer';
import { Text } from '@nike/eds';

export default function Tote({ title }) {
  const { appearance } = useColorScheme();
  const { tote, update_tote } = useToteStore();
  const { set_active_modal, set_modal_data, set_trigger_location } = useModalStore();
  const { update_print_materials, set_total_print_materials } = usePrintStore();
  const { set_export_all, set_total_materials, set_export_materials } = useExportStore();
  const { selected_materials } = useSelectStore();
  const location = useLocation();
  const { features } = useFeaturesStore();

  const [show_material, set_show_material] = useState(false);
  const [set_active_material] = useState({});
  const [active_card, set_active_card] = useState();

  const dark = appearance === 'dark';
  const [page_cursor, set_page_cursor] = useState(0);
  const [previous_route, set_previous_route] = useState(location);
  const { send_notification } = useNotificationsStore();

  useEffect(() => {
    set_previous_route(location?.state?.previous_route);
  }, [location]);

  const [params] = useState();
  const [materials, set_materials] = useState([]);

  useEffect(() => {
    const fetch_materials = async () => {
      const materials = await fetch_all_materials_by_ids(tote);
      set_materials(materials);
    };
    fetch_materials();
  }, [tote]);

  useEffect(() => {});

  function add_to() {
    set_active_modal('add_to');
    set_trigger_location('materialToteMenuModal');
    set_modal_data({
      materials: materials?.map(item => item?.id),
      on_success: () => update_tote([]),
    });
  }

  async function add_to_board(board) {
    try {
      await add_materials_to_boards({
        boards: [board],
        material_ids: materials?.map(item => item?.id),
        trigger_location: 'tote',
      });
      send_notification('success', 'Material(s) Added to Board');
    } catch (error) {
      console.error(`Error adding material to board from tote:`, error.message);
      send_notification('error', 'Error Adding Material to Board');
    }
  }

  function export_tote() {
    set_active_modal('export');
    set_export_materials(materials);
    set_total_materials(materials?.length);
    set_trigger_location('tote');
    set_export_all(false);
  }

  function print_tote() {
    set_active_modal('print');
    update_print_materials(materials);
    set_total_print_materials(materials?.length);
  }

  const { status, data, error } = usePalettesHook(params);

  useEffect(() => {
    set_page_cursor(data?.pages?.length || 0);
  }, [data]);

  function ToteHeader({ title }) {
    return (
      <Header
        title={title}
        tote_header={true}
        show_search={false}
        show_tote={false}
        className="tote-page"
        header_top={() => (
          <>
            <BreadCrumb
              title={location?.state?.boardName}
              total={materials.length}
              current_route={title}
              previous_route={location?.state?.previous_route}
            />
          </>
        )}
        header_bottom={() => (
          <div>
            <Text>
              Please note that by default, items in your tote will be removed once you add them to a
              board or your favorites.
            </Text>
            <Text>Exporting or printing will not remove them from your tote.</Text>
          </div>
        )}
      />
    );
  }

  if (status === 'error') {
    return <ErrorBoundary error={error} dark={dark} header={<ToteHeader />} />;
  }

  // since useInfiniteQuery returns pages and we want the page results we're gonna just handle that in local state
  if (data?.pages?.length > page_cursor) {
    // set the current page cursor so we know where we're at and if we need to continue fetching
    set_page_cursor(data?.pages?.length || 0);
  }

  function open_material(id) {
    if (features?.material_details_panel) {
      set_active_material(materials?.find(mat => mat?.id === id));
      if (!show_material) {
        set_show_material(true);
      }
    }
  }

  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`} data-testid="page">
      <div className="page-header">
        <ToteHeader title={title} previous_route={previous_route?.state?.previous?.name} />
      </div>
      <div className="tote-container">
        <div className={`empty-tote-page ${materials?.length === 0 ? 'center-content' : ''}`}>
          {materials?.length === 0 ? (
            <EmptyTote />
          ) : (
            <Grid
              className="grid"
              data={materials}
              card={(material, material_cad_ref, select_item) => (
                <li
                  key={`tote_${material?.id}`}
                  className="material-card__selectable"
                  ref={material_cad_ref}
                  id={material?.id}
                >
                  <MaterialCard
                    material={material}
                    selected={selected_materials.includes(material?.id)}
                    active={active_card === material?.id}
                    set_active_card={set_active_card}
                    show_card_actions={false}
                    actions={{
                      open: open_material,
                    }}
                  />
                </li>
              )}
              gap={24}
              fetchNextPage={() => {}}
              hasNextPage={false}
              isFetching={false}
              isFetchingNextPage={false}
            />
          )}
        </div>
      </div>
      <ToteFooter
        materials={materials}
        empty_tote={() => {
          if (tote?.length) {
            update_tote([]);
          }
          console.log('empty_tote');
        }}
        add_to={add_to}
        add_to_board={add_to_board}
        export_tote={export_tote}
        print={print_tote}
        material={tote}
      />
    </div>
  );
}
