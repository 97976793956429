import { useState, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const COLOR_SCHEMES = ['no-preference', 'dark', 'light'];
const DEFAULT_TARGET_COLOR_SCHEME = 'light';

export const useColorSchemeStore = create(
  persist(
    set => ({
      color_scheme: DEFAULT_TARGET_COLOR_SCHEME,
      system_color_scheme_override: false,
      set_color_scheme: data => {
        console.log({ set_color_scheme: data });
        set({ color_scheme: data });
      },
      set_system_color_scheme_override: data => {
        console.log({ system_color_scheme_override: data });
        set({ system_color_scheme_override: data });
      },
    }),
    { name: 'color_scheme' }
  )
);

function getCurrentColorScheme() {
  try {
    const QUERIES = {};

    // eslint-disable-next-line no-func-assign
    return (getCurrentColorScheme = function () {
      for (let scheme of COLOR_SCHEMES) {
        const query = QUERIES.hasOwnProperty(scheme)
          ? QUERIES[scheme]
          : (QUERIES[scheme] =
              window?.matchMedia && window.matchMedia(`(prefers-color-scheme: ${scheme})`));

        if (query?.matches) return { query, scheme };
      }
    })();
  } catch (error) {
    console.warn(error.message);
  }
}

// Define and export the `useColorScheme` hook
export default function useColorScheme() {
  const store = useColorSchemeStore.getState();
  const system_color_scheme_override = store?.system_color_scheme_override;
  const isMounted = useRef();
  const colorScheme = useRef();

  const [scheme, setColorScheme] = useState(() => {
    const currentColorScheme = (colorScheme.current = getCurrentColorScheme());
    const scheme = currentColorScheme?.scheme;

    return scheme;
  });

  useEffect(() => {
    const currentColorScheme = colorScheme.current;
    const query = currentColorScheme?.query;

    query?.addEventListener('change', schemeChangeHandler);
    isMounted.current = true;

    function schemeChangeHandler(evt) {
      if (!evt.matches) {
        this.removeEventListener('change', schemeChangeHandler);
        const { query, scheme } = (colorScheme.current = getCurrentColorScheme());

        isMounted.current && setColorScheme(scheme);
        query?.addEventListener('change', schemeChangeHandler);
      }
    }

    return () => {
      const currentColorScheme = colorScheme.current;
      const query = currentColorScheme?.query;

      query?.removeEventListener('change', schemeChangeHandler);
      isMounted.current = false;
    };
  }, [system_color_scheme_override]);

  const chosen_color = system_color_scheme_override ? store?.color_scheme : scheme;

  return { appearance: chosen_color };
}
