import { Modal, Spinner } from '@nike/eds';

import { MaterialDetails } from '.';
import { MaterialHeader } from './material_header';
import useColorScheme from '../../hooks/use_color_scheme';

import './material_details.css';
import { useModalStore } from '../../hooks/use_modal';
import { useState, useRef, useEffect } from 'react';
import Viewer from 'viewerjs';
import { IconButton } from '@nike/eds';
import { get_artifact_image_url } from '../../hooks/use_artifact_image';
import { track } from '../../utils/analytics';

export function MaterialModal() {
  const { appearance } = useColorScheme();
  const { active_modal, dismiss_modal, modal_data } = useModalStore();
  const show = active_modal === 'material';
  const material = modal_data?.material;
  const [image_src, set_image_src] = useState(null);
  const [image_alt, set_image_alt] = useState(null);
  const [show_image_viewer, set_show_image_viewer] = useState(false);
  const viewer = useRef(null);

  useEffect(() => {
    if (show) {
      track({ eventName: 'viewMaterialDetailsModal', materialID: material?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handle_show_image_viewer = async (artifact_url, material_name, view_type) => {
    set_show_image_viewer(true);

    const data = await get_artifact_image_url(artifact_url);
    const alt = `${material_name} ${view_type}`;

    set_image_src(data);
    set_image_alt(alt);
    setTimeout(() => {
      viewer.current = new Viewer(document.getElementById('material-image-viewer'), {
        inline: true,
        navbar: false,
        className: 'image-viewer-full-modal',
        tooltip: false,
        toolbar: {
          zoomIn: { show: true, size: 'large' },
          zoomOut: { show: true, size: 'large' },
          oneToOne: 0,
          reset: { show: true, size: 'large' },
          rotateLeft: { show: true, size: 'large' },
          rotateRight: { show: true, size: 'large' },
          flipHorizontal: 0,
          flipVertical: 0,
          play: 0,
          prev: 0,
          next: 0,
        },
        button: false,
      });
    }, 100);
  };

  const handle_hide_image_viewer = () => {
    if (viewer.current) viewer.current.destroy();
    set_image_src('');
    set_image_alt('');
    set_show_image_viewer(false);
  };

  const handle_dismiss_modal = () => {
    handle_hide_image_viewer();
    dismiss_modal();
  };

  return (
    <div
      key={`material_details_modal_${material?.id}`}
      className={`material-details__modal ${show_image_viewer ? 'image-viewer' : ''}`}
    >
      {show ? (
        <Modal
          className={`${
            appearance === 'dark' ? 'eds--dark' : 'eds--light'
          } material-details__modal ${show_image_viewer ? 'image-viewer' : ''}`}
          isOpen={show}
          onDismiss={handle_dismiss_modal}
          headerSlot={<MaterialHeader material={material} />}
          hideFade={true}
        >
          {show_image_viewer ? (
            <div className="image-viewer-container">
              {show_image_viewer && !image_src ? (
                <Spinner size="large" />
              ) : (
                <>
                  <IconButton
                    icon="Close"
                    className="image-viewer-close-button"
                    onClick={handle_hide_image_viewer}
                    size="small"
                  />
                  <img
                    src={image_src}
                    id="material-image-viewer"
                    className="material-image-viewer-base"
                    alt={image_alt}
                  />
                </>
              )}
            </div>
          ) : (
            <MaterialDetails material={material} show_image_viewer={handle_show_image_viewer} />
          )}
        </Modal>
      ) : undefined}
    </div>
  );
}
