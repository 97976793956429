import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import { usePalettesHook } from '../../hooks/use_palettes';
import useColorScheme from '../../hooks/use_color_scheme';
import { usePaletteFilterStore } from '../../hooks/use_palette_filters';
import { useLibraryFilterStore } from '../../hooks/use_library_filters';
import { use_engine_store } from '../../hooks/use_engine';
import { Grid } from '../../components/common/grid';
import { PaletteCard } from '../../components/common/palette_card';
import { Header } from '../../components/header';
import { PaletteFilterList } from '../../components/palette_filter_list';
import { ErrorBoundary } from '../../components/common/error_boundary';
import { Text, Icon } from '@nike/eds';
import './palettes.css';

export default function Palettes({ title }) {
  const { appearance } = useColorScheme();
  const dark = appearance === 'dark';
  const navigate = useNavigate();
  const location = useLocation();
  const [show_filters, set_show_filters] = useState(false);
  const [palettes, set_palettes] = useState([]);
  const [page_cursor, set_page_cursor] = useState(0);
  const [total, set_total] = useState(0);

  const engine_store = use_engine_store();

  const palette_filter_store = usePaletteFilterStore();
  const {
    list,
    search_query,
    get_filter_params,
    load_filters_from_search_params,
    load_chips,
    update_filter_value,
  } = palette_filter_store;
  const library_filter_store = useLibraryFilterStore();

  const [search_params, set_search_params] = useSearchParams();
  const [params, set_params] = useState();

  // when the filter list is updated we will update our search parameters
  // this will then trigger the bellow useEffect
  useEffect(() => {
    const params = get_filter_params();
    const active_engine = engine_store?.active_engine;
    params.division = `${active_engine} DIVISION`;
    // remove fields.includes from window url
    delete params['fields.includes'];
    set_search_params({ ...params });
  }, [list, search_query, get_filter_params, set_search_params, engine_store?.active_engine]);

  // load filters from the search params and set them in the filter store
  // when we set the params that will triger the useMaterialsHook to run loading in new materials
  useEffect(() => {
    load_filters_from_search_params(search_params);
    const params = get_filter_params();
    const active_engine = engine_store?.active_engine;
    params.division = `${active_engine} DIVISION`;

    set_params({ ...params });
  }, [
    search_params,
    load_filters_from_search_params,
    get_filter_params,
    engine_store?.active_engine,
  ]);

  const { status, data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    usePalettesHook(params);

  useEffect(() => {
    if (engine_store?.active_engine === 'FOOTWEAR') {
      set_palettes(data?.pages?.map(item => item.results).flat());
      set_page_cursor(data?.pages?.length || 0);
      set_total(data?.pages?.[0]?.pagination?.total);
    }
  }, [data, engine_store?.active_engine]);

  const PaletteHeader = () => {
    return (
      <Header
        title={title}
        total={total}
        actions={{
          show_filter: () => set_show_filters(!show_filters),
        }}
        filters={load_chips() || []}
        update_filter_value={update_filter_value}
        icon_row={{ filter: true, sort: false, select: false, display_settings: false }}
        filter_store={palette_filter_store}
        disable_filters={engine_store?.active_engine !== 'FOOTWEAR'}
      />
    );
  };

  if (status === 'error') {
    return <ErrorBoundary error={error} dark={dark} header={<PaletteHeader />} />;
  }

  // since useInfiniteQuery returns pages and we want the page results we're gonna just handle that in local state
  if (data?.pages?.length > page_cursor) {
    set_palettes(data?.pages?.map(item => item.results).flat());
    // set the current page cursor so we know where we're at and if we need to continue fetching
    set_page_cursor(data?.pages?.length || 0);
  }

  return (
    <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`} data-testid="page">
      <div className="page-header">
        <PaletteHeader />
      </div>
      <div className="page-contents">
        {engine_store?.active_engine === 'FOOTWEAR' ? (
          <>
            <Grid
              status={status}
              data={palettes}
              card={palette => (
                <li key={`palette_${palette?.paletteId}`}>
                  <PaletteCard
                    palette={palette}
                    actions={{
                      open: () => {
                        library_filter_store.clear_filters();
                        navigate(`/palettes/${palette.id}`, {
                          state: {
                            palette_name: palette?.name,
                            previous: location,
                          },
                        });
                      },
                    }}
                  />
                </li>
              )}
              card_size={380}
              gap={48}
              // infinite scroll props
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
            />
            <PaletteFilterList show_filters={show_filters} set_show_filters={set_show_filters} />
          </>
        ) : (
          <div className="no-palettes">
            <Icon size="l" name="AlertCircle" />
            <Text className="no-palettes--title eds-color--text-secondary" font="title-5">
              PCX Palettes Not Available
            </Text>
            <Text font="body-3 eds-color--text-secondary">
              PCX palettes are not used at this time for{' '}
              {engine_store?.active_engine?.toLowerCase()} teams.
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
