import { useLocation, useNavigate } from 'react-router-dom';
import useColorScheme from '../../hooks/use_color_scheme';
import { Icon, Text } from '@nike/eds';
import { NewBoardModal } from '../new_board_modal';

import './empty_board.css';

export default function EmptyBoard({ set_show_modal, show_modal }) {
  const { appearance } = useColorScheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dark = appearance === 'dark';

  return (
    <div>
      {show_modal && (
        <NewBoardModal
          show={show_modal}
          on_dismiss={() => set_show_modal(false)}
          on_click={() => {
            show_modal = false;
            navigate('/library', {
              state: {
                previous: location,
              },
            });
          }}
        />
      )}
      <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`}>
        <div className="empty-board-page page-contents">
          <Icon size="l" name="AlertCircle" />
          <Text className="empty-board__header" font={'title-5'} as={'h1'}>
            This board is empty
          </Text>
          <Text className="empty-board-page__body" font={'body-3'} as={'p'}>
            Once materials are added to this board, they will show up here. <br />
            You can browse the library and add materials one-by-one as you <br />
            find them, or, you can multi-select materials and add them in <br />
            groups to this board.
          </Text>
        </div>
      </div>
    </div>
  );
}
