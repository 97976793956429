export const general_user_groups_mapping = {
  ACCESSORIES: 'App.Aurora.VML.Users.ACC',
  APPAREL: 'App.Aurora.VML.Users.AP',
  FOOTWEAR: 'App.Aurora.VML.Users.FW',
};
export const admin_user_groups_mapping = {
  ACCESSORIES: 'App.Aurora.VML.Admin.ACC',
  APPAREL: 'App.Aurora.VML.Admin.AP',
  FOOTWEAR: 'App.Aurora.VML.Admin.FW',
};
export const general_user_icons_mapping = {
  ACCESSORIES: 'WorkoutEquipment',
  APPAREL: 'MuscleGroup',
  FOOTWEAR: 'Run',
};
