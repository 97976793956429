import { Text, Button, Icon, Popover } from '@nike/eds';
import { useEffect, useState } from 'react';
import './styles/color_window_pebble_range.css';

export function ColorWindowPebbleRange({ material, show_details }) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverText, setPopoverText] = useState('');
  const [popoverLink, setPopoverLink] = useState('');
  const [showColorWindowPebbleRange, setColorWindowPebbleRange] = useState(false);
  const [hoverTimeoutId, setHoverTimeoutId] = useState(null);

  useEffect(() => {
    getColorWindowPebbleRange(material);
  });

  const getColorWindowPebbleRange = material => {
    let text = null;
    let link = null;
    const tumbled =
      material?.material?.finishProcess &&
      material?.material?.finishProcess.find(process =>
        process?.toLowerCase()?.includes('tumbled')
      );

    let grainLeatherType = material?.material?.grainLeatherType;
    let grainLeatherSubType = material?.material?.grainLeatherSubType;
    let splitLeatherType = material?.material?.splitLeatherType;

    if (grainLeatherType === 'Full Grain' && grainLeatherSubType === 'Pigmented' && tumbled) {
      link = 'https://nike.box.com/s/xohubqlljmd40700ts4906s12y0wi047';
      text = 'Pebble Range Required';
    } else if (
      grainLeatherType === 'Full Grain' &&
      grainLeatherSubType === 'Semi-aniline' &&
      tumbled
    ) {
      link = 'https://nike.box.com/s/hvdq7ucngdyubtp3uerdkjoc1fob2hvq';
      text = 'Pebble Range Required';
    } else if (grainLeatherType === 'Full Grain' && grainLeatherSubType === 'Nubuck' && tumbled) {
      link = 'https://nike.box.com/s/vlk0vmumufe1gd14wdm7pnco2c3uo9s4';
      text = 'Color Window & Pebble Range Required';
    } else if (grainLeatherType === 'Full Grain' && grainLeatherSubType === 'Nubuck') {
      link = 'https://nike.box.com/s/vlk0vmumufe1gd14wdm7pnco2c3uo9s4';
      text = 'Color Window Required';
    } else if (grainLeatherType === 'Full Grain' && grainLeatherSubType === 'Aniline' && tumbled) {
      link = 'https://nike.box.com/s/nja1crc5jsmon7j5ydyaco9ur1niw1b9';
      text = 'Color Window & Pebble Range Required';
    } else if (grainLeatherType === 'Full Grain' && grainLeatherSubType === 'Aniline') {
      link = 'https://nike.box.com/s/nja1crc5jsmon7j5ydyaco9ur1niw1b9';
      text = 'Color Window Required';
    } else if (splitLeatherType === 'Suede') {
      link = 'https://nike.box.com/s/r4pxe3s9vu715w44if3c2a3udcxlvsze';
      text = 'Color Window Required';
    }

    setPopoverText(text);
    setPopoverLink(link);

    if (text) {
      setColorWindowPebbleRange(true);
    } else {
      setColorWindowPebbleRange(false);
    }
  };

  const slots = {
    headerSlot: (
      <div className="eds-grid center-content">
        <Text as={'p'} font="subtitle-2">
          {popoverText}
        </Text>
      </div>
    ),
    bodySlot: (
      <Text color="grey-2" style={{ minWidth: '160px' }} as={'p'} font="body-3">
        See process details and more information{' '}
        <a href={popoverLink} target="_blank" rel="noreferrer" className="pebble-link">
          here.
        </a>
      </Text>
    ),
  };

  return (
    <div>
      {showColorWindowPebbleRange && show_details ? (
        <div className="pebble-range__details">
          <Text font="legal-1">{popoverText?.toUpperCase()}</Text>
          <Popover
            isDark
            placement={'right'}
            isOpen={isPopoverVisible}
            onClickOutside={() => setIsPopoverVisible(false)}
            headerSlot={slots.headerSlot}
            bodySlot={slots.bodySlot}
          >
            <Button
              onClick={() => [
                getColorWindowPebbleRange(material),
                setIsPopoverVisible(!isPopoverVisible),
              ]}
              variant={'ghost'}
            >
              <Text font="legal-1" className="eds-link--hover">
                See Details
              </Text>
            </Button>
          </Popover>
        </div>
      ) : (
        ''
      )}
      <div
        onMouseEnter={() => {
          getColorWindowPebbleRange(material);
          // Clear any existing timeout
          clearTimeout(hoverTimeoutId);
          setIsPopoverVisible(true);
        }}
        onMouseLeave={() => {
          // Set a new timeout
          setHoverTimeoutId(setTimeout(() => setIsPopoverVisible(false), 500));
        }}
      >
        {showColorWindowPebbleRange && !show_details && (
          <Popover
            isDark
            placement={'right'}
            isOpen={isPopoverVisible}
            onClickOutside={() => setIsPopoverVisible(false)}
            headerSlot={slots.headerSlot}
            bodySlot={slots.bodySlot}
          >
            <Button className="color-window-pebble-range" variant={'ghost'}>
              <Icon name="InfoCircle" />
            </Button>
          </Popover>
        )}
      </div>
    </div>
  );
}
