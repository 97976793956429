import { TextField, Icon } from '@nike/eds';

export function BoardName({
  palette_summary,
  is_admin,
  palette_name,
  set_palette_name,
  board_name_ref,
  has_user_permissions,
}) {
  return (
    <div className="eds-spacing--mb-8 manage-board__title" ref={board_name_ref}>
      <TextField
        disabled={!has_user_permissions}
        placeholder={palette_summary?.name || 'Board Name'}
        afterSlot={<Icon name="Edit" />}
        message=" "
        readOnly={!is_admin}
        value={palette_name || ''}
        onChange={event => set_palette_name(event?.target?.value)}
        // hideLabel
      />
    </div>
  );
}
