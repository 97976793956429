import { Button, Text, IconButton } from '@nike/eds';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './compare_materials.css';
import { AddToBoardModal } from '../add_to_board/add_to_board_modal';
import { useModalStore } from '../../hooks/use_modal';
import { useCompareMaterialsStore } from '../../hooks/use_compare_materials';
import useColorScheme from '../../hooks/use_color_scheme';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';
import CompareMaterialsDnd from './compare_materials_dnd';
import { arrayMove } from '@dnd-kit/sortable';

export function CompareMaterials({
  materials,
  highlight_differences,
  highlight_similarities,
  set_selected_materials,
  selected_materials,
  remove_material,
}) {
  return (
    <div className="compare-materials">
      <CompareMaterialsTable
        materials={materials}
        selected_materials={selected_materials}
        set_selected_materials={set_selected_materials}
        highlight_differences={highlight_differences}
        highlight_similarities={highlight_similarities}
        remove_material={remove_material}
      />
    </div>
  );
}

const CompareMaterialsTable = ({
  materials,
  highlight_differences,
  highlight_similarities,
  selected_materials,
  set_selected_materials,
  remove_material,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [active_card, set_active_card] = useState();
  // eslint-disable-next-line no-unused-vars
  const [active_material, set_active_material] = useState({});
  const [show_material, set_show_material] = useState(false);
  const [is_add_to_board_open, set_is_add_to_board_open] = useState(false);
  const location = useLocation();
  const { set_active_modal, set_modal_data } = useModalStore();
  const { table_data } = useDisplaySettingsStore();
  const { appearance } = useColorScheme();
  // TODO: figure out how to make the rows sticky based on an ID like what's below
  const { pinned_row_ids, update_pinned_row_ids, get_pinned_rows, get_unpinned_rows } =
    useCompareMaterialsStore();

  const [pinned_rows, set_pinned_rows] = useState(get_pinned_rows());
  const [unpinned_rows, set_unpinned_rows] = useState(get_unpinned_rows());

  useEffect(() => {
    set_pinned_rows(get_pinned_rows());
    set_unpinned_rows(get_unpinned_rows());
  }, [pinned_row_ids, get_pinned_rows, get_unpinned_rows, table_data]);

  const handle_pin_row = row => {
    update_pinned_row_ids([...pinned_row_ids, row.id]);
  };

  const handle_unpin_row = row => {
    update_pinned_row_ids(pinned_row_ids.filter(id => id !== row.id));
  };

  function check_difference(material_to_compare, compared_to, column_index) {
    const are_equal = material_to_compare === compared_to ? true : false;

    if (column_index === 0 || material_to_compare === undefined) {
      return '';
    } else if (are_equal && highlight_similarities) {
      return 'same';
    } else if (highlight_differences && !are_equal) {
      return 'different';
    }
  }

  function deselect_all_items() {
    set_selected_materials([]);
  }

  function select_all_items() {
    let selected = materials.filter(material => material.material).map(material => material.id);
    set_selected_materials([...selected]);
  }

  const search = location.search;
  const [column_order, set_column_order] = useState(search.split('=')[1].split(','));
  function reorder_columns(drop_target, drag_target) {
    set_column_order(items => {
      const drag_target_index = column_order.indexOf(drag_target.id);
      const drop_target_index = column_order.indexOf(drop_target.id);

      return arrayMove(items, drag_target_index, drop_target_index);
    });
  }

  const column_width = '256px';

  const materialsMap = new Map(materials.map(m => [m.id, m]));

  return (
    <div
      className={`compare-material__table ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
    >
      <div className="eds-flex compare-materials-dnd-container">
        <div className="compare-materials__button-container" style={{ width: column_width }}>
          <Button size="small" className="eds-spacing--mb-16" onClick={select_all_items}>
            Select All
          </Button>
          <Button size="small" variant="secondary" onClick={deselect_all_items}>
            Deselect
          </Button>
        </div>
        <CompareMaterialsDnd
          key={pinned_rows?.toString()}
          materials={materials}
          selected_materials={selected_materials}
          set_selected_materials={set_selected_materials}
          active_card={active_card}
          set_active_card={set_active_card}
          set_active_material={set_active_material}
          set_is_add_to_board_open={set_is_add_to_board_open}
          remove_material={remove_material}
          set_active_modal={set_active_modal}
          set_modal_data={set_modal_data}
          check_difference={check_difference}
          column_order={column_order}
          column_width={column_width}
          reorder_columns={reorder_columns}
          show_material={show_material}
          set_show_material={set_show_material}
        />
      </div>
      <div className="pinned-rows-container">
        {pinned_rows.map((row, row_index) => (
          <div key={`pinned_row_${row.id}`} className="pinned-row eds-flex">
            <div key={`pinned-row-${row_index}`} style={{ width: column_width }}>
              <div className="eds-spacing--pl-12 eds-type--subtitle-1">
                {row.display_name}
                <IconButton
                  icon={<>📌</>}
                  variant="ghost"
                  className="pin-icon pinned"
                  onClick={() => handle_unpin_row(row)}
                  disableRipple={true}
                />
              </div>
            </div>
            {materials?.map((material, material_index) => {
              // eslint-disable-next-line no-eval
              const get_material_data = eval(row?.get_material_data);

              return (
                <div
                  style={{
                    order: column_order.indexOf(material?.id),
                    width: column_width,
                  }}
                  key={`${material_index}_${row_index}`}
                  className={`${check_difference(
                    get_material_data(materialsMap.get(material?.id)),
                    get_material_data(materialsMap.get(column_order[0])),
                    column_order.indexOf(material?.id)
                  )} eds-spacing--py-12 eds-spacing--px-24 row-column`}
                >
                  {<Text font="body-2"> {get_material_data(material) || '--'}</Text>}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {unpinned_rows.map((row, row_index) => {
        if (row?.checked) {
          return (
            <div className="scrollable-row" key={`row_${row.id}_${row?.checked}`}>
              <div className="eds-flex unpinned-row">
                <div key={`unpinned-row-${row_index}`} style={{ width: column_width }}>
                  <div className="eds-type--subtitle-1" style={{ paddingLeft: '12px' }}>
                    {row.display_name}
                    <IconButton
                      icon={<>📌</>}
                      variant="ghost"
                      className="pin-icon unpinned-icon"
                      onClick={() => handle_pin_row(row)}
                      disableRipple={true}
                    />
                  </div>
                </div>
                {materials?.map((material, material_index) => {
                  // eslint-disable-next-line no-eval
                  const get_material_data = eval(row?.get_material_data);

                  return (
                    <div
                      style={{
                        order: column_order.indexOf(material?.id),
                        width: column_width,
                      }}
                      key={`${material_index}_${row_index}`}
                      className={`${check_difference(
                        get_material_data(materialsMap.get(material?.id)),
                        get_material_data(materialsMap.get(column_order[0])),
                        column_order.indexOf(material?.id)
                      )} eds-spacing--py-12 eds-spacing--px-24 row-column`}
                    >
                      {<Text font="body-2"> {get_material_data(material) || '--'}</Text>}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return undefined;
        }
      })}
      {/* TODO: think about refactoring where this lives so we can just have one instance of it instead of anywhere this actions is being used... */}
      <AddToBoardModal
        is_open={is_add_to_board_open}
        set_is_open={set_is_add_to_board_open}
        material={active_material}
      />
    </div>
  );
};
