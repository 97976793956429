export function get_sorted_assets(assets) {
  const assets_in_order_3d = get_3d_sorted_assets(assets);
  const assets_in_order_2d = get_2d_sorted_assets(assets);

  const combined = [...assets_in_order_2d, ...assets_in_order_3d];

  return combined;
}
export function get_2d_sorted_assets(assets) {
  const view_types = ['Face', 'Back', 'Fold/Drape'];

  const assets_2d = assets?.filter(
    asset => asset?.assetClass === 'material2d' && asset?.assetMetadata?.subClass === 'vml'
  );

  const assets_in_order = assets_2d?.sort((asset_a, asset_b) => {
    // get asset_a prio order and artifact image
    let sub_class_index_a = view_types?.findIndex(
      viewType => asset_a?.assetMetadata?.viewType === viewType
    );
    sub_class_index_a = sub_class_index_a === -1 ? 9999 : sub_class_index_a;
    const asset_a_image = get_artifact_image(asset_a);

    // get asset_b prio order and artifact image
    let sub_class_index_b = view_types?.findIndex(
      viewType => asset_b?.assetMetadata?.viewType === viewType
    );
    sub_class_index_b = sub_class_index_b === -1 ? 9999 : sub_class_index_b;
    const asset_b_image = get_artifact_image(asset_b);

    if (asset_a_image && asset_b_image) {
      if (sub_class_index_a < sub_class_index_b) {
        return -1;
      } else {
        return 1;
      }
    } else if (asset_a_image) {
      return -1;
    } else {
      return 1;
    }
  });

  return assets_in_order;
}

export function get_3d_sorted_assets(assets) {
  const sub_classes = [
    'mayavray',
    'axf',
    'substance',
    'substancevizoo',
    'apparelzfab',
    'clo',
    'apparelu3m',
    'airmi',
  ];

  const assets_3d = assets?.filter(
    asset =>
      (asset?.assetClass === 'material3d' ||
        asset?.assetClass === 'trim3d' ||
        asset?.assetClass === 'airbag3d') &&
      sub_classes.includes(asset?.assetMetadata?.subClass)
  );

  const assets_in_order = assets_3d?.sort((asset_a, asset_b) => {
    // get asset_a prio order and artifact image
    let sub_class_index_a = sub_classes?.findIndex(
      subClass => asset_a?.assetMetadata?.subClass === subClass
    );
    sub_class_index_a = sub_class_index_a === -1 ? 9999 : sub_class_index_a;
    const asset_a_image = get_artifact_image(asset_a);

    // get asset_b prio order and artifact image
    let sub_class_index_b = sub_classes?.findIndex(
      subClass => asset_b?.assetMetadata?.subClass === subClass
    );
    sub_class_index_b = sub_class_index_b === -1 ? 9999 : sub_class_index_b;
    const asset_b_image = get_artifact_image(asset_b);

    if (asset_a_image && asset_b_image) {
      if (sub_class_index_a < sub_class_index_b) {
        return -1;
      } else {
        return 1;
      }
    } else if (asset_a_image) {
      return -1;
    } else {
      return 1;
    }
  });

  return assets_in_order;
}

export function get_optimal_3d_image(assets) {
  try {
    const assets_in_order = get_3d_sorted_assets(assets);
    const optimal_image = get_artifact_image(assets_in_order[0]) || assets_in_order[0]?.thumbnail;

    return optimal_image;
  } catch (error) {
    // console.warn(error.message);
  }
}

export function get_artifact_image(asset) {
  try {
    switch (asset?.assetMetadata?.subClass) {
      case 'mayavray':
        return asset?.artifactReferences?.find(
          artifact =>
            artifact?.metadata?.viewType === 'Thumbnail' &&
            artifact?.metadata?.renderEngine === 'VRay'
        );
      case 'axf':
      case 'substance':
        return asset?.artifactReferences?.find(artifact => artifact?.metadata?.surface === 'plane');
      default:
        return asset?.artifactReferences?.find(
          artifact => artifact?.metadata?.viewType === 'Thumbnail'
        );
    }
  } catch (error) {
    console.warn(error.message);

    return undefined;
  }
}
