import { useInfiniteQuery } from '@tanstack/react-query';
import { http_client } from '../utils/fetch';
import config from '../config';
import { get_sorted_assets } from '../utils/get_optimal_3d_image';

export async function getPaletteSummaries(params) {
  const url = new URL(config.apis.search.palette_summaries, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params,
  });

  return res?.data;
}

export async function getPaletteEntries(params, id) {
  const url = new URL(`${config.apis.search.palette_entries}/${id}/`, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params,
  });

  return res?.data;
}

export function usePaletteEntriesHook(params) {
  return useInfiniteQuery({
    queryKey: ['palette_entry', params],
    queryFn: async ({ pageParam = 0 }) => await fetch_palette({ ...params, from: pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'

      return next_page;
    },
  });
}

export function usePalettesHook(params) {
  return useInfiniteQuery({
    queryKey: ['all_palettes', params],
    queryFn: async ({ pageParam = 0 }) => await fetch_palettes({ ...params, from: pageParam }),
    getNextPageParam: last_page => {
      const next_page =
        last_page?.pagination?.from + last_page?.pagination?.size < last_page?.pagination?.total
          ? last_page?.pagination?.from + last_page?.pagination?.size
          : undefined; // If there is not a next page, getNextpageParam will return undefined and the hasNextPage boolean will be set to 'false'

      return next_page;
    },
  });
}

export async function fetch_palettes(params) {
  const summaries = await getPaletteSummaries({ ...params });
  let palette_with_assets = [];

  // for each palette summary grab the palette entry so we can see the assets in the palettes page
  for (const palette of summaries.results) {
    // grab only 10 entries since that's all we're going to show in the palette page inside each palette card
    // TODO: figure out if there's a better way to make this request so we can get 10 entries w/ assets
    const palette_entry = await getPaletteEntries(
      {
        size: 10,
        hasAtLeastOneAsset: true,
        paletteClass: 'material',
      },
      palette?.paletteId
    );
    // sometimes there's empty entries and some bad data in test so we're going to request more to get better results
    const palette_entry_assets = palette_entry?.results
      ?.map(entry => {
        const assets = get_sorted_assets(entry?.assets);

        return {
          ...entry,
          assets: assets,
        };
      })
      .sort((entry_a, entry_b) => {
        if (entry_a?.assets?.length > entry_b?.assets?.length) {
          return -1;
        } else {
          return 1;
        }
      });

    palette_with_assets.push({
      ...palette,
      entries: palette_entry_assets,
    });
  }

  return { ...summaries, results: palette_with_assets };
}

export async function fetch_palette(params) {
  const { palette_id, ...rest_of_params } = params;
  const palette_entry = await getPaletteEntries(
    {
      paletteClass: 'material',
      ...rest_of_params,
    },
    palette_id
  );

  return palette_entry;
}

export const fetch_all_palette_entries = async params => {
  let from = 0;
  const size = 50;
  const initialResult = await fetch_palette({ ...params, from, size });
  let materials = initialResult.results;
  let pagination = initialResult.pagination;
  from += size;

  while (from < pagination.total) {
    const result = await fetch_palette({ ...params, from, size });
    materials = [...materials, ...result.results];
    pagination = result.pagination;
    from += size;
  }

  return materials;
};
