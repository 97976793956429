import { useQuery } from '@tanstack/react-query';
import config from '../config';
import http_client from '../utils/fetch';

export function useUserLookup(lookup) {
  return useQuery({
    queryKey: ['user_lookup', lookup],
    queryFn: async () => {
      if (lookup?.length > 1) {
        const data = await fetch_users(lookup);
        return data?.results?.map(result => ({
          ...result,
          value: result?.id,
          label: result?.id,
        }));
      } else {
        return [];
      }
    },
    enabled: lookup?.length ? true : false, // only make this call if there's ids in the tote
  });
}

export function useUserPreferences(params) {
  return useQuery({
    queryKey: ['user_preferences', params],
    queryFn: async params => await fetch_preferences(params),
  });
}

export async function fetch_preferences(user) {
  const url = new URL(config.apis.user.preferences, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: {},
  });

  return res?.data;
}

export function set_preferences(key, preferences) {
  const url = new URL(config.apis.user.preferences, config.api_base).toString();
  const new_preferences = JSON.stringify(preferences);
  return http_client({
    url: `${url}/${key}`,
    method: 'post',
    data: JSON.stringify(new_preferences),
  });
}

export async function fetch_users(lookup) {
  const url = new URL(config.apis.user.lookup, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: {
      startsWith: lookup,
    },
  });

  return res?.data;
}

export async function fetch_groups(group) {
  let results = [];
  const url = new URL(`${config.apis.user.groups}/${group}/members`, config.api_base).toString();
  const res = await http_client({
    url,
    method: 'get',
    params: {
      anchor: '',
    },
  });
  let anchor = res?.data?.pagination?.anchor;
  let fetched_all = false;

  // results.push(res?.data?.results);
  results = [...res?.data?.results];
  // console.log({ anchor, fetched_all });
  if (anchor?.length) {
    while (!fetched_all) {
      const new_res = await http_client({
        url,
        method: 'get',
        params: {
          anchor: anchor,
        },
      });

      if (new_res?.data?.pagination?.anchor?.length) {
        anchor = new_res?.data?.pagination?.anchor;
      } else {
        anchor = undefined;
        fetched_all = true;
      }

      results = [...results, ...new_res?.data?.results];
      // results?.push(new_res?.data?.results);
    }
  } else {
    fetched_all = true;
  }
  // console.log({ res, results });

  return results;
}
