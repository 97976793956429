import { TextField, Icon, Select } from '@nike/eds';
import { components } from 'react-select';

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <Icon name="Search" color="var(--eds-color-text-secondary)" className="eds-spacing--mr-8" />{' '}
    {children}
  </components.Control>
);

export function AddBoardSearch({
  board_name,
  set_board_name,
  board_creator,
  set_board_creator,
  board_create_options,
}) {
  return (
    <div className="add-board__search eds-spacing--mb-24">
      <div className="add-board__search-name">
        <TextField
          label="Search by board name"
          value={board_name}
          placeholder="Search"
          beforeSlot={
            <div className="add-board__search-placeholder">
              <Icon name="Search" />
            </div>
          }
          onChange={event => set_board_name(event?.target?.value)}
        ></TextField>
      </div>
      <div className="add-board__search-name">
        <Select
          label="Search by board Creator"
          value={board_creator}
          options={board_create_options}
          placeholder="Search"
          components={{ Control }}
          isClearable
          onChange={event => set_board_creator(event)}
        ></Select>
      </div>
    </div>
  );
}
