import { useRef, useEffect } from 'react';
import { Text, Card, Skeleton, Spinner } from '@nike/eds';
import InfiniteScroll from 'react-infinite-scroller';
import useColorScheme from '../../hooks/use_color_scheme';
import { useDragSelect } from '../drag_select';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';
import { DNDGrid } from './dnd_grid';

export function Grid({
  status,
  data,
  card,
  card_size,
  gap = 24,
  fetchNextPage = () => false,
  hasNextPage,
  multi_select,
  select_materials,
  selected_materials,
  set_data,
  dnd,
}) {
  const { appearance } = useColorScheme();
  const { sizes, size } = useDisplaySettingsStore();
  const size_in_pixels = card_size || sizes[size];
  const ds = useDragSelect();
  const card_element = useRef(null);

  function select_material(material_id) {
    const material_card_element = document.getElementById(`${material_id}`);
    ds.addSelection(material_card_element);
    ds.publish('DS:end', { items: [{ id: material_id }] });
  }
  // adding a selectable element
  useEffect(() => {
    const element = card_element.current;

    if (!element || !ds) {
      return;
    }

    ds.addSelectables(element);
  }, [ds, card_element]);

  useEffect(() => {
    if (ds) {
      // set initial state for drag select on load
      const addSelectedMaterialsToDS = (selected_materials, attempt = 0) => {
        const material_card_elements = selected_materials?.map(material_id => {
          const material_card_element = document.getElementById(`${material_id}`);
          return material_card_element;
        });

        if (material_card_elements?.includes(undefined) || material_card_elements?.includes(null)) {
          if (attempt < 5) {
            setTimeout(() => {
              addSelectedMaterialsToDS(selected_materials, attempt + 1);
            }, 50);
          }
        } else {
          ds.setSelection(material_card_elements, false);
        }
      };

      addSelectedMaterialsToDS(selected_materials);
    }
  }, [ds, selected_materials]);

  // subscribing to a callback
  useEffect(() => {
    const id = ds?.subscribe('DS:end', e => {
      const ids = [...e?.items?.map(item => item?.id)];
      select_materials(ids);
    });

    return () => ds?.unsubscribe('DS:end', null, id);
  }, [ds, select_materials, selected_materials]);

  // useEffect(() => {
  //   if (!multi_select && ds) {
  //     ds.clearSelection();
  //   }
  // }, [multi_select, ds]);

  if (status === 'error') {
    return <div data-testid="grid-error">Error!</div>;
  }

  return (
    <div className="grid" id="grid">
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        useWindow={false}
        loader={
          <div className="infinite-loading-spinner" key={0}>
            <Spinner size="large" />
          </div>
        }
      >
        {status === 'loading' ? (
          <GridLoader size_in_pixels={size_in_pixels} gap={gap} />
        ) : (
          <>
            {dnd && data.length && (
              <DNDGrid
                appearance={appearance}
                gap={gap}
                size_in_pixels={size_in_pixels}
                data={data}
                card={card}
                card_element={card_element}
                select_material={select_material}
                set_data={set_data}
              />
            )}
            {!dnd && data.length && (
              <StaticGrid
                appearance={appearance}
                gap={gap}
                size_in_pixels={size_in_pixels}
                data={data}
                card={card}
                card_element={card_element}
                select_material={select_material}
              />
            )}
          </>
        )}
      </InfiniteScroll>
    </div>
  );
}

function StaticGrid({
  appearance,
  gap,
  size_in_pixels,
  data,
  card,
  card_element,
  select_material,
}) {
  return (
    <ol
      className={`eds-spacing--mt-16 eds-spacing--mb-24 material-list ${
        appearance === 'dark' ? 'eds--dark' : 'eds--light'
      }`}
      data-testid="grid-list"
      style={{
        gap,
        gridTemplateColumns: `repeat(auto-fill, minmax(${size_in_pixels}px, 1fr))`,
      }}
    >
      {data?.map((item, material_index) => {
        if (card) {
          return card(item, card_element, select_material);
        } else {
          return (
            <Card
              key={material_index}
              data-testid={item.id}
              padding={16}
              style={{ width: 184, height: 280 }}
            >
              <div>
                <Text font="body-1">{item.id}</Text>
              </div>
            </Card>
          );
        }
      })}
    </ol>
  );
}

export function GridLoader({ gap, size_in_pixels }) {
  const { appearance } = useColorScheme();
  const rows = Array.from({ length: 36 });

  return (
    <div className="grid-loader" data-testid="grid-loader">
      <ol
        className={`eds-spacing--mt-16 eds-spacing--mb-24 material-list ${
          appearance === 'dark' ? 'eds--dark' : 'eds--light'
        }`}
        style={{
          gap,
          gridTemplateColumns: `repeat(auto-fill, minmax(${size_in_pixels}px, 1fr))`,
        }}
      >
        {rows.map((item, index) => {
          return (
            <li key={index} className="grid-item__loader">
              <Skeleton />
            </li>
          );
        })}
      </ol>
    </div>
  );
}
