import { Checkbox, Icon, TableCell, Text, Spinner } from '@nike/eds';

function getStatusColor(status) {
  if (status === 'Ready to upload.') {
    return 'eds-color--success';
  } else if (status === 'File already exists.') {
    return 'eds-color--yellow-60';
  }

  return 'eds-color--danger';
}

export default function FileRow(props) {
  return (
    <tr key={`${props.id}`}>
      <TableCell>
        <Checkbox checked={props.selected} onChange={e => props.onSelect(e.target.checked)} />
      </TableCell>
      <TableCell>
        <div>
          {props.isValid ? <Text as="p">{props.materialName}</Text> : null}
          <Text as="p" color="text-secondary">
            {props.id}
          </Text>
        </div>
      </TableCell>
      <TableCell>{props.isValid ? props.engine : null}</TableCell>
      <TableCell>{props.isValid ? props.type : null}</TableCell>
      <TableCell>{props.isValid ? props.subType : null}</TableCell>
      <TableCell>{props.isValid ? props.view : null}</TableCell>
      <TableCell>{props.isValid ? (props.existingMaterial ? 'Existing' : 'New') : null}</TableCell>
      <TableCell>
        <div>
          {props.isUploading ? (
            <Spinner size="medium" />
          ) : (
            <Text as="p" className={getStatusColor(props.uploadStatus)}>
              {props.uploadStatus}
            </Text>
          )}
        </div>
      </TableCell>
      <TableCell>
        <Icon style={{ cursor: 'pointer' }} name="Delete" size="m" onClick={props.onDelete} />
      </TableCell>
    </tr>
  );
}
