import { useEffect, useState } from 'react';
import { Button, ButtonGroup, TextField } from '@nike/eds';
import './min_max_range_input.css';

export function MinMaxRangeInput({
  preLabel,
  postLabel,
  message,
  value,
  onChange,
  placeholder,
}) {
  const [minValue, setMinValue] = useState(value?.minValue || '');
  const [maxValue, setMaxValue] = useState(value?.maxValue || '');
  const re = /^[0-9\b]+$/;

  useEffect(() => {
    setMinValue(value?.minValue || '');
    setMaxValue(value?.maxValue || '');
  }, [value]);

  const onMinChange = e => {
    if (e.target.value === '' || re.test(e.target.value)) {
      setMinValue(e.target.value);
    }
  };
  const onMaxChange = e => {
    if (e.target.value === '' || re.test(e.target.value)) {
      setMaxValue(e.target.value);
    }
  };

  const onApplyClick = () => {
    onChange({ minValue, maxValue });
  };

  return (
    <>
      <div className="input-row ">
        <TextField
          id="test"
          value={minValue}
          onChange={onMinChange}
          errorMessage="Error Message"
          beforeSlot={preLabel}
          afterSlot={postLabel}
          hasErrors={minValue.length > 10}
          placeholder={placeholder === 'date' ? 'Earliest' : 'Minimum'}
        />
        <TextField
          id="test"
          value={maxValue}
          onChange={onMaxChange}
          errorMessage="Max character count exceeded"
          beforeSlot={preLabel}
          afterSlot={postLabel}
          hasErrors={maxValue.length > 10}
          placeholder={placeholder === 'date' ? 'Latest' : 'Maximum'}
        />
      </div>
      <div className="eds-input-message-group input-row" id="element-id-meta">
        <p className="eds-color--text-primary eds-input-message-group__message">{message}</p>
      </div>
      <ButtonGroup className="min-max-input__button-container">
        <Button size="small" disabled={minValue || maxValue ? false : true} onClick={onApplyClick}>
          Apply
        </Button>
      </ButtonGroup>
    </>
  );
}
