import { useOktaAuth, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useEffect, memo } from 'react';
import { Spinner } from '@nike/eds';
import { v4 as uuidv4 } from 'uuid';

import { okta_auth } from './okta_auth';
import { use_auth_store } from '../hooks/use_auth';
import { use_engine_store } from '../hooks/use_engine';
import useColorScheme from '../hooks/use_color_scheme';
import { Header } from '../components/header';
import { useNavigate } from 'react-router-dom';
import { AccessDenied } from '../components/common/access_denied';

/**
 * Group IDs for checking authorization
 */
export const AuthGroups = {
  ACC_USER: 'App.Aurora.VML.Users.ACC',
  FW_USER: 'App.Aurora.VML.Users.FW',
  FW_ADMIN: 'App.Aurora.VML.Admin.FW',
  AP_USER: 'App.Aurora.VML.Users.AP',
  AP_ADMIN: 'App.Aurora.VML.Admin.AP',
};

const convert_group_to_engines = groups => {
  const engines = [];

  for (const group of groups) {
    // TODO: address the whole AP admins having access to ACC
    if (group.includes('AP')) {
      if (!engines.includes('APPAREL')) {
        engines.push('APPAREL');
      }
      // is apparel admin user so we need to make sure to allow them to
      // access the accessories engine
      if (group.includes('Admin')) {
        if (!engines.includes('ACCESSORIES')) {
          engines.push('ACCESSORIES');
        }
      }
    } else if (group.includes('ACC')) {
      if (!engines.includes('ACCESSORIES')) {
        engines.push('ACCESSORIES');
      }
    } else if (group.includes('FW')) {
      if (!engines.includes('FOOTWEAR')) {
        engines.push('FOOTWEAR');
      }
    }
  }

  return engines;
};

/**
 * Generic component for enforcing authentication before rendering child elements
 * Replaces SecureRoute (only compatible with react-router-dom@5)
 * @see{@link https://github.com/okta/okta-react/issues/178#issuecomment-1150407182}
 */

export const RequireOktaAuth = ({ children, allowGroups }) => {
  const navigate = useNavigate();
  const { user } = use_auth_store.getState();

  const restore_original_uri = async (okta_auth, original_uri) => {
    const relative_url = toRelativeUrl(original_uri ?? '/', window.location.origin);

    if (!user?.token?.length) {
      okta_auth?.setOriginalUri(relative_url);
      okta_auth?.signInWithRedirect();
    }

    navigate(relative_url);
  };

  return (
    <Security oktaAuth={okta_auth} restoreOriginalUri={restore_original_uri}>
      <RequireAuth children={children} allowGroups={allowGroups} />
    </Security>
  );
};

export const RequireAuth = memo(({ children, allowGroups }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { user, set_user } = use_auth_store();
  const { active_engine, set_engines, set_active_engine } = use_engine_store();
  const dark = useColorScheme('dark');
  const claims = authState?.idToken?.claims;

  useEffect(() => {
    if (authState && authState.isAuthenticated && !user?.token) {
      const engines = convert_group_to_engines(claims.groups);
      set_user({
        name: claims?.name,
        sub: claims?.sub,
        preferred_username: claims?.preferred_username,
        groups: claims?.groups,
        token: authState?.accessToken?.accessToken,
        session: uuidv4(), // using to track session data for analytics
      });
      set_engines(engines);

      if (!active_engine?.length) {
        set_active_engine(engines?.[0]);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);

      oktaAuth?.setOriginalUri(originalUri);
      oktaAuth?.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <Authenticating dark={dark} />;
  }

  // Check user groups if specified for this element
  if (allowGroups?.length) {
    const tokenGroups = authState?.accessToken?.claims['groups'];
    const isUserMemberOfAllowedGroup = tokenGroups?.some(group => {
      return allowGroups.includes(group);
    });

    if (!isUserMemberOfAllowedGroup) {
      return <AccessDenied />;
    }
  }

  return children;
});

function Authenticating() {
  const { appearance } = useColorScheme();

  return (
    <div
      className={`page ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}
      data-testid="page"
    >
      <div className="page-header">
        <Header
          title="Material Library"
          icon_row={{ sort: false, select: false, display_settings: false }}
          header_bottom={() => undefined}
          show_search={false}
          show_tote={false}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Spinner size="large" />
      </div>
    </div>
  );
}
